import { useListEnvironments, type PlainEnvironment } from "@/queries/environment-queries";
import { useEffect, useState } from "react";
import { useListProjects, type PlainProject } from "@/queries/project-queries";
import { getRepoUrl } from "@/hooks/use-grouped-environments";

export type EnvironmentsGroup = {
    environments: PlainEnvironment[];
    repoFullName?: string;
    projectId?: string;
    project?: PlainProject;
    // Project data is loaded, but referenced project is missing
    projectMissing?: boolean;
};

export function useEnvironmentsByProjects() {
    const { data: environmentsData, isLoading: isLoadingWs, isPending: isPendingWs } = useListEnvironments();
    const { data: projectsData, isLoading: isLoadingRunners } = useListProjects();

    const [initialized, setInitialized] = useState<boolean>(false);
    const [data, setData] = useState<EnvironmentsGroup[]>([]);

    useEffect(() => {
        const environments = environmentsData?.environments;
        if (environments === undefined) {
            return;
        }

        const projectsMap = projectsData && new Map(projectsData?.projects.map((project) => [project.id, project]));
        const map = new Map<string, EnvironmentsGroup>();

        for (const environment of environments) {
            const projectId = environment.metadata?.projectId;
            const repoUrl = getRepoUrl(environment);
            if (projectId) {
                const entry = map.get(projectId) || { projectId, environments: [] };
                if (projectsMap) {
                    const project = projectsMap.get(projectId);
                    if (project) {
                        entry.project = project;
                    } else {
                        entry.projectMissing = true;
                    }
                }
                entry.environments.push(environment);
                map.set(projectId, entry);
                continue;
            }
            if (repoUrl) {
                const repoFullName = `${repoUrl.account}/${repoUrl.repo}`;
                const entry = map.get(repoFullName) || { repoFullName, environments: [] };
                entry.environments.push(environment);
                map.set(repoFullName, entry);
            }
        }

        const data = Array.from(map.values()).sort(orderById);
        setData(data);
        setInitialized(true);
    }, [environmentsData?.environments, projectsData]);

    return {
        isLoading: !initialized || isPendingWs || isLoadingWs || isLoadingRunners,
        data,
    };
}

function orderById(a: EnvironmentsGroup, b: EnvironmentsGroup): number {
    const toString = (g: EnvironmentsGroup) =>
        `${g.projectId || "x-no-project"}-${!g.projectId ? g.repoFullName : "is-project"}`;

    return toString(a).localeCompare(toString(b));
}
