import { useCallback, useId, useState, type FC, type FormEvent } from "react";
import { Label } from "@/components/podkit/forms/Label";
import { Input } from "@/components/podkit/forms/Input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";

import { useOrganization } from "@/queries/organization-queries";
import { useListRunners } from "@/queries/runner-queries";
import { RunnerKind, type Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import type { PlainMessage } from "@bufbuild/protobuf";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";

const regionsList = [
    { label: "EU (Frankfurt)", value: "eu-central-1" },
    { label: "EU (Ireland)", value: "eu-west-1" },
    { label: "US East (N. Virginia)", value: "us-east-1" },
    { label: "US West (N. California)", value: "us-west-1" },
    { label: "US West (Oregon)", value: "us-west-2" },
    { label: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
    { label: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
    { label: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
];

export const NewRunnerForm: FC<
    {
        formId: string;
        onSubmit: (values: { name: string; region: string }) => Promise<void>;
        onDisabledChange: (disabled: boolean) => void;
    } & PropsWithClassName
> = ({ formId, onDisabledChange, className, onSubmit }) => {
    const { data: organization, isLoading: isLoadingOrg, isPending: isPendingOrg } = useOrganization();
    const {
        data: allRunners,
        isLoading: isLoadingRunners,
        isPending: isPendingRunners,
    } = useListRunners({ kind: RunnerKind.REMOTE });

    const inputNameID = useId();
    const inputRegionID = useId();

    const [region, setRegion] = useState<string>(regionsList[0]?.value);
    const [name, setName] = useState<{ value: string; inferred: boolean }>({
        value: defaultName(region, allRunners?.runners || []) || "",
        inferred: true,
    });

    const onNameChange = useCallback(
        (value: string, inferred: boolean) => {
            setName({ value, inferred });
            onDisabledChange(!value);
        },
        [setName, onDisabledChange],
    );

    const handleRegionChange = useCallback(
        (value: string) => {
            setRegion(value);
            // We only update the name if it's an inferred value, of if the name is empty (e.g user cleared the field)
            if (name.inferred || !name.value) {
                const name = defaultName(value, allRunners?.runners || []) || "";
                onNameChange(name, true);
            }
        },
        [name, onNameChange, allRunners],
    );

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (!organization || !name) {
                return;
            }
            await onSubmit({ name: name.value, region });
        },
        [organization, name, region, onSubmit],
    );

    if (isLoadingOrg || isPendingOrg || isLoadingRunners || isPendingRunners) {
        return <></>;
    }

    return (
        <form name={formId} id={formId} onSubmit={handleSubmit}>
            <div className={cn("flex flex-col gap-2", className)}>
                <div className="space-y-1">
                    <Label htmlFor={inputRegionID}>AWS region</Label>
                    <Select name="aws_region" value={region || ""} onValueChange={handleRegionChange}>
                        <SelectTrigger id={inputRegionID} loading={false}>
                            <SelectValue placeholder={"Select a region"}>{region}</SelectValue>
                            <SelectContent>
                                <SelectGroup>
                                    {regionsList.map((region) => (
                                        <SelectItem key={region.value} value={region.value}>
                                            <div className="flex w-full flex-row items-center justify-between pr-10">
                                                <div>{region.label}</div>
                                                <div>{region.value}</div>
                                            </div>
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </SelectTrigger>
                    </Select>
                </div>
                <div className="space-y-1">
                    <Label htmlFor={inputNameID}>Name</Label>
                    <Input
                        id={inputNameID}
                        type="text"
                        name="runner_name"
                        value={name.value || ""}
                        onChange={(e) => onNameChange(e.target.value, false)}
                        className="max-w-full"
                    />
                </div>
            </div>
        </form>
    );
};

function defaultName(region: string, runners: PlainMessage<Runner>[]): string | undefined {
    const label = regionsList.find((r) => r.value === region)?.label;
    if (!label) {
        return;
    }

    const newName = `AWS ${label}`;
    let i = 1;
    const similarNames = runners.filter((r) => r.name?.startsWith(newName)).map((r) => r.name);
    while (similarNames?.includes(`${newName} ${i}`)) {
        i++;
    }
    return `${newName} ${i}`;
}
