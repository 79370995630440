import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconGauge: FC<{ size: Size; completed: number } & PropsWithClassName> = ({
    size,
    className,
    completed,
}) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_14_436)">
                        <mask
                            id="mask0_14_436"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="16"
                            height="16"
                        >
                            <circle
                                cx="8"
                                cy="8"
                                r="7"
                                transform="rotate(-45 8 8)"
                                className="text-content-green"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeMiterlimit="3.13764"
                            />
                        </mask>
                        <g mask="url(#mask0_14_436)">
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="8.68629"
                                    width="8"
                                    height="7"
                                    className={completed >= 1 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="8.68629"
                                    y="9"
                                    width="8"
                                    height="7"
                                    className={completed >= 2 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="-1.31371"
                                    y="9"
                                    width="8"
                                    height="7"
                                    className={completed >= 3 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="-1.31371"
                                    width="8"
                                    height="7"
                                    className={completed >= 4 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_14_436">
                            <rect width="16" height="16" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_14_444)">
                        <mask
                            id="mask0_14_444"
                            style={{ maskType: "alpha" }}
                            maskUnits="userSpaceOnUse"
                            x="2"
                            y="2"
                            width="16"
                            height="16"
                        >
                            <circle
                                cx="10"
                                cy="10"
                                r="7"
                                transform="rotate(-45 10 10)"
                                className="text-content-green"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeMiterlimit="3.13764"
                            />
                        </mask>
                        <g mask="url(#mask0_14_444)">
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="10.6863"
                                    y="2"
                                    width="8"
                                    height="7"
                                    className={completed >= 1 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="10.6863"
                                    y="11"
                                    width="8"
                                    height="7"
                                    className={completed >= 2 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="0.686295"
                                    y="11"
                                    width="8"
                                    height="7"
                                    className={completed >= 3 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                            <g style={{ mixBlendMode: "multiply" }}>
                                <rect
                                    x="0.686295"
                                    y="2"
                                    width="8"
                                    height="7"
                                    className={completed >= 4 ? "text-content-green" : "text-content-quaternary"}
                                    fill="currentColor"
                                />
                            </g>
                        </g>
                    </g>
                    <defs>
                        <clipPath id="clip0_14_444">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <mask
                        id="mask0_14_468"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="4"
                        y="4"
                        width="17"
                        height="17"
                    >
                        <circle
                            cx="12.3137"
                            cy="12.3137"
                            r="7"
                            transform="rotate(-45 12.3137 12.3137)"
                            className="text-content-green"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeMiterlimit="3.13764"
                        />
                    </mask>
                    <g mask="url(#mask0_14_468)">
                        <g style={{ mixBlendMode: "multiply" }}>
                            <rect
                                x="13"
                                y="4.31372"
                                width="8"
                                height="7"
                                className={completed >= 1 ? "text-content-green" : "text-content-quaternary"}
                                fill="currentColor"
                            />
                        </g>
                        <g style={{ mixBlendMode: "multiply" }}>
                            <rect
                                x="13"
                                y="13.3137"
                                width="8"
                                height="7"
                                className={completed >= 2 ? "text-content-green" : "text-content-quaternary"}
                                fill="currentColor"
                            />
                        </g>
                        <g style={{ mixBlendMode: "multiply" }}>
                            <rect
                                x="3"
                                y="13.3137"
                                width="8"
                                height="7"
                                className={completed >= 3 ? "text-content-green" : "text-content-quaternary"}
                                fill="currentColor"
                            />
                        </g>
                        <g style={{ mixBlendMode: "multiply" }}>
                            <rect
                                x="3"
                                y="4.31372"
                                width="8"
                                height="7"
                                className={completed >= 4 ? "text-content-green" : "text-content-quaternary"}
                                fill="currentColor"
                            />
                        </g>
                    </g>
                </svg>
            );
    }
};
