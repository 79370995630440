import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { ProjectFailedToLoad } from "@/components/projects/ProjectErrors";
import { ProjectSharing } from "@/components/projects/ProjectSharing";
import { useProject, useProjectState } from "@/queries/project-queries";
import type { FC } from "react";
import { useParams } from "react-router-dom";

export const ProjectSharingPage: FC = () => {
    const { projectId } = useParams();

    const { data: project, error: getProjectError, isPending: isLoadingProject } = useProject(projectId);
    const {
        data: projectsState,
        isLoading: isLoadingProjectStates,
        error: projectStateError,
    } = useProjectState(project?.id);

    let content: React.ReactNode | null = null;
    if (getProjectError || projectStateError || !projectId) {
        content = <ProjectFailedToLoad />;
    } else if (isLoadingProject || isLoadingProjectStates) {
        content = (
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[150px]" />
                <SkeletonBlock ready={false} className="h-20 w-56" />
            </div>
        );
    } else {
        content = <>{projectsState && <ProjectSharing project={project} state={projectsState} />}</>;
    }

    return <div data-testid="project-details-sharing-page">{content}</div>;
};
