import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useCreateRunnerAccessToken } from "@/queries/runner-queries";
import { createRunnerSetupURL } from "@/components/runners/RunnerSetupURL";
import type { PlainMessage } from "@bufbuild/protobuf";
import { type Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import { useCallback, type FC } from "react";

export const CloudFormationStackCreateButton: FC<{ runner: PlainMessage<Runner> }> = ({ runner }) => {
    const createRunnerToken = useCreateRunnerAccessToken();
    const { toast } = useToast();
    const onClick = useCallback(async () => {
        const region = runner.spec?.configuration?.region;
        const { accessToken } = await createRunnerToken.mutateAsync(runner.runnerId);
        const setupURL = createRunnerSetupURL(runner, accessToken, region || "");
        window.open(setupURL, "awsWindow", "popup");
        toast({
            title: "Opened AWS CloudFormation Stack URL",
            description: (
                <Text>
                    If the window was blocked by your browser, you can use{" "}
                    <ExternalLink href={setupURL}>this link</ExternalLink> instead.
                </Text>
            ),
        });
    }, [runner, createRunnerToken, toast]);
    return (
        <>
            <Button
                onClick={onClick}
                variant={"primary"}
                LeadingIcon={IconExternalLink}
                loading={createRunnerToken.isPending}
                disabled={createRunnerToken.isPending || !runner.spec?.configuration?.region}
            >
                Open AWS CloudFormation
            </Button>
            <ErrorMessage error={createRunnerToken.error} />
        </>
    );
};
