import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { Size } from "@/assets/icons/geist/Size";
import type { FC } from "react";

export const IconKey: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_41_813)">
                        <path
                            d="M2.5085 11.1582C2.28969 11.3769 2.16673 11.6736 2.16667 11.983V13.25C2.16667 13.4047 2.22813 13.5531 2.33752 13.6625C2.44692 13.7719 2.59529 13.8333 2.75 13.8333H4.5C4.65471 13.8333 4.80308 13.7719 4.91248 13.6625C5.02188 13.5531 5.08333 13.4047 5.08333 13.25V12.6667C5.08333 12.5119 5.14479 12.3636 5.25419 12.2542C5.36359 12.1448 5.51196 12.0833 5.66667 12.0833H6.25C6.40471 12.0833 6.55308 12.0219 6.66248 11.9125C6.77188 11.8031 6.83333 11.6547 6.83333 11.5V10.9167C6.83333 10.7619 6.89479 10.6136 7.00419 10.5042C7.11359 10.3948 7.26196 10.3333 7.41667 10.3333H7.517C7.82639 10.3333 8.12309 10.2103 8.34183 9.99149L8.81667 9.51666C9.6274 9.79908 10.51 9.798 11.32 9.5136C12.1301 9.2292 12.8196 8.67831 13.2759 7.95106C13.7321 7.22382 13.9281 6.36326 13.8316 5.51017C13.7352 4.65708 13.3521 3.86197 12.7451 3.25491C12.138 2.64784 11.3429 2.26477 10.4898 2.16834C9.63673 2.07192 8.77618 2.26786 8.04893 2.72412C7.32168 3.18037 6.7708 3.86992 6.4864 4.67996C6.202 5.49001 6.20092 6.37259 6.48333 7.18333L2.5085 11.1582Z"
                            stroke="currentColor"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M10.625 5.66665C10.7861 5.66665 10.9167 5.53606 10.9167 5.37498C10.9167 5.2139 10.7861 5.08331 10.625 5.08331C10.4639 5.08331 10.3333 5.2139 10.3333 5.37498C10.3333 5.53606 10.4639 5.66665 10.625 5.66665Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_41_813">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_41_816)">
                        <path
                            d="M3.724 13.6094C3.47393 13.8593 3.33341 14.1984 3.33333 14.552V16C3.33333 16.1768 3.40357 16.3464 3.5286 16.4714C3.65362 16.5965 3.82319 16.6667 4 16.6667H6C6.17681 16.6667 6.34638 16.5965 6.4714 16.4714C6.59643 16.3464 6.66667 16.1768 6.66667 16V15.3334C6.66667 15.1565 6.73691 14.987 6.86193 14.862C6.98695 14.7369 7.15652 14.6667 7.33333 14.6667H8C8.17681 14.6667 8.34638 14.5965 8.4714 14.4714C8.59643 14.3464 8.66667 14.1768 8.66667 14V13.3334C8.66667 13.1565 8.73691 12.987 8.86193 12.862C8.98695 12.7369 9.15652 12.6667 9.33333 12.6667H9.448C9.80159 12.6666 10.1407 12.5261 10.3907 12.276L10.9333 11.7334C11.8599 12.0561 12.8686 12.0549 13.7943 11.7299C14.7201 11.4048 15.5081 10.7752 16.0296 9.94411C16.551 9.11297 16.7749 8.12947 16.6647 7.15452C16.5545 6.17956 16.1167 5.27086 15.423 4.57707C14.7292 3.88328 13.8205 3.44548 12.8455 3.33528C11.8706 3.22509 10.8871 3.44902 10.0559 3.97045C9.22478 4.49188 8.59519 5.27994 8.27017 6.20571C7.94514 7.13147 7.9439 8.14014 8.26667 9.06669L3.724 13.6094Z"
                            stroke="currentColor"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M13 7.33335C13.1841 7.33335 13.3333 7.18412 13.3333 7.00002C13.3333 6.81593 13.1841 6.66669 13 6.66669C12.8159 6.66669 12.6667 6.81593 12.6667 7.00002C12.6667 7.18412 12.8159 7.33335 13 7.33335Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_41_816">
                            <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_41_819)">
                        <path
                            d="M4.9395 16.0605C4.65818 16.3417 4.50008 16.7232 4.5 17.121V18.75C4.5 18.9489 4.57902 19.1397 4.71967 19.2803C4.86032 19.421 5.05109 19.5 5.25 19.5H7.5C7.69891 19.5 7.88968 19.421 8.03033 19.2803C8.17098 19.1397 8.25 18.9489 8.25 18.75V18C8.25 17.8011 8.32902 17.6103 8.46967 17.4697C8.61032 17.329 8.80109 17.25 9 17.25H9.75C9.94891 17.25 10.1397 17.171 10.2803 17.0303C10.421 16.8897 10.5 16.6989 10.5 16.5V15.75C10.5 15.5511 10.579 15.3603 10.7197 15.2197C10.8603 15.079 11.0511 15 11.25 15H11.379C11.7768 14.9999 12.1583 14.8418 12.4395 14.5605L13.05 13.95C14.0924 14.3131 15.2271 14.3117 16.2686 13.9461C17.3101 13.5804 18.1967 12.8721 18.7833 11.9371C19.3699 11.0021 19.6218 9.89563 19.4978 8.7988C19.3739 7.70198 18.8813 6.67969 18.1008 5.89918C17.3203 5.11866 16.298 4.62614 15.2012 4.50217C14.1044 4.37819 12.9979 4.63012 12.0629 5.21673C11.1279 5.80334 10.4196 6.6899 10.0539 7.73139C9.68828 8.77287 9.68689 9.90762 10.05 10.95L4.9395 16.0605Z"
                            stroke="currentColor"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15.375 9C15.5821 9 15.75 8.83211 15.75 8.625C15.75 8.41789 15.5821 8.25 15.375 8.25C15.1679 8.25 15 8.41789 15 8.625C15 8.83211 15.1679 9 15.375 9Z"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_41_819">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
