import Breadcrumbs from "@/components/Breadcrumb";
import { PageSuspense } from "@/components/PageSuspense";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { VisibilityControlledByFeatureFlag } from "@/components/VisibilityControlledByFeatureFlag";
import { populateMenuFromRoute, type MenuItem } from "@/menu-item";
import { useProject } from "@/queries/project-queries";
import { projectDetailsRoutes } from "@/routes/projects/project-routes";
import { useMemo, type FC } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";

export const ProjectDetailsLayout: FC = () => {
    const { projectId } = useParams();
    const { data: project, error: getProjectError } = useProject(projectId);

    return (
        <div className="flex flex-col gap-6" data-testid="project-details-layout">
            <Breadcrumbs
                label={
                    <SkeletonText
                        size="lg"
                        ready={Boolean(project)}
                        failed={Boolean(getProjectError)}
                        failedFallback={projectId}
                        className="w-[150px]"
                    >
                        {project?.metadata?.name}
                    </SkeletonText>
                }
            />
            <Menu />
            <PageSuspense>
                <Outlet />
            </PageSuspense>
        </div>
    );
};

const Menu: FC = () => {
    const menu: MenuItem[] = useMemo(() => populateMenuFromRoute(projectDetailsRoutes), []);
    return (
        <nav className="flex gap-1" data-testid="project-details-menu">
            {menu.map((item) => (
                <MenuItemLink key={item.label} menu={item} />
            ))}
        </nav>
    );
};

const MenuItemLink: FC<{ menu: MenuItem }> = ({ menu }) => {
    const location = useLocation();
    const active = location.pathname.includes(menu.to);
    const link = (
        <Link
            to={menu.to}
            data-testid={`project-details-menu-item-${menu.label}`}
            data-state={active ? "active" : "inactive"}
            className={cn(
                "rounded-md px-4 py-2 text-base font-bold text-content-secondary",
                "hover:bg-surface-03",
                "data-[state=active]:bg-surface-03 data-[state=active]:text-content-primary",
            )}
        >
            {menu.label}
        </Link>
    );
    return menu.featureFlag ? (
        <VisibilityControlledByFeatureFlag featureFlag={menu.featureFlag}>{link}</VisibilityControlledByFeatureFlag>
    ) : (
        link
    );
};
