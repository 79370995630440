import { useEffect, useMemo, useRef, useState, type FC, type PropsWithChildren } from "react";
import { getPrincipal, onDidChangePrincipal } from "@/principal";
import {
    OnboardingQuestionnaireId,
    type OnboardingQuestionnaireStep,
    type OnboardingQuestionnaireStepData,
    type OnboardingQuestionnaireSteps,
} from "@/hooks/use-onboarding-questionnaire";
import { OnboardingQuestionnaireContext } from "@/contexts/OnboardingQuestionnaireContext";

function useOnboardingQuestionnaireSteps(): OnboardingQuestionnaireSteps {
    return useMemo(
        () => ({
            isLoading: false,
            steps: [
                {
                    id: OnboardingQuestionnaireId.CreateOrg,
                    label: "1",
                    state: "active",
                },
                {
                    id: OnboardingQuestionnaireId.YourRole,
                    label: "2",
                    state: "todo",
                },
                {
                    id: OnboardingQuestionnaireId.WhatToAchieve,
                    label: "3",
                    state: "todo",
                },
            ],
        }),
        [],
    );
}

export const OnboardingQuestionnaireProvider: FC<PropsWithChildren> = ({ children }) => {
    const steps = useOnboardingQuestionnaireSteps();
    const [inMemorySteps, setInMemorySteps] = useState<OnboardingQuestionnaireStep[]>(steps.steps);

    // This ensures that we trigger a re-render when the principal changes - this is needed to
    // ensure we re-render when the user is updated.
    const [, setPrincipal] = useState<string | null>(null);
    useEffect(() => {
        return onDidChangePrincipal(() => {
            setPrincipal(getPrincipal());
        });
    }, [setPrincipal]);

    const calledForPrincipal = useRef<string | null>(null);
    useEffect(() => {
        if (calledForPrincipal.current === getPrincipal()) {
            return;
        }
        if (!steps.isLoading) {
            calledForPrincipal.current = getPrincipal();
            setInMemorySteps(steps.steps);
        }
    }, [steps, setInMemorySteps]);

    const complete = useMemo(() => {
        return (step: OnboardingQuestionnaireId) => {
            setInMemorySteps((prev) => {
                if (step === OnboardingQuestionnaireId.WhatToAchieve) {
                    return steps.steps;
                }

                let nextActiveId = -1;
                return prev.map((s, idx) => {
                    if (s.id === step) {
                        nextActiveId = idx + 1;
                        return { ...s, state: "done" };
                    } else if (idx === nextActiveId) {
                        return { ...s, state: "active" };
                    }
                    return s;
                });
            });
        };
    }, [steps]);

    const updateData = useMemo(() => {
        return (data: OnboardingQuestionnaireStepData) => {
            setInMemorySteps((prev) => {
                return prev.map((s) => {
                    if (s.id === data.id) {
                        return { ...s, data: data.data } as OnboardingQuestionnaireStep;
                    }
                    return s;
                });
            });
        };
    }, []);

    return (
        <OnboardingQuestionnaireContext.Provider
            value={{
                steps: {
                    isLoading: steps.isLoading,
                    steps: inMemorySteps,
                },
                complete,
                updateData,
            }}
        >
            {children}
        </OnboardingQuestionnaireContext.Provider>
    );
};
