import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconGoogle: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_798_6798)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.8336 4.73602C10.3297 4.26318 9.44614 3.69735 8.13623 3.69735C6.29149 3.69735 4.72579 4.91428 4.15991 6.59626C4.02039 7.03031 3.93516 7.49535 3.93516 7.97594C3.93516 8.45649 4.02039 8.92159 4.16766 9.35564L4.16766 9.35565C4.72579 11.0376 6.29149 12.2545 8.13623 12.2545C9.17485 12.2545 9.96548 11.9677 10.5545 11.5569L10.5545 11.5569C11.4847 10.9058 11.9032 9.93696 11.9807 9.2936H8.13623V6.70478H14.6859C14.7866 7.13883 14.8331 7.55739 14.8331 8.13096C14.8331 10.2547 14.0735 12.0452 12.7559 13.2622C11.6009 14.3318 10.0197 14.9519 8.13623 14.9519C5.40783 14.9519 3.05151 13.3862 1.90436 11.1074L1.90436 11.1073C1.43157 10.1617 1.16028 9.09984 1.16028 7.97594C1.16028 6.85204 1.43157 5.79013 1.90436 4.84449L1.90437 4.84448C3.05151 2.5657 5.40784 1 8.13623 1C10.0197 1 11.5932 1.68985 12.8023 2.81375L10.8336 4.73602Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_798_6798">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_798_6802)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.2384 6.26974C12.6626 5.72934 11.6528 5.08269 10.1557 5.08269C8.04747 5.08269 6.2581 6.47347 5.61138 8.39573C5.45193 8.89178 5.35453 9.42326 5.35453 9.97251C5.35453 10.5217 5.45193 11.0532 5.62023 11.5493L5.62023 11.5493C6.2581 13.4716 8.04747 14.8623 10.1557 14.8623C11.3427 14.8623 12.2463 14.5345 12.9195 14.065L12.9195 14.065C13.9826 13.321 14.4609 12.2137 14.5495 11.4784H10.1557V8.51974H17.6411C17.7562 9.01581 17.8094 9.49416 17.8094 10.1497C17.8094 12.5768 16.9412 14.6231 15.4353 16.0139L15.4353 16.0139C14.1154 17.2363 12.3083 17.945 10.1557 17.945C7.03758 17.945 4.34463 16.1556 3.03361 13.5513L3.03361 13.5513C2.49327 12.4705 2.18323 11.257 2.18323 9.97251C2.18323 8.68805 2.49327 7.47443 3.03361 6.3937L3.03361 6.3937C4.34464 3.78937 7.03758 2 10.1557 2C12.3083 2 14.1066 2.7884 15.4884 4.07285L13.2384 6.26974Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_798_6802">
                            <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1143_20122)">
                        <path
                            d="M3 12C3 7.0374 7.0374 3 12 3C14.0043 3 15.9013 3.64483 17.4861 4.8648L15.3946 7.5816C14.4147 6.82731 13.2409 6.42857 12 6.42857C8.92791 6.42857 6.42857 8.92791 6.42857 12C6.42857 15.0721 8.92791 17.5714 12 17.5714C14.4743 17.5714 16.577 15.9503 17.3016 13.7143H12V10.2857H21V12C21 16.9626 16.9626 21 12 21C7.0374 21 3 16.9626 3 12Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1143_20122">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
