import { OnboardingQuestionnaireContext } from "@/contexts/OnboardingQuestionnaireContext";
import { useContext } from "react";

export enum OnboardingQuestionnaireId {
    CreateOrg = "create-org",
    YourRole = "your-role",
    WhatToAchieve = "what-to-achieve",
}

export type OnboardingQuestionnaireStepData =
    | {
          id: OnboardingQuestionnaireId.CreateOrg;
          data?: undefined;
      }
    | {
          id: OnboardingQuestionnaireId.YourRole;
          data?: {
              selection: string;
          };
      }
    | {
          id: OnboardingQuestionnaireId.WhatToAchieve;
          data?: {
              selections: string[];
          };
      };

export type OnboardingQuestionnaireStep = {
    id: OnboardingQuestionnaireId;
    label: string;
    state: "active" | "done" | "todo";
} & OnboardingQuestionnaireStepData;

export type OnboardingQuestionnaireSteps = {
    isLoading: boolean;
    steps: OnboardingQuestionnaireStep[];
};

export const useOnboardingQuestionnaire = () => {
    const ctx = useContext(OnboardingQuestionnaireContext);

    if (!ctx) {
        throw new Error("useOnboardingQuestionnaire must be used within a OnboardingQuestionnaireProvider");
    }

    return ctx;
};

export type Question = {
    title: string;
    options: string[];
    multiSelection: boolean;
};

enum YourRoleOptions {
    DevOps = "DevOps / Platform / Dev Experience",
    EngineeringManagement = "Engineering Management / Leadership",
    SoftwareEngineering = "Software Engineering",
    DataAnalytics = "Data / Analytics",
    ProductManagement = "Product Management",
    Security = "Security",
    DeveloperRelations = "Developer Relations",
    Academia = "Academia (Student, Teacher, Researcher)",
    Other = "Other",
}

enum WhatToAchieveOptions {
    ImproveProductivity = "Improve developer productivity & experience",
    SpeedOnboarding = "Speed up developer onboarding",
    ReplaceVDI = "Replace VDI for development",
    ImproveSecurity = "Improve security for development",
    EnableSelfService = "Enable developer self-service",
    Exploring = "Just exploring for personal use",
}

export const Questions = {
    [OnboardingQuestionnaireId.YourRole]: {
        title: "What area describes your role best?",
        options: [
            YourRoleOptions.DevOps,
            YourRoleOptions.EngineeringManagement,
            YourRoleOptions.SoftwareEngineering,
            YourRoleOptions.DataAnalytics,
            YourRoleOptions.ProductManagement,
            YourRoleOptions.Security,
            YourRoleOptions.DeveloperRelations,
            YourRoleOptions.Academia,
            YourRoleOptions.Other,
        ],
        multiSelection: false,
    } satisfies Question,
    [OnboardingQuestionnaireId.WhatToAchieve]: {
        title: "What are you trying to achieve with Gitpod?",
        options: [
            WhatToAchieveOptions.ImproveProductivity,
            WhatToAchieveOptions.SpeedOnboarding,
            WhatToAchieveOptions.ReplaceVDI,
            WhatToAchieveOptions.ImproveSecurity,
            WhatToAchieveOptions.EnableSelfService,
            WhatToAchieveOptions.Exploring,
        ],
        multiSelection: true,
    } satisfies Question,
};

export type Quote = {
    quote: string;
    author: string;
};

export const Quotes: Quote[] = [
    {
        quote: "Using Gitpod will improve your feature velocity and drastically reduce your cycle times from commit to deploy.",
        author: "Senior Staff Software Engineer at Quizlet",
    },
    {
        quote: "We're a platform team. Our goal is to help other data teams be productive. When you're 30 data engineers, support takes up all the data platform team's time.",
        author: "Lead Platform Engineer, Luminus",
    },
    {
        quote: "We're able to reduce exfiltration risks as well as outside actors from accessing our development environments.",
        author: "Senior Staff Software Engineer at Quizlet",
    },
    {
        quote: "We've solved dev. Now we're focused on delivering value to users.",
        author: "Senior Staff Software Engineer at Quizlet",
    },
];

export const QuoteMap: Record<string, Quote> = {
    [YourRoleOptions.DevOps]: Quotes[3],
    [YourRoleOptions.EngineeringManagement]: Quotes[0],
    [YourRoleOptions.SoftwareEngineering]: Quotes[0],
    [YourRoleOptions.DataAnalytics]: Quotes[1],
    [YourRoleOptions.ProductManagement]: Quotes[0],
    [YourRoleOptions.Security]: Quotes[2],
    [YourRoleOptions.DeveloperRelations]: Quotes[0],
    [YourRoleOptions.Academia]: Quotes[0],
    [YourRoleOptions.Other]: Quotes[0],
};

export type Benefit = {
    title: string;
    description?: string;
};

export const Benefits: Benefit[] = [
    { title: "Free tier & free premium trial" },
    { title: "Self-host Gitpod in under 3 minutes" },
    { title: "Local environments to replace Docker Desktop" },
];

export const AdditionalBenefits: Benefit[] = [
    {
        title: "Automate common development workflows",
        description: "Seed databases, provision infra, runbooks as one-click actions, configure code assistants, etc.",
    },
    {
        title: "Zero latency, secure remote development",
        description: "Ditch VDI for development to improve developer productivity without compromising security.",
    },
    {
        title: "Onboard new developers in minutes",
        description: "A single click launches a fully-prepared environment for every project.",
    },
    {
        title: "Dev container support",
        description: "Eliminate the need to manually install tools, dependencies and editor extensions.",
    },
];

export const AdditionalBenefitsMap: Record<string, Benefit[]> = {
    [WhatToAchieveOptions.ImproveProductivity]: [AdditionalBenefits[0], AdditionalBenefits[3]],
    [WhatToAchieveOptions.SpeedOnboarding]: [AdditionalBenefits[2], AdditionalBenefits[3]],
    [WhatToAchieveOptions.ReplaceVDI]: [AdditionalBenefits[1]],
    [WhatToAchieveOptions.ImproveSecurity]: [AdditionalBenefits[1], AdditionalBenefits[2]],
    [WhatToAchieveOptions.EnableSelfService]: [AdditionalBenefits[0], AdditionalBenefits[3]],
    [WhatToAchieveOptions.Exploring]: [AdditionalBenefits[3]],
};

export const YourRoleSegmentTrackingNameMap: Record<string, string> = {
    [YourRoleOptions.DevOps]: "Enabler",
    [YourRoleOptions.EngineeringManagement]: "Engineering Leader",
    [YourRoleOptions.SoftwareEngineering]: "Engineering",
    [YourRoleOptions.DataAnalytics]: "Data",
    [YourRoleOptions.ProductManagement]: "Product",
    [YourRoleOptions.Security]: "Security",
    [YourRoleOptions.DeveloperRelations]: "DevRel",
    [YourRoleOptions.Academia]: "Academia",
    [YourRoleOptions.Other]: "Other",
};

export const WhatToAchieveSegmentTrackingNameMap: Record<string, string> = {
    [WhatToAchieveOptions.ImproveSecurity]: "security",
    [WhatToAchieveOptions.ImproveProductivity]: "developer_productivity",
    [WhatToAchieveOptions.SpeedOnboarding]: "developer_onboarding",
    [WhatToAchieveOptions.ReplaceVDI]: "vdi_replacement",
    [WhatToAchieveOptions.EnableSelfService]: "developer_self_serve",
    [WhatToAchieveOptions.Exploring]: "exploring_individually",
};
