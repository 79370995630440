import { type FC, type PropsWithChildren } from "react";
import { useCallback } from "react";
import { useRunner } from "@/queries/runner-queries";
import { useListRunnerEnvironmentClasses, useRunnerConfigurationSchema } from "@/queries/runner-configuration-queries";
import { EnvironmentClasses } from "@/components/runners/details/EnvironmentClasses";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { TrackLocations } from "@/hooks/use-segment";
import { useNavigate, useParams } from "react-router-dom";
import { DivContainer, TimelineContentContainer } from "@/components/onboarding/TimelineContentContainer";
import { OnboardingStepId, routeForStep, useOnboarding } from "@/hooks/use-onboarding";
import { ErrorMessage } from "@/components/ErrorMessage";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";

export const ConfigureEnvironmentClassesPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const onboarding = useOnboarding();
    const { data: runner, isPending: isPendingRunner, error: runnerError } = useRunner(params.runnerId);
    const {
        data: schema,
        isPending: schemaIsPending,
        error: schemaError,
    } = useRunnerConfigurationSchema(runner?.runnerId);
    const {
        data: environmentClasses,
        isPending: envClassIsPending,
        error: environmentClassesError,
    } = useListRunnerEnvironmentClasses(runner?.runnerId);

    const isPending = isPendingRunner || schemaIsPending || envClassIsPending;
    const hasStack = runner?.status?.phase !== RunnerPhase.CREATED;

    const onBack = useCallback(() => {
        navigate(`/onboarding/set-up-runner/remote/configure/${params.runnerId}/source-control-access`);
    }, [navigate, params.runnerId]);

    const onComplete = useCallback(() => {
        onboarding.complete(OnboardingStepId.SetUpARunner);
        navigate(routeForStep(OnboardingStepId.AutomateYourDevEnvironment));
    }, [navigate, onboarding]);

    return (
        <TimelineContentContainer
            title="Select environment classes"
            description="Add or restrict machine sizes available for environments launched on this runner."
            nextTitle="Finish runner setup"
            onNext={onComplete}
            onBack={onBack}
            onNextDisabled={!environmentClasses?.length}
            data-track-location={TrackLocations.OnboardingRunnerConfigureEnvironmentClassesTabStep}
        >
            <ErrorMessage error={runnerError} />
            <ErrorMessage error={schemaError} />
            <ErrorMessage error={environmentClassesError} />

            <Skeleton ready={!!runner && !isPending}>
                {runner && (
                    <EnvironmentClasses
                        runner={runner}
                        schema={schema?.environmentClasses}
                        hasStack={hasStack}
                        Container={DivContainer}
                        withDescription={false}
                        data-track-location={({ type }) =>
                            type === "add"
                                ? TrackLocations.OnboardingRunnerAddEnvironmentClassModal
                                : TrackLocations.OnboardingRunnerEditEnvironmentClassModal
                        }
                    />
                )}
            </Skeleton>
        </TimelineContentContainer>
    );
};

const Skeleton: FC<PropsWithChildren<{ ready: boolean }>> = ({ ready, children }) => {
    if (ready) {
        return children;
    }
    return (
        <div className="flex w-full flex-col gap-2">
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
        </div>
    );
};
