import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCode: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.69224 10.964L4.15628 11.4281L5.08435 10.5L4.62031 10.036L1.58435 6.99998L4.62031 3.96402L5.08435 3.49998L4.15628 2.5719L3.69224 3.03594L0.346919 6.38126C0.00521 6.72297 0.00521 7.27699 0.346919 7.6187L3.69224 10.964ZM10.3079 10.964L9.84376 11.4281L8.91573 10.5L9.37975 10.036L12.4157 6.99998L9.37975 3.96402L8.91573 3.49998L9.84376 2.5719L10.3079 3.03594L13.6532 6.38126C13.9948 6.72297 13.9948 7.27699 13.6532 7.6187L10.3079 10.964Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.21969 12.5303L4.75002 13.0607L5.81068 12L5.28035 11.4697L1.81068 7.99999L5.28035 4.53032L5.81068 3.99999L4.75002 2.93933L4.21969 3.46966L0.39647 7.29289C0.00594556 7.68341 0.00594556 8.31658 0.39647 8.7071L4.21969 12.5303ZM11.7804 12.5303L11.25 13.0607L10.1894 12L10.7197 11.4697L14.1894 7.99999L10.7197 4.53032L10.1894 3.99999L11.25 2.93933L11.7804 3.46966L15.6036 7.29289C15.9941 7.68341 15.9941 8.31658 15.6036 8.7071L11.7804 12.5303Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.74714 14.0966L5.34376 14.6933L6.53701 13.5L5.94039 12.9034L2.03701 9.00001L5.94039 5.09663L6.53701 4.50001L5.34376 3.30676L4.74714 3.90338L0.446021 8.20452C0.00668113 8.64385 0.00668113 9.35617 0.446021 9.7955L4.74714 14.0966ZM13.2529 14.0966L12.6562 14.6933L11.4631 13.5L12.0597 12.9034L15.9631 9.00001L12.0597 5.09663L11.4631 4.50001L12.6562 3.30676L13.2529 3.90338L17.554 8.20452C17.9934 8.64385 17.9934 9.35617 17.554 9.7955L13.2529 14.0966Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
