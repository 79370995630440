import { useMemo, useState, type FC } from "react";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useListRunners, type UseListRunnersParams } from "@/queries/runner-queries";
import { AddNewRunnerCard, RunnerCard } from "./RunnerCard";
import { NewRunnerModal } from "./NewRunnerModal";
import { useMembers } from "@/queries/organization-queries";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useMembership } from "@/hooks/use-membership";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Text } from "@/components/podkit/typography/Text";
import { Card } from "@/components/podkit/Card";

export type RunnersListProps = {
    filter: UseListRunnersParams;
};

export const RunnersList: FC<RunnersListProps> = ({ filter }) => {
    const { data: user, isLoading: isLoadingUser } = useAuthenticatedUser();
    const useListRunnersFilter = { ...filter, creatorId: filter.kind === RunnerKind.LOCAL ? user?.id : undefined };
    const {
        data: runnersData,
        isLoading: isLoadingRunners,
        isPending: isPendingRunners,
    } = useListRunners(useListRunnersFilter);
    const { data: membersData, isLoading: isLoadingMembers, isPending: isPendingMembers } = useMembers();
    const membership = useMembership();

    const isLoading = isLoadingRunners || isLoadingMembers || isPendingRunners || isPendingMembers || isLoadingUser;

    const [showNewRunnerModal, setShowNewRunnerModal] = useState(false);

    const runnersToShow = useMemo(() => {
        if (!membersData || !runnersData || !user) {
            return [];
        }

        const currentMember = membersData.members.find((m) => m.userId === user.id);

        return runnersData.runners.map((runner) => {
            return {
                runner,
                creator: membersData.members.find((m) => m.userId === runner.creator?.id),
                currentMember,
            };
        });
    }, [membersData, runnersData, user]);

    if (isLoading) {
        return <LoadingState />;
    }

    const showAddNewRunnerCard =
        filter.kind === RunnerKind.REMOTE && membership.membership?.userRole === OrganizationRole.ADMIN;

    if (runnersToShow.length === 0 && !showAddNewRunnerCard) {
        return (
            <Card className="p-6 text-center">
                <Text className="mb-2 text-lg font-bold">No Runners Available</Text>
                <Text>There are currently no runners set up for this organization.</Text>
                <Text className="mt-2">Please contact your organization admin to set up runners.</Text>
            </Card>
        );
    }

    return (
        <div data-testid="runners-list">
            <div className="flex flex-wrap gap-6">
                {runnersToShow.map(({ runner, creator, currentMember }) => (
                    <RunnerCard runner={runner} creator={creator} currentMember={currentMember} key={runner.runnerId} />
                ))}
                {showAddNewRunnerCard && <AddNewRunnerCard setShowNewRunnerModal={setShowNewRunnerModal} />}
                {showNewRunnerModal && <NewRunnerModal onClose={() => setShowNewRunnerModal(false)} />}
            </div>
        </div>
    );
};
