import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import { useEffect, type FC, type PropsWithChildren } from "react";

export const ClosableWithEsc: FC<
    {
        onClose?: () => void;
        "data-testid"?: string;
    } & PropsWithChildren &
        PropsWithClassName
> = (p) => {
    // capture escape key to close the modal
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape" && p.onClose && !e.defaultPrevented) {
                e.preventDefault();
                p.onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [p]);

    return (
        <div data-testid={p["data-testid"]} className={p.className}>
            {p.children}
        </div>
    );
};

export const JustifiedContentColumn: FC<PropsWithChildren> = (p) => {
    return (
        <div className="flex h-full w-full grow flex-col items-center px-8 py-4">
            <div className="flex w-full min-w-[50%] max-w-[100%] grow flex-col justify-center">{p.children}</div>
        </div>
    );
};
