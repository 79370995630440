import { Button } from "@/components/flexkit/Button";
import { toast } from "@/components/podkit/toasts/use-toast";
import type { ShowNotificationParam, ShowNotificationResult } from "frontend-shared/notification";

export async function showNotification(param: ShowNotificationParam): Promise<ShowNotificationResult> {
    return await new Promise<ShowNotificationResult>((resolve) => {
        const handle = toast({
            title: param.title,
            description: param.message,
            duration: param.duration,
            indefinite: param.indefinite,
            action: (
                <>
                    {param.actions?.map((action) => (
                        <Button
                            key={action}
                            onClick={() => {
                                resolve({ action });
                                handle.dismiss();
                            }}
                        >
                            {action}
                        </Button>
                    ))}
                </>
            ),
            onClose: () => {
                resolve({});
            },
        });
    });
}
