import { Heading4 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import type React from "react";
import { useCallback, type FC } from "react";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import awsLogo from "@/assets/aws.svg";
import azureLogo from "@/assets/azure.svg";
import gcpLogo from "@/assets/gcp.svg";
import linuxLogo from "@/assets/linux.svg";
import { VideoSection } from "@/components/onboarding/VideoSection";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";
import setupRunnerImg from "@/assets/runner-setup-thumbnail.jpg";
import { useDesktop } from "@/hooks/use-desktop";
import { ProviderOption } from "@/components/onboarding/ProviderOption";
import { Link, useNavigate } from "react-router-dom";
import { OnboardingStepId, routeForStep } from "@/hooks/use-onboarding";
import { useListRunners } from "@/queries/runner-queries";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { ContinueWithExistingRunners } from "@/components/onboarding/ContinueWithExistingRunners";
import { SET_UP_A_RUNNER_DESCRIPTION, SET_UP_A_RUNNER_TITLE } from "@/routes/onboarding/SelectRunnerPage";

export const SelectAProviderPage: FC = () => {
    const desktop = useDesktop();
    const navigate = useNavigate();
    const { data: remoteRunners } = useListRunners({ kind: RunnerKind.REMOTE });

    const onSelectProvider = useCallback(() => {
        navigate("/onboarding/set-up-runner/remote/configure/create");
    }, [navigate]);

    const onContinueWithDesktop = useCallback(() => {
        navigate(routeForStep(OnboardingStepId.AutomateYourDevEnvironment));
    }, [navigate]);

    let noCloudAccessCTA: React.ReactNode = (
        <span className="text-base text-content-tertiary">
            use{" "}
            <Link to="/onboarding/set-up-runner/desktop" className="text-content-link">
                Gitpod Desktop
            </Link>{" "}
            to run locally.
        </span>
    );
    if (desktop.isDesktop) {
        noCloudAccessCTA = (
            <span className="text-base text-content-tertiary">
                <Button
                    variant={"link"}
                    className="p-0 font-normal text-content-orange"
                    onClick={onContinueWithDesktop}
                >
                    Continue using Gitpod Desktop
                </Button>{" "}
                to run locally.
            </span>
        );
    }
    return (
        <OnboardingTabContent
            title={SET_UP_A_RUNNER_TITLE}
            description={SET_UP_A_RUNNER_DESCRIPTION}
            showDivider={true}
            data-track-location={TrackLocations.OnboardingRunnerSelectProviderTab}
        >
            <div className="flex flex-col gap-8 lg:flex-row lg:items-center">
                <div className="flex basis-1/2 justify-center">
                    <VideoSection
                        playbackId="68VkfuJyAK026HPm00h5Zwhiii3unkF7Ao7DZSX4i01PlI"
                        metadataVideoTitle="Gitpod Flex - Runners (Flex)"
                        poster={setupRunnerImg}
                    />
                </div>
                <div className="flex basis-1/2 flex-col gap-6">
                    <ContinueWithExistingRunners runners={remoteRunners?.runners ?? []} />
                    <div className="flex flex-col">
                        <Heading4 className="text-xl font-normal">Select a provider</Heading4>
                    </div>
                    <div className="flex flex-col gap-2">
                        <ProviderOption name="AWS" iconImgSrc={awsLogo} onClick={onSelectProvider} />
                        <ProviderOption name="Azure" iconImgSrc={azureLogo} disabled />
                        <ProviderOption name="GCP" iconImgSrc={gcpLogo} disabled />
                        <ProviderOption name="Linux host-based" iconImgSrc={linuxLogo} disabled />
                    </div>
                    <Text className="text-center text-base text-content-tertiary">
                        No cloud access? {noCloudAccessCTA}
                    </Text>
                    <Button variant="secondary" className="w-fit self-end" asChild>
                        <Link to={routeForStep(OnboardingStepId.SetUpARunner)}>Back</Link>
                    </Button>
                </div>
            </div>
        </OnboardingTabContent>
    );
};
