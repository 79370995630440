import { ErrorMessage } from "@/components/ErrorMessage";
import { DivContainer, TimelineContentContainer } from "@/components/onboarding/TimelineContentContainer";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { SourceControlProvider } from "@/components/runners/details/SourceControlProvider";
import { TrackLocations } from "@/hooks/use-segment";
import { useListRunnerSCMIntegrations, useRunnerConfigurationSchema } from "@/queries/runner-configuration-queries";
import { useRunner } from "@/queries/runner-queries";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { type FC, type PropsWithChildren } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ConfigureSCMPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { data: runner, isPending: isPendingRunner, error: runnerError } = useRunner(params.runnerId);
    const {
        data: schema,
        isPending: schemaIsPending,
        error: schemaError,
    } = useRunnerConfigurationSchema(runner?.runnerId);
    const {
        data: integrations,
        isPending: integrationIsPending,
        error: integrationError,
    } = useListRunnerSCMIntegrations(runner?.runnerId);

    const isPending = isPendingRunner || schemaIsPending || integrationIsPending;
    const hasStack = runner?.status?.phase !== RunnerPhase.CREATED;

    return (
        <TimelineContentContainer
            title="Configure Git provider"
            description="Securely access your repos while keeping your code, data, and IP within your private network. All authentication happens on your infrastructure, ensuring maximum security and control."
            onBack={() => navigate(`/onboarding/set-up-runner/remote/configure/${params.runnerId}/install`)}
            nextTitle="Select environment classes"
            onNext={() => navigate(`/onboarding/set-up-runner/remote/configure/${params.runnerId}/environment-classes`)}
            onNextDisabled={integrationIsPending || !integrations?.length}
            docsLink="https://www.gitpod.io/docs/flex/source-control"
            data-track-location={TrackLocations.OnboardingRunnerConfigureSCMTabStep}
        >
            <ErrorMessage error={runnerError} />
            <ErrorMessage error={schemaError} />
            <ErrorMessage error={integrationError} />

            <Skeleton ready={!!runner && !isPending}>
                {runner && (
                    <SourceControlProvider
                        hasStack={hasStack}
                        runner={runner}
                        schemas={schema?.scm}
                        Container={DivContainer}
                        withDescription={false}
                        data-track-location={(modalState) =>
                            modalState.type == "add-provider"
                                ? TrackLocations.OnboardingRunnerAddSCMProviderModal
                                : modalState.type == "remove-provider"
                                  ? TrackLocations.OnboardingRunnerDeleteSCMProviderModal
                                  : TrackLocations.OnboardingRunnerEditSCMProviderModal
                        }
                    />
                )}
            </Skeleton>
        </TimelineContentContainer>
    );
};

const Skeleton: FC<PropsWithChildren<{ ready: boolean }>> = ({ ready, children }) => {
    if (ready) {
        return children;
    }
    return (
        <div className="flex w-full flex-col gap-2">
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
            <SkeletonBlock className="h-[49px] w-full" ready={false} />
        </div>
    );
};
