import gitpodLogoLarge from "@/assets/gitpod-logo-large.svg";
import { IconInfo } from "@/assets/icons/geist/IconInfo";
import { DesktopSignin } from "@/components/login/DesktopSignin";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useBootIntercom } from "@/hooks/use-intercom";
import { useLocalRunner } from "@/hooks/use-local-runner";
import { useGetAccount, useListLoginProviders } from "@/queries/account-queries";
import { ContinueWithSSOForm } from "@/routes/login/ContinueWithSSOForm";
import { DefaultSSOForm } from "@/routes/login/DefaultSSOForm";
import { LocalRunnerStatus } from "frontend-shared/local-runner";
import { useState, type FC } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

export const LoginPage: FC = () => {
    useDocumentTitle("Login");
    useBootIntercom();

    const [searchParams] = useSearchParams();

    const inviteId = searchParams.get("inviteId") || undefined;

    const location = useLocation();
    const fromPath = (location.state?.from?.pathname || "") as string;
    const navigateTo = searchParams.get("navigateTo") || fromPath || "/";

    // The returnTo URL for the login flow.
    const returnTo = searchParams.get("returnTo") || new URL(navigateTo, window.location.origin).toString();

    // "showSSO" is a flag to hide the continue with SSO button by default
    // using search params here as the Login page is not authenticated
    const enableContinueWithSSO = searchParams.get("showSSO") === "true";

    const { data: account, isPending: isLoadingAccount } = useGetAccount();

    const localRunner = useLocalRunner();

    // Wait for the user to be loaded
    if (!account && isLoadingAccount) {
        return <LoadingState />;
    }
    // This is only available in the desktop app.
    // Checking this separately to avoid confusion with the account loading state.
    if (localRunner.loading) {
        return <LoadingState />;
    }

    if (LocalRunnerStatus.needsRunnerScreen(localRunner.status)) {
        return <Navigate to="/local-runner" />;
    }

    // If user is already logged in, redirect
    if (account && !isLoadingAccount) {
        // Using the relative navigateTo path from <Navigate> in AuthenticatedUserLayout.
        return <Navigate to={navigateTo} replace />;
    }

    return (
        <div
            data-testid="login-page"
            className="flex h-full flex-col items-center justify-between gap-4 overflow-visible p-6 sm:overflow-y-auto"
        >
            <div className="my-auto flex max-w-[368px] flex-col items-center gap-7">
                <img src={gitpodLogoLarge} alt="Gitpod logo" width="64" height="64" />
                <div className="flex flex-col items-center gap-2">
                    <Heading1 className="text-center text-2xl font-medium tracking-tight text-content-primary">
                        Gitpod Flex
                    </Heading1>
                    <Text className="max-w-xs text-center text-xl text-content-secondary">
                        Automated, standardized, and secure development environments
                    </Text>
                </div>
                {localRunner.status ? (
                    <DesktopSignin />
                ) : (
                    <SSOLogin inviteId={inviteId} returnTo={returnTo} enableContinueWithSSO={enableContinueWithSSO} />
                )}
            </div>

            <Text className="text-center text-base text-content-primary">
                By signing in, you agree to our{" "}
                <ExternalLink href="https://www.gitpod.io/terms" className="text-content-orange">
                    terms of service
                </ExternalLink>{" "}
                and{" "}
                <ExternalLink href="https://www.gitpod.io/privacy" className="text-content-orange">
                    privacy policy
                </ExternalLink>
                .
            </Text>
        </div>
    );
};

type SSOLoginProps = {
    returnTo: string;
    inviteId?: string;
    enableContinueWithSSO?: boolean;
};

const SSOLogin: FC<SSOLoginProps> = ({ returnTo, inviteId, enableContinueWithSSO }) => {
    const { data: loginProviders, isPending: isPendingProviders } = useListLoginProviders(inviteId);

    const [searchParams] = useSearchParams();
    const errorParm = searchParams.get("error");
    const errorDescriptionParm = searchParams.get("error_description");
    if (errorParm) {
        console.log("error param on login page", errorParm, errorDescriptionParm);
    }

    const [showContinueWithSSO, setCustomSSO] = useState(false);

    if (inviteId && isPendingProviders) {
        return <LoadingState />;
    }

    return (
        <>
            {showContinueWithSSO ? (
                <ContinueWithSSOForm
                    className="flex w-full flex-col items-stretch gap-2"
                    returnTo={returnTo}
                    onBack={() => setCustomSSO(false)}
                />
            ) : (
                <DefaultSSOForm
                    className="flex w-full flex-col items-stretch gap-2"
                    returnTo={returnTo}
                    loginProviders={loginProviders}
                    enableContinueWithSSO={enableContinueWithSSO}
                    onContinueWithSSO={() => setCustomSSO(true)}
                />
            )}
            {errorParm && (
                <div className="mt-6 flex flex-row gap-3 rounded-xl border border-border-base bg-surface-glass px-3 py-4">
                    <IconInfo size="base" className="text-content-orange" />
                    <div className="flex w-full grow flex-col gap-2">
                        <Text className="text text-base">
                            Oops! Looks like your sign-in was interrupted. Please try again!
                        </Text>
                        {errorDescriptionParm && <Text className="text-sm">Provider says: {errorDescriptionParm}</Text>}
                    </div>
                </div>
            )}
        </>
    );
};

export default LoginPage;
