import { Button } from "@/components/flexkit/Button";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import { VideoSection } from "@/components/onboarding/VideoSection";
import { type FC, useCallback, useState } from "react";
import { ContextUrlInput } from "@/components/ContextUrlInput";
import { EnvironmentTypeSelect } from "@/components/EnvironmentTypeSelect";
import { type EnvironmentTypeEntry, useEnvironmentTypes } from "@/hooks/use-environment-types";
import { type CreateEnvironmentStage, NoEnvTypesError } from "@/routes/environments/create/CreateEnvironment";
import { useContextURL } from "@/hooks/use-context-url";
import { useDebounce } from "use-debounce";
import { useIsUserAuthenticatedWithRunner, useParseContextURL } from "@/queries/runner-queries";
import { isNotFoundError, isUnauthorizedError } from "@/queries/errors";
import { useCreateEnvironment } from "@/queries/environment-queries";
import { getDetailsURL } from "@/routes/environments/details-url";
import { ErrorMessage } from "@/components/ErrorMessage";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { formatError } from "@/utils/errors";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { SCMAuthenticationModal } from "@/routes/environments/create/SCMAuthentication";
import { useNavigate } from "react-router-dom";
import configurationImg from "@/assets/configuration-thumbnail.jpg";
import { TrackLocations } from "@/hooks/use-segment";
import { OnboardingStepId, routeForStep } from "@/hooks/use-onboarding";

export const AutomateYourDevEnvironmentPage: FC = () => {
    useDocumentTitle("Onboarding - Automate your dev environment");

    const navigate = useNavigate();
    const { toast } = useToast();

    const [stage, setStage] = useState<CreateEnvironmentStage>({ type: "input" });

    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentTypeEntry>();

    const { contextURL, setContextURL, contextURLValid } = useContextURL();
    const [debouncedContext] = useDebounce({ contextURL, contextURLValid }, 100, {
        equalityFn: (left, right) =>
            left.contextURL == right.contextURL && left.contextURLValid == right.contextURLValid,
    });

    const { envTypes, isLoading: isLoadingEnvTypes } = useEnvironmentTypes();

    const hasNoEnvTypes = !isLoadingEnvTypes && envTypes.length == 0;

    const {
        isLoading: isLoadingParseContext,
        isSuccess: isSuccessParseContext,
        error: errorParseContext,
    } = useParseContextURL(selectedEnvironment?.runner?.runnerId, debouncedContext.contextURL, {
        enabled: debouncedContext.contextURLValid,
    });

    const parseContextFailed = !isLoadingParseContext && isUnauthorizedError(errorParseContext);
    const isNotFound = isNotFoundError(errorParseContext);

    const {
        data: authCheckResponse,
        isLoading: isLoadingAuthCheck,
        isSuccess: isSuccessAuthCheck,
        isError: isErrorAuthCheck,
    } = useIsUserAuthenticatedWithRunner(selectedEnvironment?.runner?.runnerId, debouncedContext.contextURL, {
        refetchUntilAuthenticated: true,

        // Auth check should start be polled for once the parseContextURL failed with an unauthorized error
        enabled: debouncedContext.contextURLValid && parseContextFailed,
    });

    const isAuthenticationRequired = parseContextFailed || authCheckResponse?.type == "AuthenticationRequired";

    const createEnvironment = useCreateEnvironment();

    // Select first environment type by default
    if (!isLoadingEnvTypes && !selectedEnvironment && envTypes.length > 0) {
        const firstActive = envTypes.find((et) => et.runner?.status?.phase === RunnerPhase.ACTIVE);
        if (firstActive) {
            setSelectedEnvironment((prev) => {
                if (prev) {
                    return prev;
                }
                return firstActive;
            });
        }
    }

    // If the selected environment isn't present in the list of environment classes then reset the selection.
    // This can happen if you change the context URL after having selected an environment type
    if (!isLoadingEnvTypes && selectedEnvironment) {
        const selectedIsInList = envTypes?.find(
            (v) =>
                v.clazz?.id == selectedEnvironment.clazz?.id &&
                v.runner?.runnerId == selectedEnvironment?.runner?.runnerId,
        );
        if (!selectedIsInList) {
            setSelectedEnvironment(undefined);
        }
    }

    const handleOnContinue = useCallback(async () => {
        let environment;
        try {
            environment = await createEnvironment.mutateAsync({
                type: "contextUrl",
                contextURL: contextURL || "",
                classID: selectedEnvironment?.clazz?.id || "",
            });
            navigate(getDetailsURL(environment));
        } catch (error) {
            toast({ title: "Failed to create an environment", description: formatError(error) });
        }
    }, [contextURL, selectedEnvironment, createEnvironment, navigate, toast]);

    const handleNextClick = useCallback(async () => {
        const runnerID = selectedEnvironment?.runner?.runnerId;
        const classID = selectedEnvironment?.clazz?.id;

        if (!runnerID || !contextURL || !classID) {
            console.log(`Invalid runnerId=${runnerID} or contextURL=${contextURL} or classID=${classID}`);
            return;
        }

        if (authCheckResponse?.type == "AuthenticationRequired") {
            setStage({
                type: "scm-authentication",
                authenticationUrl: authCheckResponse.url,
                patSupported: authCheckResponse.patSupported,
                scmId: authCheckResponse.scmId,
                clazz: selectedEnvironment?.clazz,
            });
            return;
        }

        await handleOnContinue();
    }, [handleOnContinue, selectedEnvironment, authCheckResponse, contextURL]);

    const onBack = useCallback(() => {
        navigate(routeForStep(OnboardingStepId.SetUpARunner));
    }, [navigate]);

    const nextButtonDisabled =
        selectedEnvironment == undefined ||
        !contextURLValid ||
        isNotFound ||
        !(isSuccessParseContext || isSuccessAuthCheck);

    const showIsVerifying =
        (!isAuthenticationRequired && contextURLValid && isLoadingParseContext && !hasNoEnvTypes) ||
        (isLoadingAuthCheck && isErrorAuthCheck);

    return (
        <OnboardingTabContent
            showDivider={true}
            title="Create an environment"
            data-track-location={TrackLocations.OnboardingAutomateTab}
        >
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-8 lg:flex-row">
                    <div className="flex basis-1/2 flex-col gap-6">
                        <div
                            className="flex flex-col gap-4"
                            id="create-environment-form"
                            data-testid={isLoadingEnvTypes ? "loading" : "create-environment-form"}
                        >
                            <ContextUrlInput
                                label="Repository URL"
                                name="contextURL"
                                value={contextURL}
                                onChange={setContextURL}
                                errorMessage={
                                    !!contextURL && !contextURLValid ? "The URL must point to a valid context URL." : ""
                                }
                                className={"pb-2"}
                                autoFocus
                            />

                            <EnvironmentTypeSelect
                                label="Environment class"
                                value={selectedEnvironment}
                                onChange={(v) => setSelectedEnvironment(v)}
                                name="environment-type"
                                loading={isLoadingEnvTypes}
                                disabled={!contextURLValid}
                                envTypes={envTypes}
                            />

                            <div
                                data-testid="errors"
                                className="flex min-h-4 flex-col items-center justify-center gap-2 text-center text-sm"
                            >
                                {showIsVerifying && (
                                    <span data-testid="verifying">Verifying repository and connection…</span>
                                )}
                                {isAuthenticationRequired && (
                                    <span className="max-w-80 text-wrap">
                                        <span className="font-bold">
                                            {selectedEnvironment?.runner?.name || "Unknown runner"}
                                        </span>{" "}
                                        requires authentication with <span className="font-bold">GitHub</span>
                                    </span>
                                )}
                                {isNotFound && (
                                    <span>The repository could not be found. Please check the URL and try again.</span>
                                )}
                                {hasNoEnvTypes && <NoEnvTypesError />}
                                {!showIsVerifying && !isAuthenticationRequired && !isNotFound && !hasNoEnvTypes && (
                                    <ErrorMessage error={errorParseContext} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="flex basis-1/2 justify-center">
                        <VideoSection
                            playbackId="8G3FzXmb4InC1rO2g6DqMtLjS8f00Ez1e68yd02eMkOjc"
                            metadataVideoTitle="Gitpod Flex - Configuration (Flex)"
                            poster={configurationImg}
                        />
                    </div>
                </div>
                <div className="flex justify-end gap-2">
                    <Button onClick={onBack} variant="secondary" size="md">
                        Back
                    </Button>
                    <Button onClick={handleNextClick} variant="primary" size="md" disabled={nextButtonDisabled}>
                        Launch environment
                    </Button>
                </div>
                {stage.type === "scm-authentication" && (
                    <SCMAuthenticationModal
                        repoURL={contextURL || ""}
                        authenticationUrl={stage.authenticationUrl}
                        patSupported={stage.patSupported}
                        scmId={stage.scmId}
                        onClose={() => setStage({ type: "input" })}
                        onClickContinue={handleOnContinue}
                        clazz={stage.clazz}
                        data-track-location={TrackLocations.OnboardingAutomateSCMAuthenticationModal}
                    />
                )}
            </div>
        </OnboardingTabContent>
    );
};
