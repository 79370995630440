import { ErrorMessage } from "@/components/ErrorMessage";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { Text } from "@/components/podkit/typography/Text";
import { useRunnerConfigurationSchema } from "@/queries/runner-configuration-queries";
import { CloudFormationStack } from "@/components/runners/details/CloudFormationStack";
import { EnvironmentClassesSection } from "@/components/runners/details/EnvironmentClasses";
import { RunnerSharing } from "@/components/runners/details/RunnerSharing";
import { SourceControlProviderSection } from "@/components/runners/details/SourceControlProvider";
import { RunnerPhaseTag } from "@/components/runners/RunnerCard";
import type { PlainMessage } from "@bufbuild/protobuf";
import { RunnerPhase, type Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import { type FC } from "react";
import { TrackLocations } from "@/hooks/use-segment";
import { Tooltip } from "@/components/Tooltip";

export const RunnerDetails: FC<{ runner: PlainMessage<Runner> | undefined }> = ({ runner }) => {
    const { data: schema, isLoading, isPending, error } = useRunnerConfigurationSchema(runner?.runnerId);

    const schemaLoading = isLoading || isPending;
    const hasStack = runner?.status?.phase !== RunnerPhase.CREATED;

    return (
        <div className="flex size-full flex-col gap-6" data-testid="runner-details">
            <RunnerMetadata runner={runner} />
            <CloudFormationStack runner={runner} hasStack={hasStack} />
            <SkeletonBlock ready={!schemaLoading} failed={Boolean(error)} className="h-[255px]">
                <FailedToLoadSchema error={error} />
                {runner && (
                    <SourceControlProviderSection
                        runner={runner}
                        schemas={schema?.scm}
                        hasStack={hasStack}
                        data-track-location={(modalState) =>
                            modalState.type == "add-provider"
                                ? TrackLocations.AddSCMProviderModal
                                : modalState.type == "remove-provider"
                                  ? TrackLocations.DeleteSCMProviderModal
                                  : TrackLocations.EditSCMProviderModal
                        }
                    />
                )}
                {runner && (
                    <EnvironmentClassesSection
                        runner={runner}
                        schema={schema?.environmentClasses}
                        hasStack={hasStack}
                        data-track-location={(modalState) =>
                            modalState.type === "add"
                                ? TrackLocations.AddEnvironmentClassModal
                                : TrackLocations.EditEnvironmentClassModal
                        }
                    />
                )}
                {runner && <RunnerSharing runnerId={runner.runnerId} hasStack={hasStack} />}
            </SkeletonBlock>
        </div>
    );
};

const FailedToLoadSchema: FC<{ error: Error | null }> = ({ error }) => {
    if (!error) {
        return null;
    }
    return (
        <div className="flex flex-col items-center">
            <Text className="text-lg font-bold text-content-negative">Failed to load runner schema</Text>
            <Text className="text-base text-content-negative">
                Please try to refresh the page and contact support if the issue persists.
            </Text>
            <ErrorMessage error={error} />
        </div>
    );
};

const RunnerMetadata: FC<{ runner: PlainMessage<Runner> | undefined }> = ({ runner }) => {
    const ready = Boolean(runner);
    return (
        <div className={cn("flex flex-col justify-between", "md:flex-row md:items-center")}>
            <SkeletonText ready={ready} size="lg" className="w-28">
                <div className="flex items-center gap-2">
                    <Text className="text-xl font-bold">{runner?.name || ""}</Text>
                    <Text className="text-xl text-content-secondary">{runner?.spec?.configuration?.region}</Text>
                </div>
            </SkeletonText>
            <div className="flex items-center gap-2">
                <SkeletonText ready={ready} size="base" className="w-16">
                    <Tooltip content="Runner version">
                        <Text muted={true} className="text-base">
                            {runner?.status?.version}
                        </Text>
                    </Tooltip>
                </SkeletonText>
                <SkeletonText ready={ready} size="base" className="w-10">
                    {runner && <RunnerPhaseTag runner={runner} />}
                </SkeletonText>
            </div>
        </div>
    );
};
