import { Button } from "@/components/flexkit/Button";
import { IconArrowDown } from "@/assets/icons/geist/IconArrowDown";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/podkit/dropdown/DropDown";
import { DropdownActions } from "@/components/podkit/dropdown/DropDownActions";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/podkit/tables/Table";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Text } from "@/components/podkit/typography/Text";
import { getRepoUrl } from "@/hooks/use-grouped-environments";
import {
    useDeleteEnvironment,
    useListEnvironmentInventory,
    useStopEnvironment,
    type PlainEnvironment,
    type UseListEnvironmentInventoryParams,
} from "@/queries/environment-queries";
import { useMembers, type PlainOrganizationMember } from "@/queries/organization-queries";
import { useListProjects, type PlainProject } from "@/queries/project-queries";
import { useListRunners, type PlainRunner } from "@/queries/runner-queries";
import { EnvironmentPhaseTag } from "@/routes/environments/EnvironmentPhaseTag";
import { effectiveState } from "@/routes/environments/phase";
import { formatError } from "@/utils/errors";
import { Timestamp } from "@bufbuild/protobuf";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import { TrackLocations } from "@/hooks/use-segment";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";

export const Inventory: FC = () => {
    const [searchParams] = useSearchParams();
    const runnerFilter = searchParams.get("runner") || undefined;
    const runnerKind = useMemo(() => {
        for (const opt of runnerKindOptions) {
            if (runnerFilter === opt.key) {
                return opt.kind;
            }
        }
        return undefined;
    }, [runnerFilter]);
    const runnerID = useMemo(() => {
        // If the runner filter is not a runner kind, it's a runner ID
        return runnerKind ? undefined : runnerFilter;
    }, [runnerKind, runnerFilter]);
    const creatorID = searchParams.get("creator") || undefined;

    const { data: runnersData, isLoading: isLoadingRunners } = useListRunners({});
    const { data: membersData, isLoading: isLoadingMembers } = useMembers();
    const { data: projectsData, isLoading: isLoadingProjects } = useListProjects();
    const [filterValue, setFilterValue] = useState<FilterValue>({ runnerID, creatorID, runnerKind });

    const handleFilterChange = useCallback((value: FilterValue) => {
        setFilterValue(value);
    }, []);

    const isLoading = isLoadingRunners || isLoadingMembers || isLoadingProjects;
    if (isLoading) {
        return <LoadingState />;
    }

    return (
        <div className="flex h-full flex-col">
            <div className="text-base">Inventory of running and stopped environments in your organization.</div>
            <Filter
                runners={runnersData?.runners || []}
                members={membersData?.members || []}
                projects={projectsData?.projects || []}
                initialValue={{ runnerID, creatorID, runnerKind }}
                onFilterChange={handleFilterChange}
                className="pt-4"
            />
            <InventoryTable
                filterValue={filterValue}
                runners={runnersData?.runners || []}
                members={membersData?.members || []}
                projects={projectsData?.projects || []}
                className="flex flex-grow flex-col pt-4"
            />
        </div>
    );
};

type FilterValue = UseListEnvironmentInventoryParams;

type FilterProps = {
    initialValue: FilterValue;
    runners: PlainRunner[];
    members: PlainOrganizationMember[];
    projects: PlainProject[];
    onFilterChange?: (value: FilterValue) => void;
};

const allStatuses: Record<string, EnvironmentPhase> = {
    Creating: EnvironmentPhase.CREATING,
    Starting: EnvironmentPhase.STARTING,
    Running: EnvironmentPhase.RUNNING,
    Updating: EnvironmentPhase.UPDATING,
    Stopping: EnvironmentPhase.STOPPING,
    Stopped: EnvironmentPhase.STOPPED,
    Deleting: EnvironmentPhase.DELETING,
    Deleted: EnvironmentPhase.DELETED,
};

// Add additional filter items for these kinds in the runner filter.
// Allows selecting all local or all remote runners.
const runnerKindOptions = [{
    kind: RunnerKind.LOCAL,
    key: "local",
    name: "All Gitpod Desktop runners",
}, {
    kind: RunnerKind.REMOTE,
    key: "remote",
    name: "All remote runners",
}];

const Filter: FC<FilterProps & PropsWithClassName> = ({
    className,
    runners,
    members,
    projects,
    initialValue,
    onFilterChange,
}) => {
    const [prevFilterValue, setPrevFilterValue] = useState<FilterValue>(initialValue);

    const [selectedRunnerID, setSelectedRunnerID] = useState<string | undefined>(initialValue.runnerID);
    const [selectedMemberID, setSelectedMemberID] = useState<string | undefined>(initialValue.creatorID);
    const [selectedProjectID, setSelectedProjectID] = useState<string | undefined>(initialValue.projectID);
    const [selectedRunnerKind, setSelectedRunnerKind] = useState<RunnerKind | undefined>(initialValue.runnerKind);
    const [selectedStatus, setSelectedStatus] = useState<string | undefined>();

    const setSelectedRunner = useCallback((value: string) => {
        for (const opt of runnerKindOptions) {
            if (value === opt.key) {
                // Value is a runner kind.
                setSelectedRunnerKind(opt.kind);
                setSelectedRunnerID(undefined);
                return;
            }
        }

        // If the value is not a runner kind, it's a runner ID.
        setSelectedRunnerKind(undefined);
        setSelectedRunnerID(value);
    }, []);

    const debouncedFilterChanged = useDebouncedCallback((filter: FilterValue) => {
        if (!onFilterChange) {
            return;
        }
        onFilterChange(filter);
    }, 100);

    useEffect(() => {
        debouncedFilterChanged({ ...prevFilterValue });
    }, [prevFilterValue, debouncedFilterChanged]);

    useEffect(() => {
        setPrevFilterValue((prev) => {
            if (
                selectedRunnerID !== prev.runnerID ||
                selectedMemberID !== prev.creatorID ||
                selectedProjectID !== prev.projectID ||
                selectedRunnerKind !== prev.runnerKind ||
                selectedStatus !== prev.status
            ) {
                return {
                    ...prev,
                    runnerID: selectedRunnerID,
                    creatorID: selectedMemberID,
                    projectID: selectedProjectID,
                    runnerKind: selectedRunnerKind,
                    status: selectedStatus ? allStatuses[selectedStatus] : undefined,
                };
            }
            return prev;
        });
    }, [selectedRunnerID, selectedMemberID, selectedProjectID, selectedRunnerKind, selectedStatus]);

    const runnerItems = useMemo(() => {
        const items = new Map<string, string>();
        for (const opt of runnerKindOptions) {
            items.set(opt.key, opt.name);
        }
        for (const r of runners) {
            items.set(r.runnerId, r.name);
        }
        return items;
    }, [runners]);

    const selectedRunner = useMemo(() => {
        for (const opt of runnerKindOptions) {
            if (selectedRunnerKind === opt.kind) {
                return opt.key;
            }
        }

        return selectedRunnerID;
    }, [selectedRunnerID, selectedRunnerKind]);

    return (
        <div className={cn("flex flex-row gap-2", className)}>
            <FilterSelect
                placeholder="All projects"
                className="w-3/12"
                key={"projects-filter"}
                initialValue={selectedProjectID}
                items={new Map(projects.map((r) => [r.id, r.metadata?.name || r.id]))}
                onChange={setSelectedProjectID}
            />
            <FilterSelect
                placeholder="All members"
                className="w-3/12"
                key={"mermbers-filter"}
                initialValue={selectedMemberID}
                items={new Map(members.map((r) => [r.userId, r.fullName]))}
                onChange={setSelectedMemberID}
            />
            <FilterSelect
                placeholder="All runners"
                className="w-3/12"
                key={"runners-filter"}
                initialValue={selectedRunner}
                items={runnerItems}
                onChange={setSelectedRunner}
            />
            <FilterSelect
                placeholder="All statuses"
                className="w-3/12"
                key={"statuses-filter"}
                initialValue={selectedStatus}
                items={new Map(Object.keys(allStatuses).map((r) => [r, r]))}
                onChange={setSelectedStatus}
            />
        </div>
    );
};

type FilterSelectProps = {
    initialValue?: string;
    items: Map<string, string>;
    placeholder: string;
    onChange?: (value: string) => void;
};

const FilterSelect: FC<FilterSelectProps & PropsWithClassName> = ({ initialValue, placeholder, items, onChange }) => {
    const [value, setValue] = useState<string | undefined>(initialValue);
    const onValueChange = useCallback(
        (value: string) => {
            const newValue = value === "*" ? "" : value;
            setValue(newValue);
            if (onChange) {
                onChange(newValue);
            }
        },
        [onChange],
    );

    return (
        <Select value={value || ""} onValueChange={onValueChange}>
            <SelectTrigger className="w-3/12" loading={false}>
                <span className="inline-block truncate">
                    <SelectValue placeholder={placeholder}>{value ? items.get(value) : ""}</SelectValue>
                </span>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem key={"reset-item"} value={"*"}>
                            <div className="flex w-full flex-row items-center justify-between pr-10">
                                <div>{placeholder}</div>
                            </div>
                        </SelectItem>
                        {Array.from(items.entries()).map(([id, label]) => (
                            <SelectItem key={"item-" + id} value={id}>
                                <div className="flex w-full flex-row items-center justify-between pr-10">
                                    <div>{label}</div>
                                </div>
                            </SelectItem>
                        ))}
                    </SelectGroup>
                </SelectContent>
            </SelectTrigger>
        </Select>
    );
};

type InventoryTableProps = {
    filterValue: FilterValue;
    runners: PlainRunner[];
    members: PlainOrganizationMember[];
    projects: PlainProject[];
};

const InventoryTable: FC<InventoryTableProps & PropsWithClassName> = ({
    filterValue,
    className,
    members,
    runners,
    projects,
}) => {
    const { data, hasNextPage, fetchNextPage } = useListEnvironmentInventory({ ...filterValue });
    const environments = data?.pages.flatMap((page) => page.environments) || [];

    const projectsMap = useMemo(() => {
        const map = new Map<string | undefined, PlainProject>();
        for (const p of projects) {
            map.set(p.id, p);
        }
        return map;
    }, [projects]);
    const membersMap = useMemo(() => {
        const map = new Map<string | undefined, PlainOrganizationMember>();
        for (const m of members) {
            map.set(m.userId, m);
        }
        return map;
    }, [members]);
    const runnersMap = useMemo(() => {
        const map = new Map<string | undefined, PlainRunner>();
        for (const r of runners) {
            map.set(r.runnerId, r);
        }
        return map;
    }, [runners]);

    const isEmpty = environments.length === 0;
    if (isEmpty) {
        const anyFilters = filterValue.runnerID || filterValue.creatorID || filterValue.projectID || filterValue.status;

        if (anyFilters) {
            return (
                <div className="flex flex-grow items-center self-center py-32 text-base">
                    <div className="flex flex-col text-center">
                        <div className="mb-1 text-lg font-bold">No matches</div>
                        No environments matching the filters.
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex flex-grow items-center self-center py-32 text-base">
                    <div className="flex flex-col text-center">
                        <div className="mb-1 text-lg font-bold">It&apos;s quiet here</div>
                        Currently, there are no environments in your organization.
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={cn("flex flex-col", className)}>
            <div>
                <Table className="table-fixed">
                    <TableHeader className="[&_th]:border-b [&_th]:border-border-light [&_th]:bg-transparent">
                        <TableRow className="text-sm font-medium">
                            <TableHead className="w-[22%] xl:w-[26%]">Project</TableHead>
                            <TableHead>Member</TableHead>
                            <TableHead>Runner</TableHead>
                            <TableHead>Created</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead className="w-[80px]" />
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {environments.map((environment, i) => (
                            <InventoryItem
                                key={"item-" + i}
                                environment={environment}
                                member={membersMap.get(environment.metadata?.creator?.id)}
                                runner={runnersMap.get(environment.metadata?.runnerId)}
                                project={projectsMap.get(environment.metadata?.projectId)}
                            />
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div className="flex w-full flex-row justify-center py-8">
                {hasNextPage && (
                    <Button variant={"ghost"} title="Load more" onClick={() => fetchNextPage()}>
                        <IconArrowDown size="lg" /> Load more
                    </Button>
                )}
            </div>
        </div>
    );
};

type InventoryItemProps = {
    environment: PlainEnvironment;
    member?: PlainOrganizationMember;
    runner?: PlainRunner;
    project?: PlainProject;
};

const InventoryItem: FC<InventoryItemProps & PropsWithClassName> = ({ environment, runner, member, project }) => {
    const [showModal, setShowModal] = useState<"stop" | "delete" | undefined>(undefined);

    const createdTime = useMemo(
        () =>
            environment.metadata?.createdAt
                ? new Timestamp(environment.metadata?.createdAt).toDate().toLocaleDateString()
                : "",
        [environment.metadata?.createdAt],
    );

    const state = useMemo(() => {
        if (environment) {
            return effectiveState(environment);
        }
        return;
    }, [environment]);

    const { toast } = useToast();

    const stopEnvironment = useStopEnvironment();
    const handleStop = useCallback(() => {
        stopEnvironment.mutate(environment.id, {
            onError: (e) => {
                toast({
                    title: "Failed to stop environment",
                    description: formatError(e),
                });
            },
        });
    }, [stopEnvironment, toast, environment.id]);

    const deleteEnvironment = useDeleteEnvironment();
    const handleDelete = useCallback(() => {
        deleteEnvironment.mutate(
            { environmentId: environment.id, force: false },
            {
                onError: (e) => {
                    toast({
                        title: "Failed to delete environment",
                        description: formatError(e),
                    });
                },
            },
        );
    }, [deleteEnvironment, environment.id, toast]);

    const projectOrRepo = useMemo(() => {
        if (project?.metadata?.name) {
            return project?.metadata?.name;
        }
        return getRepoUrl(environment)?.repoUrl?.replace("https://github.com/", "") || "loading...";
    }, [environment, project?.metadata?.name]);

    const handleCopyId = useCallback(async () => {
        await navigator.clipboard.writeText(environment.id);
        toast({
            title: `Environment ID copied to clipboard`,
            description: environment.id,
        });
    }, [toast, environment.id]);

    return (
        <>
            <TableRow className="h-16 border-border-light">
                <TableCell>
                    <Text className="truncate text-base">{projectOrRepo}</Text>
                </TableCell>
                <TableCell>
                    <div className="inline-flex grow items-center space-x-2">
                        <img
                            referrerPolicy="no-referrer"
                            src={member?.avatarUrl}
                            className="hidden h-8 w-8 rounded-full xl:block"
                        />
                        <Text className="text-base">{member?.fullName}</Text>
                    </div>
                </TableCell>
                <TableCell>
                    <Text className="text-base">{runner?.name}</Text>
                </TableCell>
                <TableCell>
                    <Text className="truncate text-base">{createdTime}</Text>
                </TableCell>
                <TableCell>
                    {state && (
                        <>
                            <div className="hidden xl:block">
                                <EnvironmentPhaseTag state={state} />
                            </div>
                            <div className="block xl:hidden">
                                <EnvironmentPhaseTag state={state} variant="dot" />
                            </div>
                        </>
                    )}
                </TableCell>
                <TableCell>
                    <div className="flex flex-row justify-end">
                        <DropdownActions>
                            <DropdownMenuItem onClick={handleCopyId}>Copy ID</DropdownMenuItem>
                            <DropdownMenuSeparator className="bg-content-tertiary/20" />
                            <DropdownMenuItem
                                disabled={environment.status?.phase !== EnvironmentPhase.RUNNING}
                                onClick={() => setShowModal("stop")}
                            >
                                Stop
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setShowModal("delete")} className="text-red-500">
                                Delete
                            </DropdownMenuItem>
                        </DropdownActions>
                    </div>
                </TableCell>
            </TableRow>
            {showModal === "stop" && member && runner && (
                <StopEnvironmentModal
                    environment={environment}
                    project={project}
                    member={member}
                    runner={runner}
                    onContinue={() => {
                        setShowModal(undefined);
                        handleStop();
                    }}
                    onClose={() => {
                        setShowModal(undefined);
                    }}
                />
            )}
            {showModal === "delete" && member && runner && (
                <DeleteEnvironmentModal
                    environment={environment}
                    project={project}
                    member={member}
                    runner={runner}
                    onContinue={() => {
                        setShowModal(undefined);
                        handleDelete();
                    }}
                    onClose={() => {
                        setShowModal(undefined);
                    }}
                />
            )}
        </>
    );
};

const StopEnvironmentModal: FC<
    {
        onContinue: () => void;
        onClose: () => void;
    } & InventoryBannerProps
> = (p) => {
    return (
        <Dialog open onOpenChange={p.onClose}>
            <DialogContent className="max-w-lg" data-track-location={TrackLocations.InventoryStopEnvironmentModal}>
                <DialogHeader>
                    <DialogTitle>Stop environment</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogDescription>
                    This will stop the user&apos;s environment. They won&apos;t lose any changes.
                    <InventoryBanner {...p} className="mt-4" />
                    <div className="mt-4">
                        Tip: You may want to let {p.member.fullName} know that you&apos;ve stopped their environment.
                    </div>
                </DialogDescription>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={p.onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        autoFocus={true}
                        variant="destructive"
                        onClick={(e) => {
                            e.preventDefault();
                            p.onContinue();
                        }}
                    >
                        Stop
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const DeleteEnvironmentModal: FC<
    {
        onContinue: () => void;
        onClose: () => void;
    } & InventoryBannerProps
> = (p) => {
    return (
        <Dialog open onOpenChange={p.onClose}>
            <DialogContent className="max-w-lg" data-track-location={TrackLocations.InventoryDeleteEnvironmentModal}>
                <DialogHeader>
                    <DialogTitle>Delete environment</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogDescription>
                    This will delete the user&apos;s environment. They may lose any uncommitted changes.
                    <InventoryBanner {...p} className="mt-4" />
                    <div className="mt-4">
                        Tip: You may want to let {p.member.fullName} know that you&apos;ve deleted their environment.
                    </div>
                </DialogDescription>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={p.onClose}>
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        type="submit"
                        autoFocus={true}
                        variant="destructive"
                        onClick={(e) => {
                            e.preventDefault();
                            p.onContinue();
                        }}
                    >
                        Delete
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

type InventoryBannerProps = {
    environment: PlainEnvironment;
    member: PlainOrganizationMember;
    runner: PlainRunner;
    project?: PlainProject;
} & PropsWithClassName;

const InventoryBanner: FC<InventoryBannerProps> = (p) => {
    const projectOrRepo = useMemo(() => {
        if (p.project?.metadata?.name) {
            return p.project?.metadata?.name;
        }
        return getRepoUrl(p.environment)?.repoUrl?.replace("https://github.com/", "") || "loading...";
    }, [p.environment, p.project?.metadata?.name]);
    return (
        <div
            className={cn(
                "flex w-full flex-row rounded-lg border border-solid border-border-light bg-surface-secondary px-4 py-2",
                p.className,
            )}
        >
            <div className="flex flex-grow flex-col">
                <Text className="text-base font-bold">{projectOrRepo}</Text>
                <Text className="text-base">{p.runner.name}</Text>
            </div>
            <div className="inline-flex items-center space-x-2">
                <img referrerPolicy="no-referrer" src={p.member?.avatarUrl} className="h-8 w-8 rounded-full" />
                <Text className="text-base">{p.member?.fullName}</Text>
            </div>
        </div>
    );
};
