import { IconGitHub } from "@/assets/icons/geist/IconGitHub";
import { IconGoogle } from "@/assets/icons/geist/IconGoogle";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { useListSSOConfigurations } from "@/queries/organization-queries";
import { SSOConfigCard } from "@/routes/settings/login/SSOConfigCard";
import { SSOConfiguration_ProviderType, type SSOConfiguration } from "gitpod-next-api/gitpod/v1/organization_pb";
import { type FC } from "react";

export const LoginAndSecurity: FC<{ organizationId: string }> = ({ organizationId }) => {
    return (
        <div className="flex flex-col">
            <div className="flex h-full flex-col">
                <div className="text-base">Configure SSO for your organization.</div>
            </div>

            <LoginMethods organizationId={organizationId} />
        </div>
    );
};

const LoginMethods: FC<{ organizationId: string }> = ({ organizationId }) => {
    const { data: ssoConfigs } = useListSSOConfigurations();
    const builtinConfigs = ssoConfigs?.filter(isBuiltin) || [];
    const singleCustomConfig = ssoConfigs ? ssoConfigs.find(isCustom) : undefined;

    return (
        <div className="mt-6 flex flex-col gap-6">
            <Heading2>Log in</Heading2>
            {builtinConfigs.map((config) => (
                <SSOConfigCard
                    key={`built-in-config-${config.issuerUrl}`}
                    title={getTitle(config)}
                    icon={getIcon(config)}
                    organizationId={organizationId}
                    config={config}
                />
            ))}
            <SSOConfigCard
                title="Single Sign On"
                organizationId={organizationId}
                config={singleCustomConfig}
                isCustom={true}
            />
        </div>
    );
};

const isCustom = (config: SSOConfiguration) => config.providerType === SSOConfiguration_ProviderType.CUSTOM;
const isBuiltin = (config: SSOConfiguration) => config.providerType === SSOConfiguration_ProviderType.BUILTIN;
const getTitle = (config: SSOConfiguration) => {
    if (config.issuerUrl?.includes("github")) {
        return "GitHub";
    }
    if (config.issuerUrl?.includes("google")) {
        return "Google";
    }
    return "Unknown";
};
const getIcon = (config: SSOConfiguration) => {
    if (config.issuerUrl?.includes("github")) {
        return <IconGitHub size="lg" className="size-10" />;
    }
    if (config.issuerUrl?.includes("google")) {
        return <IconGoogle size="lg" className="size-10" />;
    }
    return undefined;
};
