import { useDocumentTitle } from "@/hooks/use-document-title";
import { JoinOrganization } from "@/routes/join-organization/JoinOrganization";
import { JoinOrganizationFromInvite } from "@/routes/join-organization/JoinOrganizationFromInvite";
import { type FC } from "react";
import { useParams } from "react-router-dom";

export const JoinOrganizationPage: FC = () => {
    const { inviteId } = useParams();
    useDocumentTitle("Join Organization");

    return (
        <>
            <div className="flex h-full w-full grow flex-col items-center justify-center px-8 py-4">
                {!inviteId && <JoinOrganization />}
                {inviteId && <JoinOrganizationFromInvite inviteID={inviteId} />}
            </div>
        </>
    );
};
