import type { PlainRunner } from "@/queries/runner-queries";

const templateURL = "https://gitpod-flex-releases.s3.amazonaws.com/ec2/stable/gitpod-ec2-runner.json";

export function createRunnerSetupURL(
    runner: PlainRunner,
    accessToken: string,
    region: string,
    cpURL = `${window.location.origin}/api`,
) {
    return `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks/create/review?templateURL=${templateURL}&stackName=${stackName(runner.name)}&param_ExchangeToken=${accessToken}&param_Endpoint=${cpURL}&param_RunnerID=${runner.runnerId}`;
}

export function createRunnerSetupText(
    runner: PlainRunner,
    accessToken: string,
    region: string,
    cpURL = `${window.location.origin}/api`,
) {
    return `
        Configuration details for ${runner?.name} runner

        Region:
        ${region}

        CloudFormation template URL:
        ${templateURL}

        Stack Name:
        ${stackName(runner.name)}

        Runner Token:
        ${accessToken}

        Runner ID:
        ${runner.runnerId}

        Endpoint:
        ${cpURL}
    `
        .split("\n")
        .map((line) => line.trimStart())
        .join("\n");
}

export function stackName(runnerName: string) {
    // Stack name can include letters (A–Z and a–z), numbers (0–9) and dashes (-).
    const name = runnerName.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9\\-]/g, "");
    return `Gitpod-${name}`;
}
