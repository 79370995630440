import { type ReactNode, useCallback, useId } from "react";
import { cn } from "@/components/podkit/lib/cn";
import { Checkbox, type CheckedState } from "@/components/podkit/checkbox/Checkbox";
import { InputFieldHint } from "@/components/podkit/forms/InputFieldHint";
import { InputField } from "@/components/podkit/forms/InputField";

type Props = {
    id?: string;
    value?: string;
    checked?: CheckedState;
    disabled?: boolean;
    label: ReactNode;
    hint?: ReactNode;
    error?: ReactNode;
    className?: string;
    onChange?: (checked: boolean) => void;
    "data-testid"?: string;
};
export const CheckboxInputField = ({
    id,
    value,
    label,
    hint,
    error,
    checked,
    disabled = false,
    className,
    onChange,
    "data-testid": testId,
}: Props) => {
    const maybeId = useId();
    const elementId = id ?? maybeId;

    const handleChange = useCallback(
        (state: CheckedState) => {
            onChange?.(state === true);
        },
        [onChange],
    );

    return (
        // Intentionally not passing label and hint to InputField because we want to render them differently for checkboxes.
        <InputField error={error} className={className} data-testid={testId}>
            <label className="flex max-w-lg cursor-pointer items-start justify-start space-x-2" htmlFor={elementId}>
                <Checkbox
                    id={elementId}
                    checked={checked}
                    disabled={disabled}
                    value={value}
                    onCheckedChange={handleChange}
                />
                <div className="flex flex-col">
                    <div
                        className={cn(
                            "cursor-pointer select-none text-sm font-bold",
                            disabled ? "text-content-secondary" : "text-content-primary",
                        )}
                    >
                        {label}
                    </div>

                    {hint && <InputFieldHint disabled={disabled}>{hint}</InputFieldHint>}
                </div>
            </label>
        </InputField>
    );
};
