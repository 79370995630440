import type { FC } from "react";
import awsLogo from "@/assets/aws.svg";
import azureLogo from "@/assets/azure.svg";
import gcpLogo from "@/assets/gcp.svg";
import linuxLogo from "@/assets/linux.svg";
import { cn } from "@/components/podkit/lib/cn";

export const IconStack: FC = () => {
    const className =
        "flex items-center justify-center rounded-lg border-0.5 border-border-light bg-surface-01 shadow-[0_0.6px_0.6px_0px_rgba(0,0,0,0.15)]";
    return (
        <div className="flex h-12 items-center">
            <div className={cn(className, "z-[10] h-12 w-12 p-1.5")}>
                <img src={awsLogo} />
            </div>
            <div className={cn(className, "z-[9] ml-[-8px] h-9 w-9 p-1 grayscale")}>
                <img src={azureLogo} />
            </div>
            <div className={cn(className, "z-[8] ml-[-8px] h-8 w-8 p-0.5 grayscale")}>
                <img src={gcpLogo} />
            </div>
            <div className={cn(className, "z-[7] ml-[-8px] h-7 w-7 p-0.5 grayscale")}>
                <img src={linuxLogo} />
            </div>
        </div>
    );
};
