import { useDocumentTitle } from "@/hooks/use-document-title";
import { useCallback, useMemo, useState, type FC } from "react";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useListPersonalAccessTokens, type PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { NewPersonalAccessTokenModal } from "@/routes/settings/user/personal-access-tokens/NewPersonalAccessTokenModal";
import { useMembers } from "@/queries/organization-queries";
import { PersonalAccessTokensList } from "@/routes/settings/user/personal-access-tokens/PersonalAccessTokensList";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { Button } from "@/components/flexkit/Button";
import { IconRefresh } from "@/assets/icons/geist/IconRefresh";
import { DeletePersonalAccessTokenModal } from "@/routes/settings/user/personal-access-tokens/DeletePersonalAccessTokenModal";

export const PersonalAccessTokensPage: FC = () => {
    useDocumentTitle("Personal Access Tokens");

    const { data: user } = useAuthenticatedUser();
    const { data: membersData, isPending: isPendingMembers, error: membersError } = useMembers();
    const {
        data: patsData,
        error: patsError,
        isPending: isPendingPATs,
    } = useListPersonalAccessTokens({ userId: user?.id || "" });

    const tokens = useMemo(() => {
        if (!membersData || !patsData || !user) {
            return [];
        }

        const currentMember = membersData.members.find((m) => m.userId === user.id);

        return patsData.pats.map((pat) => {
            return {
                pat,
                creator: membersData.members.find((m) => m.userId === pat.creator?.id),
                currentMember,
            };
        });
    }, [membersData, patsData, user]);

    const [showNewPATModal, setShowNewPATModal] = useState(false);
    const [patToDelete, setPatToDelete] = useState<PlainPersonalAccessToken | undefined>();

    let content: React.ReactNode | null = null;
    if (patsError || membersError) {
        content = <FailedToLoad />;
    } else if (isPendingPATs || isPendingMembers) {
        content = <SkeletonBlock ready={false} failed={!!patsError || !!membersError} className="h-[160px] w-full" />;
    } else {
        content = (
            <PersonalAccessTokensList
                tokens={tokens ?? []}
                onNew={() => setShowNewPATModal(true)}
                onDelete={(pat) => setPatToDelete(pat)}
            />
        );
    }

    return (
        <div data-testid="personal-access-tokens-page" className="flex flex-col gap-6">
            <Text className="text-base text-content-secondary">
                Personal access tokens (PATs) are secure credentials that allow API access to Gitpod without manually
                logging in. They enable integration with external tools and workflow automation, while providing users
                the ability to revoke access when needed.
            </Text>
            {content}
            {showNewPATModal && <NewPersonalAccessTokenModal onClose={() => setShowNewPATModal(false)} />}
            {patToDelete && (
                <DeletePersonalAccessTokenModal pat={patToDelete} onClose={() => setPatToDelete(undefined)} />
            )}
        </div>
    );
};

const FailedToLoad: FC = () => {
    const onReload = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div
            className="flex flex-col items-center gap-5 p-12"
            data-testid="error-failed-to-load-personal-access-tokens-data"
        >
            <div className="flex flex-col items-center gap-1">
                <Heading2 className="text-xl font-bold text-content-primary">
                    We&apos;re having trouble loading the personal access tokens.
                </Heading2>
                <Text className="text-lg text-content-secondary">
                    You can try refreshing the page. If the problem persists, please contact support.
                </Text>
            </div>
            <Button variant="secondary" LeadingIcon={IconRefresh} onClick={onReload}>
                Refresh Page
            </Button>
        </div>
    );
};
