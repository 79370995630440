import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";
import { BenefitsCard } from "@/components/onboarding/BenefitsCard";
import { IconStack } from "@/components/onboarding/IconStack";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import { type FC } from "react";
import { Link } from "react-router-dom";
import { GitpodDesktopBenefitsCard } from "@/components/onboarding/GitpodDesktopBenefitsCard";

export const SET_UP_A_RUNNER_TITLE = "Set up a runner";
export const SET_UP_A_RUNNER_DESCRIPTION =
    "Gitpod runs your dev environment on secure runners, deployed on your own infrastructure, AWS, or your local machine.";

export const SelectARunnerPage: FC = () => {
    return (
        <OnboardingTabContent
            data-track-location={TrackLocations.OnboardingRunnerSelectTab}
            title={SET_UP_A_RUNNER_TITLE}
            description={SET_UP_A_RUNNER_DESCRIPTION}
            showDivider={true}
        >
            <div className="flex flex-col gap-6 lg:flex-row">
                <GitpodDesktopBenefitsCard withButton withLearnMoreLink />
                <BenefitsCard
                    heading="Remote Runner"
                    subheading="Use your own infrastructure"
                    icon={<IconStack />}
                    benefits={[
                        { text: "Setup in your own cloud in minutes" },
                        { text: "Setup once and let your entire team use this runner" },
                        { text: "Access environments with enhanced memory and CPU" },
                    ]}
                    button={
                        <Button
                            size="md"
                            variant="secondary"
                            className="w-full"
                            aria-label={"Continue with remote runner"}
                            asChild
                        >
                            <Link to="/onboarding/set-up-runner/remote/provider">Continue</Link>
                        </Button>
                    }
                    learnMoreLink="https://www.gitpod.io/docs/flex/runners"
                />
            </div>
        </OnboardingTabContent>
    );
};
