import { PrivacyPolicyNudge } from "@/components/nudges/PrivacyPolicyNudge";
import { cn } from "@/components/podkit/lib/cn";
import { useNudges } from "@/hooks/use-nudges";
import type { FC } from "react";

export const Nudges: FC = () => {
    const { active } = useNudges();
    return (
        <div data-testid="nudges" className={cn({ hidden: !active })}>
            <PrivacyPolicyNudge />
        </div>
    );
};
