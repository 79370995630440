import { useListRunners, type PlainRunner } from "@/queries/runner-queries";
import { useEffect, useState } from "react";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { useAuthenticatedUser } from "@/queries/user-queries";

export function useAvailableRunners() {
    const { data: user } = useAuthenticatedUser();
    const {
        data: remoteRunners,
        isLoading: isLoadingRemoteRunners,
        isFetching: isFetchingRemoteRunners,
        isPending: isPendingRemoteRunners,
    } = useListRunners({ kind: RunnerKind.REMOTE });
    const {
        data: localRunners,
        isLoading: isLoadingLocalRunners,
        isFetching: isFetchingLocalRunners,
        isPending: isPendingLocalRunners,
    } = useListRunners({ creatorId: user?.id, kind: RunnerKind.LOCAL });

    const [availableRunners, setAvailableRunners] = useState<PlainRunner[]>([]);

    useEffect(() => {
        if (!user) {
            return;
        }
        const joined = [...(remoteRunners?.runners || []), ...(localRunners?.runners || [])];
        setAvailableRunners(joined);
    }, [remoteRunners?.runners, localRunners?.runners, user]);

    return {
        isLoading:
            isLoadingRemoteRunners ||
            isFetchingRemoteRunners ||
            isPendingRemoteRunners ||
            isLoadingLocalRunners ||
            isFetchingLocalRunners ||
            isPendingLocalRunners,
        availableRunners,
    };
}
