import { IconApp } from "@/assets/icons/geist/IconApp";
import { Button } from "@/components/flexkit/Button";
import { BenefitsCard } from "@/components/onboarding/BenefitsCard";
import type { FC } from "react";
import { Link } from "react-router-dom";

export const GitpodDesktopBenefitsCard: FC<{
    withButton?: boolean;
    withLearnMoreLink: boolean;
}> = ({ withButton, withLearnMoreLink }) => {
    return (
        <BenefitsCard
            heading="Gitpod Desktop"
            subheading="No cloud access? Start local"
            icon={<IconApp size="lg" className="size-12" />}
            benefits={[
                { text: "Best for getting started quickly without cloud access" },
                { text: "No infrastructure costs, as everything runs locally" },
                { text: "Requires macOS (Apple Silicon)" },
            ]}
            button={
                withButton ? (
                    <Button
                        size="md"
                        variant="brand"
                        className="w-full"
                        aria-label={"Continue with Gitpod Desktop"}
                        asChild
                    >
                        <Link to="/onboarding/set-up-runner/desktop">Continue</Link>
                    </Button>
                ) : undefined
            }
            learnMoreLink={withLearnMoreLink ? "https://www.gitpod.io/docs/flex/gitpod-desktop" : undefined}
        />
    );
};
