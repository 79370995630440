import type { PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const TimelineElipsis: FC<PropsWithClassName> = ({ className }) => {
    return (
        <svg
            className={className}
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="1" cy="1" r="1" fill="currentColor" />
            <circle cx="7" cy="1" r="1" fill="currentColor" />
            <circle cx="13" cy="1" r="1" fill="currentColor" />
        </svg>
    );
};

export const TimelineVerticalElipsis: FC<PropsWithClassName> = ({ className }) => {
    return (
        <svg
            className={className}
            width="2"
            height="26"
            viewBox="0 0 2 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="1" cy="1" r="1" fill="currentColor" />
            <circle cx="1" cy="7" r="1" fill="currentColor" />
            <circle cx="1" cy="13" r="1" fill="currentColor" />
            <circle cx="1" cy="19" r="1" fill="currentColor" />
            <circle cx="1" cy="25" r="1" fill="currentColor" />
        </svg>
    );
};
