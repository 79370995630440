import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconRefresh: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.00017 2.09375C5.67046 2.09375 3.64558 3.39467 2.61056 5.3066L2.29815 5.88371L3.45237 6.50854L3.76479 5.93143C4.57964 4.4262 6.17128 3.40625 8.00017 3.40625C10.1786 3.40625 12.02 4.85427 12.6125 6.84029H11.3464H10.6902V8.15279H11.3464H14.3429C14.7054 8.15279 14.9992 7.85898 14.9992 7.49654V4.5V3.84375H13.6867V4.5V5.9391C12.7823 3.68522 10.5776 2.09375 8.00017 2.09375ZM2.31372 10.4986V11.9375V12.5938H1.00122V11.9375V8.94096C1.00122 8.57852 1.29503 8.28471 1.65747 8.28471H4.65401H5.31026V9.59721H4.65401H3.38782C3.98037 11.5832 5.82168 13.0312 8.00017 13.0312C9.82007 13.0312 11.405 12.0213 12.2234 10.5283L12.5389 9.95283L13.6898 10.5838L13.3744 11.1592C12.3348 13.0557 10.3184 14.3438 8.00017 14.3438C5.42287 14.3438 3.21815 12.7524 2.31372 10.4986Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 3.25C7.33749 3.25 5.02334 4.73677 3.84047 6.92183L3.48342 7.58138L4.80253 8.29548L5.15958 7.63592C6.09084 5.91566 7.90986 4.75 10 4.75C12.4897 4.75 14.5941 6.40488 15.2713 8.67462H13.8243H13.0743V10.1746H13.8243H17.2489C17.6631 10.1746 17.9989 9.83883 17.9989 9.42462V6V5.25H16.4989V6V7.64468C15.4653 5.06882 12.9456 3.25 10 3.25ZM3.50122 12.8555V14.5V15.25H2.00122V14.5V11.0754C2.00122 10.6612 2.33701 10.3254 2.75122 10.3254H6.17584H6.92584V11.8254H6.17584H4.72876C5.40596 14.0951 7.51032 15.75 10 15.75C12.0799 15.75 13.8912 14.5958 14.8266 12.8895L15.1871 12.2318L16.5025 12.9529L16.142 13.6105C14.9539 15.7779 12.6494 17.25 10 17.25C7.05453 17.25 4.53485 15.4313 3.50122 12.8555Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.9999 4.40625C9.00452 4.40625 6.4011 6.07887 5.07038 8.53706L4.66869 9.27905L6.15269 10.0824L6.55437 9.34041C7.60204 7.40512 9.64844 6.09375 11.9999 6.09375C14.8008 6.09375 17.1682 7.95549 17.9301 10.5089H16.3022H15.4584V12.1964H16.3022H20.1549C20.6208 12.1964 20.9986 11.8187 20.9986 11.3527V7.5V6.65625H19.3111V7.5V9.35027C18.1483 6.45242 15.3136 4.40625 11.9999 4.40625ZM4.68872 15.2124V17.0625V17.9062H3.00122V17.0625V13.2098C3.00122 12.7438 3.37898 12.3661 3.84497 12.3661H7.69767H8.54142V14.0536H7.69767H6.0697C6.83155 16.607 9.19896 18.4688 11.9999 18.4688C14.3397 18.4688 16.3774 17.1703 17.4298 15.2507L17.8353 14.5108L19.3152 15.322L18.9096 16.0618C17.573 18.5001 14.9804 20.1562 11.9999 20.1562C8.68619 20.1562 5.85155 18.1102 4.68872 15.2124Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
