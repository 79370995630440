import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconMinimize: FC<{ size: Size } & PropsWithClassName> = ({ className }) => {
    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M3 8H13" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    );
};
