import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconBoxLink: FC<{ state: "open" | "closed" } & PropsWithClassName> = ({ state, className }) => {
    switch (state) {
        case "closed":
            return (
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g transform="translate(0, 1)">
                        <path
                            d="M21 15V12V8.29975C21 7.50937 20.5345 6.79313 19.8123 6.47212L12.8123 3.36101C12.2951 3.13118 11.7049 3.13118 11.1877 3.36101L4.18772 6.47212C3.46547 6.79313 3 7.50937 3 8.29975V15.7003C3 16.4906 3.46547 17.2069 4.18772 17.5279L6 18.3333"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M14 22.1958H11.5C10.1193 22.1958 9 21.0765 9 19.6958C9 18.3151 10.1193 17.1958 11.5 17.1958H14"
                            stroke="currentColor"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M15.5 22.1958H18C19.3807 22.1958 20.5 21.0765 20.5 19.6958C20.5 18.3151 19.3807 17.1958 18 17.1958H15.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                        />
                        <path d="M12 19.6958H17" stroke="currentColor" strokeWidth="1.5" />
                        <path d="M20 8L12 11V14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M4 8L12 11" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M16 5L7 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    </g>
                </svg>
            );
        case "open":
            return (
                <svg
                    width="24"
                    height="26"
                    viewBox="0 0 24 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={className}
                >
                    <g>
                        <path
                            d="M21 16.5V9.5M17.25 7.33333L16.5 7L12.8123 5.36101C12.2951 5.13118 11.7049 5.13118 11.1877 5.36101L4.18772 8.47212C3.46547 8.79313 3 9.50937 3 10.2997V17.8676C3 18.5701 3.3686 19.2212 3.97101 19.5826L5.5 20.5"
                            stroke="currentcolor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M3 9L1.468 5.93601C1.21304 5.42609 1.43532 4.80652 1.95629 4.57498L9.12678 1.3881C9.61533 1.17096 10.1883 1.37651 10.4273 1.85469L12 5"
                            stroke="currentcolor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                        <path
                            d="M14 24.1958H11.5C10.1193 24.1958 9 23.0765 9 21.6958C9 20.3151 10.1193 19.1958 11.5 19.1958H14"
                            stroke="currentcolor"
                            strokeWidth="1.5"
                        />
                        <path
                            d="M15.5 24.1958H18C19.3807 24.1958 20.5 23.0765 20.5 21.6958C20.5 20.3151 19.3807 19.1958 18 19.1958H15.5"
                            stroke="currentcolor"
                            strokeWidth="1.5"
                        />
                        <path d="M12 21.6958H17" stroke="currentcolor" strokeWidth="1.5" />
                        <path d="M21 9.5L12 13V16" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
                        <path d="M4 10L12 13" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" />
                        <path
                            d="M12 12.5L14.8286 8.72849C14.9406 8.57915 15.0922 8.46414 15.2662 8.39648L22.5247 5.57373C22.98 5.39665 23.3929 5.91058 23.1219 6.31708L21 9.5"
                            stroke="currentcolor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                        />
                    </g>
                </svg>
            );
    }
};
