import type {
    OnboardingQuestionnaireId,
    OnboardingQuestionnaireStepData,
    OnboardingQuestionnaireSteps,
} from "@/hooks/use-onboarding-questionnaire";
import { createContext } from "react";

export const OnboardingQuestionnaireContext = createContext<{
    steps: OnboardingQuestionnaireSteps;
    complete: (step: OnboardingQuestionnaireId) => void;
    updateData: (data: OnboardingQuestionnaireStepData) => void;
} | null>(null);
