import { createContext, useCallback, useEffect, useState } from "react";

export type Theme = "dark" | "light" | "system";

// eslint-disable-next-line react-refresh/only-export-components
export const themes = ["dark", "light", "system"] as const;

type ThemeProviderProps = {
    children: React.ReactNode;
    defaultTheme?: Theme;
    storageKey?: string;
};

type ThemeProviderState = {
    theme: Theme;
    effectiveTheme: "light" | "dark";
    setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
    theme: "system",
    effectiveTheme: "light",
    setTheme: () => null,
};

export const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
    children,
    defaultTheme = "system",
    storageKey = "gitpod-ui-theme",
    ...props
}: ThemeProviderProps) {
    const [theme, setTheme] = useState<Theme>(() => {
        // Attempt to fetch the stored theme, fallback to default if not found
        const storedTheme = localStorage.getItem(storageKey);
        return storedTheme ? (storedTheme as Theme) : defaultTheme;
    });

    const getCurrentTheme = () => (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    const [effectiveTheme, setEffectiveTheme] = useState<"light" | "dark">(getCurrentTheme());

    const setThemeOnDOM = useCallback((theme: "dark" | "light") => {
        const root = window.document.documentElement;
        root.classList.remove("light", "dark");
        document.documentElement.classList.add(theme);
        let meta = document.querySelector("head > meta[name='color-scheme']");
        if (!meta) {
            meta = document.createElement("meta");
            meta.setAttribute("name", "color-scheme");
            meta.setAttribute("content", theme);
            document.head.appendChild(meta);
        }
        meta.setAttribute("content", theme);
        setEffectiveTheme(theme);
    }, []);

    useEffect(() => {
        const applyThemeBasedOnSelection = (themeValue: Theme) => {
            const theme = themeValue === "system" ? getCurrentTheme() : themeValue;
            setThemeOnDOM(theme);
        };

        // Apply theme based on the current selection
        applyThemeBasedOnSelection(theme);

        // Set up a listener for changes in the system theme preference if the theme is set to 'system'
        if (theme === "system") {
            const handleSystemThemeChange = (event: MediaQueryListEvent) => {
                const newTheme = event.matches ? "dark" : "light";
                setThemeOnDOM(newTheme);
            };

            const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
            mediaQuery.addEventListener("change", handleSystemThemeChange);

            // Clean up the event listener when the component unmounts or the theme changes
            return () => {
                mediaQuery.removeEventListener("change", handleSystemThemeChange);
            };
        }
    }, [setThemeOnDOM, theme]);

    // Update the setTheme function to handle user-initiated theme changes
    const value = {
        theme,
        effectiveTheme,
        setTheme: (newTheme: Theme) => {
            localStorage.setItem(storageKey, newTheme); // Persist the new theme selection
            setTheme(newTheme); // This will re-trigger the useEffect and apply the new theme
        },
    };

    return (
        <ThemeProviderContext.Provider value={value} {...props}>
            {children}
        </ThemeProviderContext.Provider>
    );
}
