import { isMacLike } from "@/utils/platform";
import { useCallback, useEffect } from "react";

/**
 * Enables platform-specific Cmd+, (or Ctrl+,) to open settings
 */
export const useOpenSettingsShortcut = (setTriggered: (value: boolean) => void) => {
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key !== ",") {
                return;
            }
            if (isMacLike() ? event.metaKey : event.ctrlKey) {
                event.preventDefault();
                setTriggered(true);
            }
        },
        [setTriggered],
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);
};
