import { Button } from "@/components/flexkit/Button";
import { Text } from "@/components/podkit/typography/Text";
import {
    DialogBody,
    DialogClose,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import {
    type EnvironmentClass,
    type EnvironmentClassValidationResult,
    type FieldValue,
    type RunnerConfigurationSchema_Field,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { useCallback, useMemo, useState, type FC, type FormEvent } from "react";
import { useSetEnvironmentClassEnabled, useUpdateEnvironmentClass } from "@/queries/runner-configuration-queries";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { InputField } from "@/components/podkit/forms/InputField";
import { Input } from "@/components/podkit/forms/Input";

export const EnvironmentClassEditModal: FC<{
    environmentClass: EnvironmentClass;
    schema: RunnerConfigurationSchema_Field[];
    onClose: () => void;
}> = ({ environmentClass, schema, onClose }) => {
    const { toast } = useToast();
    const updateEnvironmentClass = useUpdateEnvironmentClass(environmentClass);
    const setEnvironmentClassEnabled = useSetEnvironmentClassEnabled(environmentClass);

    const [validationErrors, setValidationErrors] = useState<EnvironmentClassValidationResult | undefined>();

    const handleSubmit = useCallback(
        async (values: { displayName: string; description: string }) => {
            try {
                const validationResult = await updateEnvironmentClass.mutateAsync({
                    displayName: values.displayName,
                    description: values.description,
                });
                setValidationErrors(validationResult);
                if (!validationResult?.valid) {
                    return;
                }

                toast({
                    title: "Environment class updated",
                    description: "The environment class has been updated successfully.",
                });
                onClose();
            } catch (error) {
                toast({
                    title: "Failed to update environment class",
                    description: formatError(error),
                });
            }
        },
        [onClose, toast, updateEnvironmentClass],
    );

    return (
        <>
            <DialogHeader>
                <DialogTitle>Edit environment class</DialogTitle>
                <DialogDescription />
            </DialogHeader>

            <DialogBody>
                <DisplayForm
                    formId="environment-class-edit"
                    schema={schema}
                    configuration={environmentClass.configuration}
                    initialValues={{
                        displayName: environmentClass.displayName,
                        description: environmentClass.description,
                    }}
                    validationErrors={validationErrors}
                    onSubmit={handleSubmit}
                />
            </DialogBody>

            <DialogFooter>
                <div className="flex w-full flex-col gap-2 sm:flex-row sm:justify-end">
                    <div className="flex flex-col gap-2 sm:flex-row sm:justify-end">
                        <DialogClose asChild>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onClose}
                                disabled={updateEnvironmentClass.isPending || setEnvironmentClassEnabled.isPending}
                            >
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button
                            autoFocus={false}
                            variant="brand"
                            type="submit"
                            form="environment-class-edit"
                            loading={updateEnvironmentClass.isPending}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </DialogFooter>
        </>
    );
};

const Configuration: FC<{
    configuration: FieldValue[];
    schema: RunnerConfigurationSchema_Field[];
}> = ({ configuration, schema }) => {
    const fields = useMemo(() => Object.fromEntries(schema.map((field) => [field.id, field])), [schema]);
    return (
        <div>
            {configuration.map((field) => {
                let displayValue = field.value;
                if (field.key === "spot") {
                    displayValue = field.value === "true" ? "Yes" : "No";
                }
                return (
                    <div key={field.key} className="flex gap-1">
                        <Text className="text-base font-bold">
                            {fields[field.key]?.name || <span>field.key</span>}
                            <span>:</span>
                        </Text>
                        <Text className="text-base capitalize">{displayValue}</Text>
                    </div>
                );
            })}
        </div>
    );
};

export const DisplayForm: FC<{
    formId: string;
    schema: RunnerConfigurationSchema_Field[];
    configuration: FieldValue[];
    validationErrors: EnvironmentClassValidationResult | undefined;
    initialValues: { displayName: string; description: string };
    onSubmit: (values: { displayName: string; description: string; configuration: FieldValue[] }) => void;
}> = ({ formId, initialValues, schema, configuration, validationErrors, onSubmit }) => {
    const [name, setName] = useState(initialValues.displayName);
    const [description, setDescription] = useState(initialValues.description);

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit({
                description: description,
                displayName: name,
                configuration,
            });
        },
        [configuration, onSubmit, name, description],
    );

    return (
        <form onSubmit={handleSubmit} id={formId} name={formId} className="flex flex-col gap-4">
            <Configuration configuration={configuration} schema={schema} />

            <InputField label="Class name" id="name" disabled={false} error={validationErrors?.displayNameError}>
                <Input
                    id="name"
                    value={name}
                    onChange={(value) => {
                        setName(value.target.value);
                    }}
                />
            </InputField>

            <InputField
                label="Class description"
                id="description"
                disabled={false}
                error={validationErrors?.descriptionError}
            >
                <Input
                    id="description"
                    value={description}
                    onChange={(value) => {
                        setDescription(value.target.value);
                    }}
                />
            </InputField>
        </form>
    );
};
