import { type FC } from "react";
import { useCallback } from "react";
import { useRunner } from "@/queries/runner-queries";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { TrackLocations } from "@/hooks/use-segment";
import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { CloudFormationStackDetails } from "@/components/runners/details/CloudFormationStackDetails";
import { CloudFormationStackCreateButton } from "@/components/runners/details/CloudFormationStackCreateButton";
import { CloudFormationStackCopyDetails } from "@/components/runners/details/CloudFormationStackCopyDetails";
import { RunnerPhaseTag } from "@/components/runners/RunnerCard";
import { useNavigate, useParams } from "react-router-dom";
import { TimelineContentContainer } from "@/components/onboarding/TimelineContentContainer";

export const InstallRunnerPage: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { data: runner } = useRunner(params.runnerId);

    const hasStack = runner?.status?.phase !== RunnerPhase.CREATED;

    const troubleShootingDetails = [
        { label: "Runner ID", value: runner?.runnerId },
        { label: "Status", value: runner ? <RunnerPhaseTag runner={runner} /> : "Unknown" },
        { label: "Version", value: runner?.status?.version || "Unknown" },
        { label: "Region", value: runner?.spec?.configuration?.region },
    ];

    const onBack = useCallback(() => {
        navigate("/onboarding/set-up-runner/remote/configure/create");
    }, [navigate]);

    const onNext = useCallback(() => {
        navigate(`/onboarding/set-up-runner/remote/configure/${params.runnerId}/source-control-access`);
    }, [navigate, params.runnerId]);

    return (
        <TimelineContentContainer
            onBack={onBack}
            nextTitle="Configure Git provider"
            onNextDisabled={!hasStack}
            onNext={onNext}
            data-track-location={TrackLocations.OnboardingRunnerConfigureCloudFormationStackTabStep}
        >
            <SkeletonBlock ready={Boolean(runner)} className="h-[150px]">
                {runner && (
                    <div className="flex flex-col justify-between gap-4 lg:flex-row lg:gap-8">
                        <div className="flex w-full flex-col gap-2 lg:w-1/2">
                            <Heading2>Install runner</Heading2>
                            <Text className="text-base text-content-secondary">
                                The CloudFormation template installs an ECS service, an S3 bucket, IAM roles and
                                (optionally) security groups for running development environments. Uninstalling the
                                CloudFormation stack will remove the runner completely.
                            </Text>
                            <Text className="text-base text-content-secondary">
                                If you encounter any issues while setting up the runner, please see our{" "}
                                <ExternalLink href="https://www.gitpod.io/docs/flex/runners/aws/troubleshooting#network-misconfiguration">
                                    troubleshooting documentation
                                </ExternalLink>
                                .
                            </Text>
                            <details>
                                <summary className="cursor-pointer text-base text-content-secondary">
                                    Troubleshooting details
                                </summary>
                                <ul className="pl-3 pt-1">
                                    {troubleShootingDetails.map(({ label, value }) => (
                                        <div className="flex flex-col gap-1 md:flex-row md:gap-2" key={label}>
                                            <Text className="min-w-max text-base font-bold">{label}: </Text>
                                            <Text className="shrink truncate text-base">{value}</Text>
                                        </div>
                                    ))}
                                </ul>
                            </details>
                        </div>
                        <div className="flex w-full flex-col gap-4 lg:w-1/2">
                            <div className="rounded-lg border-0.5 border-border-base bg-surface-tertiary bg-transparent p-4">
                                {hasStack ? (
                                    <CloudFormationStackDetails runner={runner} />
                                ) : (
                                    <div className="flex flex-col gap-2">
                                        <Text className="text-base">
                                            We&apos;ll help you setup a new runner on your AWS EC2 infrastructure.
                                        </Text>
                                        <div className="py-2">
                                            <CloudFormationStackCreateButton runner={runner} />
                                        </div>
                                        <Text className="text-sm text-content-secondary">
                                            The link above will take you to AWS CloudFormation with a set of
                                            pre-populated values to quickly get everything working.
                                        </Text>
                                        <CloudFormationStackCopyDetails runner={runner} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </SkeletonBlock>
        </TimelineContentContainer>
    );
};
