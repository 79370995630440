import { Button } from "@/components/flexkit/Button";
import { Input } from "@/components/podkit/forms/Input";
import { Label } from "@/components/podkit/forms/Label";
import { TagsInput } from "@/components/podkit/forms/TagsInput";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading3, Subheading } from "@/components/podkit/typography/Headings";
import { useOrganization, useUpdateOrganization, type PlainOrganization } from "@/queries/organization-queries";
import { DeleteOrganizationModal } from "@/routes/settings/organization/manage-organization/DeleteOrganizationModal";
import { formatError } from "@/utils/errors";
import { useCallback, useEffect, useId, useState, type FC, type FormEvent } from "react";
import { useNavigate } from "react-router-dom";

export const OrganizationSettings: FC = () => {
    const { data: organization, isLoading } = useOrganization();

    if (!organization || isLoading) {
        return <LoadingState />;
    }

    return (
        <div className="flex flex-col gap-8 pt-6">
            <OrganizationDetailsCard />
            <OrganizationAllowedEmailDomainsCard />
            <OrganizationDeleteCard org={organization} />
        </div>
    );
};

const OrganizationDetailsCard: FC = () => {
    const { toast } = useToast();

    const { data: orgData } = useOrganization();

    const inputNameID = useId();
    const [organizationName, setOrganizationName] = useState<string | undefined>();
    const updateOrganization = useUpdateOrganization();

    useEffect(() => {
        setOrganizationName(orgData?.name);
    }, [orgData]);

    const handleUpdateName = useCallback(
        (evt: FormEvent<HTMLFormElement>) => {
            if (!orgData) {
                return;
            }
            evt.preventDefault();

            if (!organizationName) {
                toast({ title: "Organization name cannot be empty" });
                return;
            }

            updateOrganization.mutate(
                { name: organizationName },
                {
                    onSuccess: () => {
                        toast({ title: "Updated display name" });
                    },
                    onError: (err) => {
                        toast({ title: "Failed to update display name", description: formatError(err) });
                    },
                },
            );
        },
        [orgData, organizationName, updateOrganization, toast],
    );

    return (
        <>
            <form onSubmit={handleUpdateName}>
                <Label htmlFor={inputNameID}>Display name</Label>
                <Input
                    id={inputNameID}
                    type="text"
                    name="organization_name"
                    value={organizationName || ""}
                    className="mt-1"
                    onChange={(e) => setOrganizationName(e.target.value)}
                />

                <Button variant={"secondary"} className="mt-3" type="submit" loading={updateOrganization.isPending}>
                    Update
                </Button>
            </form>
        </>
    );
};

const OrganizationAllowedEmailDomainsCard: FC = () => {
    const { toast } = useToast();

    const { data: orgData } = useOrganization();

    const [allowedEmailDomains, setAllowedEmailDomains] = useState<string[]>([]);
    const updateOrganization = useUpdateOrganization();

    useEffect(() => {
        if (!orgData) {
            return;
        }
        setAllowedEmailDomains(orgData?.inviteDomains?.domains || []);
    }, [orgData]);

    const handleUpdateInviteDomains = useCallback(
        (evt: FormEvent<HTMLFormElement>) => {
            if (!orgData) {
                return;
            }
            evt.preventDefault();

            updateOrganization.mutate(
                { inviteDomains: allowedEmailDomains },
                {
                    onSuccess: ({ organization }) => {
                        setAllowedEmailDomains(organization.inviteDomains?.domains || []);
                        toast({
                            title: "Updated allowed email domains",
                        });
                    },
                    onSettled: (_, err) => {
                        if (err) {
                            setAllowedEmailDomains(orgData.inviteDomains?.domains || []);
                            toast({
                                title: "Failed to update organization's allowed email domains",
                                description: formatError(err),
                            });
                        }
                    },
                },
            );
        },
        [allowedEmailDomains, updateOrganization, orgData, toast],
    );

    return (
        <div className="flex flex-col gap-3">
            <div>
                <Heading3>Allowed email domains</Heading3>
                <Subheading>Enter an email domain of an existing member of this organization.</Subheading>
            </div>

            <form onSubmit={handleUpdateInviteDomains}>
                <TagsInput
                    name="invite_domains"
                    value={allowedEmailDomains}
                    onChange={setAllowedEmailDomains}
                    placeHolder="yourcompany.com"
                />

                <Button variant={"secondary"} className="mt-3" type="submit" loading={updateOrganization.isPending}>
                    Update
                </Button>
            </form>
        </div>
    );
};

type OrganizationDeleteCardProps = {
    org: PlainOrganization;
};

const OrganizationDeleteCard: FC<OrganizationDeleteCardProps> = ({ org }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const onDeletion = useCallback(() => {
        // Move us away from the organization settings page
        navigate("/", { replace: true });
    }, [navigate]);
    return (
        <div className="space-y-3">
            <div>
                <Heading3>Delete organization</Heading3>
                <Subheading>This will delete this organization, including members and all their resources.</Subheading>
            </div>

            <Button type="button" variant={"destructive"} onClick={() => setShowModal(true)}>
                Delete this Organization
            </Button>

            {showModal && (
                <DeleteOrganizationModal org={org} onContinue={onDeletion} onClose={() => setShowModal(false)} />
            )}
        </div>
    );
};
