import Breadcrumbs from "@/components/Breadcrumb";
import { PageSuspense } from "@/components/PageSuspense";
import type { FC } from "react";
import { Outlet } from "react-router-dom";

export const SettingsPageLayout: FC = () => {
    return (
        <div className="flex flex-col gap-4">
            <div>
                <Breadcrumbs />
            </div>
            <div>
                <PageSuspense>
                    <Outlet />
                </PageSuspense>
            </div>
        </div>
    );
};
