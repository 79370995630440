import { Heading4 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, useState, type FC } from "react";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import appleSilicon from "@/assets/apple-silicon.svg";
import windowsIcon from "@/assets/microsoft-windows.svg";
import linuxLogo from "@/assets/linux.svg";
import { Button } from "@/components/flexkit/Button";
import { TrackLocations } from "@/hooks/use-segment";
import { Link } from "react-router-dom";
import { OnboardingStepId, routeForStep, useOnboarding } from "@/hooks/use-onboarding";
import { DesktopPlatformOption } from "@/components/onboarding/DesktopPlatformOption";
import { DESKTOP_APP_DOWNLOAD_URL } from "@/desktop";
import { GitpodDesktopBenefitsCard } from "@/components/onboarding/GitpodDesktopBenefitsCard";
import { SET_UP_A_RUNNER_DESCRIPTION, SET_UP_A_RUNNER_TITLE } from "@/routes/onboarding/SelectRunnerPage";

export const GitpodDesktopPage: FC = () => {
    const onboarding = useOnboarding();
    const [hasClickedDownload, setHasClickedDownload] = useState(false);

    const onContinueWithDesktop = useCallback(() => {
        onboarding.complete(OnboardingStepId.SetUpARunner);
        setHasClickedDownload(true);
    }, [onboarding, setHasClickedDownload]);

    return (
        <OnboardingTabContent
            title={SET_UP_A_RUNNER_TITLE}
            description={SET_UP_A_RUNNER_DESCRIPTION}
            showDivider={true}
            data-track-location={TrackLocations.OnboardingRunnerDesktopTab}
        >
            <div className="flex flex-col gap-8 lg:flex-row">
                <GitpodDesktopBenefitsCard withButton={false} withLearnMoreLink={false} />

                <div className="flex basis-1/2 flex-col gap-6">
                    <div className="flex flex-col">
                        <Heading4 className="text-xl font-normal">Select platform</Heading4>
                    </div>
                    {hasClickedDownload ? (
                        <div className="flex flex-col gap-4 rounded-xl border-[0.5px] border-border-base px-5 py-4">
                            <div className="flex items-center">
                                <img src={appleSilicon} className={"mr-3 h-8 w-8"} />
                                <div className="text-base font-medium text-content-secondary">
                                    macOS (Apple Silicon)
                                </div>
                            </div>
                            <div className="border-t border-dashed border-border-base" />
                            <div className="flex flex-col pb-4 pt-2">
                                <Text className="text-center text-xl font-bold text-content-primary">
                                    Download in progress...
                                </Text>
                                <Text className="text-center text-base text-content-primary">
                                    After installation, you can continue in Gitpod Desktop
                                </Text>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-2">
                            <DesktopPlatformOption
                                name="macOS (Apple Silicon)"
                                downloadUrl={DESKTOP_APP_DOWNLOAD_URL}
                                iconImgSrc={appleSilicon}
                                onClick={onContinueWithDesktop}
                            />
                            <DesktopPlatformOption name="Microsoft Windows" iconImgSrc={windowsIcon} disabled />
                            <DesktopPlatformOption name="Linux" iconImgSrc={linuxLogo} disabled />
                        </div>
                    )}
                    <Text className="text-center text-base text-content-tertiary">
                        Alternatively, you can set up a{" "}
                        <Link to="/onboarding/set-up-runner/remote/provider" className="text-content-link">
                            remote runner
                        </Link>{" "}
                        and run environments in the cloud.
                    </Text>
                    <Button variant="secondary" className="w-fit self-end" asChild>
                        <Link to={routeForStep(OnboardingStepId.SetUpARunner)}>Back</Link>
                    </Button>
                </div>
            </div>
        </OnboardingTabContent>
    );
};
