import { type FC, type PropsWithChildren } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { Button } from "@/components/flexkit/Button";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { type TrackLocation } from "@/hooks/use-segment";
import type { SectionStatus } from "@/components/runners/details/RunnerDetailsSection";

export const TimelineContentContainer: FC<{
    title?: string;
    description?: string;
    onBack?: () => void;
    nextTitle?: string;
    onNext?: () => void;
    onNextDisabled?: boolean;
    children: React.ReactNode;
    docsLink?: string;
    "data-track-location": TrackLocation;
}> = ({
    onBack,
    onNext,
    nextTitle,
    onNextDisabled,
    children,
    title,
    description,
    docsLink,
    "data-track-location": dataTrackLocation,
}) => {
    return (
        <div className="flex flex-col gap-4" data-track-location={dataTrackLocation}>
            {title || description ? (
                <div className="flex items-start justify-between">
                    <div className="max-w-2xl">
                        <Heading2 className="text-xl font-medium text-content-primary">{title}</Heading2>
                        <Text className="text-base text-content-secondary">{description}</Text>
                    </div>
                    {docsLink && (
                        <ExternalLink href={docsLink} iconSize="sm">
                            Docs
                        </ExternalLink>
                    )}
                </div>
            ) : null}
            <div className="w-full">{children}</div>
            {onBack || onNext ? (
                <div className="flex gap-2 self-end">
                    {onBack && (
                        <Button variant="secondary" onClick={onBack}>
                            Back
                        </Button>
                    )}
                    {onNext && (
                        <Button variant="primary" onClick={onNext} disabled={onNextDisabled}>
                            {nextTitle || "Next"}
                        </Button>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export const DivContainer: FC<{ status: SectionStatus } & PropsWithChildren> = ({ children }) => {
    return <div>{children}</div>;
};
