import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconBook: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="2.37777"
                        y="1.6"
                        width="11.2444"
                        height="12.8"
                        rx="2.4"
                        stroke="currentColor"
                        strokeWidth="1.2"
                    />
                    <path
                        d="M7.22221 5.66663H11.1111"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7.22221 8H11.1111"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M4.88889 1.77783V14.2223" stroke="currentColor" strokeWidth="1.2" />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="3.58889"
                        y="2.7"
                        width="12.8222"
                        height="14.6"
                        rx="2.3"
                        stroke="currentColor"
                        strokeWidth="1.4"
                    />
                    <path
                        d="M9.11111 7.33337H13.5556"
                        stroke="currentColor"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M9.11111 10H13.5556"
                        stroke="currentColor"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M6.44444 2.88892V17.1111" stroke="currentColor" strokeWidth="1.4" />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="4.75"
                        y="3.75"
                        width="14.5"
                        height="16.5"
                        rx="2.25"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                    <path
                        d="M11 9H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M11 12H16"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path d="M8 4V20" stroke="currentColor" strokeWidth="1.5" />
                </svg>
            );
    }
};
