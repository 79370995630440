import { Text } from "@/components/podkit/typography/Text";
import type { PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { getValidityDuration } from "@/routes/settings/user/personal-access-tokens/time-format";
import type { FC, ReactNode } from "react";

export const PersonalAccessTokenCard: FC<{ pat: PlainPersonalAccessToken; children?: ReactNode }> = ({
    pat,
    children,
}) => {
    return (
        <div className="group flex flex-col justify-between gap-4 overflow-x-hidden rounded-xl border border-border-base bg-surface-glass px-4 py-2.5 pl-3">
            <div className="flex flex-col">
                <Text className="text-base font-bold">{pat.description}</Text>
                <Text className="text-sm text-content-secondary">{getValidityDuration(pat)}</Text>
            </div>
            {children}
        </div>
    );
};
