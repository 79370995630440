import { NotAuthorized } from "@/components/NotAuthorized";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useMembership } from "@/hooks/use-membership";
import { LoginAndSecurity } from "@/routes/settings/login/LoginAndSecurity";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import type { FC } from "react";

export const LoginAndSecurityPage: FC = () => {
    useDocumentTitle("Log in and security");

    const { membership, isPending: isLoadingMembership } = useMembership();

    if (isLoadingMembership) {
        return <LoadingState />;
    }

    if (!membership) {
        return <></>;
    }

    if (membership.userRole !== OrganizationRole.ADMIN) {
        return <NotAuthorized />;
    }

    return <LoginAndSecurity organizationId={membership.organizationId} />;
};
