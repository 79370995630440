import { IconExternalLink } from "@/assets/icons/geist/IconExternalLink";
import { Button } from "@/components/flexkit/Button";
import { Nudge } from "@/components/nudges/Nudge";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { NudgeKeys, useNudges } from "@/hooks/use-nudges";
import { TrackLocations } from "@/hooks/use-segment";
import { useSetPreference } from "@/queries/user-preferences-queries";
import { useCallback, useEffect, useState, type FC } from "react";

export const PrivacyPolicyNudge: FC = () => {
    const { active: nudge, error, isPending } = useNudges();
    const { mutate: setPreference } = useSetPreference();
    const [open, setOpen] = useState(false);

    // Auto-show nudge if applicable
    useEffect(() => {
        if (isPending || error) {
            return;
        }

        if (nudge?.key === NudgeKeys.PRIVACY_POLICY_UPDATED) {
            setOpen(true);
            return;
        }
        setOpen(false);
    }, [isPending, error, nudge?.key]);

    const toggleOpen = useCallback(() => {
        setOpen((previousOpenState) => {
            const isNowOpen = !previousOpenState;
            if (!isNowOpen) {
                setPreference({
                    key: NudgeKeys.PRIVACY_POLICY_UPDATED,
                    value: new Date().toISOString(),
                });
            }
            return isNowOpen;
        });
    }, [setPreference]);

    const ariaLabelPrefix = "Announcement - Privacy Policy";
    return (
        <Nudge
            open={open}
            toggleOpen={toggleOpen}
            ariaLabelPrefix={ariaLabelPrefix}
            data-testid="nudge-privacy-policy-trigger"
            data-track-location={TrackLocations.PrivacyPolicyNudge}
        >
            <div className="p-2 text-center">
                <Text className="text-base font-bold text-content-primary">Privacy Policy</Text>
                <Text className="text-base text-content-secondary">We&apos;ve updated our Privacy Policy.</Text>
            </div>
            <Button
                variant="primary"
                size="md"
                className="grow px-4"
                aria-label={`${ariaLabelPrefix} - Learn more`}
                LeadingIcon={IconExternalLink}
                asChild
            >
                <ExternalLink href="https://www.gitpod.io/privacy">Learn more</ExternalLink>
            </Button>
        </Nudge>
    );
};
