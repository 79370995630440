import { cn } from "@/components/podkit/lib/cn";
import { type FC, type ReactNode } from "react";
import { Link } from "react-router-dom";

type SidebarButtonProps = {
    id?: string;
    label: string;
    className?: string;
    active?: boolean;
    icon?: ReactNode;
    iconRight?: ReactNode;
    to?: string;
    onClick?: () => void;
};
export const SidebarButton: FC<SidebarButtonProps> = ({
    id,
    icon,
    iconRight,
    label,
    className,
    active,
    onClick,
    to,
}) => {
    const content = (
        <>
            <div className="w-5">{icon}</div>
            <div className={cn("flex-grow truncate pl-3 text-start text-base", className)}>{label}</div>
            <div className="w-4" />
            {iconRight && <div className="px-2">{iconRight}</div>}
        </>
    );
    const clazzes = cn(
        "flex h-9 flex-row items-center rounded-lg pl-2",
        active ? "bg-surface-secondary" : "hover:bg-surface-03",
    );
    if (to) {
        return (
            <Link id={id} className={clazzes} to={to}>
                {content}
            </Link>
        );
    }
    return (
        <button id={id} className={clazzes} onClick={onClick}>
            {content}
        </button>
    );
};
