import { PageSuspense } from "@/components/PageSuspense";
import { Link, Outlet, useLocation } from "react-router-dom";
import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import { routeForStep, stepFromRoute, useOnboarding } from "@/hooks/use-onboarding";
import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/podkit/tabs/Tabs";
import { useMemo } from "react";
import { cn } from "@/components/podkit/lib/cn";
import { Tooltip } from "@/components/Tooltip";

export const OnboardingLayout = () => {
    const onboarding = useOnboarding();
    const location = useLocation();
    const selectedStep = useMemo(() => stepFromRoute(location.pathname), [location.pathname]);
    return (
        <div className="flex flex-col gap-6" data-testid="onboarding-layout">
            <div className="flex flex-col gap-6 pl-2 pt-2">
                <div className="flex flex-col gap-1">
                    <Heading1 className="flex flex-row align-middle text-content-primary">Getting started</Heading1>
                    <Subheading className="font-normal">
                        You are on your way to your first one-click development environment
                    </Subheading>
                </div>
                <Tabs defaultValue={selectedStep} value={selectedStep}>
                    <div className="flex flex-col gap-6">
                        <nav>
                            <TabsList>
                                {onboarding.steps.steps.map((step, idx) => {
                                    const disabled = idx > 0 && onboarding.steps.steps[idx - 1]?.completed === false;
                                    return (
                                        <TabsTrigger
                                            key={step.id}
                                            value={step.id}
                                            disabled={disabled}
                                            aria-label={`${idx + 1}. ${step.title}`}
                                            asChild
                                        >
                                            <Link
                                                aria-disabled={disabled}
                                                to={routeForStep(step.id)}
                                                className={cn("flex items-center justify-center gap-1 font-medium", {
                                                    "active:pointer-events-none cursor-default": disabled,
                                                })}
                                            >
                                                <Tooltip content={step.title}>
                                                    <span className="min-w-0 truncate">{`${idx + 1}. ${step.title}`}</span>
                                                </Tooltip>
                                                {step.completed && (
                                                    <IconCheckFill
                                                        size="base"
                                                        className="shrink-0 text-content-green"
                                                    />
                                                )}
                                            </Link>
                                        </TabsTrigger>
                                    );
                                })}
                            </TabsList>
                        </nav>
                        <main>
                            <PageSuspense>
                                <TabsContent value={selectedStep}>
                                    <Outlet />
                                </TabsContent>
                            </PageSuspense>
                        </main>
                    </div>
                </Tabs>
            </div>
        </div>
    );
};
