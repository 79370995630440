import { ContextUrlInput } from "@/components/ContextUrlInput";
import { EnvironmentTypeSelect } from "@/components/EnvironmentTypeSelect";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { Dialog, DialogContent } from "@/components/podkit/modal/Modal";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading1 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useContextURL, useContextUrlRememberer } from "@/hooks/use-context-url";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { type EnvironmentTypeEntry, useEnvironmentTypes } from "@/hooks/use-environment-types";
import { useSCMAuthentication } from "@/hooks/use-scm-authentication.ts";
import { TrackLocations, type TrackLocation } from "@/hooks/use-segment";
import { type PlainRunnerEnvironmentClass, useCreateEnvironment } from "@/queries/environment-queries";
import { isNotFoundError, isUnauthorizedError } from "@/queries/errors";
import { useListProjects } from "@/queries/project-queries";
import { useIsUserAuthenticatedWithRunner, useParseContextURL } from "@/queries/runner-queries";
import { SCMAuthentication, type SCMAuthenticationModalProps } from "@/routes/environments/create/SCMAuthentication";
import { getDetailsURL } from "@/routes/environments/details-url";
import { ProjectSelectorLayout } from "@/components/projects/ProjectSelectorModal";
import { formatError } from "@/utils/errors";
import { ConnectError } from "@connectrpc/connect";
import { getScmProviderName } from "frontend-shared/local-runner";
import { ParseContextURLPreconditionFailureDetails, RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { type FC, type FormEvent, useCallback, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

export type CreateEnvironmentStage =
    | { type: "input" }
    | {
          type: "scm-authentication";
          authenticationUrl: string;
          patSupported: boolean;
          scmId: string;
          clazz: PlainRunnerEnvironmentClass;
      };

export const CreateEnvironmentDetailsLayout: FC<{ onClose: () => void; onCreateSuccess?: () => void }> = ({
    onClose,
    onCreateSuccess,
}) => {
    const navigate = useNavigate();
    useDocumentTitle("Create Environment");
    const { remember } = useContextUrlRememberer();

    const { toast } = useToast();

    const [stage, setStage] = useState<CreateEnvironmentStage>({ type: "input" });

    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentTypeEntry>();

    const { contextURL, setContextURL, contextURLValid } = useContextURL();
    const [debouncedContext] = useDebounce({ contextURL, contextURLValid }, 100, {
        equalityFn: (left, right) =>
            left.contextURL == right.contextURL && left.contextURLValid == right.contextURLValid,
    });

    const { envTypes, isLoading: isLoadingEnvTypes } = useEnvironmentTypes();

    const hasNoEnvTypes = !isLoadingEnvTypes && envTypes.length == 0;

    const {
        isLoading: isLoadingParseContext,
        isSuccess: isSuccessParseContext,
        error: errorParseContext,
    } = useParseContextURL(selectedEnvironment?.runner?.runnerId, debouncedContext.contextURL, {
        enabled: debouncedContext.contextURLValid,
    });

    const parseContextFailed = !isLoadingParseContext && isUnauthorizedError(errorParseContext);
    const isNotFound = isNotFoundError(errorParseContext);

    const {
        data: authCheckResponse,
        isLoading: isLoadingAuthCheck,
        isSuccess: isSuccessAuthCheck,
        isError: isErrorAuthCheck,
    } = useIsUserAuthenticatedWithRunner(selectedEnvironment?.runner?.runnerId, debouncedContext.contextURL, {
        refetchUntilAuthenticated: true,

        // Auth check should start be polled for once the parseContextURL failed with an unauthorized error
        enabled: debouncedContext.contextURLValid && parseContextFailed,
    });
    const isAuthenticationRequired = parseContextFailed || authCheckResponse?.type == "AuthenticationRequired";

    const getScmName = () => {
        let scmID = "";
        if (isAuthenticationRequired) {
            if (parseContextFailed) {
                if (errorParseContext instanceof ConnectError) {
                    const details = errorParseContext.findDetails(ParseContextURLPreconditionFailureDetails);
                    for (const detail of details) {
                        if (detail.scmId) {
                            scmID = detail.scmId;
                        }
                    }
                }
            } else if (authCheckResponse?.type == "AuthenticationRequired") {
                scmID = authCheckResponse.scmId;
            }
        }

        return getScmProviderName(scmID);
    };

    const createEnvironment = useCreateEnvironment();

    // Select first environment type by default
    if (!isLoadingEnvTypes && !selectedEnvironment && envTypes.length > 0) {
        const firstActive = envTypes.find((et) => et.runner?.status?.phase === RunnerPhase.ACTIVE);
        if (firstActive) {
            setSelectedEnvironment((prev) => {
                if (prev) {
                    return prev;
                }
                return firstActive;
            });
        }
    }

    // If the selected environment isn't present in the list of environment classes then reset the selection.
    // This can happen if you change the context URL after having selected an environment type
    if (!isLoadingEnvTypes && selectedEnvironment) {
        const selectedIsInList = envTypes?.find(
            (v) =>
                v.clazz?.id == selectedEnvironment.clazz?.id &&
                v.runner?.runnerId == selectedEnvironment?.runner?.runnerId,
        );
        if (!selectedIsInList) {
            setSelectedEnvironment(undefined);
        }
    }

    const handleOnContinue = useCallback(async () => {
        let environment;
        try {
            environment = await createEnvironment.mutateAsync({
                type: "contextUrl",
                contextURL: contextURL || "",
                classID: selectedEnvironment?.clazz?.id || "",
            });
            remember(contextURL || "");
            onCreateSuccess?.();
            navigate(getDetailsURL(environment));
        } catch (error) {
            toast({ title: "Failed to create an environment", description: formatError(error) });
        }
    }, [contextURL, selectedEnvironment, createEnvironment, navigate, remember, toast, onCreateSuccess]);

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const formData = new FormData(event.currentTarget);
            const runnerID = selectedEnvironment?.runner?.runnerId;
            const classID = selectedEnvironment?.clazz?.id;
            const contextURL = formData.get("contextURL") as string;

            if (!runnerID || !contextURL || !classID) {
                console.log(`Invalid runnerId=${runnerID} or contextURL=${contextURL} or classID=${classID}`);
                return;
            }

            if (authCheckResponse?.type == "AuthenticationRequired") {
                setStage({
                    type: "scm-authentication",
                    authenticationUrl: authCheckResponse.url,
                    patSupported: authCheckResponse.patSupported,
                    scmId: authCheckResponse.scmId,
                    clazz: selectedEnvironment?.clazz,
                });
                return;
            }

            await handleOnContinue();
        },
        [handleOnContinue, selectedEnvironment, authCheckResponse],
    );

    const createButtonDisabled =
        selectedEnvironment == undefined ||
        !contextURLValid ||
        isNotFound ||
        !(isSuccessParseContext || isSuccessAuthCheck);

    const createButtonText = isAuthenticationRequired ? "Authenticate and Create" : "Create";

    if (stage.type === "scm-authentication") {
        return (
            <div className="flex w-full flex-col items-center justify-center gap-4">
                <Authentication
                    repoURL={contextURL || ""}
                    authenticationUrl={stage.authenticationUrl}
                    patSupported={stage.patSupported}
                    scmId={stage.scmId}
                    onClose={onClose}
                    onClickContinue={handleOnContinue}
                    clazz={stage.clazz}
                    data-track-location={TrackLocations.CreateEnvironmentSCMAuthenticationModal}
                />
            </div>
        );
    }

    const showIsVerifying =
        (!isAuthenticationRequired && contextURLValid && isLoadingParseContext && !hasNoEnvTypes) ||
        (isLoadingAuthCheck && isErrorAuthCheck);

    return (
        <div className="flex flex-col gap-4 overflow-hidden px-5 py-4">
            <div className="flex flex-col items-center justify-start">
                <Heading1>New environment</Heading1>
                <Text className="pt-1 text-base text-content-secondary">From a repository URL</Text>
            </div>
            <form onSubmit={handleSubmit} className="pt-8">
                <div
                    className="flex flex-col gap-4"
                    id="create-environment-form"
                    data-testid={isLoadingEnvTypes ? "loading" : "create-environment-form"}
                >
                    <ContextUrlInput
                        label="Paste repository URL"
                        name="contextURL"
                        value={contextURL}
                        onChange={setContextURL}
                        errorMessage={
                            !!contextURL && !contextURLValid ? "The URL must point to a valid context URL." : ""
                        }
                        className={"pb-2"}
                        autoFocus
                    />

                    <EnvironmentTypeSelect
                        label="Environment class"
                        value={selectedEnvironment}
                        onChange={(v) => setSelectedEnvironment(v)}
                        name="environment-type"
                        loading={isLoadingEnvTypes}
                        disabled={!contextURLValid}
                        envTypes={envTypes}
                    />

                    <div
                        data-testid="errors"
                        className="flex min-h-4 flex-col items-center justify-center gap-2 text-center text-sm"
                    >
                        {showIsVerifying && <span data-testid="verifying">Verifying repository and connection…</span>}
                        {isAuthenticationRequired && (
                            <span className="max-w-80 text-wrap">
                                <span className="font-bold">
                                    {selectedEnvironment?.runner?.name || "Unknown runner"}
                                </span>{" "}
                                requires authentication with <span className="font-bold">{getScmName()}</span>
                            </span>
                        )}
                        {isNotFound && (
                            <span>The repository could not be found. Please check the URL and try again.</span>
                        )}
                        {hasNoEnvTypes && <NoEnvTypesError onClose={onClose} />}
                        {!showIsVerifying && !isAuthenticationRequired && !isNotFound && !hasNoEnvTypes && (
                            <ErrorMessage error={errorParseContext} />
                        )}
                    </div>

                    <Button
                        type="submit"
                        variant="primary"
                        className="w-full text-base font-bold"
                        size={"md"}
                        loading={createEnvironment.isPending}
                        disabled={createButtonDisabled}
                        data-testid="create-environment-button"
                    >
                        {createButtonText}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export const NoEnvTypesError: FC<{ onClose?: () => void }> = ({ onClose }) => {
    return (
        <Text className="text-base">
            To create an environment, please first{" "}
            <Link
                to="/onboarding/set-up-runner"
                onClick={onClose}
                className="text-content-link"
                data-testid="runner-onboarding-link"
            >
                set up a runner
            </Link>
            .
        </Text>
    );
};

export const CreateEnvironmentModal: FC<{
    onClose: () => void;
    "data-track-location": TrackLocation;
}> = ({ onClose, "data-track-location": dataTrackLocation }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { data: projectsData, isLoading, isPending } = useListProjects();

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
            navigate({ hash: "" }, { replace: true });
        }
    };

    const [showCreateFromUrl, setShowCreateFromUrl] = useState(!!location.hash);

    if (isLoading || isPending) {
        return <LoadingState />;
    }

    const doShowCreateFromUrl = showCreateFromUrl || projectsData?.projects.length === 0;

    return (
        <Dialog open onOpenChange={onOpenChange}>
            <DialogContent
                data-track-location={dataTrackLocation}
                className={cn(
                    !doShowCreateFromUrl && "min-h-[560px] w-[800px] max-w-[800px]",
                    doShowCreateFromUrl && "max-w-128 min-h-[220px] w-128",
                )}
            >
                {!doShowCreateFromUrl && (
                    <ProjectSelectorLayout onClose={onClose} onCreateFromUrl={() => setShowCreateFromUrl(true)} />
                )}
                {doShowCreateFromUrl && <CreateEnvironmentDetailsLayout onClose={onClose} onCreateSuccess={onClose} />}
            </DialogContent>
        </Dialog>
    );
};

const Authentication: FC<SCMAuthenticationModalProps> = (p) => {
    const props = useSCMAuthentication({
        repoURL: p.repoURL,
        authenticationUrl: p.authenticationUrl,
        clazz: p.clazz,
    });
    return <SCMAuthentication {...{ ...p, ...props }} />;
};
