import { useListPreferences, type PlainUserPreference } from "@/queries/user-preferences-queries";
import { useAuthenticatedUser, type PlainUser } from "@/queries/user-queries";

export const PRIVACY_POLICY_LAST_UPDATED_DATE = new Date("2024-12-03T00:00:00Z");

export enum NudgeKeys {
    "SHOW_REINVENT_BANNER" = "SHOW_REINVENT_BANNER",
    "PRIVACY_POLICY_UPDATED" = "PRIVACY_POLICY_UPDATED",
}

type Nudge = { key: NudgeKeys.SHOW_REINVENT_BANNER } | { key: NudgeKeys.PRIVACY_POLICY_UPDATED };

/**
 * Computes the active nudge (if any).
 * This ensures we only ever render one nudge at a time.
 */
export function useNudges(): { active?: Nudge; isPending: boolean; error: Error | null } {
    const { data: user, isPending: isPendingUser, error: errorUser } = useAuthenticatedUser();
    const { preferences, isPending: isPendingPreferences, error: errorPreferences } = useListPreferences();

    if (!user) {
        return { isPending: isPendingUser, error: errorUser };
    }

    if (!preferences) {
        return { isPending: isPendingPreferences, error: errorPreferences };
    }

    if (!hasAcceptedCurrentPrivacyPolicy({ user, preferences })) {
        return {
            active: { key: NudgeKeys.PRIVACY_POLICY_UPDATED },
            isPending: isPendingPreferences,
            error: errorPreferences,
        };
    }

    if (!hasDismissedAWSNudge(NudgeKeys.SHOW_REINVENT_BANNER, preferences)) {
        return {
            active: { key: NudgeKeys.SHOW_REINVENT_BANNER },
            isPending: isPendingPreferences,
            error: errorPreferences,
        };
    }

    return { isPending: isPendingPreferences, error: errorPreferences };
}

function hasDismissedAWSNudge(key: string, preferences: PlainUserPreference[]): boolean {
    const savedPreference = preferences?.find((pref) => pref.key === key);
    if (!savedPreference || savedPreference.value === "true") {
        return false;
    }
    return true;
}

function hasAcceptedCurrentPrivacyPolicy(options: { user: PlainUser; preferences: PlainUserPreference[] }): boolean {
    const { user, preferences } = options;
    const preference = preferences?.find((pref) => pref.key === NudgeKeys.PRIVACY_POLICY_UPDATED.toString());

    // If the user was created after the last privacy policy update, we don't need to show the nudge
    if (user.createdAt?.seconds && user.createdAt?.seconds > PRIVACY_POLICY_LAST_UPDATED_DATE.getTime() / 1000) {
        return true;
    }

    // If the user has already accepted the current privacy policy, we don't need to show the nudge
    if (preference?.value) {
        const acceptedAt = new Date(preference?.value);
        if (acceptedAt > PRIVACY_POLICY_LAST_UPDATED_DATE) {
            return true;
        }
    }

    return false;
}
