export const RunnerConfigurationKeys = {
    AWSInstanceType: "instanceType",
    DiskSizeGB: "diskSizeGB",
    SpotInstanceEnabled: "spot",
};

export const RunnerAdditionalFieldsKeys = {
    AWSAccountID: "awsAccountID",
    StackURL: "awsCloudFormationStackURL",
    StackName: "awsCloudFormationStackName",
    Region: "region",
};
