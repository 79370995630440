import { useCallback, useId, useState, type FC, type FormEvent } from "react";
import {
    Dialog,
    DialogHeader,
    DialogContent,
    DialogFooter,
    DialogClose,
    DialogBody,
    DialogTitle,
    DialogDescription,
} from "@/components/podkit/modal/Modal";

import { Text } from "@/components/podkit/typography/Text";
import { Label } from "@/components/podkit/forms/Label";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Input } from "@/components/podkit/forms/Input";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";
import { useCreatePersonalAccessToken, type PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { formatError } from "@/utils/errors";
import { Button } from "@/components/flexkit/Button";
import { CopyableTextArea } from "@/components/podkit/forms/CopyableTextArea";
import { PersonalAccessTokenCard } from "@/routes/settings/user/personal-access-tokens/PersonalAccessTokenCard";
import { TrackLocations } from "@/hooks/use-segment";

type Props = {
    onClose: () => void;
};

export const NewPersonalAccessTokenModal: FC<Props> = ({ onClose }) => {
    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );
    const [token, setToken] = useState<string>();
    const [pat, setPat] = useState<PlainPersonalAccessToken>();

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="max-w-[600px]" data-track-location={TrackLocations.NewPersonalAccessTokenModal}>
                <DialogHeader>
                    <DialogTitle>New personal access token</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                {!token && (
                    <NewPersonalAccessTokenForm
                        onSuccess={(pat, tkn) => {
                            setPat(pat);
                            setToken(tkn);
                        }}
                    />
                )}
                {token && pat && <NewPersonalAccessTokenResult pat={pat} token={token} />}
            </DialogContent>
        </Dialog>
    );
};

type NewPersonalAccessTokenFormProps = {
    onSuccess?: (pat: PlainPersonalAccessToken, token: string) => void;
    onClose?: () => void;
};
const NewPersonalAccessTokenForm: FC<NewPersonalAccessTokenFormProps> = ({ onSuccess }) => {
    const { toast } = useToast();

    const [description, setDescription] = useState<string>();
    const [validForDays, setValidForDays] = useState<string>("30 days");
    const inputDescriptionID = useId();
    const inputValidForID = useId();
    const createPAT = useCreatePersonalAccessToken();

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLElement>) => {
            event.preventDefault();
            if (!description || !validForDays) {
                return;
            }

            try {
                const validForDaysNumber = parseInt(validForDays.substring(0, validForDays.length - " days".length));
                const { token } = await createPAT.mutateAsync({
                    description: description,
                    validForDays: validForDaysNumber,
                });

                // close on success only
                onSuccess?.(
                    // We fake the PAT structure here because the API doesn't return it
                    {
                        description,
                        id: "unknown",
                        userId: "unknown",
                        expiresAt: {
                            seconds: BigInt(Math.floor(Date.now() / 1000) + validForDaysNumber * 24 * 60 * 60),
                            nanos: 0,
                        },
                    },
                    token,
                );
            } catch (error) {
                toast({
                    title: "Failed to create personal access token.",
                    description: formatError(error),
                });
            }
        },
        [description, validForDays, onSuccess, toast, createPAT],
    );

    return (
        <>
            <DialogBody>
                <form onSubmit={handleSubmit} data-testid="new-personal-access-token-form">
                    <div className="flex flex-col gap-7">
                        <div className="flex flex-col gap-2">
                            <div className="space-y-1">
                                <Label htmlFor={inputDescriptionID}>Description</Label>
                                <Input
                                    id={inputDescriptionID}
                                    type="text"
                                    name="name"
                                    value={description || ""}
                                    placeholder="Enter a description"
                                    onChange={(e) => setDescription(e.target.value)}
                                    data-testid="personal-access-token-description-input"
                                />
                            </div>
                            <div className="space-y-1">
                                <Label htmlFor={inputValidForID}>Token should be valid for</Label>
                                <Select
                                    name="valid_for"
                                    value={validForDays || ""}
                                    onValueChange={(e) => setValidForDays(e)}
                                >
                                    <SelectTrigger id={inputValidForID} loading={false}>
                                        <SelectValue placeholder={"30 days"}>{validForDays}</SelectValue>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem key={30} value={"30 days"}>
                                                    <div className="flex w-full flex-row items-center justify-between pr-10">
                                                        <div>30 days</div>
                                                    </div>
                                                </SelectItem>
                                                <SelectItem key={60} value={"60 days"}>
                                                    <div className="flex w-full flex-row items-center justify-between pr-10">
                                                        <div>60 days</div>
                                                    </div>
                                                </SelectItem>
                                                <SelectItem key={90} value={"90 days"}>
                                                    <div className="flex w-full flex-row items-center justify-between pr-10">
                                                        <div>90 days</div>
                                                    </div>
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </SelectTrigger>
                                </Select>
                            </div>
                        </div>
                    </div>
                </form>
            </DialogBody>
            <DialogFooter className="sm:justify-end">
                <DialogClose asChild>
                    <Button variant="secondary" size="md">
                        Cancel
                    </Button>
                </DialogClose>
                <Button
                    aria-label="create"
                    autoFocus={true}
                    data-testid="create-personal-access-token-button"
                    disabled={!description || !validForDays}
                    form="new-runner-form"
                    loading={createPAT.isPending}
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                    size="md"
                    variant="primary"
                >
                    Create
                </Button>
            </DialogFooter>
        </>
    );
};

type NewPersonalAccessTokenResultProps = {
    pat: PlainPersonalAccessToken;
    token: string;
};
const NewPersonalAccessTokenResult: FC<NewPersonalAccessTokenResultProps> = ({ pat, token }) => {
    return (
        <>
            <DialogBody>
                <div className="flex flex-col gap-4">
                    <Text className="text-lg">
                        Be sure to copy your personal access token now, as it won&apos;t be viewable again.
                    </Text>
                    <PersonalAccessTokenCard pat={pat}>
                        <CopyableTextArea
                            copyable
                            className="h-80 text-base text-content-secondary"
                            value={token}
                            data-testid="personal-access-token-value"
                        />
                    </PersonalAccessTokenCard>
                </div>
            </DialogBody>
            <DialogFooter className="sm:justify-end">
                <DialogClose asChild>
                    <Button size="md" variant="primary">
                        Done
                    </Button>
                </DialogClose>
            </DialogFooter>
        </>
    );
};
