import { IconChevronDown } from "@/assets/icons/geist/IconChevronDown";
import { AccountInfo } from "@/components/AccountInfo";
import { Button } from "@/components/flexkit/Button";
import { OrgIcon } from "@/components/OrgIcon";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { SwitchOrgModal } from "@/components/SwitchOrgModal";
import { DESKTOP_APP_DOWNLOAD_URL } from "@/desktop";
import { useDesktop } from "@/hooks/use-desktop";
import type { TrackLocation } from "@/hooks/use-segment";
import { getPrincipal, setPrincipal } from "@/principal";
import { useGetAccount } from "@/queries/account-queries";
import { useLogout } from "@/queries/user-queries";
import { isMacLike } from "@/utils/platform";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { CheckIcon } from "lucide-react";
import React, { useCallback, useState, type FC } from "react";
import { Link, useNavigate } from "react-router-dom";

export type OrgSwitcherProps = {
    "data-track-location": TrackLocation;
};
export const OrgSwitcher: FC<OrgSwitcherProps> = (p) => {
    const desktop = useDesktop();
    const { data: account, isPending: isLoadingAccount } = useGetAccount();
    const navigate = useNavigate();
    const logout = useLogout();
    const [switchToMemberId, setSwitchToMemberId] = useState<string | undefined>();

    const principal = getPrincipal();
    const isMac = isMacLike();
    const showDownloadApp = !desktop.isDesktop && isMac;

    const membership = principal ? account?.memberships?.find((m) => m.userId === principal) : undefined;
    const orgName = membership?.organizationName;
    const onLogout = useCallback(
        async (e: Event) => {
            e.preventDefault();
            await logout.mutateAsync();
            navigate("/");
        },
        [logout, navigate],
    );

    const handleSwitchOrg = useCallback(
        (memberId: string) => {
            if (!memberId || memberId === principal) {
                return;
            }
            setSwitchToMemberId(memberId);
        },
        [principal],
    );

    if (!isLoadingAccount && account && !membership) {
        return <AccountInfo />;
    }

    if (isLoadingAccount || !account || !principal) {
        return <div />;
    }

    return (
        <>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <Button
                        variant={"ghost"}
                        className={cn(
                            "w-full hover:bg-transparent",
                            "flex gap-2 border-0",
                            "px-2",
                            "focus-visible:outline-1 focus-visible:outline-outline-blue",
                        )}
                        aria-label="Switch organization"
                    >
                        <OrgIcon orgName={membership?.organizationName || ""} />
                        <div className="truncate text-base">{orgName}</div>
                        <IconChevronDown size="sm" />
                    </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        align={"start"}
                        loop={true}
                        className="z-10 w-80 select-none rounded-lg border border-border-light bg-surface-secondary shadow-sm focus-visible:ring-0"
                        translate="no"
                    >
                        <DropdownMenuItem className="mt-2 h-6 px-6" disabled={true} aria-label="Account">
                            <div className="text-xs text-content-secondary">{account.email}</div>
                        </DropdownMenuItem>
                        {(account?.memberships || []).map((m) => (
                            <DropdownMenuItem
                                key={"m-" + m.userId}
                                className="h-9 w-full justify-between px-6"
                                onSelect={() => handleSwitchOrg(m.userId)}
                                aria-label="Organization"
                            >
                                <div className="flex h-6 items-center justify-start gap-2 truncate">
                                    <OrgIcon orgName={m.organizationName} />
                                    <span className="inline-block truncate text-base">{m.organizationName}</span>
                                </div>
                                <div className="flex items-center justify-center gap-2 p-0.5 pl-2">
                                    {m.userId === principal && <CheckIcon className="h-5 w-5" />}
                                </div>
                            </DropdownMenuItem>
                        ))}
                        <DropdownMenu.Separator className="my-2 border border-t-0 border-border-light" />
                        {showDownloadApp && (
                            <DropdownMenuItem className="h-9 px-6" asChild>
                                <a href={DESKTOP_APP_DOWNLOAD_URL} download>
                                    Get the desktop app
                                </a>
                            </DropdownMenuItem>
                        )}
                        <DropdownMenuItem className="h-9 px-6" asChild>
                            <a href="https://www.gitpod.io/docs" target="_blank" rel="noreferrer">
                                Documentation
                            </a>
                        </DropdownMenuItem>
                        <DropdownMenuItem className="h-9 px-6" asChild>
                            <Link to="/join-organization">Join or create an organization</Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem className="h-9 px-6" onSelect={onLogout}>
                            Logout
                        </DropdownMenuItem>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
            {!!switchToMemberId && (
                <SwitchOrgModal
                    onClose={() => setSwitchToMemberId(undefined)}
                    onConfirm={() => {
                        setSwitchToMemberId(undefined);
                        setPrincipal(switchToMemberId);
                        navigate("/", { replace: true });
                    }}
                    data-track-location={p["data-track-location"]}
                />
            )}
        </>
    );
};

const DropdownMenuItem = React.forwardRef<
    React.ElementRef<typeof DropdownMenu.Item>,
    React.ComponentPropsWithoutRef<typeof DropdownMenu.Item> &
        PropsWithClassName<{
            inset?: boolean;
        }>
>(({ className, inset, ...props }, ref) => (
    <DropdownMenu.Item
        ref={ref}
        className={cn(
            "relative flex h-10 cursor-pointer items-center rounded px-4 text-base outline-none",
            "focus:bg-surface-tertiary focus:text-content-primary focus-visible:ring-0",
            inset && "pl-8",
            className,
        )}
        {...props}
    />
));
DropdownMenuItem.displayName = DropdownMenu.Item.displayName;
