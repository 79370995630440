import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconPlus: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.9375 0.875C7.9375 0.71967 7.81158 0.59375 7.65625 0.59375H6.34375C6.18842 0.59375 6.0625 0.71967 6.0625 0.875V1.53125V5.625H1.96875H1.3125C1.15717 5.625 1.03125 5.75092 1.03125 5.90625V7.21875C1.03125 7.37408 1.15717 7.5 1.3125 7.5H1.96875H6.0625V11.5938V12.25C6.0625 12.4053 6.18842 12.5312 6.34375 12.5312H7.65625C7.81158 12.5312 7.9375 12.4053 7.9375 12.25V11.5938V7.5H12.0312H12.6875C12.8428 7.5 12.9688 7.37408 12.9688 7.21875V5.90625C12.9688 5.75092 12.8428 5.625 12.6875 5.625H12.0312H7.9375V1.53125V0.875Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="0.5625"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M11.0312 3C11.0312 2.84467 10.9053 2.71875 10.75 2.71875H9.25C9.09467 2.71875 8.96875 2.84467 8.96875 3V3.75V8.46875H4.25H3.5C3.34467 8.46875 3.21875 8.59467 3.21875 8.75V10.25C3.21875 10.4053 3.34467 10.5312 3.5 10.5312H4.25H8.96875V15.25V16C8.96875 16.1553 9.09467 16.2812 9.25 16.2812H10.75C10.9053 16.2812 11.0312 16.1553 11.0312 16V15.25V10.5312H15.75H16.5C16.6553 10.5312 16.7812 10.4053 16.7812 10.25V8.75C16.7812 8.59467 16.6553 8.46875 16.5 8.46875H15.75H11.0312V3.75V3Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="0.5625"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.125 4.125C13.125 3.96967 12.9991 3.84375 12.8438 3.84375H11.1562C11.0009 3.84375 10.875 3.96967 10.875 4.125V4.96875V10.3125H5.53125H4.6875C4.53217 10.3125 4.40625 10.4384 4.40625 10.5938V12.2812C4.40625 12.4366 4.53217 12.5625 4.6875 12.5625H5.53125H10.875V17.9062V18.75C10.875 18.9053 11.0009 19.0312 11.1562 19.0312H12.8438C12.9991 19.0312 13.125 18.9053 13.125 18.75V17.9062V12.5625H18.4688H19.3125C19.4678 12.5625 19.5938 12.4366 19.5938 12.2812V10.5938C19.5938 10.4384 19.4678 10.3125 19.3125 10.3125H18.4688H13.125V4.96875V4.125Z"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="0.5625"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
