import { routeForStep, useOnboarding } from "@/hooks/use-onboarding";
import type { FC } from "react";
import { Navigate } from "react-router-dom";

/**
 * Backwards compatibility with the old URLs
 * Ensures that any client on /onboarding/enabler is redirected to the correct url
 */
export const OnboardingRedirect: FC = () => {
    const onboarding = useOnboarding();
    const to = routeForStep(onboarding.steps.defaultActive);

    if (onboarding.steps.isLoading) {
        return;
    }

    return <Navigate to={to} replace />;
};
