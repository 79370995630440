import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { RunnerDetailsSection, type SectionStatus } from "@/components/runners/details/RunnerDetailsSection";
import { RunnerPhaseTag } from "@/components/runners/RunnerCard";
import type { PlainMessage } from "@bufbuild/protobuf";
import { RunnerKind, type Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import { type FC } from "react";
import { CloudFormationStackCreateButton } from "@/components/runners/details/CloudFormationStackCreateButton";
import { CloudFormationStackCopyDetails } from "@/components/runners/details/CloudFormationStackCopyDetails";
import { CloudFormationStackDetails } from "@/components/runners/details/CloudFormationStackDetails";

export const CloudFormationStack: FC<{ runner: PlainMessage<Runner> | undefined; hasStack: boolean }> = ({
    runner,
    hasStack,
}) => {
    if (runner && runner.kind !== RunnerKind.REMOTE) {
        return null;
    }

    let status: SectionStatus = "loading";
    if (runner) {
        status = hasStack ? "complete" : "required";
    }

    return (
        <RunnerDetailsSection status={status}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                    <SkeletonText ready={status !== "loading"} size="lg" className="w-48">
                        <Text className="text-lg font-bold">AWS CloudFormation</Text>
                    </SkeletonText>
                    {!hasStack && (
                        <Text className="text-base">
                            We&#x2019;ll help you setup a new runner on your AWS EC2 infrastructure. The link below will
                            take you to AWS CloudFormation with a set of pre-populated values to quickly get everything
                            working.{" "}
                            <ExternalLink
                                className="font-bold"
                                href="https://www.gitpod.io/docs/flex/runners/aws/setup-aws-runners#cloudformation-template"
                            >
                                Read the docs.
                            </ExternalLink>
                        </Text>
                    )}
                </div>
                <CloudFormationStackContent runner={runner} hasStack={hasStack} />
            </div>
        </RunnerDetailsSection>
    );
};

const CloudFormationStackContent: FC<
    { runner: PlainMessage<Runner> | undefined; hasStack: boolean } & PropsWithClassName
> = ({ runner, hasStack, className }) => {
    return (
        <SkeletonBlock ready={Boolean(runner)} className="h-[150px]">
            {runner && (
                <div className="flex flex-col gap-4">
                    <div className={cn("rounded-lg bg-surface-tertiary p-4", className)}>
                        {hasStack ? (
                            <CloudFormationStackDetails runner={runner} />
                        ) : (
                            <CloudFormationStackCreate runner={runner} />
                        )}
                    </div>
                    {!hasStack && <CloudFormationStackCopyDetails runner={runner} />}
                </div>
            )}
        </SkeletonBlock>
    );
};

const CloudFormationStackCreate: FC<{ runner: PlainMessage<Runner> }> = ({ runner }) => {
    return (
        <div className="flex flex-col items-center gap-4 text-center">
            <div className="flex flex-col">
                <Text className="text-lg font-bold">
                    Once you&apos;ve executed the CloudFormation template in AWS, please return to this page.
                </Text>
                <Text className="text-base text-content-secondary">
                    Gitpod will register your runner after the stack has been created. This will take 2-3 minutes.
                </Text>
            </div>
            {runner && <RunnerPhaseTag runner={runner} />}
            <CloudFormationStackCreateButton runner={runner} />
        </div>
    );
};
