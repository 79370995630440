import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconCircleWithOptionalCheckmark: FC<{ checked: boolean } & PropsWithClassName> = ({
    checked,
    className,
}) => {
    if (checked) {
        return (
            <svg
                className={className}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    xmlns="http://www.w3.org/2000/svg"
                    cx="12"
                    cy="12"
                    r="8"
                    fill="#17C165"
                    stroke="#17C165"
                    strokeOpacity="0.3"
                    strokeWidth="3"
                />
                <path d="M8 12.5L11 15L16 9" stroke="white" strokeWidth="2" strokeLinejoin="round" />
            </svg>
        );
    }

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="9" fill="#64645F" opacity={0.3} />
        </svg>
    );
};
