import { Input } from "@/components/podkit/forms/Input";
import { InputField } from "@/components/podkit/forms/InputField";
import { ThemeToggle } from "@/components/ThemeToggle";
import { useGetAccount, type PlainAccount } from "@/queries/account-queries";
import { useAuthenticatedUser, type PlainUser } from "@/queries/user-queries";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { IconCopy } from "@/assets/icons/geist/IconCopy";
import type { FC } from "react";

export const UserProfilePage: FC = () => {
    // Explicitly not handling loading/error states for account/user as that's taken care of in AuthenticatedUserLayout
    const { data: account } = useGetAccount();
    const { data: user } = useAuthenticatedUser();
    return (
        <div className="flex flex-col gap-4" data-testid="user-profile-page">
            <Avatar user={user} />
            <Name user={user} />
            <EmailAddress account={account || undefined} />
            <AccountId account={account || undefined} />
            <Appearance />
        </div>
    );
};

const Appearance: FC = () => {
    return (
        <InputField label="Appearance" hint="Customize how Gitpod looks on your device">
            <ThemeToggle />
        </InputField>
    );
};

const Avatar: FC<{ user?: PlainUser }> = ({ user }) => {
    return (
        <InputField label="Avatar">
            <img
                src={user?.avatarUrl}
                alt="Avatar"
                className="border-border-bases rounded-full border"
                height={60}
                width={60}
            />
        </InputField>
    );
};

const Name: FC<{ user?: PlainUser }> = ({ user }) => {
    return (
        <InputField label="Name">
            <Input disabled={true} value={user?.name} data-testid="name-input" />
        </InputField>
    );
};

const EmailAddress: FC<{ account?: PlainAccount }> = ({ account }) => {
    return (
        <InputField label="Email">
            <Input disabled={true} value={account?.email} data-testid="email-input" />
        </InputField>
    );
};

const AccountId: FC<{ account?: PlainAccount }> = ({ account }) => {
    const { toast } = useToast();

    const copyToClipboard = async () => {
        if (account?.id) {
            try {
                await navigator.clipboard.writeText(account.id);
                toast({ title: "Account ID copied to clipboard" });
            } catch (error) {
                toast({
                    title: "Failed to copy Account ID to clipboard",
                    description: formatError(error),
                });
            }
        }
    };

    return (
        <InputField label="Account ID">
            <div className="relative w-full max-w-[600px]">
                <Input disabled={true} value={account?.id} data-testid="account-id-input" />
                <button
                    onClick={copyToClipboard}
                    className="absolute right-3 top-1/2 z-10 -translate-y-1/2 hover:text-gray-600"
                    aria-label="Copy account ID"
                    title="Copy account ID"
                >
                    <IconCopy size={"base"} />
                </button>
            </div>
        </InputField>
    );
};
