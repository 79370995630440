import { type PlainRunner } from "@/queries/runner-queries";
import { useListEnvironmentClasses, type PlainRunnerEnvironmentClass } from "@/queries/environment-queries";
import { useEffect, useState } from "react";
import { useAvailableRunners } from "@/hooks/use-available-runners";

export type EnvironmentTypeEntry = {
    clazz: PlainRunnerEnvironmentClass;
    runner: PlainRunner;
};

export function useEnvironmentTypes() {
    const { data: environmentClasses, isLoading: isLoadingClasses } = useListEnvironmentClasses({ filter: { enabled: true } });
    const { availableRunners, isLoading: isLoadingRunners } = useAvailableRunners();

    const [envTypes, setEnvTypes] = useState<EnvironmentTypeEntry[]>([]);

    const [initialzed, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const classes = environmentClasses?.classes;
        if (availableRunners === undefined || classes === undefined) {
            return;
        }

        const runnersMap = new Map(availableRunners.map((r) => [r.runnerId, r]));
        const joined: EnvironmentTypeEntry[] = [];
        for (const clazz of classes) {
            const runner = runnersMap.get(clazz.runnerId);
            if (runner) {
                joined.push({ clazz, runner });
            }
        }

        setEnvTypes(joined);
        setInitialized(true);
    }, [availableRunners, environmentClasses?.classes]);

    return {
        isLoading: !initialzed || isLoadingClasses || isLoadingRunners,
        envTypes,
    };
}
