import { cn } from "@/components/podkit/lib/cn";
import { type FC, useMemo } from "react";

const iconColors = ["#49bbcb", "#5e93d1", "#72c083", "#987ad9", "#9b8c9d", "#b76491", "#de7a31", "#f46565"];

function hashCode(s: string) {
    return Math.abs(
        s.split("").reduce(function (a, b) {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0),
    );
}

type OrgIconProps = {
    orgName: string;
};
export const OrgIcon: FC<OrgIconProps> = ({ orgName }) => {
    const orgInitials = useMemo(() => {
        const initials = orgName
            .split(" ")
            .filter((w) => !!w && w.length > 1)
            .map((n) => n[0])
            .join("");
        return initials.substring(0, 2).toUpperCase();
    }, [orgName]);

    const backgroundColor = iconColors[hashCode(orgName) % iconColors.length];

    return (
        <div
            className={cn("inline-flex h-6 w-6 select-none items-center justify-center rounded-md")}
            style={{ backgroundColor }}
        >
            <span className="inline-block w-6 text-center text-xs leading-6 text-white">{orgInitials}</span>
        </div>
    );
};
