import { NotAuthorized } from "@/components/NotAuthorized";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useMembership } from "@/hooks/use-membership";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import type { FC } from "react";
import { Inventory } from "@/routes/settings/organization/environment-inventory/Inventory";

export const EnvironmentInventoryPage: FC = () => {
    useDocumentTitle("Environments");

    const { membership, isPending: isLoadingMembership } = useMembership();

    if (isLoadingMembership) {
        return <LoadingState />;
    }

    if (!membership) {
        return <></>;
    }

    if (membership.userRole !== OrganizationRole.ADMIN) {
        return <NotAuthorized />;
    }

    return <Inventory />;
};
