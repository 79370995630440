import gitpodLogo from "@/assets/gitpod-logo-large.svg";
import { useLocalRunner } from "@/hooks/use-local-runner";
import {
    LocalRunnerStatus,
    type LocalRunnerInstallationState,
    type LocalRunnerInstallationStep,
} from "frontend-shared/local-runner";
import { Loader2 as ProgressIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState, type FC, type PropsWithChildren } from "react";

import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { IconDot } from "@/assets/icons/geist/IconDot";
import { IconSpinner } from "@/assets/icons/geist/IconSpinner";
import { Button } from "@/components/flexkit/Button";
import { Alert } from "@/components/podkit/alert/Alert";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { Heading1, Heading2 } from "@/components/podkit/typography/Headings";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { appMainService } from "@/ipc";
import { Navigate } from "react-router-dom";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { useBootIntercom, useNewIntercomMessage } from "@/hooks/use-intercom";
import { useDesktop } from "@/hooks/use-desktop";
import { Text } from "@/components/podkit/typography/Text";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { IconRefresh } from "@/assets/icons/geist/IconRefresh";

const LocalRunnerLayout = ({ children }: PropsWithChildren) => {
    return (
        <div className="flex h-full flex-col items-center">
            <div className="my-auto flex max-w-[368px] flex-col items-center gap-2">
                <img src={gitpodLogo} alt="Gitpod logo" width="45" height="45" className="mb-5" />
                {children}
            </div>
        </div>
    );
};

export const LocalRunner: FC = () => {
    useBootIntercom();

    const localRunner = useLocalRunner();
    useDocumentTitle("Local Runner");

    if (localRunner.loading) {
        return <LoadingState />;
    }
    if (!LocalRunnerStatus.needsRunnerScreen(localRunner.status)) {
        return <Navigate to="/" />;
    }
    if (localRunner.status?.phase === "failed") {
        return <FailedLocalRunner status={localRunner.status} />;
    }
    if (
        localRunner.status?.phase === "installing" &&
        localRunner.status?.installation?.needsElevatedPermissions &&
        Object.entries(localRunner.status?.installation?.steps || {}).length === 0
    ) {
        return <LocalRunnerAuthorizationRequired status={localRunner.status} />;
    }

    if (localRunner.status?.phase === "installing") {
        return <InstallLocalRunner />;
    }
    return <div>{localRunner.status?.phase}</div>;
};

type LocalRunnerProps = {
    status: LocalRunnerStatus;
};

export function FailedLocalRunner({ status }: LocalRunnerProps) {
    const desktop = useDesktop();
    const newIntercomMessage = useNewIntercomMessage();

    const onCollectLogsAndDiagnostics = useCallback(() => appMainService?.collectLogsAndDiagnostics(), []);

    const onRetry = useCallback(() => desktop.runnerService?.run({}), [desktop.runnerService]);
    const onContactSupport = useCallback(() => {
        newIntercomMessage(
            "Hello Support Team,\n\nI encountered an issue with the local runner. Please find the collected logs and diagnostics attached.",
        );
    }, [newIntercomMessage]);

    const title = status.failure?.message || "Oops, something went wrong";

    return (
        <LocalRunnerLayout>
            <div className="flex flex-col items-center gap-7">
                <div className="flex flex-col gap-2">
                    <Heading1 className="text-center text-2xl font-medium tracking-tight text-content-primary">
                        {title}
                    </Heading1>
                    <Text className="max-w-xs text-center text-xl">
                        We&apos;re sorry for the inconvenience. Please try the following steps:
                    </Text>
                </div>

                <div className="flex flex-col gap-2">
                    <Text className="text-center text-base">
                        Click <span className="font-[700]">Retry</span> below to restart the local runner.
                    </Text>
                    <Text className="text-center text-base">
                        If the issue persists after retrying, please click{" "}
                        <span className="font-[700]">Collect Diagnostics</span> and start a support chat by clicking{" "}
                        <span className="font-[700]">Contact Support</span> with the diagnostics and a screenshot
                        attached.
                    </Text>
                    <Text className="text-center text-base">
                        For more details, see our{" "}
                        <ExternalLink
                            href="https://www.gitpod.io/docs/flex/gitpod-desktop#report-issue"
                            className="text-base"
                        >
                            troubleshooting guide
                        </ExternalLink>
                        .
                    </Text>
                </div>

                <div className="flex w-full flex-col gap-2">
                    <Button type="button" variant="primary" size="lg" onClick={onRetry} LeadingIcon={IconRefresh}>
                        Retry
                    </Button>
                    <Button type="button" variant="secondary" size="lg" onClick={onCollectLogsAndDiagnostics}>
                        Collect Diagnostics
                    </Button>
                    <Button type="button" variant="secondary" size="lg" onClick={onContactSupport}>
                        Contact Support
                    </Button>
                </div>
            </div>
        </LocalRunnerLayout>
    );
}

export function InstallLocalRunner() {
    const desktop = useDesktop();
    const localRunner = useLocalRunner();

    const onRetry = useCallback(() => desktop.runnerService?.run({}), [desktop.runnerService]);
    const newIntercomMessage = useNewIntercomMessage();
    const onCollectLogsAndDiagnostics = useCallback(() => appMainService?.collectLogsAndDiagnostics(), []);
    const onContactSupport = useCallback(() => {
        newIntercomMessage(
            "Hello Support Team,\n\nI encountered an issue with the Gitpod Desktop installation. Please find the collected logs, diagnostics, and a screenshot attached.",
        );
    }, [newIntercomMessage]);

    // We want do delay the "done" state of the individual steps, to create a more pleasing impression during installation
    const timer = useRef<NodeJS.Timeout | undefined>(undefined);
    const [stepCountCap, setStepCountCap] = useState(0);
    const [output, setOutput] = useState<LocalRunnerInstallationState>({ steps: {} });

    useEffect(() => {
        const filterStepsDone = (newState: LocalRunnerInstallationState, cap: number) => {
            const steps = Object.entries(newState.steps);
            const filteredSteps: Record<number, LocalRunnerInstallationStep> = {};
            for (const [ok, os] of steps) {
                const k = Number.parseInt(ok);
                const s = { ...os };
                filteredSteps[k] = s;
                if (k + 1 > cap) {
                    s.done = false;
                }
                if (k > cap) {
                    s.start = false;
                }
            }
            setOutput({
                ...newState,
                steps: filteredSteps,
            });
        };

        const newState = localRunner.status?.installation;
        if (!newState) {
            return;
        }

        const steps = Object.entries(newState.steps);
        const someError =
            newState.exitCode !== undefined || !!localRunner.status?.failure || steps.some(([_, s]) => !!s.error);
        const doneSteps = steps.filter(([_, s]) => s.done).length;
        const allDone = steps.length === doneSteps;
        if (someError || doneSteps === stepCountCap) {
            // Something is odd, or we don't want to slow down: set immediately
            setOutput(newState);
            if (timer.current) {
                clearTimeout(timer.current);
            }
            return;
        }
        filterStepsDone(newState, stepCountCap);

        if (doneSteps > stepCountCap) {
            if (timer.current) {
                return;
            }
            const waitTime = allDone ? 500 : 2000; // don't let people wait forever if we're already done
            timer.current = setTimeout(() => {
                timer.current = undefined;
                setStepCountCap(stepCountCap + 1);
            }, waitTime);
        }
    }, [localRunner.status?.installation, localRunner.status?.failure, stepCountCap, setStepCountCap, setOutput]);

    const steps = Object.entries(output.steps).map(([, step]) => step);
    const isError = typeof output.exitCode === "number" && output.exitCode !== 0;

    return (
        <InstallLocalRunnerView
            steps={steps}
            installFailed={isError}
            onRetry={onRetry}
            onCollectLogsAndDiagnostics={onCollectLogsAndDiagnostics}
            onContactSupport={onContactSupport}
        />
    );
}

type InstallLocalRunnerViewProps = {
    steps: LocalRunnerInstallationStep[];
    installFailed: boolean;
    onRetry: () => void;
    onCollectLogsAndDiagnostics: () => void;
    onContactSupport: () => void;
};

export const InstallLocalRunnerView: FC<InstallLocalRunnerViewProps> = ({
    steps,
    installFailed,
    onRetry,
    onCollectLogsAndDiagnostics,
    onContactSupport,
}) => {
    const errorMessage =
        steps
            .map((step) => step.error)
            .reverse()
            .find((e) => e) || "Unknown errors occurred.";
    return (
        <LocalRunnerLayout>
            <div className="flex w-[500px] flex-col items-center px-12">
                <Heading1 className="pb-3">Just a second ...</Heading1>

                <div className="pl-6">
                    {steps.map((step) => (
                        <div key={step.name} className="flex w-full flex-row items-center justify-start">
                            <div className="flex size-8 items-center justify-center">{getStatusIcon(step)}</div>
                            <div className="ml-1">{step.name}</div>
                        </div>
                    ))}
                </div>

                {installFailed && (
                    <Alert className="mt-4 bg-surface-02">
                        <div className="space-y-4">
                            <h2 className="text-center text-xl font-semibold">Oops, something went wrong</h2>

                            <div className="rounded-md border-[0.5px] border-border-base bg-surface-glass p-4">
                                <p className="break-words font-mono text-xs">{errorMessage}</p>
                            </div>

                            <div className="space-y-4">
                                <Text className="text-center">
                                    Make any required system adjustments and click{" "}
                                    <span className="font-[700]">Retry</span> below.
                                </Text>
                                <Text className="text-center">
                                    If the issue persists after retrying, please click{" "}
                                    <span className="font-[700]">Collect Diagnostics</span> and start a support chat by
                                    clicking <span className="font-[700]">Contact Support</span> with the diagnostics
                                    and a screenshot attached.
                                </Text>

                                <div className="flex w-full flex-col gap-2">
                                    <Button
                                        type="button"
                                        variant="primary"
                                        size="lg"
                                        onClick={onRetry}
                                        LeadingIcon={IconRefresh}
                                    >
                                        Retry
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        size="lg"
                                        onClick={onCollectLogsAndDiagnostics}
                                    >
                                        Collect Diagnostics
                                    </Button>
                                    <Button type="button" variant="secondary" size="lg" onClick={onContactSupport}>
                                        Contact Support
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Alert>
                )}
            </div>
        </LocalRunnerLayout>
    );
};

function getStatusIcon(step: { start: boolean; done: boolean; error?: string }) {
    if (step.error) {
        return <IconWarning size="lg" className="text-content-negative" />;
    }
    if (step.done) {
        return <IconCheckFill size="lg" className="text-content-green" />;
    }
    if (step.start) {
        return <IconSpinner size="lg" className="animate-spin text-content-yield" />;
    }

    return <IconDot size="lg" className="text-content-secondary" />;
}

export function LocalRunnerAuthorizationRequired({ status }: LocalRunnerProps) {
    const desktop = useDesktop();
    const onAuthorize = useCallback(
        () => desktop.runnerService?.run({ elevatePermissions: true }),
        [desktop.runnerService],
    );

    return (
        <LocalRunnerLayout>
            <div className="flex w-[500px] flex-col items-center p-8 px-12">
                <Heading1 className="pb-3" id="permission-required">
                    Welcome to Gitpod
                </Heading1>
                <p className="text-center text-xl text-content-secondary">
                    Gitpod requires system access to configure local Linux virtualization.
                </p>

                <div className="mt-6 w-full text-center">
                    <Button
                        id="authorize"
                        className="w-full"
                        variant={"primary"}
                        size={"lg"}
                        onClick={onAuthorize}
                        disabled={Object.entries(status?.installation?.steps || {}).length > 0}
                    >
                        Authorize Gitpod
                    </Button>
                </div>
            </div>
        </LocalRunnerLayout>
    );
}

export const QuitApp: FC = () => {
    const params = new URLSearchParams(window.location.search);
    const appName = params.get("app") || "Gitpod";
    const message = params.get("message") || "Stopping local environments...";
    return (
        <LocalRunnerLayout>
            <Heading2>Closing {appName}</Heading2>
            <div className="flex items-center py-3">
                <ProgressIcon className="mr-1 size-4 animate-spin stroke-content-yield" />
                {message}
            </div>
        </LocalRunnerLayout>
    );
};
