import { timeAgo } from "@/format/time";
import type { PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { Timestamp } from "@bufbuild/protobuf";

const timeAgoFormat = new Intl.RelativeTimeFormat("en", {
    style: "long",
});
export const getValidityDuration = (pat: PlainPersonalAccessToken) => {
    if (!pat.expiresAt) {
        return "";
    }
    const date = new Timestamp(pat.expiresAt).toDate();
    const relative = timeAgo(date, timeAgoFormat);
    let prefix = "Expires";
    if (date.getTime() < Date.now()) {
        prefix = "Expired";
    }
    return (
        <span title={date.toISOString()}>
            {prefix} {relative}
        </span>
    );
};

export const getCreationTime = (pat: PlainPersonalAccessToken) => {
    if (!pat.createdAt) {
        return "";
    }
    const date = new Timestamp(pat.createdAt).toDate();
    const relative = timeAgo(date, timeAgoFormat);
    return <span title={date.toISOString()}>{relative}</span>;
};
