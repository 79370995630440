import { IconCheckCircle } from "@/assets/icons/geist/IconCheckCircle";
import { IconInfoFilled } from "@/assets/icons/geist/IconInfoFilled";
import { IconLock } from "@/assets/icons/geist/IconLock";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonText } from "@/components/podkit/loading/Skeleton";
import { useMemo, type FC, type PropsWithChildren } from "react";

export type SectionStatus = "loading" | "required" | "optional" | "locked" | "initializing" | "complete";

export const RunnerDetailsSection: FC<
    PropsWithChildren<{
        status: SectionStatus;
    }>
> = ({ children, status }) => {
    return (
        <div className="flex flex-col rounded-xl border border-border-light bg-surface-primary">
            <div className="border-b border-border-light px-4 py-5">
                <SkeletonText size="sm" ready={status !== "loading"} className="w-[150px]">
                    <div className="flex gap-1">
                        <SectionStatusIcon status={status} />
                        <SectionStatusText status={status} />
                    </div>
                </SkeletonText>
            </div>
            <div className="px-4 py-5">{children}</div>
        </div>
    );
};

const SectionStatusIcon: FC<{ status: SectionStatus }> = ({ status }) => {
    switch (status) {
        case "required":
            return <IconInfoFilled size="sm" className="text-content-yield" />;
        case "locked":
        case "initializing":
            return <IconLock size="sm" className="text-content-secondary" />;
        case "optional":
            return null;
        case "complete":
            return <IconCheckCircle size="sm" className="text-content-positive" />;
    }
};

const SectionStatusText: FC<{ status: SectionStatus }> = ({ status }) => {
    const { text, fgColor } = useMemo(() => {
        switch (status) {
            case "loading":
                return {
                    text: "Loading...",
                    fgColor: "text-content-primary",
                };
            case "required":
                return {
                    text: "Please complete this step",
                    fgColor: "text-content-primary",
                };
            case "locked":
                return {
                    text: "Complete the previous steps to unlock this",
                    fgColor: "text-content-secondary",
                };
            case "initializing":
                return {
                    text: "Waiting for runner to fully initialize",
                    fgColor: "text-content-secondary",
                };
            case "optional":
                return {
                    text: "Optional",
                    fgColor: "text-content-primary",
                };
            case "complete":
                return {
                    text: "Complete",
                    fgColor: "text-content-primary",
                };
        }
    }, [status]);
    return <div className={cn("text-sm", fgColor)}>{text}</div>;
};
