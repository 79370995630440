import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { type FC, useCallback, useMemo } from "react";

import { useToast } from "@/components/podkit/toasts/use-toast";
import { type PlainRunner, useSetLocalRunnersEnabled } from "@/queries/runner-queries";
import { formatError } from "@/utils/errors";
import { useNavigate } from "react-router-dom";
import { TrackLocations } from "@/hooks/use-segment";
import { useListProjects } from "@/queries/project-queries.ts";
import { useListEnvironmentInventory } from "@/queries/environment-queries.ts";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { pluralizeWithCount } from "@/utils/strings.ts";
import { Text } from "@/components/podkit/typography/Text.tsx";
import { IconCheck } from "@/assets/icons/geist/IconCheck.tsx";
import { IconWarning } from "@/assets/icons/geist/IconWarning.tsx";

type Props = {
    localConfigRunner: PlainRunner;
    onClose: () => void;
};

export const DisableLocalRunnersModal: FC<Props> = ({ localConfigRunner, onClose }) => {
    const { toast } = useToast();
    const setLocalRunnersEnabled = useSetLocalRunnersEnabled(localConfigRunner);

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const { data: projectsData } = useListProjects();
    const numProjects = useMemo(() => {
        return projectsData?.projects.filter((p) => {
            return p.environmentClass?.environmentClass?.case === "localRunner";
        }).length ?? 0;
    }, [projectsData]);

    const {
        data: environmentsData,
        hasNextPage: hasNextPageEnvironments,
    } = useListEnvironmentInventory({
        runnerKind: RunnerKind.LOCAL,
    });
    const numEnvironments = useMemo(() => {
        return environmentsData?.pages.flatMap((page) => page.environments).length ?? 0;
    }, [environmentsData]);

    const handleSubmit = useCallback(async () => {
        try {
            await setLocalRunnersEnabled.mutateAsync({ enabled: false });
            toast({
                title: "Disabled local environments",
            });
            onClose();
        } catch (error) {
            toast({
                title: "Failed to disable local environments",
                description: formatError(error),
            });
        }
    }, [onClose, setLocalRunnersEnabled, toast]);

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="max-w-lg" data-track-location={TrackLocations.DisableLocalRunnersModal}>
                <DialogHeader>
                    <DialogTitle>Turn off local development environments</DialogTitle>
                    <DialogDescription>Gitpod Desktop</DialogDescription>
                </DialogHeader>

                <DialogBody>
                    <div className="flex flex-col gap-4">
                        <AffectedItem
                            count={numProjects}
                            itemsHeader={`${pluralizeWithCount(numProjects, false, "project")} will stop working`}
                            itemsDescription="These will need new runners assigned to them"
                            noItemsHeader="No projects are affected"
                            noItemsDescription="There are no projects using Gitpod Desktop"
                            viewLink="/projects?environmentClass=local"
                        />
                        <AffectedItem
                            count={numEnvironments}
                            itemsHeader={`${pluralizeWithCount(numEnvironments, hasNextPageEnvironments, "environment")} will stop`}
                            itemsDescription="These cannot be restarted by the user"
                            noItemsHeader="No environments are affected"
                            noItemsDescription="There are no environments using Gitpod Desktop"
                            viewLink="/settings/environments?runner=local"
                        />
                    </div>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        loading={setLocalRunnersEnabled.isPending}
                        onClick={handleSubmit}
                        autoFocus={true}
                        aria-label="create"
                        type="submit"
                        data-testid="disable-local-runners-button"
                        variant="destructive"
                        form="disable-local-runners-form"
                    >
                        Turn off
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

type AffectedItemProps = {
    count: number;
    itemsHeader: string;
    itemsDescription: string;
    noItemsHeader: string;
    noItemsDescription: string;
    viewLink: string;
};

const AffectedItem: FC<AffectedItemProps> = ({
    count,
    itemsHeader,
    itemsDescription,
    noItemsHeader,
    noItemsDescription,
    viewLink,
}) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center gap-4 rounded-md border-[0.5px] border-border-base p-4">
            {count === 0 ? (
                <IconCheck size="base" className="text-content-green" />
            ) : (
                <IconWarning size="base" className="text-content-red" />
            )}
            <div className="flex flex-col">
                <Text className="font-medium">
                    {count === 0 ? noItemsHeader : itemsHeader}
                </Text>
                <Text className="text-sm text-content-secondary">
                    {count === 0 ? noItemsDescription : itemsDescription}
                </Text>
            </div>
            <Button
                variant="secondary"
                disabled={count === 0}
                onClick={() => navigate(viewLink)}
                className="ml-auto"
            >
                View
            </Button>
        </div>
    );
};
