import { getTopLevelDomain } from "@/utils/tld";
import jsCookie from "js-cookie";

/**
 * This cookie indicates whether the connected client is a Gitpod user (= "has logged in within the last year") or not.
 * This is used by "gitpod.io" and "www.gitpod.io" to display different content/buttons.
 */
export const NAME = "gitpod-flex-user";
export const VALUE = "true";

export function installCookie(url: string) {
    try {
        jsCookie.set(NAME, VALUE, {
            path: "/",
            httpOnly: false,
            secure: false,
            "max-age": String(60 * 60 * 24 * 365), // 1 year
            sameSite: "Lax", // "Lax" needed to ensure we see cookies from users that navigate to gitpod.io from external sites
            domain: getTopLevelDomain(url),
        });
    } catch {
        /* empty */
    }
}

export function isCookiePresent(): boolean {
    try {
        return !!jsCookie.get(NAME);
    } catch {
        return false;
    }
}
