import { useCallback, useEffect, useId, useState, type FC, type FormEvent, useMemo } from "react";
import {
    useCreateProjectPolicy,
    useDeleteProjectPolicy,
    useUpdateProject,
    type PlainProject,
} from "@/queries/project-queries";
import { ContextUrlInput } from "@/components/ContextUrlInput";
import { EnvironmentTypeSelect } from "@/components/EnvironmentTypeSelect";
import { Input } from "@/components/podkit/forms/Input";
import { useEnvironmentTypes, type EnvironmentTypeEntry } from "@/hooks/use-environment-types";
import {
    type ContextURLInitializer,
    type EnvironmentInitializer,
    type GitInitializer,
} from "gitpod-next-api/gitpod/v1/environment_pb";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { InputField } from "@/components/podkit/forms/InputField";
import type { PlainMessage } from "@bufbuild/protobuf";
import { equals } from "@/utils/objects";
import { RunnerKind, RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Button } from "@/components/flexkit/Button";

// localEnvType is a "special" environment type for projects that represents local runners.
const localEnvType: EnvironmentTypeEntry = {
    clazz: {
        enabled: true,
        id: "local",
        description: "Local",
        displayName: "Gitpod Desktop",
        runnerId: "local",
        configuration: [],
    },
    runner: {
        status: {
            phase: RunnerPhase.ACTIVE,
            version: "",
            systemDetails: "",
            logUrl: "",
            additionalInfo: [],
            message: "",
            region: "",
            capabilities: [],
        },
        runnerId: "local",
        name: "",
        kind: RunnerKind.LOCAL,
    },
};

export const ProjectSettings: FC<{
    project: PlainProject;
}> = ({ project }) => {
    const [projectName, setProjectName] = useState<string>(project.metadata?.name || "");
    const inputNameID = useId();
    const inputDevContainerFilePathID = useId();
    const inputAutomationsFilePath = useId();

    const [initializer, setInitializer] = useState<PlainMessage<EnvironmentInitializer> | undefined>(
        project.initializer,
    );

    const { envTypes: queriedEnvTypes, isLoading: isLoadingEnvTypes } = useEnvironmentTypes();
    const [selectedEnvironment, setSelectedEnvironment] = useState<EnvironmentTypeEntry>();

    const envTypes = useMemo(() => {
        return [localEnvType].concat(queriedEnvTypes.filter((e) => e.runner.kind !== RunnerKind.LOCAL));
    }, [queriedEnvTypes]);

    const [devcontainerFilePath, setDevcontainerFilePath] = useState<string>(
        project.devcontainerFilePath || ".devcontainer/devcontainer.json",
    );

    const [automationsFilePath, setAutomationsFilePath] = useState<string>(
        project.automationsFilePath || ".gitpod/automations.yaml",
    );

    const { toast } = useToast();

    const updateProject = useUpdateProject();
    const createProjectPolicy = useCreateProjectPolicy();
    const deleteProjectPolicy = useDeleteProjectPolicy();

    const runnerID = selectedEnvironment?.runner.runnerId;
    const classID = selectedEnvironment?.clazz.id;
    const formChanged =
        projectName !== project.metadata?.name ||
        !equals(project.initializer, initializer) ||
        (!!classID && classID !== project.environmentClass?.environmentClass.value) ||
        devcontainerFilePath !== project.devcontainerFilePath ||
        automationsFilePath !== project.automationsFilePath;

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (!projectName || !runnerID || !classID || !initializer) {
                console.log(
                    `Invalid project Name${projectName} or runnerId=${runnerID} or classID=${classID} or initializer`,
                );
                return;
            }

            try {
                await updateProject.mutateAsync({
                    projectId: project.id,
                    name: projectName !== project.metadata?.name ? projectName : undefined,
                    initializer: !equals(project.initializer, initializer) ? initializer : undefined,
                    classID: classID === localEnvType.clazz.id ? "" : classID,
                    devcontainerFilePath:
                        devcontainerFilePath !== project.devcontainerFilePath ? devcontainerFilePath : undefined,
                    automationsFilePath:
                        automationsFilePath !== project.automationsFilePath ? automationsFilePath : undefined,
                });
                toast({
                    title: "Project updated",
                });
            } catch (error) {
                toast({
                    title: "Failed to update project",
                    description: formatError(error),
                });
            }
        },
        [
            projectName,
            runnerID,
            classID,
            updateProject,
            project,
            devcontainerFilePath,
            automationsFilePath,
            toast,
            initializer,
        ],
    );

    // Set contextURL and environment type from project data
    useEffect(() => {
        if (!selectedEnvironment && envTypes.length) {
            let projectEnvType: EnvironmentTypeEntry | undefined;
            if (project?.environmentClass?.environmentClass.case === "localRunner") {
                projectEnvType = localEnvType;
            } else {
                projectEnvType = envTypes.find((e) => e.clazz.id === project?.environmentClass?.environmentClass.value);
            }
            if (projectEnvType) {
                setSelectedEnvironment(projectEnvType);
            }
        }
    }, [project, envTypes, selectedEnvironment]);

    const updateButtonDisabled = !selectedEnvironment || !projectName || !initializer || !formChanged;
    const updateButtonLoading =
        updateProject.isPending || createProjectPolicy.isPending || deleteProjectPolicy.isPending;

    const metadataFields = (
        <>
            <InputField label="Display name" id={inputNameID}>
                <Input
                    data-testid="project-name-input"
                    id={inputNameID}
                    type="text"
                    value={projectName || ""}
                    placeholder="Ex: My Project"
                    onChange={(e) => setProjectName(e.target.value.trimStart().replace(/\s+/g, " "))}
                />
            </InputField>
            <ProjectInitializerFields
                initializer={project?.initializer}
                onUpdate={(v) => {
                    setInitializer(v);
                }}
                onError={() => setInitializer(undefined)}
            />
            <EnvironmentTypeSelect
                label="Default environment"
                value={selectedEnvironment}
                onChange={(v) => setSelectedEnvironment(v)}
                name="environment-type"
                loading={isLoadingEnvTypes}
                disabled={false}
                envTypes={envTypes}
            />
        </>
    );

    const devcontainerFields = (
        <InputField label="Dev Container file path" id={inputDevContainerFilePathID}>
            <Input
                id={inputDevContainerFilePathID}
                type="text"
                value={devcontainerFilePath}
                onChange={(e) => setDevcontainerFilePath(e.target.value)}
            />
        </InputField>
    );

    const automationFields = (
        <InputField label="Automations file path" id={inputAutomationsFilePath}>
            <Input
                id={inputAutomationsFilePath}
                type="text"
                value={automationsFilePath}
                onChange={(e) => setAutomationsFilePath(e.target.value)}
            />
        </InputField>
    );

    return (
        <form onSubmit={handleSubmit} className="flex max-w-[456px] flex-col gap-6" data-testid="project-details-form">
            {metadataFields}
            {devcontainerFields}
            {automationFields}
            <Button
                className="max-w-fit"
                type="submit"
                variant="primary"
                loading={updateButtonLoading}
                disabled={updateButtonDisabled}
                data-testid="save-changes-button"
            >
                Save Changes
            </Button>
        </form>
    );
};

type InitializerProps = {
    initializer?: PlainMessage<EnvironmentInitializer>;
    onUpdate: (initializer?: PlainMessage<EnvironmentInitializer>) => void;
    onError: (error: string) => void;
};

const ProjectInitializerFields: React.FC<InitializerProps> = ({ initializer, onUpdate, onError }) => {
    if (initializer?.specs[0].spec.case === "contextUrl") {
        return (
            <ContextURLInputField
                initializer={initializer?.specs[0].spec.value}
                onUpdate={(updatedValue) =>
                    onUpdate({ specs: [{ spec: { value: updatedValue, case: "contextUrl" } }] })
                }
                onError={onError}
            />
        );
    }
    if (initializer?.specs[0].spec.case === "git") {
        return (
            <GitInitializerInput
                initializer={initializer?.specs[0].spec.value}
                onUpdate={(updatedValue) => onUpdate({ specs: [{ spec: { value: updatedValue, case: "git" } }] })}
                onError={onError}
            />
        );
    }
    return <></>;
};

const ContextURLInputField: React.FC<{
    initializer: PlainMessage<ContextURLInitializer>;
    onUpdate: (initializer: PlainMessage<ContextURLInitializer>) => void;
    onError: (error: string) => void;
}> = ({ initializer, onUpdate, onError }) => {
    const [contextURL, setContextURL] = useState(initializer.url);

    const handleChange = useCallback(
        (newURL: string) => {
            setContextURL(newURL);

            if (!newURL) {
                onError("Repository URL is required");
                return;
            }
            try {
                new URL(newURL);
            } catch {
                onError("Invalid Repository URL");
                return;
            }

            onUpdate({ url: newURL });
        },
        [onUpdate, onError],
    );

    return <ContextUrlInput label="Context URL" onChange={(v) => handleChange(v)} value={contextURL} />;
};

const GitInitializerInput: React.FC<{
    initializer: PlainMessage<GitInitializer>;
    onUpdate: (initializer: PlainMessage<GitInitializer>) => void;
    onError: (error: string) => void;
}> = ({ initializer, onUpdate, onError }) => {
    const inputRepoUrlID = useId();
    const inputBranchID = useId();

    const [branch, setBranch] = useState<string>(initializer.cloneTarget);
    const [repoURL, setRepoURL] = useState<string>(initializer.remoteUri);

    const handleChange = useCallback(
        (newRepoURL: string, newBranch: string) => {
            setRepoURL(newRepoURL);
            setBranch(newBranch);

            if (!newRepoURL) {
                onError("Repository URL is required");
                return;
            }
            if (!newBranch) {
                onError("Branch is required");
                return;
            }
            try {
                new URL(newRepoURL);
            } catch {
                onError("Invalid Repository URL");
                return;
            }

            const value = {
                ...initializer,
                cloneTarget: newBranch,
                remoteUri: newRepoURL,
            };
            onUpdate(value);
        },
        [initializer, onUpdate, onError],
    );

    return (
        <div className="flex flex-col gap-2">
            <InputField label="Repository Clone URL" id={inputRepoUrlID}>
                <Input
                    id={inputRepoUrlID}
                    type="text"
                    value={repoURL}
                    onChange={(e) => handleChange(e.target.value.trimStart().replace(/\s+/g, " "), branch)}
                />
            </InputField>
            <InputField label="Branch" id={inputBranchID}>
                <Input
                    id={inputBranchID}
                    type="text"
                    value={branch}
                    onChange={(e) => handleChange(repoURL, e.target.value.trimStart().replace(/\s+/g, " "))}
                />
            </InputField>
        </div>
    );
};
