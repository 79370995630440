import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { type PlainRunner } from "@/queries/runner-queries";
import { RunnerAdditionalFieldsKeys } from "@/components/runners/details/runner-configuration-keys";
import { RunnerPhaseTag } from "@/components/runners/RunnerCard";
import { type FC, type ReactNode } from "react";

export const CloudFormationStackDetails: FC<{ runner: PlainRunner }> = ({ runner }) => {
    const dict = Object.fromEntries(runner.status?.additionalInfo.map((info) => [info.key, info.value]) || []);

    const accountID = dict[RunnerAdditionalFieldsKeys.AWSAccountID] || "";
    const url = dict[RunnerAdditionalFieldsKeys.StackURL] || "";
    const name = dict[RunnerAdditionalFieldsKeys.StackName] || "";
    const region = dict[RunnerAdditionalFieldsKeys.Region] || "";

    return (
        <div className="flex flex-col gap-2 overflow-x-hidden">
            {runner && (
                <Info label="Status">
                    <RunnerPhaseTag runner={runner} />
                </Info>
            )}
            {accountID && <Info label="AWS Account ID">{accountID}</Info>}
            {name && <Info label="Stack name">{name}</Info>}
            {url && (
                <Info label="Stack URL">
                    <ExternalLink href={url} className="block truncate text-content-orange">
                        {url}
                    </ExternalLink>
                </Info>
            )}
            <Info label="Runner name">{runner.name}</Info>
            {region && <Info label="Runner region">{region}</Info>}
        </div>
    );
};

const Info: FC<{ label: string; children: ReactNode }> = ({ label, children }) => (
    <div className="flex flex-col gap-1 md:flex-row md:gap-2">
        <Text className="min-w-max text-base">{label}: </Text>
        <Text className="shrink truncate text-base font-bold">{children}</Text>
    </div>
);
