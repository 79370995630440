import { useCallback, useState, useMemo, type FC } from "react";
import { useCreateRemoteRunner, useListRunners } from "@/queries/runner-queries";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { NewRunnerForm } from "@/components/runners/NewRunnerForm";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { Button } from "@/components/flexkit/Button";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { TrackLocations } from "@/hooks/use-segment";
import { useNavigate } from "react-router-dom";
import { TimelineContentContainer } from "@/components/onboarding/TimelineContentContainer";
import { ContinueWithExistingRunners } from "@/components/onboarding/ContinueWithExistingRunners";

export const CreateRunnerPage: FC = () => {
    const navigate = useNavigate();
    const createRunner = useCreateRemoteRunner();
    const [disabled, setDisabled] = useState(false);
    const { data: remoteRunners } = useListRunners({ kind: RunnerKind.REMOTE });

    const hasExistingRunner = useMemo(() => !!remoteRunners?.runners?.length, [remoteRunners]);

    const onBack = useCallback(() => {
        navigate("/onboarding/set-up-runner/remote/provider");
    }, [navigate]);

    const handleCreateRunner = useCallback<(values: { name: string; region: string }) => Promise<void>>(
        async ({ name, region }) => {
            const { runner } = await createRunner.mutateAsync({ name, region });
            navigate(`/onboarding/set-up-runner/remote/configure/${runner.runnerId}/install`);
        },
        [createRunner, navigate],
    );

    return (
        <TimelineContentContainer data-track-location={TrackLocations.OnboardingRunnerCreateRunnerTabStep}>
            <div className="flex flex-col items-start gap-8">
                <div className="flex w-full flex-col gap-4 lg:flex-row lg:gap-8">
                    <div className="flex w-full flex-col gap-2 lg:w-1/2">
                        <Heading2>Create runner</Heading2>
                        <Text className="text-base text-content-secondary">
                            To continue you will need an{" "}
                            <ExternalLink href="https://aws.amazon.com/resources/create-account/">
                                AWS account
                            </ExternalLink>
                            . The AWS Free Tier covers the fixed-cost of the AWS runner (not that of environments).
                        </Text>
                        <Text className="text-base text-content-secondary">
                            Choose the AWS region to deploy the runner into.
                        </Text>
                    </div>
                    <div className="flex w-full flex-col gap-4 rounded-lg border-0.5 p-4 pr-6 lg:w-1/2 lg:min-w-[495px]">
                        {hasExistingRunner && (
                            <>
                                <div className="flex flex-col gap-2">
                                    <Text className="text-lg font-bold text-content-primary">
                                        Continue with an existing runner
                                    </Text>
                                    <ContinueWithExistingRunners runners={remoteRunners?.runners ?? []} />
                                </div>
                                <Text className="text-lg font-bold text-content-primary">Or, create a new runner</Text>
                            </>
                        )}
                        <NewRunnerForm
                            formId="new-remote-runner"
                            onDisabledChange={setDisabled}
                            onSubmit={handleCreateRunner}
                            className="flex-col *:flex-grow"
                        />
                    </div>
                </div>
                <div className="flex gap-2 self-end">
                    <Button variant="secondary" onClick={onBack}>
                        Back
                    </Button>
                    <Button
                        type="submit"
                        form="new-remote-runner"
                        variant="primary"
                        disabled={disabled}
                        loading={createRunner.isPending}
                        aria-label="Create"
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </TimelineContentContainer>
    );
};
