import { type FC } from "react";
import { type PlainPersonalAccessToken } from "@/queries/personal-access-tokens-queries";
import { type PlainOrganizationMember } from "@/queries/organization-queries";
import { Button } from "@/components/flexkit/Button";
import { Text } from "@/components/podkit/typography/Text";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { getCreationTime, getValidityDuration } from "@/routes/settings/user/personal-access-tokens/time-format";
import { IconPlusSquare } from "@/assets/icons/geist/IconPlusSquare";

export const PersonalAccessTokensList: FC<{
    tokens: {
        pat: PlainPersonalAccessToken;
        creator?: PlainOrganizationMember;
        currentMember?: PlainOrganizationMember;
    }[];
    onNew: () => void;
    onDelete: (pat: PlainPersonalAccessToken) => void;
}> = ({ tokens, onNew, onDelete }) => {
    const hasPATs = tokens.length > 0;
    return (
        <div data-testid="personal-access-tokens-list">
            <div className="flex flex-col flex-wrap items-center gap-2">
                {tokens.map(({ pat, creator, currentMember }) => (
                    <PersonalAccessTokenRow
                        key={pat.id}
                        pat={pat}
                        creator={creator}
                        currentMember={currentMember}
                        onDelete={() => onDelete(pat)}
                    />
                ))}
                {hasPATs && (
                    <Button
                        data-testid="new-token-button-row"
                        variant="ghost"
                        onClick={() => onNew()}
                        size="lg"
                        LeadingIcon={IconPlusSquare}
                        className="group w-full rounded-xl border-[1px] border-dashed px-3 py-2 hover:bg-surface-02"
                    >
                        <Text className="flex h-9 w-full items-center group-disabled:text-content-secondary">
                            New Token
                        </Text>
                    </Button>
                )}
                {!hasPATs && (
                    <div
                        className="flex flex-col gap-4 py-10 text-center items-center"
                        data-testid="personal-access-tokens-empty-state"
                    >
                        <div className="flex flex-col">
                            <Heading2 className="text-lg font-bold">No Personal Access Tokens</Heading2>
                            <div className="text-base">Create a new token to integrate with other systems.</div>
                        </div>
                        <Button
                            onClick={() => onNew()}
                            size="md"
                            variant="primary"
                            data-testid="new-token-button-empty-state"
                        >
                            New Token
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const PersonalAccessTokenRow: FC<{
    pat: PlainPersonalAccessToken;
    creator?: PlainOrganizationMember;
    currentMember?: PlainOrganizationMember;
    onDelete: () => void;
}> = ({ pat, creator, currentMember, onDelete }) => {
    return (
        <div
            className="group inline-flex h-14 w-full items-center justify-start gap-4 rounded-xl border border-border-base bg-surface-glass px-4 py-2.5 text-xs transition-shadow duration-200 hover:shadow"
            data-testid={pat.id}
        >
            <div className="min-w-6/12 max-w-6/12 flex w-6/12 flex-col truncate">
                <Text className="truncate text-base font-bold text-content-primary">{pat.description}</Text>
                <Text className="truncate text-sm text-content-secondary">
                    by{" "}
                    {currentMember?.userId === creator?.userId ? (
                        <span>You</span>
                    ) : (
                        creator?.fullName || <span>Unknown</span>
                    )}
                    {" · "}
                    {getCreationTime(pat)}
                </Text>
            </div>
            <div className="max-w-3/12 flex w-3/12 flex-col items-start justify-start truncate">
                <div className="truncate text-base text-content-secondary">{getValidityDuration(pat)}</div>
            </div>
            <div className="max-w-4/12 flex w-3/12 items-end justify-end gap-2">
                <Button
                    variant={"primary"}
                    size={"md"}
                    onClick={() => onDelete()}
                    data-testid={`delete-personal-access-token-${pat.id}`}
                >
                    Remove
                </Button>
            </div>
        </div>
    );
};
