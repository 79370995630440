import { SkeletonBlock } from "@/components/podkit/loading/Skeleton";
import { ProjectFailedToLoad } from "@/components/projects/ProjectErrors";
import { ProjectSecrets } from "@/components/projects/ProjectSecrets";
import { Flags } from "@/feature-flags";
import { useFlag } from "@/hooks/use-flag";
import { useListSecrets } from "@/queries/secret-queries";
import { useCallback, useState, type FC } from "react";
import { useParams } from "react-router-dom";
import { NewSecretModal } from "@/components/projects/NewSecretModal";

export const ProjectSecretsPage: FC = () => {
    const { projectId } = useParams();
    const { value: secretsEnabled } = useFlag(Flags.SecretsEnabled, false);
    const { data: secrets, isPending, error } = useListSecrets(projectId);

    const [showAddSecretModal, setShowAddSecretModal] = useState(false);

    const onNewSecret = useCallback(() => {
        setShowAddSecretModal(true);
    }, [setShowAddSecretModal]);

    let content: React.ReactNode | null = null;
    if (error) {
        content = <ProjectFailedToLoad />;
    } else if (isPending) {
        content = <SkeletonBlock ready={!isPending} failed={!!error} className="h-[160px] w-[800px]" />;
    } else {
        content = <ProjectSecrets secrets={secrets?.secrets ?? []} onNewSecret={onNewSecret} />;
    }

    return (
        <div data-testid="project-details-secrets-page">
            {secretsEnabled && content}
            {showAddSecretModal && projectId && (
                <NewSecretModal projectId={projectId} onClose={() => setShowAddSecretModal(false)} />
            )}
        </div>
    );
};
