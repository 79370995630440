import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { PlainRunner } from "@/queries/runner-queries";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import { LaptopIcon, CloudIcon } from "lucide-react";
import { useMemo, type FC } from "react";

export const RunnerIcon: FC<PropsWithClassName & { runner?: PlainRunner }> = ({ runner, className }) => {
    const isPersonal = useMemo(() => runner?.kind === RunnerKind.LOCAL, [runner?.kind]);

    const classes = cn("h-5 w-5 text-content-primary", className);

    return isPersonal ? <LaptopIcon className={classes} /> : <CloudIcon className={classes} />;
};
