import { toast } from "@/components/podkit/toasts/use-toast";
import { useSetEnvironmentClassEnabled } from "@/queries/runner-configuration-queries";
import { formatError } from "@/utils/errors";
import type { EnvironmentClass } from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { type FC, useCallback } from "react";
import { Toggle } from "@/components/Toggle.tsx";

export const EnvironmentClassToggle: FC<{
    environmentClass: EnvironmentClass,
    disabled: boolean
}> = ({ environmentClass, disabled }) => {
    const setEnvironmentClassEnabled = useSetEnvironmentClassEnabled(environmentClass);

    const handleClick = useCallback(async () => {
        try {
            if (environmentClass.enabled) {
                await setEnvironmentClassEnabled.mutateAsync({ enabled: false });
            } else {
                await setEnvironmentClassEnabled.mutateAsync({ enabled: true });
            }
        } catch (error) {
            toast({
                title: `Failed to ${environmentClass.enabled ? "disable" : "enable"} environment class`,
                description: formatError(error),
            });
        }
    }, [setEnvironmentClassEnabled, environmentClass.enabled]);

    return (
        <Toggle
            state={setEnvironmentClassEnabled.isPending ? "pending" : environmentClass.enabled ? "checked" : "unchecked"}
            hasFailed={false}
            disabled={disabled}
            onToggle={handleClick}
            fixedWidth={true} />
    );
};
