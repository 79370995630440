import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconLaptop: FC<{ size: Size | "xl" } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.1135 10.2371V4.48448C13.1135 4.14544 12.9788 3.82028 12.739 3.58054C12.4993 3.3408 12.1741 3.20612 11.8351 3.20612H4.1649C3.82586 3.20612 3.5007 3.3408 3.26096 3.58054C3.02122 3.82028 2.88654 4.14544 2.88654 4.48448V10.2371M13.1135 10.2371H2.88654M13.1135 10.2371L13.9316 11.867C13.9809 11.9648 14.0042 12.0736 13.9994 12.183C13.9945 12.2924 13.9616 12.3987 13.9039 12.4918C13.8461 12.5848 13.7655 12.6615 13.6696 12.7143C13.5737 12.7672 13.4659 12.7946 13.3564 12.7939H2.64365C2.53415 12.7946 2.42629 12.7672 2.33041 12.7143C2.23453 12.6615 2.15385 12.5848 2.09611 12.4918C2.03836 12.3987 2.00548 12.2924 2.00063 12.183C1.99577 12.0736 2.0191 11.9648 2.06838 11.867L2.88654 10.2371"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.9657 12.61V5.89856C15.9657 5.50301 15.8086 5.12366 15.5289 4.84396C15.2492 4.56427 14.8698 4.40714 14.4743 4.40714H5.52572C5.13017 4.40714 4.75082 4.56427 4.47112 4.84396C4.19142 5.12366 4.03429 5.50301 4.03429 5.89856V12.61M15.9657 12.61H4.03429M15.9657 12.61L16.9202 14.5116C16.9777 14.6256 17.0049 14.7526 16.9993 14.8802C16.9936 15.0078 16.9552 15.1319 16.8879 15.2404C16.8205 15.3489 16.7264 15.4384 16.6145 15.5001C16.5027 15.5618 16.3768 15.5937 16.2491 15.5928H3.75092C3.62317 15.5937 3.49734 15.5618 3.38548 15.5001C3.27362 15.4384 3.1795 15.3489 3.11213 15.2404C3.04475 15.1319 3.0064 15.0078 3.00073 14.8802C2.99507 14.7526 3.02229 14.6256 3.07978 14.5116L4.03429 12.61"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18.818 14.9828V7.31264C18.818 6.86058 18.6384 6.42704 18.3187 6.10739C17.9991 5.78773 17.5655 5.60815 17.1135 5.60815H6.88654C6.43448 5.60815 6.00093 5.78773 5.68128 6.10739C5.36163 6.42704 5.18205 6.86058 5.18205 7.31264V14.9828M18.818 14.9828H5.18205M18.818 14.9828L19.9088 17.1561C19.9745 17.2864 20.0056 17.4315 19.9992 17.5774C19.9927 17.7232 19.9489 17.865 19.8719 17.989C19.7949 18.1131 19.6873 18.2153 19.5595 18.2858C19.4316 18.3563 19.2878 18.3928 19.1418 18.3918H4.8582C4.7122 18.3928 4.56839 18.3563 4.44055 18.2858C4.31271 18.2153 4.20514 18.1131 4.12814 17.989C4.05115 17.865 4.00731 17.7232 4.00084 17.5774C3.99437 17.4315 4.02547 17.2864 4.09118 17.1561L5.18205 14.9828"
                        stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
        case "xl":
            return (
                <svg
                    className={className}
                    width="32"
                    height="25"
                    viewBox="0 0 32 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M28 18V4.5C28 3.70435 27.684 2.94129 27.1214 2.37868C26.5587 1.81607 25.7957 1.5 25 1.5H7.00003C6.20438 1.5 5.44132 1.81607 4.87871 2.37868C4.3161 2.94129 4.00003 3.70435 4.00003 4.5V18M28 18H4.00003M28 18L29.92 21.825C30.0357 22.0545 30.0904 22.3098 30.079 22.5665C30.0676 22.8233 29.9905 23.0727 29.855 23.2911C29.7194 23.5094 29.5301 23.6893 29.3051 23.8134C29.0801 23.9375 28.827 24.0018 28.57 24H3.43003C3.17307 24.0018 2.91995 23.9375 2.69495 23.8134C2.46995 23.6893 2.28061 23.5094 2.1451 23.2911C2.00958 23.0727 1.93242 22.8233 1.92103 22.5665C1.90964 22.3098 1.96439 22.0545 2.08003 21.825L4.00003 18"
                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            );
    }
};
