import { IconPlusSquare } from "@/assets/icons/geist/IconPlusSquare";
import { ErrorMessage } from "@/components/ErrorMessage";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { Dialog, DialogContent } from "@/components/podkit/modal/Modal";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import { useMembership } from "@/hooks/use-membership";
import { useListRunnerSCMIntegrations } from "@/queries/runner-configuration-queries";
import { RunnerDetailsSection, type SectionStatus } from "@/components/runners/details/RunnerDetailsSection";
import { ProviderRow, SourceControlProviderAddModal } from "@/components/runners/details/SourceControlProviderAddModal";
import { SourceControlProviderIcon } from "@/components/runners/details/SourceControlProviderIcon";
import { SourceControlProviderModal } from "@/components/runners/details/SourceControlProviderModal";
import { SourceControlProviderRemoveModal } from "@/components/runners/details/SourceControlProviderRemoveModal";
import type { PlainMessage } from "@bufbuild/protobuf";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import {
    type RunnerConfigurationSchema_SCMConfigSchema,
    SCMIntegration,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { type Runner, type RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import type React from "react";
import { type FC, type PropsWithChildren, useCallback, useState } from "react";
import type { TrackLocation } from "@/hooks/use-segment";
import { AllProviders } from "@/components/runners/details/source-control-providers.tsx";

export type Provider = { schema: RunnerConfigurationSchema_SCMConfigSchema; name: string; state: ProviderState };
export type ProviderState = "coming-soon" | "available";

type ModalState =
    | { type: "closed" }
    | { type: "add-provider" }
    | {
    type: "edit-provider";
    schema: RunnerConfigurationSchema_SCMConfigSchema;
    integration: SCMIntegration;
}
    | {
    type: "remove-provider";
    integration: SCMIntegration;
};

export const SourceControlProviderSection: FC<{
    runner: PlainMessage<Runner>;
    schemas?: RunnerConfigurationSchema_SCMConfigSchema[];
    hasStack: boolean;
    "data-track-location": (modalState: ModalState) => TrackLocation;
}> = ({ runner, schemas, hasStack, "data-track-location": dataTrackLocation }) => {
    return (
        <SourceControlProvider
            runner={runner}
            schemas={schemas}
            hasStack={hasStack}
            Container={RunnerDetailsSection}
            withDescription={true}
            data-track-location={dataTrackLocation}
        />
    );
};

export const SourceControlProvider: FC<{
    runner: PlainMessage<Runner>;
    schemas?: RunnerConfigurationSchema_SCMConfigSchema[];
    hasStack: boolean;
    Container: React.ComponentType<{ status: SectionStatus } & PropsWithChildren>;
    withDescription: boolean;
    "data-track-location": (modalState: ModalState) => TrackLocation;
}> = ({ runner, schemas, hasStack, Container, withDescription, "data-track-location": dataTrackLocation }) => {
    const {
        data: integrations,
        isLoading: isLoadingIntegrations,
        error,
    } = useListRunnerSCMIntegrations(runner.runnerId);
    const { membership, isPending: isLoadingMembership } = useMembership();
    const isLoading = isLoadingIntegrations || isLoadingMembership;

    const [modalState, setModalState] = useState<ModalState>({ type: "closed" });

    const onCloseModal = (open: boolean) => {
        if (!open) {
            setModalState({ type: "closed" });
        }
    };

    const onEditProvider = useCallback(
        (integration: SCMIntegration, schema: RunnerConfigurationSchema_SCMConfigSchema) => {
            setModalState({
                type: "edit-provider",
                schema: schema,
                integration,
            });
        },
        [setModalState],
    );

    const onRemoveProvider = useCallback(
        (integration: SCMIntegration) => {
            setModalState({
                type: "remove-provider",
                integration,
            });
        },
        [setModalState],
    );

    const onBack = useCallback(() => {
        setModalState({ type: "add-provider" });
    }, [setModalState]);

    const onAddProvider = useCallback(() => {
        setModalState({ type: "add-provider" });
    }, [setModalState]);

    const onProviderCreate = useCallback(
        (provider: Provider) => {
            setModalState({
                type: "edit-provider",
                schema: provider.schema,
                integration: new SCMIntegration({ scmId: provider.schema.scmId }),
            });
        },
        [setModalState],
    );

    const hasConfiguredProviders = integrations && integrations.length > 0;
    let status: SectionStatus = "loading";
    if (!isLoading) {
        if (!hasStack) {
            status = "locked";
        } else if (!schemas?.length) {
            status = "initializing";
        } else {
            status = hasConfiguredProviders ? "complete" : "required";
        }
    }

    const providers: Provider[] = [];

    if (schemas?.length) {
        schemas.forEach((scm) => {
            providers.push({ schema: scm, name: scm.name, state: "available" });
        });

        // Add default providers that are not in the list of configured providers, but in a "coming-soon" state.
        AllProviders.forEach((provider) => {
            if (!schemas.map((provider) => provider.scmId).includes(provider.schema.scmId)) {
                providers.push({
                    ...provider,
                    state: "coming-soon",
                });
            }
        });
    }

    const ready = status !== "locked" && status !== "initializing";
    const readOnly = !ready || membership?.userRole !== OrganizationRole.ADMIN;

    return (
        <Container status={status}>
            <div className="flex flex-col gap-1" data-testid="scm-section">
                {withDescription && (
                    <SkeletonText ready={status !== "loading"} size="lg" className="w-48">
                        <Text className={cn(!ready && "text-content-secondary", "text-lg font-bold")}>
                            Configure repository access
                        </Text>
                    </SkeletonText>
                )}
                <SkeletonBlock ready={!isLoading} className="mt-1 h-[100px]">
                    <div className="flex flex-col gap-4">
                        {withDescription && (
                            <div className="flex flex-col items-start justify-between gap-4 md:flex-row">
                                <Text className={cn(!ready && "text-content-secondary", "text-base")}>
                                    In order for your runner to access your repos, you&#x2019;ll need to configure your
                                    repository provider (GitHub, GitLab, or Bitbucket).{" "}
                                    {ready && (
                                        <ExternalLink
                                            className="font-bold"
                                            href="https://www.gitpod.io/docs/flex/source-control"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Read the docs.
                                        </ExternalLink>
                                    )}
                                </Text>
                            </div>
                        )}
                        <ProviderConfigurations
                            ready={ready}
                            error={error}
                            readOnly={readOnly}
                            providers={providers}
                            runnerKind={runner.kind}
                            integrations={integrations || []}
                            onAddProvider={onAddProvider}
                            onProviderCreate={onProviderCreate}
                            onEditProvider={onEditProvider}
                        />
                    </div>
                </SkeletonBlock>
            </div>
            {modalState.type !== "closed" && (
                <Dialog open onOpenChange={onCloseModal}>
                    <DialogContent className="max-w-[600px]" data-track-location={dataTrackLocation(modalState)}>
                        {modalState.type == "add-provider" && (
                            <SourceControlProviderAddModal providers={providers} runnerKind={runner.kind}
                                                           onProviderCreate={onProviderCreate} />
                        )}
                        {modalState.type == "remove-provider" && (
                            <SourceControlProviderRemoveModal
                                runnerId={runner.runnerId}
                                integration={modalState.integration}
                                onClose={() => onCloseModal(false)}
                            />
                        )}
                        {modalState.type == "edit-provider" && (
                            <SourceControlProviderModal
                                runnerId={runner.runnerId}
                                schema={modalState.schema}
                                integration={modalState.integration}
                                onClose={() => onCloseModal(false)}
                                onBack={() => onBack()}
                                onRemove={() => onRemoveProvider(modalState.integration)}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            )}
        </Container>
    );
};

export const ProviderConfigurations: FC<{
    ready: boolean;
    error: Error | null;
    providers: Provider[];
    integrations: SCMIntegration[];
    runnerKind: RunnerKind;
    readOnly: boolean;
    onAddProvider: () => void;
    onEditProvider: (integration: SCMIntegration, schema: RunnerConfigurationSchema_SCMConfigSchema) => void;
    onProviderCreate: (provider: Provider) => void;
}> = ({
    ready,
    error,
    providers,
    runnerKind,
    integrations,
    readOnly,
    onAddProvider,
    onEditProvider,
    onProviderCreate,
}) => {
    if (!error && integrations.length === 0 && providers.length > 0) {
        return (
            <EmptyProviderConfiguration providers={providers} runnerKind={runnerKind} readOnly={readOnly}
                                        onProviderCreate={onProviderCreate} />
        );
    }

    return (
        <div className="flex w-full flex-col gap-2">
            {error && (
                <div className="flex flex-col items-center">
                    <Text className="text-lg font-bold text-content-negative">Failed to list providers</Text>
                    <Text className="text-base text-content-negative">
                        Please try to refresh the page and contact support if the issue persists.
                    </Text>
                    <ErrorMessage error={error} />
                </div>
            )}
            {integrations.map((integration) => (
                <ProviderConfiguration
                    key={`${integration.scmId}-${integration.host}`}
                    integration={integration}
                    providers={providers}
                    readOnly={readOnly}
                    onEditProvider={onEditProvider}
                />
            ))}
            {ready && (
                <Button
                    data-testid="add-provider"
                    variant="ghost"
                    onClick={onAddProvider}
                    size="lg"
                    LeadingIcon={IconPlusSquare}
                    disabled={readOnly}
                    className="group w-full rounded-xl border-[1px] border-dashed px-3 py-2 hover:bg-surface-02"
                >
                    <Text className="flex h-9 w-full items-center group-disabled:text-content-secondary">
                        Add a new provider
                    </Text>
                </Button>
            )}
        </div>
    );
};

const ProviderConfiguration: FC<{
    integration: SCMIntegration;
    providers: Provider[];
    readOnly: boolean;
    onEditProvider: (integration: SCMIntegration, schema: RunnerConfigurationSchema_SCMConfigSchema) => void;
}> = ({ integration, providers, readOnly, onEditProvider }) => {
    const authMethods = [];
    if (integration.oauth) {
        authMethods.push("OAuth");
    }
    if (integration.pat) {
        authMethods.push("Personal Access Token");
    }

    const provider = providers.find((provider) => provider.schema.scmId === integration.scmId);

    return (
        <div className="flex justify-between gap-4 rounded-xl border-0.5 border-border-base px-3 py-2">
            <div className="flex items-center gap-4">
                <SourceControlProviderIcon
                    scmId={integration.scmId}
                    className="hidden size-8 text-content-primary md:block"
                />
                <div className="flex flex-col">
                    <div className="flex gap-1">
                        <Text className="text-base font-bold">{integration.scmId}</Text>
                        <Text className="text-base text-content-secondary">{integration.host}</Text>
                    </div>
                    <Text className="text-sm">Auth: {authMethods.join(" or ")}</Text>
                </div>
            </div>
            <Button
                variant="secondary"
                disabled={!provider || readOnly}
                data-testid={`edit-provider-${integration.id}`}
                onClick={() => {
                    if (provider) {
                        onEditProvider(integration, provider?.schema);
                    }
                }}
            >
                Edit
            </Button>
        </div>
    );
};

const EmptyProviderConfiguration: FC<{
    readOnly: boolean;
    providers: Provider[];
    runnerKind: RunnerKind;
    onProviderCreate: (provider: Provider) => void;
}> = ({ readOnly, providers, runnerKind, onProviderCreate }) => {
    return (
        <div className="flex w-full flex-col items-center justify-center gap-3 rounded-lg">
            <div className="flex w-full flex-col gap-2">
                {providers.map((provider) => (
                    <ProviderRow
                        key={provider.schema.scmId}
                        provider={provider}
                        runnerKind={runnerKind}
                        onProviderCreate={onProviderCreate}
                        readOnly={readOnly}
                    />
                ))}
            </div>
        </div>
    );
};
