import { Button } from "@/components/flexkit/Button";
import { CopyableInput } from "@/components/podkit/forms/CopyableInput";
import { Input } from "@/components/podkit/forms/Input";
import { InputField } from "@/components/podkit/forms/InputField";
import { InputFieldHint } from "@/components/podkit/forms/InputFieldHint";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { useCreateSSOConfiguration, useUpdateSSOConfiguration } from "@/queries/organization-queries";
import { formatError } from "@/utils/errors";
import { Code, ConnectError } from "@connectrpc/connect";
import {
    CreateSSOConfigurationRequest,
    UpdateSSOConfigurationRequest,
    type SSOConfiguration,
} from "gitpod-next-api/gitpod/v1/organization_pb";
import { useCallback, useMemo, useState, type FC, type FormEvent } from "react";
import { useForm } from "react-hook-form";

export type SetupSSOModalProps = {
    onClose?: () => void;
    testValidationOnly?: boolean;
    organizationId: string;
    config?: SSOConfiguration;
};

type SSOForm = {
    domain: string;
    issuer_url: string;
    client_id: string;
    client_secret: string;
};

export const SetupSSOModal: FC<SetupSSOModalProps> = ({ onClose, testValidationOnly, organizationId, config }) => {
    const isEdit = !!config;
    const { toast } = useToast();
    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, dirtyFields, isDirty },
    } = useForm<SSOForm>({
        mode: "all",
        defaultValues: isEdit
            ? {
                  domain: config.emailDomain,
                  issuer_url: config.issuerUrl,
                  client_id: config.clientId,
                  client_secret: config.id,
              }
            : undefined,
    });

    const createSSOConfig = useCreateSSOConfiguration();
    const updateSSOConfig = useUpdateSSOConfiguration();

    const [serverError, setServerError] = useState<string>();

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose?.();
            }
        },
        [onClose],
    );

    const handleServerErrors = useCallback(
        (error: unknown, data: SSOForm) => {
            let description = formatError(error);
            if (error instanceof ConnectError) {
                if (error.code === Code.AlreadyExists) {
                    description = `It seems like the SSO configuration for ${data.domain} already exists.`;
                }
            }
            setServerError(description);
            toast({
                title: "Failed to create SSO configuration",
                description,
            });
        },
        [toast],
    );

    const handleOnSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            const onValid = async (data: SSOForm) => {
                if (testValidationOnly) {
                    console.warn("Test validation only, not submitting form.");
                    return;
                }
                setServerError(undefined);
                let ssoId = "";
                try {
                    if (isEdit) {
                        ssoId = config.id;
                        await updateSSOConfig.mutateAsync(
                            new UpdateSSOConfigurationRequest({
                                ssoConfigurationId: config.id,
                                clientId: dirtyFields.client_id ? data.client_id : undefined,
                                emailDomain: dirtyFields.domain ? data.domain : undefined,
                                issuerUrl: dirtyFields.issuer_url ? data.issuer_url : undefined,
                                clientSecret: dirtyFields.client_secret ? data.client_secret : undefined,
                            }),
                        );
                    } else {
                        const result = await createSSOConfig.mutateAsync(
                            new CreateSSOConfigurationRequest({
                                organizationId,
                                emailDomain: data.domain,
                                issuerUrl: data.issuer_url,
                                clientId: data.client_id,
                                clientSecret: data.client_secret,
                            }),
                        );
                        ssoId = result.id;
                    }
                } catch (error) {
                    handleServerErrors(error, data);
                    return;
                }
                window.open(`/auth/oidc/start?ssoId=${ssoId}&verify=true`, "_blank");

                // TODO add poller to check for successful verification
            };
            await handleSubmit(onValid)(event);
        },
        [
            config,
            createSSOConfig,
            handleServerErrors,
            handleSubmit,
            isEdit,
            organizationId,
            testValidationOnly,
            dirtyFields,
            updateSSOConfig,
        ],
    );

    const callbackURL = useMemo(() => {
        return new URL("/auth/oidc/callback", window.location.origin).toString();
    }, []);

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="flex max-w-[600px] flex-col p-6" data-track-location="setup_sso_modal">
                <DialogHeader className="flex flex-col gap-2">
                    <DialogTitle className="font-base text-2xl">
                        <>{config ? "Edit" : "Setup"}</> SSO
                    </DialogTitle>
                    <DialogDescription className="">
                        Configure OpenID Connect (OIDC) Single Sign-On with your identity provider.
                    </DialogDescription>
                </DialogHeader>
                <DialogBody className="flex justify-between gap-3 py-10">
                    <form
                        onSubmit={handleOnSubmit}
                        id={"setup-sso-form"}
                        name={"Setup SSO"}
                        className="flex w-full flex-col gap-4"
                    >
                        {serverError && (
                            <div className="gap2 flex flex-col rounded-xl border border-border-base bg-surface-red px-4 py-3">
                                <div className="text-base font-bold">Failed to update the SSO configuration</div>
                                <div className="text-sm text-content-secondary">{serverError}</div>
                            </div>
                        )}
                        <InputField
                            label="Email domain"
                            id="domain"
                            hint="This is the work email domain that people will use to sign in."
                            error={errors?.domain?.message || ""}
                        >
                            <Input
                                id="domain"
                                placeholder="acme-corp.com"
                                type="text"
                                {...register("domain", {
                                    pattern: {
                                        value: /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/,
                                        message: "Email domain must be a valid domain.",
                                    },
                                    required: {
                                        value: true,
                                        message: "Email domain is required.",
                                    },
                                })}
                            />
                        </InputField>
                        <InputField
                            label="Issuer URL"
                            id="issuer_url"
                            hint={
                                <span>
                                    <kbd>&lt;Issuer URL&gt;/.well-known/openid-configuration</kbd> should provide
                                    Discovery metadata.
                                </span>
                            }
                            error={errors?.issuer_url?.message || ""}
                        >
                            <Input
                                id="issuer_url"
                                placeholder="https://acme-corp.okta.com/app-123"
                                {...register("issuer_url", {
                                    pattern: {
                                        value: /^https:\/\/(?:[a-zA-Z0-9.-]+|\d{1,3}(?:\.\d{1,3}){3})(?::\d{1,5})?(?:\/[a-zA-Z0-9._~-]*)*\/?$/,
                                        message: "Must be a valid URL starting with https://.",
                                    },
                                    required: {
                                        value: true,
                                        message: "Issuer URL is required.",
                                    },
                                })}
                            />
                        </InputField>

                        <InputField label="Client ID" id="client_id" error={errors?.client_id?.message || ""}>
                            <Input
                                id="client_id"
                                {...register("client_id", {
                                    required: {
                                        value: true,
                                        message: "Client ID is required.",
                                    },
                                })}
                            />
                        </InputField>
                        <InputField
                            label="Client secret"
                            id="client_secret"
                            error={errors?.client_secret?.message || ""}
                        >
                            <Input
                                id="client_secret"
                                type="password"
                                {...register("client_secret", {
                                    required: {
                                        value: true,
                                        message: "Client secret is required.",
                                    },
                                })}
                            />
                        </InputField>
                        <InputField
                            label="Callback URL"
                            id="callback_url"
                            hint="Copy and paste this URL into the OIDC app of your identity provider."
                        >
                            <CopyableInput id="callback_url" readOnly value={callbackURL} />
                        </InputField>
                    </form>
                </DialogBody>
                <DialogFooter className="flex flex-row">
                    {isValid && (
                        <div className="flex items-center">
                            <InputFieldHint>Test run will be opened in a new window.</InputFieldHint>
                        </div>
                    )}
                    <DialogClose asChild>
                        <Button variant={"secondary"}>Close</Button>
                    </DialogClose>
                    <Button
                        autoFocus={true}
                        aria-label="test-and-continue"
                        type="submit"
                        data-testid="test-and-continue-button"
                        form={"setup-sso-form"}
                        loading={isSubmitting}
                        disabled={!isValid || !(isDirty || isEdit)}
                    >
                        Test & Continue
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
