import { Text } from "@/components/podkit/typography/Text";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { InviteMembers } from "@/routes/settings/organization/members/InviteMembersPage";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import { type FC } from "react";
import { Link } from "react-router-dom";
import { TrackLocations } from "@/hooks/use-segment";

export const ShareWithYourTeamPage: FC = () => {
    useDocumentTitle("Onboarding - Share with your team");
    return (
        <OnboardingTabContent title="Share with your team" data-track-location={TrackLocations.OnboardingShareTab}>
            <InviteMembers />
            <Text className="text-xs text-content-primary">
                <span className="font-bold">Tip</span>: You can do this later by navigating to{" "}
                <Link to="/settings/members" className="font-bold text-content-orange">
                    Settings &gt; Members
                </Link>
                .
            </Text>
        </OnboardingTabContent>
    );
};
