import {
    RunnerConfigurationSchema_PersonalAccessToken,
    RunnerConfigurationSchema_SCMConfigSchema,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb.ts";
import { type Provider } from "@/components/runners/details/SourceControlProvider.tsx";

// AllProviders lists all available source control providers in Gitpod.
// Remote runners publish their supported providers via the runner's schema.
// Providers not in the schema will be shown as "coming soon".
// Local runners show all providers with PAT in the schema as available.
// Local runner settings are at the org level and not tied to a specific runner.
// Some local runners might not support all providers if they are not up to date.
export const AllProviders: Provider[] = [
    {
        schema: new RunnerConfigurationSchema_SCMConfigSchema({
            scmId: "github",
            name: "GitHub",
            defaultHosts: ["github.com"],
            oauth: undefined,
            pat: new RunnerConfigurationSchema_PersonalAccessToken({
                description: "Personal Access Token",
                docsLink: "https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens",
            }),
        }),
        name: "GitHub",
        state: "available",
    },
    {
        schema: new RunnerConfigurationSchema_SCMConfigSchema({
            scmId: "gitlab",
            name: "GitLab",
            defaultHosts: ["gitlab.com"],
            oauth: undefined,
            pat: new RunnerConfigurationSchema_PersonalAccessToken({
                description: "Personal Access Token",
                docsLink: "https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html",
            }),
        }),
        name: "GitLab",
        state: "available",
    },
    {
        schema: new RunnerConfigurationSchema_SCMConfigSchema({
            scmId: "bitbucket",
            name: "Bitbucket",
            defaultHosts: ["bitbucket.org"],
            oauth: undefined,
        }),
        name: "Bitbucket",
        state: "available",
    },
];
