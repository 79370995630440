import { Button } from "@/components/flexkit/Button";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Text } from "@/components/podkit/typography/Text";
import { useCreateRunnerAccessToken } from "@/queries/runner-queries";
import { createRunnerSetupText } from "@/components/runners/RunnerSetupURL";
import { formatError } from "@/utils/errors";
import type { PlainMessage } from "@bufbuild/protobuf";
import { type Runner } from "gitpod-next-api/gitpod/v1/runner_pb";
import { useCallback, type FC } from "react";

export const CloudFormationStackCopyDetails: FC<{ runner?: PlainMessage<Runner> }> = ({ runner }) => {
    const { toast } = useToast();
    const createRunnerToken = useCreateRunnerAccessToken();

    const onCopyDetails = useCallback(async () => {
        if (!runner) {
            return;
        }
        try {
            const region = runner.spec?.configuration?.region;
            const { accessToken } = await createRunnerToken.mutateAsync(runner.runnerId);
            await navigator.clipboard.writeText(createRunnerSetupText(runner, accessToken, region || ""));
            toast({
                title: "Message copied to clipboard",
            });
        } catch (error) {
            toast({
                title: "Failed to copy details to your clipboard",
                description: formatError(error),
            });
        }
    }, [toast, createRunnerToken, runner]);
    return (
        <div className="flex flex-wrap gap-1">
            <Text className="text-sm text-content-secondary">Does someone else manage your AWS account?</Text>
            <Button
                variant="link"
                onClick={onCopyDetails}
                className="inline-block h-auto p-0 text-sm text-content-orange disabled:bg-transparent"
                disabled={createRunnerToken.isPending}
            >
                Copy the details and share them.
            </Button>
        </div>
    );
};
