import { type FC, type PropsWithChildren, Suspense } from "react";
import { LoadingState } from "@/components/podkit/loading/LoadingState";

export const PageSuspense: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Suspense
            fallback={
                <div className="flex items-center justify-center">
                    <LoadingState />
                </div>
            }
        >
            {children}
        </Suspense>
    );
};
