import { Heading1 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import type React from "react";
import { type FC } from "react";
import gitpodLogo from "@/assets/gitpod-logo.svg";
import { useAuthenticatedUser } from "@/queries/user-queries";
import onboardingLayersImg from "@/assets/onboarding-layers.png";
import avatarImg from "@/assets/avatar.jpg";
import {
    AdditionalBenefitsMap,
    Benefits,
    OnboardingQuestionnaireId,
    QuoteMap,
    Quotes,
    useOnboardingQuestionnaire,
    type Quote,
} from "@/hooks/use-onboarding-questionnaire";
import { IconCircleWithOptionalCheckmark } from "@/assets/icons/geist/IconCircleWithOptionalCheckmark";
import { coalesce, distinct } from "@/utils/arrays";

export const OnboardingSidebar: FC = () => {
    const { data: user } = useAuthenticatedUser();
    const onboardingQuestionnaire = useOnboardingQuestionnaire();

    let quoteComponent: React.ReactNode = undefined;
    const yourRoleStep = onboardingQuestionnaire.steps.steps.find((s) => s.id === OnboardingQuestionnaireId.YourRole)!;
    if (yourRoleStep.state !== "todo") {
        let quote: Quote | undefined;
        if (yourRoleStep.state === "done" && !yourRoleStep.data) {
            quote = Quotes[0];
        } else if (yourRoleStep.data) {
            quote = QuoteMap[yourRoleStep.data.selection];
        }
        if (quote) {
            quoteComponent = <QuoteComponent quote={quote} />;
        }
    }

    const whatToAcieveStep = onboardingQuestionnaire.steps.steps.find(
        (s) => s.id === OnboardingQuestionnaireId.WhatToAchieve,
    )!;
    const benefits = whatToAcieveStep.state === "active" && (
        <div className="flex flex-col gap-4">
            {Benefits.map((t) => (
                <BenefitComponent key={t.title} title={t.title} description={t.description} />
            ))}
        </div>
    );

    let addtionalBenefits: React.ReactNode = undefined;
    if (whatToAcieveStep.data?.selections.length) {
        addtionalBenefits = (
            <div className="flex flex-col gap-4">
                <Text className="text-base font-[700]">Features we think you&apos;ll love...</Text>
                {distinct(
                    coalesce(whatToAcieveStep.data.selections.flatMap((t) => AdditionalBenefitsMap[t])),
                    (i) => i.title,
                ).map((t) => (
                    <BenefitComponent key={t.title} title={t.title} description={t.description} />
                ))}
            </div>
        );
    }

    return (
        <div data-testid="onboarding-sidebar" className="h-dvh min-w-fit overflow-hidden py-12">
            <div className="h-full overflow-y-auto px-12">
                <div className="flex w-[355px] flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <img src={gitpodLogo} alt="Gitpod logo" width="32" height="32" className="mb-4" />
                        <Heading1 className="text-2xl font-medium text-content-primary">
                            Welcome to Gitpod, <span>{user?.name.split(" ")[0]}</span>
                        </Heading1>
                        <Text className="text-xl font-normal text-content-secondary">
                            Tell us about yourself so we can optimize your experience. It&apos;ll only take a minute!
                        </Text>
                    </div>
                    <div className="flex flex-col gap-6">
                        {!quoteComponent && <img src={onboardingLayersImg} className="p-14" />}
                        {quoteComponent}
                        {benefits}
                        {addtionalBenefits}
                    </div>
                </div>
            </div>
        </div>
    );
};

const QuoteComponent: FC<{ quote: Quote }> = ({ quote }) => {
    return (
        <div className="flex flex-col items-center rounded-lg border border-border-light bg-surface-glass p-6">
            <img src={avatarImg} className="mb-2 h-[24px] w-[24px] rounded-full object-cover object-top" />
            <Text className="mb-6 text-center text-base font-normal leading-4">&quot;{quote.quote}&quot;</Text>
            <Text className="text-sm font-normal text-content-tertiary">{quote.author}</Text>
        </div>
    );
};

const BenefitComponent: FC<{ title: string; description?: string }> = ({ title, description }) => {
    return (
        <div className="flex flex-col">
            <div className="flex items-center gap-1">
                <IconCircleWithOptionalCheckmark checked={true} />
                <Text className="text-base font-medium text-content-primary">{title}</Text>
            </div>
            {description && (
                <Text className="ml-[28px] mt-1 text-base font-normal text-content-secondary">{description}</Text>
            )}
        </div>
    );
};
