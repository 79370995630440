import { IconKey } from "@/assets/icons/geist/IconKey";
import { Button } from "@/components/flexkit/Button";
import { Input } from "@/components/podkit/forms/Input";
import { InputField } from "@/components/podkit/forms/InputField";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { formatError } from "@/utils/errors";
import { GetSSOLoginURLRequest } from "gitpod-next-api/gitpod/v1/account_pb";
import { useCallback, useState, type FC } from "react";

type Props = {
    onBack: () => void;
    returnTo: string;
};

export const ContinueWithSSOForm: FC<Props & PropsWithClassName> = ({ onBack, className, returnTo }) => {
    const api = useGitpodAPI();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const handleContinueWithSSO = useCallback(async () => {
        if (!email) {
            return;
        }
        setSubmitting(true);

        try {
            const { loginUrl } = await api.accountService.getSSOLoginURL(
                new GetSSOLoginURLRequest({
                    email,
                    returnTo: returnTo !== "/" ? returnTo : undefined,
                }),
            );

            window.location.href = loginUrl;
        } catch (error) {
            setEmailError(formatError(error));
        }
        setSubmitting(false);
    }, [api.accountService, email, returnTo]);

    return (
        <div id="custom-sso-form" className={cn(className)}>
            <InputField label="Email address" id={"email"} className="text-center" error={emailError}>
                <Input
                    id={"email"}
                    placeholder="Enter your email address"
                    type="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                    }}
                />
            </InputField>
            <Button
                data-track-label="true"
                variant="secondary"
                LeadingIcon={IconKey}
                className="w-full"
                disabled={!email || !!emailError || submitting}
                onClick={handleContinueWithSSO}
            >
                <span className="flex-grow text-center">Continue with SSO</span>
            </Button>
            <Button
                data-track-label="true"
                size="lg"
                variant={"link"}
                className="w-full cursor-pointer"
                onClick={() => onBack()}
            >
                <span>Back</span>
            </Button>
        </div>
    );
};
