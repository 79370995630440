import { type FC } from "react";
import { Outlet } from "react-router-dom";
import { ConfigureRemoteRunnerTimeline } from "@/components/onboarding/ConfigureRemoteRunnerTimeline";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import { TrackLocations } from "@/hooks/use-segment";

export const ConfigureRemoteRunnerLayout: FC = () => {
    return (
        <OnboardingTabContent showDivider={false} data-track-location={TrackLocations.OnboardingRunnerConfigureTab}>
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-8">
                    <ConfigureRemoteRunnerTimeline />
                    <Outlet />
                </div>
            </div>
        </OnboardingTabContent>
    );
};
