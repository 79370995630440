import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconInfoFilled: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_556_56)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15 8C15 11.866 11.866 15 8 15C4.134 15 1 11.866 1 8C1 4.134 4.134 1 8 1C11.866 1 15 4.134 15 8ZM6.46877 7.125H7.12502H7.78125C8.2645 7.125 8.65625 7.51676 8.65625 8V11.0625V11.7188H7.34375V11.0625V8.4375H7.12502H6.46877V7.125ZM8 6.25C8.48325 6.25 8.875 5.85824 8.875 5.375C8.875 4.89176 8.48325 4.5 8 4.5C7.51676 4.5 7.125 4.89176 7.125 5.375C7.125 5.85824 7.51676 6.25 8 6.25Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_556_56">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_556_59)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM8.25002 9H9.00002H9.75C10.3023 9 10.75 9.44772 10.75 10V13.5V14.25H9.25V13.5V10.5H9.00002H8.25002V9ZM10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_556_59">
                            <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_556_62)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21 12C21 16.9706 16.9706 21 12 21C7.02943 21 3 16.9706 3 12C3 7.02943 7.02943 3 12 3C16.9706 3 21 7.02943 21 12ZM10.0313 10.875H10.875H11.7188C12.3401 10.875 12.8438 11.3787 12.8438 12V15.9375V16.7812H11.1562V15.9375V12.5625H10.875H10.0313V10.875ZM12 9.75C12.6213 9.75 13.125 9.24632 13.125 8.625C13.125 8.00368 12.6213 7.5 12 7.5C11.3787 7.5 10.875 8.00368 10.875 8.625C10.875 9.24632 11.3787 9.75 12 9.75Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_556_62">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
