import { cn } from "@/components/podkit/lib/cn";
import * as tags from "react-tag-input-component";
import "./TagsInput.css";

export type TagsInputProps = {
    className?: string;
    tagsClassName?: string;
    validate?: (value: string) => boolean;
    onValidationFail?: (value: string) => void;
} & tags.TagsInputProps;

export const TagsInput: React.FC<TagsInputProps> = ({
    className,
    tagsClassName,
    validate,
    onValidationFail,
    ...props
}) => {
    const handleBeforeAdd = (value: string) => {
        if (validate && !validate(value)) {
            onValidationFail?.(value);
            return false;
        }
        return true;
    };
    return (
        <tags.TagsInput
            beforeAddValidate={handleBeforeAdd}
            classNames={{
                tag: cn(
                    "text-sm border border-border-thin bg-surface-tertiary text-content-tertiary px-8",
                    tagsClassName,
                ),
                input: cn("text-base bg-surface-secondary text-content-secondary px-1.5 py-1 rounded-sm", className),
            }}
            {...props}
        />
    );
};
TagsInput.displayName = "TagsInput";
