import { useCallback, useState, type FC } from "react";
import { cn } from "@/components/podkit/lib/cn";
import { Button } from "@/components/flexkit/Button";
import { useSegmentTrack } from "@/hooks/use-segment";

export const ProviderOption: FC<{ name: string; iconImgSrc: string; disabled?: boolean; onClick?: () => void }> = ({
    name,
    iconImgSrc,
    disabled,
    onClick,
}) => {
    const segmentTrack = useSegmentTrack();
    const [waitListJoined, setWaitListJoined] = useState(false);

    const onJoinWaitlist = useCallback(() => {
        segmentTrack("waitlist_joined", { provider: name, type: "infrastructure" });
        setWaitListJoined(true);
    }, [segmentTrack, setWaitListJoined, name]);

    const label = waitListJoined ? "Joined waitlist" : "Join waitlist";
    return (
        <div className="flex items-center justify-between rounded-xl border border-border-light p-2">
            <div className="flex items-center">
                <img src={iconImgSrc} className={cn("mr-3 h-8 w-8", disabled && "grayscale")} />
                <div className="text-base font-medium text-content-secondary">{name}</div>
            </div>
            {disabled ? (
                <Button
                    variant="link"
                    size="md"
                    onClick={onJoinWaitlist}
                    disabled={waitListJoined}
                    className={cn(waitListJoined && "disabled:bg-transparent disabled:text-content-green")}
                    aria-label={`${label}: ${name}`}
                >
                    {label}
                </Button>
            ) : (
                <Button size="md" variant="secondary" onClick={onClick} aria-label={`Select Runner: ${name}`}>
                    Select
                </Button>
            )}
        </div>
    );
};
