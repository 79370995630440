import { type FC, type FormEvent, useCallback, useId, useState } from "react";
import { DialogBody, DialogClose, DialogFooter, DialogHeader, DialogTitle } from "@/components/podkit/modal/Modal";
import { Text } from "@/components/podkit/typography/Text";
import { DialogDescription } from "@radix-ui/react-dialog";
import type {
    RunnerConfigurationSchema_OAuth,
    RunnerConfigurationSchema_PersonalAccessToken,
    RunnerConfigurationSchema_SCMConfigSchema,
    SCMIntegration,
    SCMIntegrationValidationResult,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { SwitchInputField } from "@/components/podkit/switch/Switch";
import { Button } from "@/components/flexkit/Button";
import { InputField } from "@/components/podkit/forms/InputField";
import { Input } from "@/components/podkit/forms/Input";
import { CopyableInput } from "@/components/podkit/forms/CopyableInput";
import { type IntegrationConfiguration, useCreateOrUpdateSCMIntegration } from "@/queries/runner-configuration-queries";
import type { PlainMessage } from "@bufbuild/protobuf";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { ErrorMessage } from "@/components/ErrorMessage";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { getScmProviderName } from "frontend-shared/local-runner.ts";
import { IconInfo } from "@/assets/icons/geist/IconInfo.tsx";

export const SourceControlProviderModal: FC<{
    runnerId: string;
    schema: RunnerConfigurationSchema_SCMConfigSchema;
    integration: SCMIntegration;
    onRemove: () => void;
    onBack: () => void;
    onClose: () => void;
}> = ({ runnerId, schema, integration, onRemove, onBack, onClose }) => {
    // When the schema only supports PATs (like for local runners), we don't show the toggles, and only let you configure
    // the host. It would not be possible to disable PATs, as at least one auth method is required.
    const onlyPAT = !schema.oauth && !!schema.pat;

    const createOrUpdateSCMIntegration = useCreateOrUpdateSCMIntegration(runnerId, integration.id);
    const [configuration, setConfiguration] = useState<IntegrationConfiguration>({
        scmId: integration.scmId,
        host: integration.host || schema.defaultHosts[0],
        oauth: integration.oauth
            ? {
                clientId: integration.oauth?.clientId,
                encryptedClientSecret: integration.oauth?.encryptedClientSecret,
            }
            : undefined,
        pat: integration.pat || onlyPAT,
    });
    const [validationErrors, setValidationErrors] = useState<SCMIntegrationValidationResult | undefined>();

    const { toast } = useToast();

    const isUpdate = Boolean(integration.id);

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            try {
                const validationResult = await createOrUpdateSCMIntegration.mutateAsync(configuration);
                setValidationErrors(validationResult);
                if (!validationResult?.valid) {
                    return;
                }

                const action = isUpdate ? "updated" : "created";
                toast({
                    title: `Provider ${action}`,
                    description: `The provider has been successfully ${action}.`,
                });
                onClose();
            } catch (e) {
                const action = isUpdate ? "update" : "create";
                toast({
                    title: `Failed to ${action} provider`,
                    description: formatError(e),
                });
            }
        },
        [onClose, createOrUpdateSCMIntegration, toast, isUpdate, configuration],
    );

    return (
        <form onSubmit={handleSubmit} id="provider-integration" name="provider-integration">
            <DialogHeader>
                <DialogTitle>{`${isUpdate ? "Edit" : "Configure"} repository access`}</DialogTitle>
                <DialogDescription className="text-base text-content-secondary">
                    In order for your runner to access your repos, you&apos;ll need to configure your repository
                    provider (GitHub, GitLab, or Bitbucket).
                </DialogDescription>
            </DialogHeader>

            <DialogBody className="flex flex-col gap-8">
                <ErrorMessage error={validationErrors?.scmIdError} />
                {onlyPAT && (
                    <div className="flex flex-row gap-2 items-center">
                        <IconInfo size="sm" />
                        <Text className="text-base font-bold">
                            We currently support Personal Access Tokens with {getScmProviderName(integration.scmId)}.
                            OAuth coming soon.
                        </Text>
                    </div>
                )}
                <Host
                    schema={schema}
                    integration={integration}
                    validationError={validationErrors?.hostError}
                    onHostChange={(host) => setConfiguration((prev) => ({ ...prev, host }))}
                />
                {!onlyPAT && (
                    <>
                        <OAuth
                            schema={schema.oauth}
                            host={configuration.host || "-"}
                            integration={integration}
                            validationError={validationErrors?.oauthError}
                            onAuthChange={(oauth) => setConfiguration((prev) => ({ ...prev, oauth: oauth }))}
                        />
                        <PAT
                            schema={schema.pat}
                            integration={integration}
                            validationError={validationErrors?.patError}
                            onPatChange={(pat) => setConfiguration((prev) => ({ ...prev, pat: pat }))}
                        />
                    </>
                )}
            </DialogBody>

            <DialogFooter>
                <div className="flex w-full flex-col gap-2 sm:flex-row sm:justify-between">
                    {integration.id && (
                        <Button variant="destructive" type="button" onClick={() => onRemove()}>
                            Remove
                        </Button>
                    )}
                    {!integration.id && (
                        <Button variant="secondary" type="button" onClick={() => onBack()}>
                            Back
                        </Button>
                    )}
                    <div className="flex flex-col gap-2 sm:flex-row sm:justify-end">
                        <DialogClose asChild>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onClose}
                                disabled={createOrUpdateSCMIntegration.isPending}
                            >
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button
                            autoFocus={false}
                            variant="primary"
                            type="submit"
                            data-testid="save-provider"
                            loading={createOrUpdateSCMIntegration.isPending}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </DialogFooter>
        </form>
    );
};

export const Host: FC<{
    schema: RunnerConfigurationSchema_SCMConfigSchema;
    integration: PlainMessage<SCMIntegration>;
    validationError: string | undefined;
    onHostChange: (host: string) => void;
}> = ({ schema, integration, validationError, onHostChange }) => {
    const isEdit = !!integration.id;
    const hostID = useId();
    const [host, setHost] = useState(integration?.host || schema.defaultHosts[0]);
    let hint = "The host of the source control provider.";
    switch (integration.scmId) {
        case "github":
            hint = "If you are using GitHub Enterprise, you can update this (e.g. mycompany.githubenterprise.com)";
            break;
        case "gitlab":
            hint =
                "If you are using GitLab Self Managed or GitLab Dedicated, you can update this (e.g. mycompany.gitlab.com)";
            break;
    }
    return (
        <div>
            <InputField
                label={`${getScmProviderName(integration.scmId)} host`}
                id={hostID}
                hint={isEdit ? "" : hint}
                error={validationError}
            >
                <Input
                    id={hostID}
                    disabled={isEdit}
                    autoFocus={!isEdit}
                    required={true}
                    title={isEdit ? "The host of existing providers can't be edited." : ""}
                    data-testid="provider-host"
                    value={host}
                    onChange={(value) => {
                        setHost(value.target.value);
                        onHostChange(value.target.value);
                    }}
                    placeholder={schema.defaultHosts[0]}
                />
            </InputField>
        </div>
    );
};

type OAuthConfiguration = {
    clientId: string;
    clientSecret?: string;
    encryptedClientSecret?: Uint8Array;
};

export const OAuth: FC<{
    schema: RunnerConfigurationSchema_OAuth | undefined;
    integration: PlainMessage<SCMIntegration>;
    host: string;
    validationError: string | undefined;
    onAuthChange: (oauth?: OAuthConfiguration) => void;
}> = ({ schema, integration, validationError, onAuthChange, host }) => {
    const [configuration, setConfiguration] = useState<OAuthConfiguration | undefined>(
        integration.oauth
            ? {
                clientId: integration.oauth?.clientId,
                encryptedClientSecret: integration.oauth?.encryptedClientSecret,
            }
            : undefined,
    );

    const updateConfiguration = useCallback(
        (newConfig?: OAuthConfiguration) => {
            setConfiguration(newConfig);
            onAuthChange(newConfig);
        },
        [setConfiguration, onAuthChange],
    );

    if (!schema) {
        return null;
    }

    const checked = Boolean(configuration);

    const callbackUrl = schema.callbackUrl.replace("$HOST", host);

    const onlyHasEnctryptedValue =
        Boolean(configuration?.encryptedClientSecret) && configuration?.clientSecret == undefined;

    const value =
        configuration?.clientSecret == undefined
            ? configuration?.encryptedClientSecret?.toString()
            : configuration?.clientSecret;

    const documentationLink = (scmId: string) => {
        switch (scmId) {
            case "github":
                return "https://www.gitpod.io/docs/flex/source-control/github";
            case "gitlab":
                return "https://www.gitpod.io/docs/flex/source-control/gitlab";
            case "bitbucket":
                return "https://www.gitpod.io/docs/flex/source-control/bitbucket";
            default:
                return "https://www.gitpod.io/docs/flex/source-control";
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <Divider />
            <ErrorMessage error={validationError} />
            <SwitchInputField
                label="Enable OAuth"
                name="enable-oauth"
                id="enable-oauth"
                data-testid="enable-oauth"
                description="Setup an OAuth app so users can sign in quickly."
                checked={checked}
                onCheckedChange={(enabled) => {
                    if (enabled) {
                        updateConfiguration({
                            clientId: integration.oauth?.clientId || "",
                            clientSecret: integration.oauth?.encryptedClientSecret ? undefined : "",
                            encryptedClientSecret: integration.oauth?.encryptedClientSecret,
                        });
                    } else {
                        updateConfiguration(undefined);
                    }
                }}
            />
            {checked && (
                <div className="flex flex-col gap-4">
                    <Text>
                        You&#x2019;ll need to setup an OAuth App at {host}. Copy the Authorization callback URL and
                        paste it in the OAuth App. Read the{" "}
                        <span>
                            <ExternalLink href={documentationLink(integration.scmId)}>
                                full documentation here
                            </ExternalLink>
                        </span>
                        <span>.</span>
                    </Text>
                    <InputField
                        label="Authorization callback URL"
                        id="authorization-callback-url"
                        disabled={false}
                        hint={`Copy and paste at ${host}`}
                    >
                        <CopyableInput id="authorization-callback-url" disabled={true} value={callbackUrl} />
                    </InputField>
                    <InputField label="Client ID" id="client-id" disabled={false} hint={`You'll find this at ${host}`}>
                        <Input
                            id="client-id"
                            data-testid="oauth-client-id"
                            value={configuration?.clientId}
                            onChange={(e) =>
                                updateConfiguration({
                                    clientId: e.target.value,
                                    clientSecret: configuration?.clientSecret,
                                    encryptedClientSecret: configuration?.encryptedClientSecret,
                                })
                            }
                        />
                    </InputField>
                    <InputField
                        label="Client secret"
                        id="client-secret"
                        disabled={false}
                        hint={`You'll find this at ${host}`}
                    >
                        <Input
                            id="client-secret"
                            data-testid="oauth-client-secret"
                            value={value}
                            type="password"
                            onFocus={() => {
                                if (onlyHasEnctryptedValue) {
                                    updateConfiguration({
                                        clientId: configuration?.clientId || "",
                                        clientSecret: "",
                                        encryptedClientSecret: configuration?.encryptedClientSecret,
                                    });
                                }
                            }}
                            onBlur={() => {
                                if (configuration?.clientSecret == "") {
                                    updateConfiguration({
                                        clientId: configuration?.clientId || "",
                                        clientSecret: undefined,
                                        encryptedClientSecret: configuration?.encryptedClientSecret,
                                    });
                                }
                            }}
                            onChange={(e) =>
                                updateConfiguration({
                                    clientId: configuration?.clientId || "",
                                    clientSecret: e.target.value,
                                    encryptedClientSecret: configuration?.encryptedClientSecret,
                                })
                            }
                        />
                    </InputField>
                </div>
            )}
        </div>
    );
};

export const PAT: FC<{
    schema: RunnerConfigurationSchema_PersonalAccessToken | undefined;
    integration: PlainMessage<SCMIntegration>;
    validationError: string | undefined;
    onPatChange: (pat: boolean) => void;
}> = ({ schema, integration, validationError, onPatChange }) => {
    const [checked, setChecked] = useState(integration.pat);
    const updateConfiguration = useCallback(
        (pat: boolean) => {
            setChecked(pat);
            onPatChange(pat);
        },
        [setChecked, onPatChange],
    );

    if (!schema) {
        return;
    }

    return (
        <div className="flex flex-col gap-4">
            <Divider />
            <ErrorMessage error={validationError} />
            <SwitchInputField
                id="enable-pat"
                data-testid="enable-pat"
                name="enable-oauth"
                label="Personal Access Token"
                description="Allow members to sign in with a Personal Access Token."
                checked={checked}
                onCheckedChange={(checked) => {
                    updateConfiguration(checked);
                }}
            />
        </div>
    );
};

const Divider = () => {
    return <div className="h-[1px] w-full border-b border-border-light" />;
};
