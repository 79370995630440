import { IconDot } from "@/assets/icons/geist/IconDot";
import { Heading4 } from "@/components/podkit/typography/Headings";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { Text } from "@/components/podkit/typography/Text";
import type { FC } from "react";

type Benefit = {
    text: string;
};

export const BenefitsCard: FC<{
    heading: string;
    subheading: string;
    icon: React.ReactNode;
    benefits: Benefit[];
    button?: React.ReactNode;
    learnMoreLink?: string;
}> = ({ heading, subheading, icon, benefits, button, learnMoreLink }) => {
    return (
        <div className="flex basis-1/2 flex-col justify-between gap-8 rounded-xl border border-border-light p-6">
            <div className="flex flex-col gap-4">
                {icon}
                <div className="flex flex-col">
                    <Heading4 className="text-xl font-bold">{heading}</Heading4>
                    <Text className="text-xl text-content-secondary">{subheading}</Text>
                </div>
                <ul className="flex flex-col gap-1">
                    {benefits.map(({ text }) => (
                        <li className="flex items-center gap-1" key={text}>
                            <div className="shrink-0">
                                <IconDot size="sm" className="text-content-primary" />
                            </div>
                            <Text className="text-lg text-content-primary">{text}</Text>
                        </li>
                    ))}
                </ul>
            </div>
            {(button || learnMoreLink) && (
                <div className="flex flex-col gap-4">
                    {button}
                    {learnMoreLink && (
                        <ExternalLink href={learnMoreLink} className="justify-center" iconSize="sm">
                            Learn more
                        </ExternalLink>
                    )}
                </div>
            )}
        </div>
    );
};
