import { Button } from "@/components/flexkit/Button";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { useCallback, useState, type FC } from "react";

import awsLogo from "@/assets/aws.svg";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { useCreateRemoteRunner } from "@/queries/runner-queries";
import { NewRunnerForm } from "@/components/runners/NewRunnerForm";
import { formatError } from "@/utils/errors";
import { useNavigate } from "react-router-dom";
import { ExternalLink } from "@/components/podkit/typography/Link";
import { TrackLocations } from "@/hooks/use-segment";

type Props = {
    onClose: () => void;
};

export const NewRunnerModal: FC<Props> = ({ onClose }) => {
    const navigate = useNavigate();
    const { toast } = useToast();
    const createRunner = useCreateRemoteRunner();
    const [disabled, setDisabled] = useState(false);

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const handleSubmit = useCallback<(values: { name: string; region: string }) => Promise<void>>(
        async ({ name, region }) => {
            try {
                const { runner } = await createRunner.mutateAsync({ name, region });
                navigate(`/settings/runners/${runner.runnerId}`);
            } catch (error) {
                toast({ title: "Failed to create a runner", description: formatError(error) });
            }
        },
        [createRunner, navigate, toast],
    );

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="max-w-[400px]" data-track-location={TrackLocations.NewRunnerModal}>
                <DialogHeader>
                    <DialogTitle>Add a runner</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <DialogBody>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-4 space-y-1">
                            <div className="flex-shrink-0 p-4">
                                <img src={awsLogo} alt="AWS" className="w-12" />
                            </div>
                            <div className="flex grow flex-col gap-2">
                                <div className="text-sm">
                                    Run environments for your organization on an EC2 instance. We will help you setup
                                    your CloudFormation stack.
                                </div>
                                <div>
                                    <Button variant="primary" asChild>
                                        <ExternalLink
                                            href="https://www.gitpod.io/docs/flex/getting-started/create-dev-environment-self-hosted-aws-runner"
                                            iconSize="sm"
                                        >
                                            <span>View Documentation</span>
                                        </ExternalLink>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <NewRunnerForm
                            formId="new-runner-form"
                            onSubmit={handleSubmit}
                            onDisabledChange={setDisabled}
                        />
                        <div className="mt-4 text-xs">
                            You are responsible for the costs associated with runners installed on your AWS
                            infrastructure.
                        </div>
                    </div>
                </DialogBody>

                <DialogFooter className="sm:justify-end">
                    <DialogClose asChild>
                        <Button type="button" variant="secondary">
                            Cancel
                        </Button>
                    </DialogClose>
                    <Button
                        loading={createRunner.isPending}
                        autoFocus={true}
                        aria-label="create"
                        type="submit"
                        data-testid="create"
                        variant={"brand"}
                        form="new-runner-form"
                        disabled={disabled}
                    >
                        Create
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
