import { Button } from "@/components/flexkit/Button";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import { setPrincipal } from "@/principal";
import { useGetAccount } from "@/queries/account-queries";
import { useJoinOrganizationWithInvite, useOrganizationInviteSummary } from "@/queries/organization-queries";
import { JoinableOrganizationCard } from "@/routes/join-organization/JoinOrganization";
import { formatError } from "@/utils/errors";
import { Code, ConnectError } from "@connectrpc/connect";
import { JoinableOrganization } from "gitpod-next-api/gitpod/v1/account_pb";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
    inviteID: string;
};
export const JoinOrganizationFromInvite = ({ inviteID }: Props) => {
    const { toast } = useToast();
    const navigate = useNavigate();
    const joinOrganization = useJoinOrganizationWithInvite();

    const { data: account, isLoading: isLoadingAccount, isError: isErrorAccount } = useGetAccount();
    const {
        data: summary,
        isLoading: isLoadingSummary,
        isError: isErrorSummary,
    } = useOrganizationInviteSummary(inviteID);

    const isLoading = isLoadingAccount || isLoadingSummary;
    const isError = isErrorAccount || isErrorSummary;

    const handleJoinOrganization = useCallback(async () => {
        if (!inviteID || !summary || !account) {
            return;
        }
        try {
            const member = await joinOrganization.mutateAsync({ inviteID });
            toast({ title: "Joined organization" });
            setPrincipal(member.userId);
            navigate("/", { replace: true });
        } catch (error) {
            if (isConnectCode(error, Code.AlreadyExists)) {
                const membership = account.memberships.find((m) => m.organizationId === summary.organizationId);
                const userId = membership?.userId;
                if (userId) {
                    setPrincipal(userId);
                }
                navigate("/", { replace: true });
                return;
            }
            toast({ title: "Failed to join organization", description: formatError(error) });
        }
    }, [toast, account, inviteID, joinOrganization, navigate, summary]);

    if (isLoading) {
        return <LoadingState />;
    }

    if (isError || isError) {
        return (
            <div data-testid="broken-invite-url" className="flex h-screen flex-col">
                <div className="flex-1 grow">
                    <div className="m-8 mt-48 flex flex-auto flex-col content-center items-center">
                        <h1 className="mb-4 text-6xl font-bold text-content-tertiary">Oops</h1>
                        <p className="mb-6 text-xl text-content-tertiary">The entered invite URL seems to be broken.</p>
                        <a href="/">
                            <Button variant={"primary"}>Go to Dashboard</Button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    const joinable = new JoinableOrganization({
        organizationId: summary?.organizationId,
        organizationName: summary?.organizationName,
        organizationMemberCount: summary?.organizationMemberCount,
    });

    return (
        <div data-testid="join-from-invite" className="flex w-128 flex-col items-center justify-start self-center">
            <div className="flex flex-col items-center justify-start gap-2 py-8">
                <Heading1>Join organization</Heading1>
                <Subheading className="pt-1">
                    You have been invited to join the organization{" "}
                    <span data-testid="join-from-invite-organization-name">{summary?.organizationName}</span>, do you
                    want to proceed?
                </Subheading>
            </div>
            <JoinableOrganizationCard
                org={joinable}
                isLoading={joinOrganization.isPending}
                handleJoin={handleJoinOrganization}
            />
        </div>
    );
};

function isConnectCode(e: unknown, code: Code): boolean {
    return e instanceof ConnectError && e.code === code;
}
