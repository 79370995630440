import { useGetAccount } from "@/queries/account-queries";
import { type FC } from "react";

export const AccountInfo: FC = () => {
    const { data: account, isLoading } = useGetAccount();

    if (isLoading || !account) {
        // TODO(at) add a nice loading state, maybe a skeleton?
        return <div />;
    }

    return (
        <div className="flex flex-col justify-center">
            <span className="select-none overflow-hidden overflow-ellipsis whitespace-nowrap pl-4 text-sm">
                {account.email}
            </span>
        </div>
    );
};
