import type React from "react";
import { useCallback, useRef, useState } from "react";
import { cn } from "@/components/podkit/lib/cn";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import { Combobox, ComboboxProvider, ComboboxItem, ComboboxList } from "@ariakit/react";
import * as RadixPopover from "@radix-ui/react-popover";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";

type Props = {
    id?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    name?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    placeholder?: string;
    suggestions: string[];
    value?: string;
    error?: string;
    "data-testid"?: string;
    "data-track-location": string;
} & PropsWithClassName;

const InputWithSuggestions: React.FC<Props> = ({
    id,
    autoFocus,
    disabled,
    name,
    onChange,
    onBlur,
    placeholder,
    suggestions,
    value,
    error,
    "data-testid": testId,
    "data-track-location": dataTrackLocation,
}) => {
    const updateValue = (value: string) => {
        if (onChange) {
            onChange(value);
        }
    };

    const filteredSuggestions = suggestions.filter(
        (item) => !value || item.toLowerCase().includes(value.toLowerCase()),
    );
    const hasSuggestions = filteredSuggestions.length > 0;

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur();
        }
    }, [onBlur]);

    const comboboxRef = useRef<HTMLInputElement>(null);
    const listboxRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    return (
        <RadixPopover.Root open={open} onOpenChange={setOpen} modal data-track-location={dataTrackLocation}>
            <ComboboxProvider setValue={updateValue} value={value} open={open} setOpen={setOpen}>
                <RadixPopover.Anchor asChild>
                    <Combobox
                        id={id}
                        placeholder={placeholder}
                        className={cn(
                            "flex h-9 w-full rounded-lg border border-border-light bg-surface-secondary px-3 py-2 text-base ring-offset-surface-primary",
                            "file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-content-tertiary",
                            "disabled:cursor-not-allowed disabled:opacity-50",
                            error ? "border-content-negative focus-visible:border-border-light" : "border-border-light",
                        )}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        name={name}
                        onBlur={handleBlur}
                        data-testid={testId}
                        ref={comboboxRef}
                    />
                </RadixPopover.Anchor>
                {hasSuggestions && (
                    <RadixPopover.Portal>
                        <RadixPopover.Content
                            asChild
                            sideOffset={8}
                            onOpenAutoFocus={(event) => event.preventDefault()}
                            onInteractOutside={(event) => {
                                const target = event.target as Element | null;
                                const isCombobox = target === comboboxRef.current;
                                const inListbox = target && listboxRef.current?.contains(target);
                                if (isCombobox || inListbox) {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <ScrollAreaPrimitive.Root className="z-50 rounded-lg border border-border-base" type="auto">
                                <ScrollAreaPrimitive.Viewport
                                    className={cn(
                                        "rounded-lg",
                                        "max-h-[min(320px,calc(var(--radix-popper-available-height)-61px))]",
                                        "w-[var(--radix-popover-trigger-width)] min-w-[8rem]",
                                    )}
                                >
                                    <ComboboxList
                                        ref={listboxRef}
                                        role="listbox"
                                        className={cn(
                                            "bg-surface-secondary text-content-primary shadow-md",
                                            "focus:ring-0",
                                            "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
                                        )}
                                    >
                                        {filteredSuggestions.map((value) => (
                                            <ComboboxItem
                                                key={value}
                                                value={value}
                                                className={cn(
                                                    "cursor-pointer px-3 py-2 text-base opacity-100",
                                                    "hover:bg-surface-tertiary",
                                                    "data-[active-item=true]:bg-surface-tertiary data-[active-item=true]:text-content-primary data-[active-item=true]:ring-0",
                                                )}
                                            />
                                        ))}
                                    </ComboboxList>
                                </ScrollAreaPrimitive.Viewport>
                                <ScrollAreaPrimitive.Scrollbar className="px-[2px] py-[2px]" orientation="vertical">
                                    <ScrollAreaPrimitive.Thumb
                                        className="h-2 w-2 rounded-lg bg-surface-tertiary"
                                        style={{ "--radix-scroll-area-thumb-width": "8px" } as React.CSSProperties}
                                    />
                                </ScrollAreaPrimitive.Scrollbar>
                            </ScrollAreaPrimitive.Root>
                        </RadixPopover.Content>
                    </RadixPopover.Portal>
                )}
                {error && <div className="text-sm text-content-negative">{error}</div>}
            </ComboboxProvider>
        </RadixPopover.Root>
    );
};

export default InputWithSuggestions;
