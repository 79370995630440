import { useCallback, type FC } from "react";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { Button } from "@/components/flexkit/Button";
import { CheckedText } from "@/components/CheckedText";
import { useLocalStorage } from "@/hooks/use-local-storage";
import { TrackLocations } from "@/hooks/use-segment";

export const FlexUpgradeModal: FC = () => {
    const { storedValue: showModal, setValue: setShowModal } = useLocalStorage("SHOW_FLEX_UPGRADE_MODAL", "true");

    const onOpenChange = useCallback(
        (open: boolean) => {
            if (!open) {
                setShowModal("false");
            }
        },
        [setShowModal],
    );

    return (
        <Dialog open={showModal === "true"} onOpenChange={onOpenChange}>
            <DialogContent
                className="flex max-w-[730px] flex-col p-5"
                data-track-location={TrackLocations.FlexUpgradeModal}
            >
                <DialogHeader className="flex flex-col items-center gap-2">
                    <DialogTitle className="font-base text-center text-2xl">
                        We&apos;ve upgraded you to Flex
                    </DialogTitle>
                    <DialogDescription className="text-center text-lg text-content-secondary md:px-32">
                        Enjoy unlimited use of all features during early access and a 14-day trial afterwards.
                    </DialogDescription>
                </DialogHeader>
                <DialogBody className="flex justify-between gap-3 py-10">
                    <BenefitsGroup
                        benefits={[
                            "Up to 896 cores, 12TB RAM, GPU support",
                            "Self-host in under 3 minutes",
                            "Run locally with Gitpod Desktop",
                            "Dev Container support",
                            "Custom workflow automations",
                            "CLI access",
                        ]}
                    />
                    <BenefitsGroup
                        benefits={[
                            "Unlimited members per org",
                            "Unlimited environments",
                            "Shared auto-scaling runner infrastructure",
                            "Shared environment configuration",
                            "Custom environment timeouts",
                        ]}
                    />
                </DialogBody>
                <DialogFooter className="flex flex-row justify-center sm:justify-center">
                    <DialogClose asChild>
                        <Button>Start exploring</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

const BenefitsGroup: FC<{ benefits: string[] }> = ({ benefits }) => {
    return (
        <div className="flex basis-1/2 flex-col justify-between gap-8">
            <div className="flex flex-col gap-2 text-content-secondary">
                {benefits.map((text, i) => (
                    <div key={i} className="rounded-xl border border-border-light p-2">
                        <CheckedText text={text} checked={true} />
                    </div>
                ))}
            </div>
        </div>
    );
};
