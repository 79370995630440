import type { FC } from "react";
import { IconCircleWithOptionalCheckmark } from "@/assets/icons/geist/IconCircleWithOptionalCheckmark";
import { Text } from "@/components/podkit/typography/Text";

export const CheckedText: FC<{ text: string; checked: boolean }> = ({ text, checked }) => {
    return (
        <div className="flex items-center gap-1">
            <div className="shrink-0">
                <IconCircleWithOptionalCheckmark checked={checked} />
            </div>
            <Text className="text-base text-content-secondary">{text}</Text>
        </div>
    );
};
