import { Button } from "@/components/flexkit/Button";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Subheading } from "@/components/podkit/typography/Headings";
import { appMainService } from "@/ipc";
import { formatError } from "@/utils/errors";
import { useCallback, type FC } from "react";

export const DesktopSignin: FC = () => {
    const { toast } = useToast();
    const cpHost = window.location.origin;

    const handleConnectToGitpod = useCallback(async () => {
        const result = await appMainService!.signin({ host: cpHost });
        if (result === "timeout") {
            toast({ title: "Timed out", description: "Please try again" });
            return;
        }
        const parsed = parseSigninResult(result);
        if (!parsed) {
            toast({ title: "Unexpected parse error", description: "Please try again" });
            return;
        }
        try {
            // clear cookies first
            await fetch("/auth/oidc/logout", {
                method: "POST",
                credentials: "same-origin",
            });
        } catch {
            // explicitly ignore as we're about to login using the desktop flow
        }
        try {
            const response = await fetch("/auth/desktop/callback", {
                method: "POST",
                credentials: "same-origin",
                body: new URLSearchParams(parsed),
            });
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            window.location.href = "/";
        } catch (error) {
            toast({ title: "Unexpected parse error", description: formatError(error) });
        }
    }, [cpHost, toast]);

    return (
        <>
            <Subheading>Sign into {cpHost}</Subheading>
            <div className="my-8" onClick={() => handleConnectToGitpod()}>
                <Button id="signin" variant={"secondary"} size={"lg"}>
                    Sign into Gitpod
                </Button>
            </div>
        </>
    );
};

function parseSigninResult(result: string) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const asObject = JSON.parse(result);
        if (typeof asObject === "object" && "code" in asObject && "code_verifier" in asObject) {
            return {
                code: asObject.code as string,
                code_verifier: asObject.code_verifier as string,
            };
        }
    } catch (error) {
        console.error("error processing signin result", error);
    }
}
