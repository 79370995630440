import { Card } from "@/components/podkit/Card";
import { Heading3, Subheading } from "@/components/podkit/typography/Headings";
import { type FC, type ReactNode } from "react";

export const defaultHeading = "You're not authorized";
export const defaultDescription =
    "You do not have sufficient permissions to manage this organization. Contact your organization's admin to grant those permissions.";

export const NotAuthorized: FC<{ heading?: ReactNode; description?: ReactNode }> = ({
    heading = defaultHeading,
    description = defaultDescription,
}) => {
    return (
        <Card className="space-y-3">
            <div>
                <Heading3>{heading}</Heading3>
                <Subheading>{description}</Subheading>
            </div>
        </Card>
    );
};
