// ensure to bind ipc as first thing
import "./ipc";

// then import other things
import { GitpodAPI } from "@/api";
import { App } from "@/app";
import { ExceptionErrorBoundary } from "@/components/ExceptionHandler";
import { Toaster } from "@/components/podkit/toasts/Toaster";
import { GitpodAPIContext } from "@/contexts/GitpodAPIContext";
import { OnboardingProvider } from "@/contexts/OnboardingProvider";
import { OnboardingQuestionnaireProvider } from "@/contexts/OnboardingQuestionnaireProvider";
import { createSegment } from "@/contexts/SegmentContext";
import { SegmentProvider } from "@/contexts/SegmentProvider";
import { restorePrincipal } from "@/principal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigCatProvider } from "configcat-react";
import React from "react";
import ReactDOM from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "./contexts/ThemeProvider";
import "./index.css";
import { DesktopProvider } from "@/contexts/DesktopProvider";
import { appMainService, runnerMainService } from "./ipc";

// Restoring principal from local storage before warming up the query client here.
restorePrincipal();

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
    queries: {
        refetchOnReconnect: true,
        staleTime: 1_000 * 5, // 5 seconds
    },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <SegmentProvider create={() => createSegment()}>
            <ConfigCatProvider
                sdkKey={"configcat-proxy/default"}
                options={{
                    baseUrl: window.origin + "/feature-flags/configcat",
                    setupHooks: (hooks) => hooks.on("clientReady", () => console.log("ConfigCat client is ready!")),
                }}
            >
                <IntercomProvider appId="ax8s6m84" apiBase="https://api-iam.intercom.io" initializeDelay={2000}>
                    <ThemeProvider>
                        <DesktopProvider
                            runnerService={runnerMainService}
                            isDesktop={!!runnerMainService}
                            appMainService={appMainService}
                        >
                            <GitpodAPIContext.Provider value={GitpodAPI}>
                                <QueryClientProvider client={queryClient}>
                                    <ExceptionErrorBoundary>
                                        <OnboardingQuestionnaireProvider>
                                            <OnboardingProvider>
                                                <ReactQueryDevtools initialIsOpen={false} />
                                                <App />
                                                <Toaster />
                                            </OnboardingProvider>
                                        </OnboardingQuestionnaireProvider>
                                    </ExceptionErrorBoundary>
                                </QueryClientProvider>
                            </GitpodAPIContext.Provider>
                        </DesktopProvider>
                    </ThemeProvider>
                </IntercomProvider>
            </ConfigCatProvider>
        </SegmentProvider>
    </React.StrictMode>,
);
