import type { RouteObject } from "react-router-dom";

export type MenuItem = {
    label: string;
    section: string;
    to: string;
    isAdminRole?: boolean;
    featureFlag?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMenuItem(value: any): value is MenuItem {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
        value &&
        typeof value.label === "string" &&
        typeof value.section === "string" &&
        typeof value.to === "string" &&
        (typeof value.isAdminRole === "boolean" || value.isAdminRole === undefined)
    );
}

export const populateMenuFromRoute = (route: RouteObject): MenuItem[] => {
    const menu: MenuItem[] = [];
    if (route.children) {
        for (const child of route.children) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const { handle } = child;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const menuItem = handle?.menuItem;
            if (isMenuItem(menuItem)) {
                menu.push(menuItem);
                handle.menu = menu;
            }
        }
    }
    return menu;
};
