/**
 * @returns true if the platform is Mac, iPad or iPhone
 */
export const isMacLike = (): boolean => {
    if (navigator.platform.startsWith("Mac") || navigator.platform === "iPhone") {
        return true;
    }

    return false;
};

/**
 * @returns either "⌘" or "Ctrl + " depending on the platform for use in key bindings
 */
export const getPlatformModifierKey = (): string => (isMacLike() ? "⌘" : "Ctrl + ");

export const StartEnvironmentModalKeyBinding = getPlatformModifierKey() + "O";

export const OpenProjectsModalKeyBinding = getPlatformModifierKey() + "P";

export const OpenSettingsModalKeyBinding = getPlatformModifierKey() + ",";
