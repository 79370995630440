export function pluralize(count: number, singular: string, plural = `${singular}s`): string {
    return count === 1 ? singular : plural;
}

// pluralizeWithCount returns a string with the count and the pluralized word.
// If hasMore is true, a "+" is appended to the count.
// Example: pluralizeWithCount(1, false, "project") returns "1 project"
export const pluralizeWithCount = (count: number, hasMore: boolean, singular: string, plural = `${singular}s`) => {
    return `${count}${hasMore ? "+" : ""} ${pluralize(count, singular, plural)}`;
};
