import { useCallback, useState } from "react";

export const useLocalStorage = (key: string, defaultValue: string) => {
    const readValue = useCallback(() => {
        try {
            const raw = window.localStorage.getItem(key);
            return raw ? raw : defaultValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return defaultValue;
        }
    }, [key, defaultValue]);

    const [storedValue, setStoredValue] = useState(() => readValue());

    const setValue = useCallback(
        (newValue: string) => {
            try {
                // Save to local storage
                window.localStorage.setItem(key, newValue);
                // Save state
                setStoredValue(newValue);
            } catch (error) {
                console.warn(`Error setting localStorage key “${key}”:`, error);
            }
        },
        [key],
    );

    return { storedValue, setValue };
};
