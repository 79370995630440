import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconArrowDown: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.0578 9.33904L13.5218 8.875L12.5937 7.94692L12.1297 8.41096L8.65624 11.8844L8.65624 2.53125L8.65624 1.875L7.34374 1.875L7.34374 2.53125L7.34374 11.8844L3.87026 8.41096L3.40625 7.94692L2.47814 8.875L2.94224 9.33904L7.38128 13.7781C7.72298 14.1198 8.27701 14.1198 8.61871 13.7781L13.0578 9.33904Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.7803 11.5303L16.3107 11L15.25 9.93934L14.7197 10.4697L10.75 14.4393L10.75 3.75L10.75 3L9.24999 3L9.24999 3.75L9.24999 14.4393L5.2803 10.4697L4.75 9.93934L3.6893 11L4.2197 11.5303L9.29289 16.6036C9.68341 16.9941 10.3166 16.9941 10.7071 16.6036L15.7803 11.5303Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.5029 13.7216L19.0995 13.125L17.9062 11.9318L17.3096 12.5284L12.8437 16.9942L12.8437 4.96875L12.8437 4.125L11.1562 4.125L11.1562 4.96875L11.1562 16.9942L6.69034 12.5284L6.09376 11.9318L4.90047 13.125L5.49717 13.7216L11.2045 19.429C11.6438 19.8684 12.3562 19.8684 12.7955 19.429L18.5029 13.7216Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
