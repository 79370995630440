import { Input } from "@/components/podkit/forms/Input";
import { InputField } from "@/components/podkit/forms/InputField";
import { RadioGroup, RadioGroupItem } from "@/components/podkit/forms/RadioListField";
import { Textarea } from "@/components/podkit/forms/Textarea";
import type { SecretMountType } from "@/queries/secret-queries";
import { useCallback, useEffect, useId, useState, type FC } from "react";

export type NewSecretFormOnSubmitInput = { secretName: string; secretValuePlaintext: string; mount: SecretMountType };
type MountOption = SecretMountType["case"];

export const NewSecretForm: FC<{
    formName: string;
    onDisabledChange: (disabled: boolean) => void;
    onSubmit: (values: NewSecretFormOnSubmitInput) => Promise<void>;
}> = ({ formName, onSubmit, onDisabledChange }) => {
    const nameId = useId();
    const secretId = useId();
    const mountId = useId();
    const filePathId = useId();

    const [name, setName] = useState<string>("");
    const [secret, setSecret] = useState<string>("");

    const [mountOption, setMountOption] = useState<MountOption>("filePath");
    const [filePath, setFilePath] = useState<string>("/usr/local/secrets/");

    const onUpdateName = useCallback(
        (value: string) => {
            setName(value);
            setFilePath((path) => {
                const parts = path.split("/");
                parts.pop();
                parts.push(value.replace(/[^a-zA-Z0-9_]/g, ""));
                return parts.join("/");
            });
        },
        [setName, setFilePath],
    );

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            let mount: SecretMountType = {
                case: "environmentVariable",
                value: true,
            };
            if (mountOption === "filePath") {
                mount = {
                    case: "filePath",
                    value: filePath,
                };
            }
            await onSubmit({ secretName: name, secretValuePlaintext: secret, mount: mount });
        },
        [onSubmit, name, secret, mountOption, filePath],
    );

    useEffect(() => {
        if (!name || !secret) {
            onDisabledChange(true);
            return;
        }
        onDisabledChange(false);
    }, [name, secret, onDisabledChange]);

    return (
        <form
            onSubmit={handleSubmit}
            name={formName}
            id={formName}
            data-testid="new-secret-form"
            className="flex flex-col gap-4"
        >
            <InputField
                label="Name"
                id={nameId}
                hint="Secret names can only contain alphanumeric characters ([a-z], [A-Z], [0-9]) or underscores (_)."
            >
                <Input
                    id={nameId}
                    type="text"
                    name="secret-name"
                    value={name || ""}
                    placeholder="Ex: MY_SECRET"
                    pattern="[a-zA-Z0-9_]+"
                    onChange={(e) => onUpdateName(e.target.value)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    data-testid="secret-name-input"
                />
            </InputField>

            <InputField id={nameId} label="Secret">
                <Textarea
                    id={secretId}
                    name="secret-value"
                    rows={10}
                    value={secret || ""}
                    placeholder="Enter your secret"
                    onChange={(e) => setSecret(e.target.value)}
                    className="resize-none"
                    data-testid="secret-value-input"
                />
            </InputField>

            <div className="flex flex-col gap-1">
                <InputField id={mountId} label="Make secret available as...">
                    <RadioGroup
                        value={mountOption}
                        onValueChange={(value) => setMountOption(value === "environmentVariable" ? value : "filePath")}
                    >
                        <div className="flex items-center justify-start gap-2">
                            <RadioGroupItem
                                value={"environmentVariable"}
                                id={"environmentVariable"}
                                data-testid="secret-mount-option-env"
                            />
                            <label htmlFor={"environmentVariable"}>Environment Variable</label>
                        </div>

                        <div className="flex items-center justify-start gap-2">
                            <RadioGroupItem value={"filePath"} id={"filePath"} data-testid="secret-mount-option-path" />
                            <label htmlFor={"filePath"}>File</label>
                        </div>
                    </RadioGroup>
                </InputField>
                <div className="ml-6">
                    <Input
                        id={filePathId}
                        type="text"
                        name="mount-file-path"
                        value={filePath || ""}
                        placeholder="Ex: /usr/local/secrets/MY_SECRET"
                        onChange={(e) => setFilePath(e.target.value)}
                        disabled={mountOption !== "filePath"}
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        data-testid="secret-filepath-input"
                    />
                </div>
            </div>
        </form>
    );
};
