import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconLock: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.75 4.9375V6.25H6.25V4.9375C6.25 3.971 7.0335 3.1875 8 3.1875C8.9665 3.1875 9.75 3.971 9.75 4.9375ZM4.9375 6.25V4.9375C4.9375 3.24612 6.30862 1.875 8 1.875C9.69137 1.875 11.0625 3.24612 11.0625 4.9375V6.25H11.9375H13.25V7.5625V11.9375C13.25 13.1456 12.2706 14.125 11.0625 14.125H4.9375C3.72938 14.125 2.75 13.1456 2.75 11.9375V7.5625V6.25H4.0625H4.9375ZM11.0625 7.5625H9.75H6.25H4.9375H4.0625V11.9375C4.0625 12.4208 4.45425 12.8125 4.9375 12.8125H11.0625C11.5458 12.8125 11.9375 12.4208 11.9375 11.9375V7.5625H11.0625Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12 6.5V8H8V6.5C8 5.39543 8.89543 4.5 10 4.5C11.1046 4.5 12 5.39543 12 6.5ZM6.5 8V6.5C6.5 4.567 8.067 3 10 3C11.933 3 13.5 4.567 13.5 6.5V8H14.5H16V9.5V14.5C16 15.8807 14.8807 17 13.5 17H6.5C5.11929 17 4 15.8807 4 14.5V9.5V8H5.5H6.5ZM13.5 9.5H12H8H6.5H5.5V14.5C5.5 15.0523 5.94772 15.5 6.5 15.5H13.5C14.0523 15.5 14.5 15.0523 14.5 14.5V9.5H13.5Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.25 8.0625V9.75H9.75V8.0625C9.75 6.81986 10.7574 5.8125 12 5.8125C13.2426 5.8125 14.25 6.81986 14.25 8.0625ZM8.0625 9.75V8.0625C8.0625 5.88788 9.82538 4.125 12 4.125C14.1746 4.125 15.9375 5.88788 15.9375 8.0625V9.75H17.0625H18.75V11.4375V17.0625C18.75 18.6158 17.4908 19.875 15.9375 19.875H8.0625C6.5092 19.875 5.25 18.6158 5.25 17.0625V11.4375V9.75H6.9375H8.0625ZM15.9375 11.4375H14.25H9.75H8.0625H6.9375V17.0625C6.9375 17.6838 7.44118 18.1875 8.0625 18.1875H15.9375C16.5588 18.1875 17.0625 17.6838 17.0625 17.0625V11.4375H15.9375Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
