import { useDocumentTitle } from "@/hooks/use-document-title";
import { Button } from "@/components/flexkit/Button";
import { type FC } from "react";

export const NotFound: FC = () => {
    useDocumentTitle("Not Found");
    return (
        <div className="flex h-screen flex-col">
            <div className="flex-1 grow">
                <div className="m-8 mt-48 flex flex-auto flex-col content-center items-center">
                    <h1 className="mb-4 text-8xl font-bold text-content-tertiary">404</h1>
                    <p className="mb-6 text-xl text-content-tertiary">Oops, nothing to see here.</p>
                    <a href="/">
                        <Button variant={"brand"} size={"lg"}>
                            Go to Dashboard
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};
