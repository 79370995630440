import { ipcRequest } from "./ipc";

export type ShowNotificationParam = {
    title: string;
    message: string;
    actions?: string[];

    // dissmiss after this duration in ms
    duration?: number;
    // `true` to disable auto-dismiss
    indefinite?: boolean;
};

export type ShowNotificationResult = {
    action?: string;
};

export const notificationRendererType = {
    type: "NotificationRendererService",
    methods: {
        show: ipcRequest<ShowNotificationParam, ShowNotificationResult>(),
    },
};
