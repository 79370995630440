import { CheckboxInputField } from "@/components/podkit/checkbox/CheckboxInputField";
import { Input } from "@/components/podkit/forms/Input";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading1, Subheading } from "@/components/podkit/typography/Headings";
import { useGetAccount } from "@/queries/account-queries";
import {
    type PlainOrganization,
    type PlainOrganizationMember,
    useCreateOrganization,
} from "@/queries/organization-queries";
import { useCallback, useState, type FC, useId, useEffect, useMemo, type FormEvent } from "react";
import { Button } from "@/components/flexkit/Button";
import { InputField } from "@/components/podkit/forms/InputField";
import { ErrorMessage } from "@/components/ErrorMessage";
import { LoadingState } from "@/components/podkit/loading/LoadingState";

type Props = {
    onSuccess?: (org: PlainOrganization, member: PlainOrganizationMember) => void;
};
export const CreateOrganizationComponent: FC<Props> = ({ onSuccess }) => {
    const { toast } = useToast();

    const { data: account } = useGetAccount();

    const [organizationName, setOrganizationName] = useState<string>();
    const inputNameID = useId();

    const [inviteAccountsWithMatchingDomain, setInviteAccountsWithMatchingDomain] = useState<boolean>(false);
    const inviteSameEmailDomainID = useId();

    const createOrganization = useCreateOrganization();

    const accountEmailDomain = useMemo(() => {
        if (!account) {
            return "";
        }

        const parts = account?.email?.split("@") || [];
        if (parts.length < 2) {
            return "";
        }
        return parts[parts.length - 1];
    }, [account]);

    // initialize organization name with email domain
    const [once, setOnce] = useState(true);
    useEffect(() => {
        if (!accountEmailDomain || !once) {
            return;
        }

        if (account?.publicEmailProvider) {
            return;
        }

        setOrganizationName(accountEmailDomain);
        setInviteAccountsWithMatchingDomain(true);
        setOnce(false);
    }, [account, accountEmailDomain, organizationName, once]);

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            const name = organizationName?.trim();
            if (!name) {
                toast({ title: `Organization name is required` });
                return;
            }

            createOrganization.mutate(
                {
                    name,
                    inviteAccountsWithMatchingDomain: inviteAccountsWithMatchingDomain,
                },
                {
                    onSuccess: ({ organization, member }) => {
                        toast({ title: `Organization created`, description: `${organization.name}` });
                        if (onSuccess) {
                            onSuccess(organization, member);
                        }
                    },
                },
            );
        },
        [createOrganization, organizationName, onSuccess, inviteAccountsWithMatchingDomain, toast],
    );

    if (!account) {
        return <LoadingState />;
    }

    return (
        <div
            data-testid="create-organization"
            className="flex w-96 flex-col items-center justify-start gap-6 self-center"
        >
            <div className="flex flex-col gap-2">
                <Heading1 className="text-center text-2xl font-normal text-content-primary">
                    Create your organization
                </Heading1>
                <Subheading className="text-center font-normal">
                    Organizations allow you to manage related projects and collaborate with team members.
                </Subheading>
            </div>
            <form
                onSubmit={handleSubmit}
                className="flex w-full flex-col gap-4"
                name="create-organization"
                id="create-organization-form"
            >
                <div className="space-y-1">
                    <InputField label="Organization name">
                        <Input
                            autoFocus={true}
                            id={inputNameID}
                            type="text"
                            name="organization_name"
                            value={organizationName || ""}
                            onChange={(e) => setOrganizationName(e.target.value)}
                            data-testid="organization-name"
                        />
                    </InputField>
                </div>

                {!account.publicEmailProvider && (
                    <CheckboxInputField
                        id={inviteSameEmailDomainID}
                        data-testid="anyone-with-email-domain-can-join"
                        checked={inviteAccountsWithMatchingDomain}
                        label={`Anyone with a "${accountEmailDomain}" domain can join`}
                        onChange={setInviteAccountsWithMatchingDomain}
                    />
                )}
                <ErrorMessage error={createOrganization.error} />
            </form>
            <Button
                form="create-organization-form"
                id="create-organization"
                data-testid="create-organization-button"
                type="submit"
                variant="primary"
                size="lg"
                className="w-full"
                loading={createOrganization.isPending}
                disabled={(organizationName?.length ?? 0) < 3}
            >
                Continue
            </Button>
        </div>
    );
};
