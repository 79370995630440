import { useGitpodAPI } from "@/hooks/use-gitpod-api";
import { defaultRetry, defaultThrowOnError } from "@/queries/errors";
import { keyWithPrincipal } from "@/queries/principal-key";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { toPlainMessage, type PlainMessage } from "@bufbuild/protobuf";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { PaginationResponse } from "gitpod-next-api/gitpod/v1/pagination_pb";
import {
    ListPersonalAccessTokensRequest,
    ListPersonalAccessTokensRequest_Filter,
    type PersonalAccessToken,
} from "gitpod-next-api/gitpod/v1/user_pb";

export type PlainPersonalAccessToken = PlainMessage<PersonalAccessToken>;

export function toPlainPersonalAccessToken(pat: PersonalAccessToken): PlainPersonalAccessToken {
    return toPlainMessage(pat);
}

export const personalAccessTokensQueryKey = {
    list: (filter: Record<string, string | number | undefined>) => keyWithPrincipal(["pats", "list", filter]),
    get: (patId?: string) => keyWithPrincipal(["pats", { patId }]),
};

export type UseListPersonalAccessTokensParams = {
    userId: string;
};

export const useListPersonalAccessTokens = ({ userId }: UseListPersonalAccessTokensParams) => {
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    const query = useQuery({
        queryKey: personalAccessTokensQueryKey.list({ userId }),
        queryFn: async (): Promise<CachedPersonalAccessTokenList> => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { personalAccessTokens, pagination } = await api.userService.listPersonalAccessTokens(
                new ListPersonalAccessTokensRequest({
                    filter: new ListPersonalAccessTokensRequest_Filter({
                        userIds: [userId],
                    }),
                }),
            );

            return {
                pats: personalAccessTokens.map(toPlainPersonalAccessToken),
                pagination,
            };
        },
        throwOnError: defaultThrowOnError,
        retry: defaultRetry,
        enabled: !!user,
        staleTime: 1_000 * 10, // 10 seconds
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
    });
    return { ...query, personalAccessTokens: query.data?.pats };
};

type CachedPersonalAccessTokenList = {
    pats: PlainPersonalAccessToken[];
    pagination: PaginationResponse | undefined;
};

export const useCreatePersonalAccessToken = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ description, validForDays }: { description: string; validForDays: number }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            const { token } = await api.userService.createPersonalAccessToken({
                description,
                validFor: { seconds: BigInt(validForDays * 60 * 60 * 24) },
                userId: user.id,
            });

            if (!token) {
                throw new Error("Error creating runner");
            }

            return {
                token,
            };
        },
        onSuccess: async () => {
            const prefixOfKeys = personalAccessTokensQueryKey.list({}).slice(0, -1);
            await client.invalidateQueries({ queryKey: prefixOfKeys });
        },
    });
};

export const useDeletePersonalAccessToken = () => {
    const client = useQueryClient();
    const api = useGitpodAPI();
    const { data: user } = useAuthenticatedUser();

    return useMutation({
        mutationFn: async ({ personalAccessTokenId }: { personalAccessTokenId: string }) => {
            if (!user) {
                throw new Error("User not authenticated");
            }

            return await api.userService.deletePersonalAccessToken({
                personalAccessTokenId,
            });
        },
        onSuccess: async () => {
            const prefixOfAllKeys = personalAccessTokensQueryKey.list({}).slice(0, -1);
            await client.invalidateQueries({ queryKey: prefixOfAllKeys });
        },
    });
};
