import { ProjectDetailsLayout } from "@/routes/projects/details/ProjectDetailsLayout";
import { ProjectSettingsPage } from "@/routes/projects/details/ProjectSettingsPage";
import { ProjectSecretsPage } from "@/routes/projects/details/ProjectSecretsPage";
import { ProjectSharingPage } from "@/routes/projects/details/ProjectSharingPage";
import { ProjectsPage } from "@/routes/projects/ProjectsPage";
import { PageSuspense } from "@/components/PageSuspense";
import { Navigate, Outlet, type RouteObject } from "react-router-dom";
import { Flags } from "@/feature-flags";

export const projectDetailsRoutes: RouteObject = {
    path: ":projectId",
    element: <ProjectDetailsLayout />,
    children: [
        {
            index: true,
            element: <Navigate to="settings" replace />,
        },
        {
            path: "settings",
            element: <ProjectSettingsPage />,
            handle: {
                menuItem: {
                    label: "Settings",
                    to: "settings",
                    section: "project",
                },
            },
        },
        {
            path: "secrets",
            element: <ProjectSecretsPage />,
            handle: {
                menuItem: {
                    label: "Secrets",
                    to: "secrets",
                    section: "project",
                    featureFlag: Flags.SecretsEnabled,
                },
            },
        },
        {
            path: "sharing",
            element: <ProjectSharingPage />,
            handle: {
                menuItem: {
                    label: "Sharing",
                    to: "sharing",
                    section: "project",
                },
            },
        },
    ],
};

export const projectRoutes: RouteObject = {
    path: "projects",
    handle: {
        label: "Projects",
    },
    element: (
        <PageSuspense>
            <Outlet />
        </PageSuspense>
    ),
    children: [
        {
            index: true,
            element: <ProjectsPage />,
        },
        projectDetailsRoutes,
    ],
};
