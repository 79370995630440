import { Text } from "@/components/podkit/typography/Text";
import { TimelineDot } from "@/components/onboarding/TimelineDot";
import type { RunnerConfigurationPhase } from "@/routes/onboarding/use-runner-configuration-progress";
import { type FC } from "react";
import { Tooltip } from "@/components/Tooltip";

export const TimelinePill: FC<{ phase: RunnerConfigurationPhase }> = ({ phase }) => {
    return (
        <Tooltip content={phase.label}>
            <div className="flex min-w-0 items-center justify-center gap-1 rounded-[100px] border-0.5 border-border-base bg-surface-01 p-1 pr-3 text-center shadow-sm">
                <TimelineDot state={phase.state} className="size-4 min-h-4 min-w-4" />
                <Text className="pointer-events-none min-w-0 truncate text-sm font-medium text-content-primary">
                    {phase.label}
                </Text>
            </div>
        </Tooltip>
    );
};
