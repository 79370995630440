import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useMembers } from "@/queries/organization-queries";
import { useListProjects, useListProjectStates } from "@/queries/project-queries";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { ProjectsFilterType } from "@/components/projects/ProjectConstants";
import type { PlainMessage } from "@bufbuild/protobuf";
import type { ContextURLInitializer } from "gitpod-next-api/gitpod/v1/environment_pb";
import { useMemo, type FC } from "react";
import { NoProjectCard, ProjectCard } from "./ProjectCard";

export const ProjectsList: FC<{
    canEdit: boolean;
    showRecentlyUsedOnly?: boolean;
    showNoProjectCard?: boolean;
    filter?: ProjectsFilterType;
    search?: string;
    selectProjectId?: string;
    selectEnvironmentClass?: string;
    onCreateSuccess?: () => void;
    onCreateFromUrl?: () => void;
}> = ({
          canEdit,
          showNoProjectCard,
          filter,
          search,
          selectProjectId,
          selectEnvironmentClass,
          onCreateSuccess,
          onCreateFromUrl,
      }) => {
    const { data: user, isLoading: isLoadingUser } = useAuthenticatedUser();
    const { data: projectData, isLoading: isLoadingProjects, isPending: isPendingProjects } = useListProjects();
    const { data: projectsStates, isLoading: isLoadingProjectsStates } = useListProjectStates(
        projectData?.projects.map((p) => p.id),
    );
    const { data: membersData, isPending: isPendingMembers } = useMembers();

    const allProjects = useMemo(() => {
        if (!membersData || !projectData || !projectsStates) {
            return []; // should be caught by isLoading check
        }

        return (projectData.projects ?? []).map((project, idx) => {
            return {
                project,
                state: projectsStates[idx],
            };
        });
    }, [membersData, projectData, projectsStates]);

    const projectsToShow = allProjects.filter((p) => {
        let visible = true;
        if (selectProjectId) {
            return p.project.id === selectProjectId;
        }
        if (selectEnvironmentClass) {
            if (selectEnvironmentClass === "local") {
                return p.project.environmentClass?.environmentClass.case === "localRunner";
            } else {
                return p.project.environmentClass?.environmentClass.case === "environmentClassId" &&
                    p.project.environmentClass.environmentClass.value === selectEnvironmentClass;
            }
        }
        if (filter === ProjectsFilterType.Active) {
            visible = p.state.shared;
        }
        if (filter === ProjectsFilterType.NotShared) {
            visible = !p.state.shared;
        }
        if (search && visible) {
            const spec = p.project.initializer?.specs.find((s) => s.spec.case === "contextUrl");
            const projectContextUrl = (spec?.spec.value as PlainMessage<ContextURLInitializer>)?.url;
            visible = `${p.project.metadata?.name} ${projectContextUrl}`
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase());
        }
        return visible;
    });

    const isLoading =
        isLoadingProjects || isLoadingProjectsStates || isPendingProjects || isPendingMembers || isLoadingUser;

    if (isLoading) {
        return <LoadingState />;
    }

    return (
        <div className="flex flex-col gap-4">
            {showNoProjectCard && <NoProjectCard onCreateEnvironment={onCreateFromUrl} />}
            <div data-testid="projects-list" className="flex flex-col flex-nowrap gap-4">
                {projectsToShow.map(({ project, state }) => (
                    <ProjectCard
                        key={project.id}
                        canEdit={canEdit}
                        project={project}
                        state={state}
                        orgMembers={membersData?.members || []}
                        currentUserId={user?.id || ""}
                        forceHoverState={!!selectProjectId}
                        onCreateSuccess={onCreateSuccess}
                    />
                ))}
            </div>
        </div>
    );
};
