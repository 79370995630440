import { DesktopTitlebar } from "@/components/desktop/DesktopTitlebar";
import { WebLoginSidebar } from "@/components/login/WebLoginSidebar";
import { useDesktop } from "@/hooks/use-desktop";
import type { FC } from "react";
import { Outlet } from "react-router-dom";

export const LoginLayout: FC = () => {
    const desktop = useDesktop();
    return (
        <div className="app-background-resetting">
            <div className="app-background h-dvh">
                {desktop.isDesktop && <DesktopTitlebar />}
                <div className="flex h-dvh flex-col overflow-y-auto sm:flex-row sm:overflow-hidden">
                    <div className="w-full flex-grow p-2">
                        <div className="h-full w-full rounded-lg border-0.5 border-border-base bg-surface-01 p-0.5 shadow-content-surface dark:shadow-none">
                            <div className="h-full w-full content-center items-center justify-center">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                    {!desktop.isDesktop && <WebLoginSidebar />}
                </div>
            </div>
        </div>
    );
};
