import { IconDot } from "@/assets/icons/geist/IconDot";
import { cn } from "@/components/podkit/lib/cn";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/podkit/popover/Popover";
import { type EffectiveState } from "@/routes/environments/phase";
import { EnvironmentPhase } from "gitpod-next-api/gitpod/v1/environment_pb";
import { ChevronDownIcon } from "lucide-react";
import { useMemo, type FC } from "react";

export const EnvironmentPhaseTag: FC<{
    state: EffectiveState;
    variant?: "dot" | "label";
}> = ({ state, variant = "label" }) => {
    const { bgColor, fgColor, dotBgColor, pulse, name, warnings, failures, timeout } = useMemo(() => {
        if (state.failures && state.state !== EnvironmentPhase.DELETING) {
            return {
                bgColor: "bg-surface-negative/10",
                fgColor: "content-negative",
                dotBgColor: "bg-content-negative",
                pulse: false,
                name: "Failed",
                ...state,
            };
        }

        let bgColor = "bg-surface-tertiary";
        let dotBgColor = "bg-content-tertiary";
        let fgColor = "content-tertiary";
        let name = "Unknown";
        let pulse = false;

        switch (state.state) {
            case EnvironmentPhase.RUNNING:
                bgColor = "bg-content-positive/10";
                dotBgColor = "bg-content-positive";
                fgColor = "text-content-positive";
                name = "Running";
                pulse = false;
                if (state.warnings) {
                    dotBgColor = "bg-content-yield";
                    bgColor = "bg-surface-yield";
                    fgColor = "content-yield";
                }
                break;
            case EnvironmentPhase.CREATING:
                bgColor = "bg-content-yield/10";
                dotBgColor = "bg-content-yield";
                fgColor = "text-content-yield";
                name = "Creating...";
                pulse = true;
                break;
            case EnvironmentPhase.STARTING:
                bgColor = "bg-content-positive/4";
                dotBgColor = "bg-content-positive";
                fgColor = "text-content-positive";
                name = "Starting...";
                pulse = true;
                break;
            case EnvironmentPhase.UPDATING:
                bgColor = "bg-content-yield/10";
                dotBgColor = "bg-content-yield";
                fgColor = "text-content-yield";
                name = "Updating...";
                pulse = true;
                break;
            case EnvironmentPhase.STOPPING:
                bgColor = "bg-content-yield/10";
                dotBgColor = "bg-content-yield";
                fgColor = "text-content-yield";
                name = "Stopping...";
                if (state.timeout) {
                    name = `Auto-stopping...`;
                }
                pulse = true;
                break;
            case EnvironmentPhase.STOPPED:
                bgColor = "bg-surface-tertiary";
                dotBgColor = "bg-content-tertiary";
                fgColor = "content-primary";
                name = "Stopped";
                if (state.timeout) {
                    name = `Auto-stopped`;
                }
                break;
            case EnvironmentPhase.DELETING:
                bgColor = "bg-surface-negative/10";
                dotBgColor = "bg-content-negative";
                fgColor = "content-negative";
                name = "Deleting...";
                pulse = true;
                break;
        }
        return {
            bgColor,
            fgColor,
            dotBgColor,
            pulse,
            name,
            ...state,
        };
    }, [state]);

    if (variant === "dot") {
        return (
            <>
                <IconDot size="lg" className={fgColor} />
            </>
        );
    }

    const problems =
        timeout || warnings || failures
            ? [...(timeout ? [timeout] : []), ...(failures || []), ...(warnings || [])]
            : undefined;

    return (
        <div
            className={cn(
                "inline-flex select-none flex-row items-center gap-1 rounded-md border border-border-light p-1 pr-2 text-sm",
                bgColor,
            )}
        >
            <div
                className={cn(
                    "inline-flex h-4 flex-row items-center gap-2 rounded-full px-1 py-1 text-sm",
                    bgColor,
                    pulse && "animate-pulse",
                )}
            >
                <div className={cn("h-2 w-2 rounded-full", dotBgColor)} />
            </div>
            <div className={cn("whitespace-nowrap", fgColor)}>{name}</div>
            {problems && (
                <Popover>
                    <PopoverTrigger data-testid="problems-popover-trigger">
                        <ChevronDownIcon size={16} className={fgColor} />
                    </PopoverTrigger>
                    <PopoverContent align="end">
                        <div className="text-base text-content-secondary">
                            {problems
                                .map((m) => m.split("\n"))
                                .flat()
                                .map((e, i) => (
                                    <p key={i}>{e}</p>
                                ))}
                        </div>
                    </PopoverContent>
                </Popover>
            )}
        </div>
    );
};
