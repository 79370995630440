import { IconMinimize } from "@/assets/icons/geist/IconDash";
import type { PropsWithTestId } from "@/components/podkit/lib/testid";
import { type PropsWithTrackLocation } from "@/hooks/use-segment";
import * as Popover from "@radix-ui/react-popover";
import { type FC, type PropsWithChildren } from "react";

type NudgeProps = {
    open: boolean;
    toggleOpen: () => void;
    ariaLabelPrefix: string;
} & PropsWithTestId &
    PropsWithTrackLocation &
    PropsWithChildren;

export const Nudge: FC<NudgeProps> = ({
    open,
    toggleOpen,
    ariaLabelPrefix,
    children,
    "data-testid": testId,
    "data-track-location": trackLocation,
}) => {
    return (
        <Popover.Root open={open}>
            <Popover.Trigger data-testid={testId} />
            <Popover.Portal>
                <Popover.Content
                    side="top"
                    sideOffset={-32}
                    sticky="always"
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    className="z-10 hidden max-w-[220px] will-change-[transform,opacity] data-[state=closed]:animate-slideDownAndFadeOut data-[state=open]:animate-slideUpAndFadeIn md:block"
                    data-track-location={trackLocation}
                >
                    <Popover.Close
                        className="text-red fixed -right-1 top-3 z-10 inline-flex h-6 w-6 outline-none ring-0 focus-visible:ring-0"
                        onClick={toggleOpen}
                        aria-label={`${ariaLabelPrefix} - Dismiss`}
                    >
                        <IconMinimize size="sm" />
                    </Popover.Close>
                    <div className="flex flex-col items-center gap-2">
                        <div className="flex min-w-[240px] flex-col rounded-2xl border-[0.5px] border-border-base/20 bg-surface-glass p-2 shadow-sm backdrop-blur-modal">
                            {children}
                        </div>
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
