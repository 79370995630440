import { SkeletonBlock, SkeletonText } from "@/components/podkit/loading/Skeleton";
import { ProjectFailedToLoad } from "@/components/projects/ProjectErrors";
import { ProjectSettings } from "@/components/projects/ProjectSettings";
import { useProject } from "@/queries/project-queries";
import type { FC } from "react";
import { useParams } from "react-router-dom";

export const ProjectSettingsPage: FC = () => {
    const { projectId } = useParams();

    const { data: project, error: getProjectError, isPending: isLoadingProject } = useProject(projectId);

    let content: React.ReactNode | null = null;
    if (isLoadingProject) {
        content = <Loading />;
    } else if (getProjectError || !projectId) {
        content = <ProjectFailedToLoad />;
    } else {
        content = <>{project && <ProjectSettings project={project} key={projectId} />}</>;
    }

    return <div data-testid="project-details-settings-page">{content}</div>;
};

const Loading: FC = () => {
    return (
        <div className="flex max-w-[456px] flex-col gap-5">
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[120px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[120px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[135px]" />
                <SkeletonBlock ready={false} className="h-14 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[160px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <div className="flex flex-col gap-1">
                <SkeletonText size="lg" ready={false} className="w-[150px]" />
                <SkeletonBlock ready={false} className="h-9 w-full" />
            </div>
            <SkeletonBlock ready={false} className="h-8 w-32" />
        </div>
    );
};
