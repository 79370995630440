import { IconRefresh } from "@/assets/icons/geist/IconRefresh";
import { SadBox } from "@/assets/icons/SadBax";
import { Button } from "@/components/flexkit/Button";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { useCallback, type FC } from "react";

export const ProjectFailedToLoad: FC = () => {
    const onReload = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <div className="flex flex-col items-center gap-5 p-12" data-testid="error-failed-to-load-project-data">
            <SadBox />
            <div className="flex flex-col items-center gap-1">
                <Heading2 className="text-xl font-bold text-content-primary">
                    We&apos;re having trouble loading the project.
                </Heading2>
                <Text className="text-lg text-content-secondary">
                    You can try refreshing the page. If the problem persists, please contact support.
                </Text>
            </div>
            <Button variant="secondary" LeadingIcon={IconRefresh} onClick={onReload}>
                Refresh Page
            </Button>
        </div>
    );
};
