import { IconGitHub } from "@/assets/icons/geist/IconGitHub";
import { IconGoogle } from "@/assets/icons/geist/IconGoogle";
import { IconKey } from "@/assets/icons/geist/IconKey";
import { Button } from "@/components/flexkit/Button";
import { cn, type PropsWithClassName } from "@/components/podkit/lib/cn";
import { ExternalLink } from "@/components/podkit/typography/Link";
import type { PlainLoginProvider } from "@/queries/account-queries";
import { type FC } from "react";

type Props = {
    onContinueWithSSO: () => void;
    enableContinueWithSSO?: boolean;
    returnTo: string;
    loginProviders?: PlainLoginProvider[];
};

export const DefaultSSOForm: FC<Props & PropsWithClassName> = ({
    onContinueWithSSO,
    className,
    enableContinueWithSSO,
    returnTo,
    loginProviders,
}) => {
    const showGithub = !loginProviders || loginProviders.some((p) => p.provider === "github");
    const showGoogle = !loginProviders || loginProviders.some((p) => p.provider === "google");
    const customSSO = loginProviders?.find((p) => p.provider === "custom");
    const showContinueWithSSO = enableContinueWithSSO && !loginProviders;
    const showClassicAlternative = !loginProviders;

    return (
        <div id="default-sso-form" className={cn(className)}>
            {showGoogle && (
                <a
                    id="signin"
                    href={`/auth/oidc/start?returnTo=${encodeURIComponent(returnTo)}&issuer=https://accounts.google.com`}
                >
                    <Button
                        data-track-label="true"
                        size="lg"
                        variant={"secondary"}
                        LeadingIcon={IconGoogle}
                        className="w-full"
                    >
                        <span className="flex-grow text-center">Continue with Google</span>
                    </Button>
                </a>
            )}
            {showGithub && (
                <a
                    id="signin-github"
                    href={`/auth/oidc/start?returnTo=${encodeURIComponent(returnTo)}&issuer=github.com`}
                >
                    <Button
                        data-track-label="true"
                        size="lg"
                        variant={"secondary"}
                        LeadingIcon={IconGitHub}
                        className="w-full"
                    >
                        <span className="flex-grow text-center">Continue with GitHub</span>
                    </Button>
                </a>
            )}
            {showContinueWithSSO && (
                <Button
                    data-track-label="true"
                    size="lg"
                    variant="secondary"
                    LeadingIcon={IconKey}
                    className="w-full"
                    onClick={onContinueWithSSO}
                >
                    <span className="flex-grow text-center">Continue with SSO</span>
                </Button>
            )}
            {customSSO && (
                <a id="signin-custom" href={customSSO.loginUrl}>
                    <Button
                        data-track-label="true"
                        size="lg"
                        variant="secondary"
                        LeadingIcon={IconKey}
                        className="w-full"
                    >
                        <span className="flex-grow text-center">Continue with SSO</span>
                    </Button>
                </a>
            )}

            {showClassicAlternative && (
                <Button data-track-label="true" size="lg" variant={"link"} className="w-full" asChild>
                    <ExternalLink href="https://gitpod.io/login">Log in to Gitpod Classic</ExternalLink>
                </Button>
            )}
        </div>
    );
};
