import { IconTrash } from "@/assets/icons/geist/IconTrash";
import { Button } from "@/components/flexkit/Button";
import { cn } from "@/components/podkit/lib/cn";
import { Text } from "@/components/podkit/typography/Text";
import type { PlainRunner } from "@/queries/runner-queries";
import {
    routeForProgress,
    useRunnerConfigurationProgress,
} from "@/routes/onboarding/use-runner-configuration-progress";
import { useMemo, useState, type FC } from "react";
import { Link } from "react-router-dom";
import awsLogo from "@/assets/aws.svg";
import { type PlainOrganizationMember } from "@/queries/organization-queries";
import { DeleteRunnerModal } from "@/components/runners/DeleteRunnerModal";

export const ContinueWithExistingRunnerCard: FC<{
    runner: PlainRunner;
    creator?: PlainOrganizationMember;
    currentMember?: PlainOrganizationMember;
}> = ({ runner, creator, currentMember }) => {
    const [showDeletionModal, setShowDeletionModal] = useState(false);

    const { progress } = useRunnerConfigurationProgress(runner.runnerId);
    const to = routeForProgress(progress);
    const disabled = !to;

    const createdBy = useMemo(() => {
        if (currentMember?.userId === creator?.userId) {
            return "You";
        }

        if (creator?.fullName) {
            return creator?.fullName;
        }

        return;
    }, [creator, currentMember]);

    return (
        <>
            <div className="flex items-center justify-between gap-4 rounded-xl border-[0.5px] border-border-base px-5 py-4">
                {/* Content */}
                <div className="flex gap-4">
                    <img alt="Amazon AWS" src={awsLogo} className={"size-8"} />
                    <div className="flex flex-col">
                        <Text className="text-base font-bold text-content-primary">{runner.name}</Text>
                        {createdBy && (
                            <Text className="text-sm text-content-secondary">Setup started by {createdBy}</Text>
                        )}
                    </div>
                </div>
                {/* Buttons */}
                <div className="flex gap-2">
                    <Button variant="primary" disabled={disabled} asChild>
                        <Link
                            className={cn({ "pointer-events-none": disabled })}
                            to={to || ""}
                            aria-disabled={disabled}
                            aria-label={`Continue with ${runner.name}`}
                        >
                            Continue
                        </Link>
                    </Button>
                    <Button variant="secondary" LeadingIcon={IconTrash} onClick={() => setShowDeletionModal(true)} />
                </div>
            </div>
            {showDeletionModal && <DeleteRunnerModal runner={runner} onClose={() => setShowDeletionModal(false)} />}
        </>
    );
};
