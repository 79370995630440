import { Button } from "@/components/flexkit/Button";
import { Input } from "@/components/podkit/forms/Input";
import { Label } from "@/components/podkit/forms/Label";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { useCallback, useId, useState, type FC, type FormEvent } from "react";

import { useUpdateRunner, type PlainRunner } from "@/queries/runner-queries";
import { TrackLocations } from "@/hooks/use-segment";

type Props = {
    runner: PlainRunner;
    onClose: () => void;
};

export const RenameRunnerModal: FC<Props> = ({ runner, onClose }) => {
    const updateRunner = useUpdateRunner();

    const [newName, setNewName] = useState<string>();
    const name = newName || runner.name;

    const inputNameID = useId();

    const handleOpenChange = useCallback(
        (nextOpen: boolean) => {
            if (!nextOpen) {
                onClose();
            }
        },
        [onClose],
    );

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (!name) {
                return;
            }

            await updateRunner.mutateAsync({
                name,
                runnerId: runner.runnerId,
            });

            onClose();
        },
        [name, updateRunner, runner.runnerId, onClose],
    );

    return (
        <Dialog open onOpenChange={handleOpenChange}>
            <DialogContent className="max-w-[400px]" data-track-location={TrackLocations.RenameRunnerModal}>
                <DialogHeader>
                    <DialogTitle>Rename runner</DialogTitle>
                    <DialogDescription />
                </DialogHeader>

                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <div className="flex flex-col gap-2">
                            <div className="space-y-1">
                                <Label htmlFor={inputNameID}>Name</Label>
                                <Input
                                    id={inputNameID}
                                    type="text"
                                    name="runner_name"
                                    value={name || ""}
                                    onChange={(e) => setNewName(e.target.value)}
                                />
                            </div>
                        </div>
                    </DialogBody>

                    <DialogFooter className="sm:justify-end">
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Cancel
                            </Button>
                        </DialogClose>
                        <Button
                            loading={updateRunner.isPending}
                            autoFocus={true}
                            aria-label="Rename"
                            type="submit"
                            disabled={!name}
                            variant={"brand"}
                        >
                            Rename
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};
