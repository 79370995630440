import { type FC, useCallback, useMemo, useState } from "react";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { type PlainRunner, useLocalConfigurationRunner, useSetLocalRunnersEnabled } from "@/queries/runner-queries";
import { RunnerKind, RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { Text } from "@/components/podkit/typography/Text";
import { Heading3 } from "@/components/podkit/typography/Headings.tsx";
import { Collapsable } from "@/components/Collapsable.tsx";
import { Button } from "@/components/flexkit/Button.tsx";
import { cn } from "@/components/podkit/lib/cn.ts";
import { SourceControlProvider } from "@/components/runners/details/SourceControlProvider.tsx";
import { IconChevronRight } from "@/assets/icons/geist/IconChevronRight.tsx";
import { IconLaptop } from "@/assets/icons/geist/IconLaptop.tsx";
import { AllProviders } from "@/components/runners/details/source-control-providers.tsx";
import { TrackLocations } from "@/hooks/use-segment.ts";
import { type RunnerConfigurationSchema_SCMConfigSchema } from "gitpod-next-api/gitpod/v1/runner_configuration_pb.ts";
import { toast } from "@/components/podkit/toasts/use-toast.tsx";
import { formatError } from "@/utils/errors.ts";
import { IconGrid } from "@/assets/icons/geist/IconGrid.tsx";
import { IconCode } from "@/assets/icons/geist/IconCode.tsx";
import { useNavigate } from "react-router-dom";
import { useListProjects } from "@/queries/project-queries.ts";
import { useListEnvironmentInventory } from "@/queries/environment-queries.ts";
import { pluralizeWithCount } from "@/utils/strings.ts";
import { DisableLocalRunnersModal } from "@/components/runners/DisableLocalRunnersModal.tsx";
import { Toggle } from "@/components/Toggle.tsx";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb.ts";
import { useMembership } from "@/hooks/use-membership.ts";

export const LocalRunnerSettings: FC = () => {
    const { data: localConfigRunner, isPending: isPendingLocalConfigRunner } = useLocalConfigurationRunner();

    const { membership, isPending: isLoadingMembership } = useMembership();
    const ready = !isLoadingMembership && !isPendingLocalConfigRunner;
    const readOnly = !ready || membership?.userRole !== OrganizationRole.ADMIN;

    if (!ready) {
        return <LoadingState />;
    }

    if (!localConfigRunner) {
        return null;
    }

    return (
        <div data-testid="local-runner-settings" className="flex flex-col gap-6">
            <Heading3>Gitpod Desktop</Heading3>

            <LocalRunnerSettingsCard localConfigRunner={localConfigRunner} readOnly={readOnly} />
        </div>
    );
};

type LocalRunnerSettingsCardProps = {
    localConfigRunner: PlainRunner;
    readOnly: boolean;
};

const LocalRunnerSettingsCard = ({ localConfigRunner, readOnly }: LocalRunnerSettingsCardProps) => {
    const navigate = useNavigate();
    const [isExpanded, setIsExpanded] = useState(false);

    const schemas = useMemo(() => {
        // Only show PAT providers.
        return AllProviders.filter((p) => p.schema.pat).map((p) => p.schema);
    }, []);

    const { data: projectsData, isPending: isPendingProjects } = useListProjects();
    const numProjects = useMemo(() => {
        return projectsData?.projects.filter((p) => {
            return p.environmentClass?.environmentClass?.case === "localRunner";
        }).length ?? 0;
    }, [projectsData]);

    const {
        data: environmentsData,
        hasNextPage: hasNextPageEnvironments,
        isPending: isPendingEnvironments,
    } = useListEnvironmentInventory({
        runnerKind: RunnerKind.LOCAL,
    });
    const numEnvironments = useMemo(() => {
        return environmentsData?.pages.flatMap((page) => page.environments).length ?? 0;
    }, [environmentsData]);

    return (
        <div className="flex flex-col gap-4">
            <div className="rounded-xl border border-border-base">
                <div className="flex flex-col lg:flex-row lg:items-center justify-between p-6 gap-4 lg:gap-8">
                    <div className="flex items-center gap-4 min-w-0">
                        <IconLaptop size="xl" className="flex-shrink-0" />
                        <div className="flex flex-col gap-1 min-w-0">
                            <Text className="font-semibold">Allow local environments using Gitpod
                                Desktop</Text>
                            <Text className="text-sm text-content-secondary">
                                {readOnly ? "Organization admins can change these settings" : "This will affect all members using Gitpod Desktop on this organization"}
                            </Text>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center gap-4 lg:flex-nowrap">
                        <Text className="text-sm font-bold text-content-secondary whitespace-nowrap">Used by</Text>
                        <div className="flex items-center gap-4 flex-wrap sm:flex-nowrap">
                            <Button
                                LeadingIcon={IconGrid}
                                size="sm"
                                variant="secondary"
                                disabled={numProjects === 0}
                                loading={isPendingProjects}
                                onClick={() => navigate("/projects?environmentClass=local")}
                                className="w-full sm:w-auto"
                                data-testid="local-runner-settings-projects-button"
                            >
                                {pluralizeWithCount(numProjects, false, "Project")}
                            </Button>
                            <Button
                                LeadingIcon={IconCode}
                                size="sm"
                                variant="secondary"
                                disabled={numEnvironments === 0}
                                loading={isPendingEnvironments}
                                onClick={() => navigate("/settings/environments?runner=local")}
                                className="w-full sm:w-auto"
                                data-testid="local-runner-settings-environments-button"
                            >
                                {pluralizeWithCount(numEnvironments, hasNextPageEnvironments, "Environment")}
                            </Button>
                            <LocalRunnersToggleButton
                                localConfigRunner={localConfigRunner}
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                </div>
                <RepositoryAccessSettings
                    isExpanded={isExpanded}
                    setIsExpanded={setIsExpanded}
                    schemas={schemas}
                    localConfigRunner={localConfigRunner}
                />
            </div>
        </div>
    );
};

type RepositoryAccessSettingsProps = {
    isExpanded: boolean;
    setIsExpanded: (expanded: boolean) => void;
    schemas: RunnerConfigurationSchema_SCMConfigSchema[];
    localConfigRunner: PlainRunner;
};

const RepositoryAccessSettings: FC<RepositoryAccessSettingsProps> = ({
    isExpanded,
    setIsExpanded,
    schemas,
    localConfigRunner,
}) => {
    return (
        <div className="border-t border-border-base">
            <div className="flex flex-col">
                <div
                    className="flex w-full items-center justify-between p-4 hover:cursor-pointer"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <Text>Gitpod Desktop repository access settings</Text>
                    <Button
                        variant="ghost"
                        className="border-0 transition-transform hover:bg-transparent"
                        onClick={() => setIsExpanded(!isExpanded)}
                        LeadingIcon={(props) => (
                            <IconChevronRight className={cn(isExpanded && "rotate-90")} {...props} />
                        )}
                        aria-label={isExpanded ? "Collapse" : "Expand"}
                    />
                </div>
                <Collapsable collapsed={!isExpanded}>
                    <div className="p-4 pt-0">
                        <SourceControlProvider
                            runner={localConfigRunner}
                            hasStack={true}
                            withDescription={false}
                            schemas={schemas}
                            Container={({ children }) => <div className="flex flex-col gap-4">{children}</div>}
                            data-track-location={(modalState) =>
                                modalState.type == "add-provider"
                                    ? TrackLocations.AddLocalSCMProviderModal
                                    : modalState.type == "remove-provider"
                                        ? TrackLocations.DeleteLocalSCMProviderModal
                                        : TrackLocations.EditLocalSCMProviderModal
                            }
                        />
                    </div>
                </Collapsable>
            </div>
        </div>
    );
};

const LocalRunnersToggleButton: FC<{ localConfigRunner: PlainRunner, disabled: boolean }> = ({
    localConfigRunner,
    disabled,
}) => {
    const setLocalRunnersEnabled = useSetLocalRunnersEnabled(localConfigRunner);
    const enabled = localConfigRunner.spec?.desiredPhase === RunnerPhase.ACTIVE;

    const [showDisableLocalRunnersModal, setShowDisableLocalRunnersModal] = useState(false);

    const handleToggle = useCallback(async () => {
        if (enabled) {
            setShowDisableLocalRunnersModal(true);
        } else {
            try {
                await setLocalRunnersEnabled.mutateAsync({ enabled: true });
                toast({
                    title: "Enabled local environments",
                });
            } catch (error) {
                toast({
                    title: "Failed to enable local environments",
                    description: formatError(error),
                });
            }
        }
    }, [enabled, setLocalRunnersEnabled]);

    return (
        <>
            <Toggle
                state={setLocalRunnersEnabled.isPending ? "pending" : enabled ? "checked" : "unchecked"}
                hasFailed={false}
                disabled={disabled}
                onToggle={handleToggle}
                fixedWidth={true}
                id="local-runners-toggle"
            />
            {showDisableLocalRunnersModal &&
                <DisableLocalRunnersModal localConfigRunner={localConfigRunner}
                                          onClose={() => setShowDisableLocalRunnersModal(false)} />}
        </>
    );
};
