import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, type NavigateOptions, type To } from "react-router-dom";
import { initDB } from "@frecency/indexeddb-datasource";
import { useDebouncedCallback } from "use-debounce";

/**
 * useContextURL is a hook that provides a context URL from the hash of the current location
 *
 * @returns contextURL: the URL from the hash of the current location
 */
export function useContextURL() {
    const location = useLocation();
    const navigate = useNavigate();

    const [contextURL, setContextURL] = useState<string | undefined>();
    const [contextURLValid, setContextURLValid] = useState<boolean>(false);

    const debouncedNavigate = useDebouncedCallback((to: To, options?: NavigateOptions) => {
        navigate(to, options);
    }, 1000);

    const updateContextURL = useCallback(
        (newUrl: string, fromLocation = false) => {
            setContextURL((prev) => {
                if (prev === newUrl) return prev;
                if (!fromLocation) {
                    //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    debouncedNavigate({ hash: newUrl }, { replace: true, state: { ...location.state, fromLocation } });
                }
                return newUrl;
            });
            try {
                new URL(newUrl.toLocaleLowerCase());
                setContextURLValid(true);
            } catch {
                setContextURLValid(false);
            }
        },
        [debouncedNavigate, location.state],
    );

    useEffect(() => {
        const newUrl = (location.hash || "#").slice(1).trim();
        if (/^https?:\/\//.test(newUrl)) {
            updateContextURL(newUrl, true);
        }
    }, [location.hash, updateContextURL]);

    return {
        contextURL,
        /**
         * `setContext` must be a memoized function, i.e. a result of useCallback. Otherwise whenever `location.hash` changes
         * and the effect is running to sync the incoming change, it would notify the parent, which, because it's depending
         * on `setContextURL`, would re-render and effectively override the update.
         */
        setContextURL: updateContextURL,
        contextURLValid,
    };
}

const datasource = globalThis.indexedDB
    ? initDB("contexturls")
    : // No-op datasource if indexedDB is not available
      { visit: () => Promise.resolve(), list: () => Promise.resolve([]) };

export function useContextUrlRememberer() {
    return useMemo(
        () => ({
            remember: (url: string): void => {
                if (url) {
                    datasource.visit("contexturls", url).catch((e: Error) => {
                        console.error(e);
                    });
                }
            },
            listContextUrls: () => datasource.list("contexturls"),
        }),
        [],
    );
}
