import { useGetAccount } from "@/queries/account-queries";
import * as GitpodCookie from "@/gitpod-cookie";
import { useEffect } from "react";

export function useGitpodCookie() {
    const { data: account } = useGetAccount();
    useEffect(() => {
        if (account && !GitpodCookie.isCookiePresent()) {
            GitpodCookie.installCookie(window.location.href);
        }
    }, [account]);
}
