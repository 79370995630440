import { IconCheckFill } from "@/assets/icons/geist/IconCheckFill";
import { Text } from "@/components/podkit/typography/Text";
import flexCard from "@/assets/gitpod-flex-card.png";
import type { FC } from "react";

export const WebLoginSidebar: FC = () => {
    return (
        <div className="flex flex-col overflow-visible px-6 py-6 sm:overflow-y-auto lg:px-12">
            <div className="my-auto max-w-xl max-md:mt-4">
                <Text className="mb-2 text-3xl font-semibold text-content-primary">Gitpod Flex</Text>
                <Text className="mb-8 text-lg text-content-secondary">
                    Automate, standardize, and secure development environments.
                </Text>
                <div className="mb-8 flex flex-col gap-4">
                    <li className="flex items-center gap-3">
                        <IconCheckFill className="text-content-green" size="base" />
                        <Text className="text-content-secondary">
                            Self-host in your cloud account in under 3 minutes
                        </Text>
                    </li>
                    <li className="flex items-center gap-3">
                        <IconCheckFill className="text-content-green" size="base" />
                        <Text className="text-content-secondary">
                            Local environments available to replace Docker Desktop
                        </Text>
                    </li>
                    <li className="flex items-center gap-3">
                        <IconCheckFill className="text-content-green" size="base" />
                        <Text className="text-content-secondary">Automate common development workflows</Text>
                    </li>
                    <li className="flex items-center gap-3">
                        <IconCheckFill className="text-content-green" size="base" />
                        <Text className="text-content-secondary">Dev Container support</Text>
                    </li>
                </div>
                <img src={flexCard} alt="Gitpod Flex" className="w-full rounded-lg" />
            </div>
        </div>
    );
};
