import { useDocumentTitle } from "@/hooks/use-document-title";
import { useCallback, type FC } from "react";
import { MembersList } from "@/components/settings/organization/members/MembersList";
import { useMembers } from "@/queries/organization-queries";
import { useMembership } from "@/hooks/use-membership";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { useNavigate } from "react-router-dom";

export const MembersPage: FC = () => {
    useDocumentTitle("Members");
    const { data: members } = useMembers();
    const { data: user } = useAuthenticatedUser();

    const { membership } = useMembership();

    const navigate = useNavigate();

    const onInviteMembers = useCallback(() => {
        navigate("invite", { replace: true });
    }, [navigate]);

    return (
        <MembersList
            user={user}
            membership={membership}
            data={{
                members: members?.members || [],
                pagination: members?.pagination,
            }}
            onInviteMembers={onInviteMembers}
        />
    );
};
