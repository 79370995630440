import { TimelineElipsis } from "@/components/onboarding/TimelineElipsis";
import { TimelinePill } from "@/components/onboarding/TimelinePill";
import { phasesFromProgress, useProgressFromRoute } from "@/routes/onboarding/use-runner-configuration-progress";
import type { FC } from "react";

export const ConfigureRemoteRunnerTimeline: FC = () => {
    const progress = useProgressFromRoute();
    const phases = phasesFromProgress(progress);
    return (
        <div className="flex w-full flex-col">
            <div className="flex w-full items-center justify-center gap-2">
                {phases.map((phase, index) => (
                    <div key={phase.id} className="flex min-w-0 items-center gap-2">
                        <TimelinePill phase={phase} />
                        {index < phases.length - 1 && (
                            <TimelineElipsis className="block h-[2px] min-h-[2px] w-[14px] min-w-[14px] text-content-quaternary" />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
