import { IconSSO } from "@/assets/icons/geist/IconSSO";
import { Button } from "@/components/flexkit/Button";
import { Checkbox, type CheckedState } from "@/components/podkit/checkbox/Checkbox";
import {
    Dialog,
    DialogBody,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { TrackLocations } from "@/hooks/use-segment";
import { useUpdateSSOConfiguration } from "@/queries/organization-queries";
import { SetupSSOModal } from "@/routes/settings/login/SetupSSOModal";
import { formatError } from "@/utils/errors";
import * as Switch from "@radix-ui/react-switch";
import {
    SSOConfigurationState,
    UpdateSSOConfigurationRequest,
    type SSOConfiguration,
} from "gitpod-next-api/gitpod/v1/organization_pb";
import { useCallback, useState, type FC } from "react";

type Props = {
    organizationId: string;
    config?: SSOConfiguration;
    isCustom?: boolean;
    title: string;
    icon?: JSX.Element;
};
export const SSOConfigCard: FC<Props> = ({ organizationId, config, isCustom, title, icon }) => {
    const [showSetupSOO, setShowSetupSSO] = useState(false);

    return (
        <>
            <div className="flex h-[88px] w-[550px] flex-row gap-4 rounded-xl border border-border-base px-6 py-5">
                <div className="flex items-center">
                    {icon || <IconSSO className="size-10 text-content-secondary" />}
                </div>
                <div className="flex grow flex-col justify-center gap-1">
                    <Heading2>{title}</Heading2>
                    {config && <AllowNewSignupsCheckbox config={config} />}
                </div>
                <div className="flex items-center justify-end gap-4">
                    {isCustom && !config && (
                        <Button variant={"primary"} size={"md"} onClick={() => setShowSetupSSO(true)}>
                            Setup
                        </Button>
                    )}
                    {isCustom && config && (
                        <Button variant={"secondary"} size={"md"} onClick={() => setShowSetupSSO(true)}>
                            Edit
                        </Button>
                    )}
                    {config && <EnablementToggle title={title} config={config} />}
                </div>
            </div>

            {isCustom && showSetupSOO && (
                <SetupSSOModal organizationId={organizationId} config={config} onClose={() => setShowSetupSSO(false)} />
            )}
        </>
    );
};

const EnablementToggle: FC<{ config: SSOConfiguration; title: string }> = ({ config: { id, state }, title }) => {
    const updateSSOConfig = useUpdateSSOConfiguration();
    const { toast } = useToast();

    const [showModal, setShowModal] = useState(false);

    const isActive =
        state === SSOConfigurationState.SSO_CONFIGURATION_STATE_ACTIVE ||
        state === SSOConfigurationState.SSO_CONFIGURATION_STATE_ACTIVE_NO_SIGNUPS;

    const updateState = useCallback(
        async (state: SSOConfigurationState) => {
            try {
                await updateSSOConfig.mutateAsync(
                    new UpdateSSOConfigurationRequest({
                        ssoConfigurationId: id,
                        state,
                    }),
                );
            } catch (error) {
                toast({
                    title: "Failed to update SSO configuration",
                    description: formatError(error),
                });
            }
        },
        [id, toast, updateSSOConfig],
    );

    const handleCheckedChange = useCallback(async () => {
        if (!isActive) {
            await updateState(SSOConfigurationState.SSO_CONFIGURATION_STATE_ACTIVE);
        } else {
            setShowModal(true);
        }
    }, [isActive, updateState]);

    const handleSubmitDeactivation = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            await updateState(SSOConfigurationState.SSO_CONFIGURATION_STATE_INACTIVE);
            setShowModal(false);
        },
        [updateState],
    );

    return (
        <>
            <Switch.Root
                checked={isActive}
                disabled={false}
                onCheckedChange={handleCheckedChange}
                className={
                    "relative h-[25px] w-[42px] cursor-pointer rounded-full bg-surface-04 outline-none disabled:cursor-not-allowed data-[state=checked]:bg-content-green disabled:data-[state=checked]:bg-content-green/50"
                }
                id={"toggle-" + id}
            >
                <Switch.Thumb
                    className={
                        "flex size-5 translate-x-0.5 items-center justify-center gap-2 rounded-full bg-surface-pure shadow-black/10 transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[19px]"
                    }
                />
            </Switch.Root>
            {showModal && (
                <Dialog open onOpenChange={setShowModal}>
                    <DialogContent
                        className="max-w-[600px]"
                        data-testid="deactivate-form-modal-content"
                        data-track-location={TrackLocations.DeactivateSSOModal}
                    >
                        <DialogHeader>
                            <DialogTitle>Deactivating {title} log in </DialogTitle>
                            <DialogDescription />
                        </DialogHeader>

                        <DialogBody className="overflow-x max-w-full">
                            <form
                                id="deactivate-form"
                                onSubmit={handleSubmitDeactivation}
                                className="flex flex-col gap-4"
                            >
                                <Text className="text-base">Users will no longer be able to log in with {title}.</Text>
                                <Text className="text-base">
                                    They will need to create a new account using one of the other available log in
                                    methods.
                                </Text>
                            </form>
                        </DialogBody>

                        <DialogFooter className="sm:justify-end">
                            <DialogClose asChild>
                                <Button type="button" variant="secondary" autoFocus={true}>
                                    Cancel
                                </Button>
                            </DialogClose>
                            <Button
                                type="submit"
                                variant="destructive"
                                form="deactivate-form"
                                loading={updateSSOConfig.isPending}
                                disabled={updateSSOConfig.isPending}
                                data-testid="deactivate-submit-button"
                            >
                                Deactivate
                            </Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

const AllowNewSignupsCheckbox: FC<{ config: SSOConfiguration }> = ({ config }) => {
    const updateSSOConfig = useUpdateSSOConfiguration();
    const { toast } = useToast();

    const handleCheckedChange = useCallback(
        async (checkedState: CheckedState) => {
            if (config.state === SSOConfigurationState.SSO_CONFIGURATION_STATE_INACTIVE) {
                return;
            }

            try {
                await updateSSOConfig.mutateAsync(
                    new UpdateSSOConfigurationRequest({
                        ssoConfigurationId: config.id,
                        state:
                            checkedState === true
                                ? SSOConfigurationState.SSO_CONFIGURATION_STATE_ACTIVE_NO_SIGNUPS
                                : SSOConfigurationState.SSO_CONFIGURATION_STATE_ACTIVE,
                    }),
                );
            } catch (error) {
                toast({
                    title: "Failed to update SSO configuration",
                    description: formatError(error),
                });
            }
        },
        [config, toast, updateSSOConfig],
    );

    const elementId = "custom_sso_dont_allow_sign_ups" + config.id;

    return (
        <div className="flex">
            <label htmlFor={elementId} className="inline-flex items-center gap-2 text-base text-content-secondary">
                <Checkbox
                    id={elementId}
                    checked={config.state === SSOConfigurationState.SSO_CONFIGURATION_STATE_ACTIVE_NO_SIGNUPS}
                    disabled={config.state === SSOConfigurationState.SSO_CONFIGURATION_STATE_INACTIVE}
                    onCheckedChange={handleCheckedChange}
                />
                Don&apos;t allow new sign ups
            </label>
        </div>
    );
};
