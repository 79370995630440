import { NotAuthorized } from "@/components/NotAuthorized";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useOrganization } from "@/queries/organization-queries";
import { useMembership } from "@/hooks/use-membership";
import { OrganizationSettings } from "@/routes/settings/organization/manage-organization/OrganizationSettings";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import type { FC } from "react";

export const ManageOrganizationPage: FC = () => {
    useDocumentTitle("Manage Organization");

    const { data: organization, isLoading: isLoadingOrganization } = useOrganization();
    const { membership, isPending: isLoadingMembership } = useMembership();

    if (!organization && isLoadingOrganization) {
        return <LoadingState />;
    }

    if (!membership && isLoadingMembership) {
        return <LoadingState />;
    }

    if (membership?.userRole !== OrganizationRole.ADMIN) {
        return <NotAuthorized />;
    }

    return (
        <>
            <OrganizationSettings />
        </>
    );
};
