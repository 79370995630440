import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconInfo: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_808)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8 13.6875C11.1412 13.6875 13.6875 11.1412 13.6875 8C13.6875 4.85888 11.1412 2.3125 8 2.3125C4.85888 2.3125 2.3125 4.85888 2.3125 8C2.3125 11.1412 4.85888 13.6875 8 13.6875ZM8 15C11.866 15 15 11.866 15 8C15 4.134 11.866 1 8 1C4.134 1 1 4.134 1 8C1 11.866 4.134 15 8 15ZM6.46875 7.125H7.125H7.78124C8.26449 7.125 8.65624 7.51676 8.65624 8V11.0625V11.7188H7.34374V11.0625V8.4375H7.125H6.46875V7.125ZM8 6.25C8.48325 6.25 8.875 5.85824 8.875 5.375C8.875 4.89176 8.48325 4.5 8 4.5C7.51676 4.5 7.125 4.89176 7.125 5.375C7.125 5.85824 7.51676 6.25 8 6.25Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_7_808">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_811)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 13.5899 6.41015 16.5 10 16.5ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM8.25 9H9H9.74999C10.3023 9 10.75 9.44772 10.75 10V13.5V14.25H9.24999V13.5V10.5H9H8.25V9ZM10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_7_811">
                            <rect width="16" height="16" fill="white" transform="translate(2 2)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_7_814)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12 19.3125C16.0386 19.3125 19.3125 16.0386 19.3125 12C19.3125 7.96142 16.0386 4.6875 12 4.6875C7.96142 4.6875 4.6875 7.96142 4.6875 12C4.6875 16.0386 7.96142 19.3125 12 19.3125ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02943 16.9706 3 12 3C7.02943 3 3 7.02943 3 12C3 16.9706 7.02943 21 12 21ZM10.0312 10.875H10.875H11.7187C12.3401 10.875 12.8437 11.3787 12.8437 12V15.9375V16.7812H11.1562V15.9375V12.5625H10.875H10.0312V10.875ZM12 9.75C12.6213 9.75 13.125 9.24632 13.125 8.625C13.125 8.00368 12.6213 7.5 12 7.5C11.3787 7.5 10.875 8.00368 10.875 8.625C10.875 9.24632 11.3787 9.75 12 9.75Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_7_814">
                            <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
