import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconGrid: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_726_3)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.1875 5.8125V3.1875H5.8125V5.8125H3.1875ZM1.875 2.75C1.875 2.26675 2.26675 1.875 2.75 1.875H6.25C6.73324 1.875 7.125 2.26675 7.125 2.75V6.25C7.125 6.73324 6.73324 7.125 6.25 7.125H2.75C2.26675 7.125 1.875 6.73324 1.875 6.25V2.75ZM3.1875 12.8125V10.1875H5.8125V12.8125H3.1875ZM1.875 9.75C1.875 9.26676 2.26675 8.875 2.75 8.875H6.25C6.73324 8.875 7.125 9.26676 7.125 9.75V13.25C7.125 13.7333 6.73324 14.125 6.25 14.125H2.75C2.26675 14.125 1.875 13.7333 1.875 13.25V9.75ZM10.1875 3.1875V5.8125H12.8125V3.1875H10.1875ZM9.75 1.875C9.26676 1.875 8.875 2.26675 8.875 2.75V6.25C8.875 6.73324 9.26676 7.125 9.75 7.125H13.25C13.7333 7.125 14.125 6.73324 14.125 6.25V2.75C14.125 2.26675 13.7333 1.875 13.25 1.875H9.75ZM10.1875 12.8125V10.1875H12.8125V12.8125H10.1875ZM8.875 9.75C8.875 9.26676 9.26676 8.875 9.75 8.875H13.25C13.7333 8.875 14.125 9.26676 14.125 9.75V13.25C14.125 13.7333 13.7333 14.125 13.25 14.125H9.75C9.26676 14.125 8.875 13.7333 8.875 13.25V9.75Z"
                            fill="currentColor"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_726_3">
                            <rect width="14" height="14" fill="white" transform="translate(1 1)" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.5 7.5V4.5H7.5V7.5H4.5ZM3 4C3 3.44772 3.44772 3 4 3H8C8.55228 3 9 3.44772 9 4V8C9 8.55228 8.55228 9 8 9H4C3.44772 9 3 8.55228 3 8V4ZM4.5 15.5V12.5H7.5V15.5H4.5ZM3 12C3 11.4477 3.44772 11 4 11H8C8.55228 11 9 11.4477 9 12V16C9 16.5523 8.55228 17 8 17H4C3.44772 17 3 16.5523 3 16V12ZM12.5 4.5V7.5H15.5V4.5H12.5ZM12 3C11.4477 3 11 3.44772 11 4V8C11 8.55228 11.4477 9 12 9H16C16.5523 9 17 8.55228 17 8V4C17 3.44772 16.5523 3 16 3H12ZM12.5 15.5V12.5H15.5V15.5H12.5ZM11 12C11 11.4477 11.4477 11 12 11H16C16.5523 11 17 11.4477 17 12V16C17 16.5523 16.5523 17 16 17H12C11.4477 17 11 16.5523 11 16V12Z"
                        fill="currentColor"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.8125 9.1875V5.8125H9.1875V9.1875H5.8125ZM4.125 5.25C4.125 4.62868 4.62868 4.125 5.25 4.125H9.75C10.3713 4.125 10.875 4.62868 10.875 5.25V9.75C10.875 10.3713 10.3713 10.875 9.75 10.875H5.25C4.62868 10.875 4.125 10.3713 4.125 9.75V5.25ZM5.8125 18.1875V14.8125H9.1875V18.1875H5.8125ZM4.125 14.25C4.125 13.6287 4.62868 13.125 5.25 13.125H9.75C10.3713 13.125 10.875 13.6287 10.875 14.25V18.75C10.875 19.3713 10.3713 19.875 9.75 19.875H5.25C4.62868 19.875 4.125 19.3713 4.125 18.75V14.25ZM14.8125 5.8125V9.1875H18.1875V5.8125H14.8125ZM14.25 4.125C13.6287 4.125 13.125 4.62868 13.125 5.25V9.75C13.125 10.3713 13.6287 10.875 14.25 10.875H18.75C19.3713 10.875 19.875 10.3713 19.875 9.75V5.25C19.875 4.62868 19.3713 4.125 18.75 4.125H14.25ZM14.8125 18.1875V14.8125H18.1875V18.1875H14.8125ZM13.125 14.25C13.125 13.6287 13.6287 13.125 14.25 13.125H18.75C19.3713 13.125 19.875 13.6287 19.875 14.25V18.75C19.875 19.3713 19.3713 19.875 18.75 19.875H14.25C13.6287 19.875 13.125 19.3713 13.125 18.75V14.25Z"
                        fill="currentColor"
                    />
                </svg>
            );
    }
};
