import { type FC, useId, useState, startTransition, useMemo } from "react";
import { ComboboxProvider } from "@ariakit/react";
import * as SelectPrimitive from "@radix-ui/react-select";

import {
    SelectTrigger,
    SelectValue,
    SelectItemCombobox,
    SelectContentCombobox,
} from "@/components/podkit/select/Select";
import { cn } from "@/components/podkit/lib/cn";
import { Label } from "@/components/podkit/forms/Label";
import type { PlainRunnerEnvironmentClass } from "@/queries/environment-queries";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import type { EnvironmentTypeEntry } from "@/hooks/use-environment-types";

function selectionFromValue(envTypes: EnvironmentTypeEntry[], value: string): EnvironmentTypeEntry | undefined {
    const [runnerId, classId] = value.split(":");
    return envTypes.find((v) => v?.clazz?.id == classId && v?.clazz.runnerId == runnerId);
}
function valueFromSelection(selection: PlainRunnerEnvironmentClass): string {
    return `${selection.runnerId || "unknown"}:${selection?.id || "unknown"}`;
}

type Props = {
    envTypes: EnvironmentTypeEntry[];
    label: string;
    name: string;
    value?: EnvironmentTypeEntry;
    loading: boolean;
    disabled: boolean;
    onChange: (value: EnvironmentTypeEntry | undefined) => void;
};

export const EnvironmentTypeSelect: FC<Props> = (props) => {
    const elId = useId();

    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const hasAvailableTypes = props.envTypes.length > 0;

    const matches = useMemo(() => {
        if (!searchValue) return props.envTypes;

        const words = searchValue.split(" ");
        const matches = props.envTypes.filter((v) => {
            const label = `${v?.runner?.name || ""},${v?.clazz?.displayName}`.toLowerCase();
            return words.reduce((acc, word) => acc && label.includes(word), true);
        });

        // Radix Select does not work if we don't render the selected item, so we
        // make sure to include it in the list of matches.
        const vv = (props.value && valueFromSelection(props.value.clazz)) || "";
        if (props.value !== undefined) {
            const selectedEnvironmentType = props.envTypes.find((v) => valueFromSelection(v.clazz) == vv);
            if (selectedEnvironmentType && !matches.includes(selectedEnvironmentType)) {
                matches.push(selectedEnvironmentType);
            }
        }
        return matches;
    }, [props.envTypes, props.value, searchValue]);

    return (
        <div className="flex items-center space-x-2">
            <div className="flex w-full flex-col gap-2">
                <Label htmlFor={elId} className="font-medium">
                    {props.label}
                </Label>
                <SelectPrimitive.Root
                    value={props.value ? valueFromSelection(props.value.clazz) : undefined}
                    onValueChange={(s) => props.onChange(selectionFromValue(props.envTypes, s))}
                    open={open}
                    onOpenChange={setOpen}
                    disabled={props.loading || props.disabled || !hasAvailableTypes}
                >
                    <ComboboxProvider
                        open={open}
                        setOpen={setOpen}
                        resetValueOnHide
                        includesBaseElement={false}
                        setValue={(value) => {
                            startTransition(() => {
                                setSearchValue(value.toLowerCase());
                            });
                        }}
                    >
                        <SelectTrigger
                            id={elId}
                            data-testid="environment-class-select-trigger"
                            aria-label="Environment class"
                            loading={props.loading}
                            className="flex flex-row items-center justify-center"
                        >
                            {/* A <span style="pointer-events: none;" /> wraps our value if the item is disabled which breaks out layout
                                As we have no control over the span we instead style it using pseudo selectors ↓ */}
                            <div className="flex h-10 w-11/12 items-center [&>span]:flex [&>span]:w-full">
                                <SelectValue
                                    placeholder={
                                        <span className="flex flex-col justify-center">
                                            {hasAvailableTypes
                                                ? "Select an environment class"
                                                : "No available environment classes"}
                                        </span>
                                    }
                                />
                            </div>
                        </SelectTrigger>

                        {hasAvailableTypes && (
                            <SelectContentCombobox inputPlaceholder="Search environment classes">
                                {matches.map((envType) => {
                                    const offline = envType?.runner?.status?.phase !== RunnerPhase.ACTIVE;
                                    const id = valueFromSelection(envType.clazz);
                                    return (
                                        <SelectItemCombobox
                                            key={id}
                                            value={id}
                                            className={cn(
                                                "group w-full border-b border-border-light px-3 pl-2 pr-8 last:border-b-0",
                                                // Overwriting the default "disabled" behavior as we don't want to apply
                                                // opacity to the borders.
                                                "data-[disabled]:opacity-100",
                                            )}
                                            disabled={offline}
                                            asChild
                                        >
                                            <div className="flex w-full flex-row items-center justify-between">
                                                <div
                                                    className={cn(
                                                        "flex w-full flex-col items-start",
                                                        offline && "max-w-[85%]",
                                                    )}
                                                >
                                                    <div className="flex w-full gap-1">
                                                        <span
                                                            title={envType?.clazz?.displayName}
                                                            className="overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold text-content-primary group-data-[disabled]:text-content-tertiary"
                                                        >
                                                            {envType?.clazz?.displayName}
                                                        </span>
                                                        <span
                                                            className="overflow-hidden text-ellipsis whitespace-nowrap text-base text-content-tertiary"
                                                            title={envType?.runner?.name}
                                                        >
                                                            {envType?.runner?.name}
                                                        </span>
                                                    </div>
                                                    <div className="flex w-full gap-1">
                                                        <span
                                                            className="overflow-hidden text-ellipsis whitespace-nowrap text-left text-content-tertiary"
                                                            title={envType?.clazz?.description}
                                                        >
                                                            {envType?.clazz?.description}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    {offline && (
                                                        <span className="text-sm text-content-tertiary">Offline</span>
                                                    )}
                                                </div>
                                            </div>
                                        </SelectItemCombobox>
                                    );
                                })}
                            </SelectContentCombobox>
                        )}
                    </ComboboxProvider>
                </SelectPrimitive.Root>
            </div>
        </div>
    );
};
