import { useListRunnerSCMIntegrations } from "@/queries/runner-configuration-queries";
import { useRunner } from "@/queries/runner-queries";
import { RunnerPhase } from "gitpod-next-api/gitpod/v1/runner_pb";
import { useLocation, useParams } from "react-router-dom";

export type RunnerConfigurationProgress =
    | { id: "select-region" }
    | { id: "install-runner"; runnerId: string }
    | { id: "configure-scm"; runnerId: string }
    | { id: "configure-environment-classes"; runnerId: string };

type RunnerConfigurationProgressIds = RunnerConfigurationProgress["id"];

export type RunnerConfigurationPhase = {
    id: RunnerConfigurationProgressIds;
    label: string;
    state: "active" | "done" | "todo";
};

export type RunnerConfigurationPhaseState = RunnerConfigurationPhase["state"];

export function useRunnerConfigurationProgress(runnerId?: string): {
    isLoading: boolean;
    progress?: RunnerConfigurationProgress;
} {
    const { data: runner, isLoading: isLoadingRunner } = useRunner(runnerId);
    const { data: integrations, isLoading: isLoadingIntegrations } = useListRunnerSCMIntegrations(runner?.runnerId);

    if (runnerId && isLoadingRunner) {
        return {
            isLoading: true,
        };
    }

    if (!runner) {
        return {
            isLoading: false,
            progress: { id: "select-region" },
        };
    }

    if (isLoadingIntegrations) {
        return {
            isLoading: true,
        };
    }

    let progress: RunnerConfigurationProgress;
    if (runner?.status?.phase === RunnerPhase.CREATED) {
        progress = { id: "install-runner", runnerId: runner.runnerId };
    } else if (!integrations?.length) {
        progress = { id: "configure-scm", runnerId: runner.runnerId };
    } else {
        progress = { id: "configure-environment-classes", runnerId: runner.runnerId };
    }

    return {
        isLoading: false,
        progress,
    };
}

const phases: Omit<RunnerConfigurationPhase, "state">[] = [
    {
        id: "select-region",
        label: "Create runner",
    },
    {
        id: "install-runner",
        label: "Install runner",
    },
    {
        id: "configure-scm",
        label: "Configure Git provider",
    },
    {
        id: "configure-environment-classes",
        label: "Select environment Classes",
    },
];

export function phasesFromProgress(progress?: RunnerConfigurationProgress): RunnerConfigurationPhase[] {
    const activeIndex = phases.findIndex((phase) => phase.id === progress?.id);

    const result: RunnerConfigurationPhase[] = [];
    phases.forEach((phase, index) => {
        const state = index < activeIndex ? "done" : index === activeIndex ? "active" : "todo";
        result.push({
            ...phase,
            state,
        });
    });

    return result;
}

export function useProgressFromRoute(): RunnerConfigurationProgress {
    const location = useLocation();
    const params = useParams();
    const runnerId = params.runnerId;

    if (location.pathname === "/onboarding/set-up-runner/remote/configure/create") {
        return { id: "select-region" };
    }

    if (!runnerId) {
        return { id: "select-region" };
    }

    if (location.pathname.endsWith("/install")) {
        return { id: "install-runner", runnerId: runnerId };
    } else if (location.pathname.endsWith("/source-control-access")) {
        return { id: "configure-scm", runnerId: runnerId };
    } else if (location.pathname.endsWith("/environment-classes")) {
        return { id: "configure-environment-classes", runnerId: runnerId };
    } else {
        // Fallback to the first step
        return { id: "select-region" };
    }
}

export function routeForProgress(progress?: RunnerConfigurationProgress): string | undefined {
    if (!progress) {
        return;
    }

    switch (progress.id) {
        case "select-region":
            return "/onboarding/set-up-runner/remote/configure/create";
        case "install-runner":
            return `/onboarding/set-up-runner/remote/configure/${progress.runnerId}/install`;
        case "configure-scm":
            return `/onboarding/set-up-runner/remote/configure/${progress.runnerId}/source-control-access`;
        case "configure-environment-classes":
            return `/onboarding/set-up-runner/remote/configure/${progress.runnerId}/environment-classes`;
    }
}
