import { IconChevronRightSmall } from "@/assets/icons/geist/IconChevronRightSmall";
import { useEffect, type FC, type ReactNode } from "react";
import { Link, useMatches, useNavigate, type UIMatch } from "react-router-dom";

const Breadcrumbs: FC<{ label?: string | ReactNode }> = ({ label }) => {
    const matches = useMatches() as UIMatch<unknown, { label: string; pathname: string }>[];
    const crumbs = matches.filter((match) => Boolean(match.handle));

    const parents = crumbs.slice(0, -1);
    const current = crumbs[crumbs.length - 1];

    const navigate = useNavigate();
    const pathOfParentPage = parents.length > 0 && parents[parents.length - 1].pathname;

    // capture escape key navigate to the previous part of the breadcrumb
    useEffect(() => {
        if (!pathOfParentPage) {
            return;
        }
        const handleKeyDown = (e: KeyboardEvent) => {
            // We only want to handle the event if no other element is focused
            if (document.activeElement?.nodeName !== "BODY") {
                return;
            }

            if (e.key === "Escape") {
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
                navigate(pathOfParentPage);
            }
        };
        window.addEventListener("keydown", handleKeyDown, { capture: true });
        return () => {
            window.removeEventListener("keydown", handleKeyDown, { capture: true });
        };
    }, [navigate, pathOfParentPage, current]);

    return (
        <ol className="flex flex-row items-center gap-2 text-lg font-bold">
            {parents.map((crumb, index) => (
                <li
                    key={index}
                    className="flex flex-row items-center text-2xl font-semibold tracking-[-0.2px] text-content-primary"
                >
                    <Link to={crumb.pathname}>{crumb?.handle?.label}</Link>
                    {index != crumbs.length - 1 ? (
                        <IconChevronRightSmall size="lg" className="ml-2 text-content-tertiary" />
                    ) : null}
                </li>
            ))}
            <li className="text-2xl font-semibold tracking-[-0.2px] text-content-primary">
                {current?.handle?.label ?? label}
            </li>
        </ol>
    );
};

export default Breadcrumbs;
