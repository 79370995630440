import type { FC } from "react";

/**
 * This component is intended to be used in the Desktop Application where we use a custom title bar.
 * The component ensures that the window can still be dragged around.
 */
export const DesktopTitlebar: FC = () => {
    return (
        <div className="fixed h-8 w-full">
            <div className="h-8 w-full [-webkit-app-region:drag]" />
        </div>
    );
};
