import { useDocumentTitle } from "@/hooks/use-document-title";
import { type FC } from "react";
import { RunnersList } from "@/components/runners/RunnersList";
import { RunnerKind } from "gitpod-next-api/gitpod/v1/runner_pb";
import type { UseListRunnersParams } from "@/queries/runner-queries";
import { LocalRunnerSettings } from "@/components/runners/LocalRunnerSettings.tsx";
import { Text } from "@/components/podkit/typography/Text.tsx";
import { Heading3 } from "@/components/podkit/typography/Headings.tsx";

const filter: UseListRunnersParams = {
    kind: RunnerKind.REMOTE,
};

export const RunnersPage: FC = () => {
    useDocumentTitle("Runners");

    return (
        <div className="flex flex-col gap-6">
            <Text className="text-base text-content-secondary">
                Manage where environments run for your organization.
            </Text>

            <LocalRunnerSettings />

            <Heading3>Self-hosted runners</Heading3>
            <RunnersList filter={filter} />
        </div>
    );
};
