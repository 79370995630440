import { withUser, GitpodAPI } from "@/api";
import { showNotification } from "@/desktop/RendererNotifications";
import { getPrincipal, onDidChangePrincipal } from "@/principal";
import { bindIPCService, createIPCProxy } from "frontend-shared/ipc";
import {
    appMainType,
    localRunnerMainType,
    localRunnerRendererType,
    type CreateRunnerParam,
    type CreateRunnerTokenParam,
    type DeleteRunnerParam,
    type DidChangeUserEvent,
} from "frontend-shared/local-runner";
import { notificationRendererType } from "frontend-shared/notification";

/**
 * window.ipcRenderer is set by Electron in our preload script, see frontend/desktop/electron/preload.ts
 * You should not rely on window.ipcRenderer outside of this file, instead use the useDesktop hook.
 **/
if (window.ipcRenderer) {
    bindIPCService(localRunnerRendererType, window.ipcRenderer, {
        onDidChangeUser: (callback: (param: DidChangeUserEvent) => void) => {
            const listener = () => {
                const principal = getPrincipal();
                let userId: string | undefined;
                if (principal) {
                    userId = principal;
                }
                callback({ userId });
            };
            listener();
            return onDidChangePrincipal(listener);
        },
        createRunner: async (param: CreateRunnerParam, signal?: AbortSignal) => {
            const response = await GitpodAPI.runnerService.createRunner(
                {
                    name: param.runnerName,
                },
                withUser({ signal }, param.userId),
            );
            return {
                runnerId: response.runner?.runnerId || "unknown",
                token: response.accessToken,
                endpoint: `${window.location.origin}/api`,
            };
        },
        createRunnerToken: async (param: CreateRunnerTokenParam, signal?: AbortSignal) => {
            const response = await GitpodAPI.runnerService.createRunnerToken(
                {
                    runnerId: param.runnerId,
                },
                withUser({ signal }, param.userId),
            );
            return {
                token: response.accessToken,
            };
        },
        deleteRunner: async (param: DeleteRunnerParam, signal?: AbortSignal) => {
            await GitpodAPI.runnerService.deleteRunner(
                {
                    runnerId: param.runnerId,
                    force: param.force,
                },
                withUser({ signal }, param.userId),
            );
            return;
        },
    });
    bindIPCService(notificationRendererType, window.ipcRenderer, {
        show: showNotification,
    });
}

export const runnerMainService = window.ipcRenderer && createIPCProxy(localRunnerMainType, window.ipcRenderer);
export type RunnerMainService = typeof runnerMainService;

export const appMainService = window.ipcRenderer && createIPCProxy(appMainType, window.ipcRenderer);
