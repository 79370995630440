import { ErrorMessage } from "@/components/ErrorMessage";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useRunner } from "@/queries/runner-queries";
import { Text } from "@/components/podkit/typography/Text";
import type { FC } from "react";
import { useParams } from "react-router-dom";
import { RunnerDetails } from "@/components/runners/details/RunnerDetails";
import { IconWarning } from "@/assets/icons/geist/IconWarning";
import { Button } from "@/components/flexkit/Button";
import { IconRefresh } from "@/assets/icons/geist/IconRefresh";
import { Code } from "@connectrpc/connect";

export const RunnerDetailsPage: FC<{ runnerId?: string }> = (p) => {
    const params = useParams();
    useDocumentTitle("Runner - Details");

    const runnerId = p.runnerId ?? params.runnerId;

    const { data: runner, error, refetch } = useRunner(runnerId);

    if (error) {
        return <FailedToLoadRunner runnerId={runnerId} error={error} onRefresh={refetch} />;
    }

    return <RunnerDetails runner={runner} />;
};

const FailedToLoadRunner: FC<{ runnerId?: string; error: Error; onRefresh: () => void }> = ({
    runnerId,
    error,
    onRefresh,
}) => {
    return (
        <div className="flex min-h-72 flex-col items-center justify-center gap-4 rounded-xl border border-border-light bg-surface-primary">
            <div className="flex flex-col items-center gap-1">
                <IconWarning size="lg" className="text-content-negative" />
                <Text className="text-lg font-bold text-content-negative">
                    We&#x2019;re having trouble loading this runner.
                </Text>
                <Text className="text-center text-base text-content-negative">
                    You can try refreshing the page. If the problem persists, please contact support.
                </Text>
                <Text className="text-sm text-content-negative">Runner ID: {runnerId}</Text>
                <ErrorMessage
                    error={error}
                    className="text-sm"
                    messages={{
                        [Code.InvalidArgument]: "Invalid runner ID",
                    }}
                />
            </div>
            <div>
                <Button type="button" variant="secondary" onClick={onRefresh}>
                    <IconRefresh size="base" />
                    Refresh
                </Button>
            </div>
        </div>
    );
};
