import { useEffect, useMemo, useState, type FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { settingsRoutes } from "@/app";
import { IconSettings } from "@/assets/icons/geist/IconSettings";
import { Collapsable } from "@/components/Collapsable";
import { SettingsMenu } from "@/components/settings/SettingsMenu";
import { SidebarButton } from "@/components/SidebarButton";
import { type MenuItem, populateMenuFromRoute } from "@/menu-item";
import { cn } from "@/components/podkit/lib/cn";
import { useOpenSettingsShortcut } from "@/hooks/use-open-settings-shortcut";

export const SidebarSectionSettings: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(!location.pathname.startsWith("/settings"));

    useOpenSettingsShortcut(() => navigate("/settings/profile"));

    // If the user navigates to a settings page we want to auto-expand the section
    useEffect(() => {
        if (location.pathname.startsWith("/settings")) {
            setCollapsed(false);
        }
    }, [location]);

    const menu: MenuItem[] = useMemo(() => populateMenuFromRoute(settingsRoutes), []);

    return (
        <div className="flex w-full flex-col">
            <SidebarButton
                icon={<IconSettings size="lg" className={cn("transition-all", collapsed ? "rotate-0" : "rotate-45")} />}
                label={"Settings"}
                onClick={() => setCollapsed((prev) => !prev)}
            />
            <Collapsable collapsed={collapsed}>
                <SettingsMenu menu={menu} />
            </Collapsable>
        </div>
    );
};
