import { useCallback, useState, type FC, type FormEvent } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Button } from "@/components/flexkit/Button";
import {
    useCreateProjectPolicy,
    useDeleteProjectPolicy,
    type PlainProject,
    type ProjectState,
} from "@/queries/project-queries";
import { ProjectSharingType } from "@/components/projects/ProjectConstants";
import { RadioGroup, RadioGroupItem } from "@/components/podkit/forms/RadioListField";
import { Text } from "@/components/podkit/typography/Text";
import { Label } from "@/components/podkit/forms/Label";
import { ProjectRole } from "gitpod-next-api/gitpod/v1/project_pb";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";

export const ProjectSharing: FC<{ project: PlainProject; state: ProjectState }> = ({ project, state }) => {
    const { toast } = useToast();

    const createProjectPolicy = useCreateProjectPolicy();
    const deleteProjectPolicy = useDeleteProjectPolicy();

    const [sharingValue, setSharingValue] = useState<string>(
        state.shared ? ProjectSharingType.AnyoneInOrg : ProjectSharingType.OnlyMe,
    );

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            try {
                if (sharingValue === (ProjectSharingType.AnyoneInOrg as string) && !state.shared) {
                    await createProjectPolicy.mutateAsync({
                        projectId: project.id,
                        groupId: state.orgMembersGroupId,
                        role: ProjectRole.USER,
                    });
                } else if (sharingValue === (ProjectSharingType.OnlyMe as string) && state.shared) {
                    await deleteProjectPolicy.mutateAsync({ projectId: project.id, groupId: state.orgMembersGroupId });
                }
                toast({
                    title: "Sharing setting updated",
                });
            } catch (error) {
                toast({
                    title: "Failed to update sharing setting",
                    description: formatError(error),
                });
            }
        },
        [
            createProjectPolicy,
            deleteProjectPolicy,
            project.id,
            sharingValue,
            state.orgMembersGroupId,
            state.shared,
            toast,
        ],
    );

    const isPending = createProjectPolicy.isPending || deleteProjectPolicy.isPending;
    const formChanged =
        (sharingValue === (ProjectSharingType.AnyoneInOrg as string) && !state.shared) ||
        (sharingValue === (ProjectSharingType.OnlyMe as string) && state.shared);

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-6" data-testid="project-sharing-form">
            <RadioGroup value={sharingValue} onValueChange={setSharingValue} className={"flex flex-col gap-2"}>
                <div className="flex flex-col gap-2">
                    <Heading2 className="text-base font-bold text-content-primary">Sharing</Heading2>
                    <Text className="text-base font-bold text-content-secondary">Who can use this project?</Text>
                </div>
                <Label className="flex gap-2">
                    <RadioGroupItem
                        value={ProjectSharingType.OnlyMe}
                        data-testid={`option-${ProjectSharingType.OnlyMe}`}
                    />
                    <span className="text-base">Only organization admins</span>
                </Label>
                <Label className="flex gap-2">
                    <RadioGroupItem
                        value={ProjectSharingType.AnyoneInOrg}
                        data-testid={`option-${ProjectSharingType.AnyoneInOrg}`}
                    />
                    <span className="text-base">Everyone in the organization</span>
                </Label>
            </RadioGroup>

            <Button
                className="max-w-fit"
                type="submit"
                variant="primary"
                loading={isPending}
                disabled={!formChanged}
                data-testid="save-sharing-changes-button"
            >
                Save Changes
            </Button>
        </form>
    );
};
