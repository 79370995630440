import { AuthenticatedUserLayout } from "@/components/AuthenticatedUserLayout";
import { LoginLayout } from "@/components/login/LoginLayout";
import { OnboardingSidebar } from "@/components/onboarding/OnboardingSidebar";
import { PageSuspense } from "@/components/PageSuspense";
import { RunnerDetailsPage } from "@/components/runners/details/RunnerDetailsPage";
import { SettingsPageLayout } from "@/components/settings/SettingsPageLayout";
import { NoSidebar, SidebarLayout } from "@/components/SidebarLayout";
import { Flags } from "@/feature-flags";
import { useGitpodCookie } from "@/hooks/use-gitpod-cookie";
import { useLoaded } from "@/hooks/use-loaded";
import { useNoMinWidth } from "@/hooks/use-no-min-width";
import { useButtonOrAnchorTracking } from "@/hooks/use-segment";
import { type MenuItem } from "@/menu-item";
import { CreateOrganizationPage } from "@/routes/create-organization/CreateOrganizationPage";
import { createEnvironmentDetailsRoute } from "@/routes/environments/details/EnvironmentDetailsRoute";
import { JOIN_ORGANIZATION_PATH } from "@/routes/join-organization/join-organization";
import { JoinOrganizationPage } from "@/routes/join-organization/JoinOrganizationPage";
import { LocalRunner, QuitApp } from "@/routes/local-runner/LocalRunner";
import LoginPage from "@/routes/login/LoginPage";
import { NotFound } from "@/routes/NotFound";
import { FlexUpgradeModal } from "@/components/onboarding/FlexUpgradeModal";
import { HowGitpodWorksPage } from "@/routes/onboarding/HowGitpodWorksPage";
import { OnboardingLayout } from "@/components/onboarding/OnboardingLayout";
import { OnboardingQuestionnairePage } from "@/routes/onboarding/signup/OnboardingQuestionnairePage";
import { SelectAProviderPage } from "@/routes/onboarding/remote/SelectAProviderPage";
import { SelectARunnerPage } from "@/routes/onboarding/SelectRunnerPage";
import { projectRoutes } from "@/routes/projects/project-routes";
import { LoginAndSecurityPage } from "@/routes/settings/login/LoginAndSecurityPage";
import { EnvironmentInventoryPage } from "@/routes/settings/organization/environment-inventory/EnvironmentInventoryPage";
import { ManageOrganizationPage } from "@/routes/settings/organization/manage-organization/ManageOrganizationPage";
import { InviteMembersPage } from "@/routes/settings/organization/members/InviteMembersPage";
import { MembersPage } from "@/routes/settings/organization/members/MembersPage";
import { RunnersPage } from "@/routes/settings/organization/runners/RunnersPage";
import { GitAuthenticationsPage } from "@/routes/settings/user/git-authentications/GitAuthenticationsPage";
import { PersonalAccessTokensPage } from "@/routes/settings/user/personal-access-tokens/PersonalAccessTokensPage";
import { UserProfilePage } from "@/routes/settings/user/profile/UserProfilePage";
import { SigninDesktopPage } from "@/routes/signin-desktop/SigninDesktopPage";
import { type FC, type PropsWithChildren } from "react";
import { createBrowserRouter, createMemoryRouter, Navigate, RouterProvider, type RouteObject } from "react-router-dom";
import { AutomateYourDevEnvironmentPage } from "@/routes/onboarding/AutomateYourDevEnvironmentPage";
import { ShareWithYourTeamPage } from "@/routes/onboarding/ShareWithYourTeamPage";
import { OnboardingRedirect } from "@/routes/onboarding/OnboardingRedirect";
import { InstallRunnerPage } from "@/routes/onboarding/remote/InstallRunnerPage";
import { ConfigureSCMPage } from "@/routes/onboarding/remote/ConfigureSCMPage";
import { ConfigureEnvironmentClassesPage } from "@/routes/onboarding/remote/ConfigureEnvironmentClassesPage";
import { ConfigureRemoteRunnerLayout } from "@/components/onboarding/ConfigureRemoteRunnerLayout";
import { CreateRunnerPage } from "@/routes/onboarding/remote/CreateRunnerPage";
import { GitpodDesktopPage } from "@/routes/onboarding/GitpodDesktopPage";

export const App: FC = () => {
    useLoaded();
    useButtonOrAnchorTracking();
    useGitpodCookie();
    return <RouterProvider router={NewSidebarRouter} />;
};

const NoMinWidth: FC<PropsWithChildren> = ({ children }) => {
    useNoMinWidth();
    return children;
};

// eslint-disable-next-line react-refresh/only-export-components
export const settingsRoutes: RouteObject = {
    element: <SettingsPageLayout />,
    path: "/settings",
    children: [
        {
            index: true,
            handle: {},
            element: <Navigate to={"/settings/runners"} replace={true} />,
        },
        {
            // /settings/preferences is deprecated, redirect to /settings/profile
            path: "preferences",
            element: <Navigate to={"/settings/profile"} replace={true} />,
        },
        {
            path: "profile",
            element: <UserProfilePage />,
            handle: {
                label: "Profile",
                menuItem: {
                    label: "Profile",
                    section: "My account",
                    to: "/settings/profile",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
        },
        {
            path: "git-authentications",
            element: <GitAuthenticationsPage />,
            handle: {
                label: "Git authentications",
                menuItem: {
                    label: "Git authentications",
                    section: "My account",
                    to: "/settings/git-authentications",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
        },
        {
            path: "personal-access-tokens",
            element: <PersonalAccessTokensPage />,
            handle: {
                label: "Personal access tokens",
                menuItem: {
                    label: "Personal access tokens",
                    section: "My account",
                    to: "/settings/personal-access-tokens",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
        },
        {
            path: "manage-organization",
            element: <ManageOrganizationPage />,
            handle: {
                label: "General",
                menuItem: {
                    label: "General",
                    section: "Organization",
                    to: "/settings/manage-organization",
                    isAdminRole: true,
                } satisfies MenuItem,
            },
        },
        {
            path: "members",
            handle: {
                label: "Members",
                menuItem: {
                    label: "Members",
                    section: "Organization",
                    to: "/settings/members",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
            children: [
                {
                    index: true,
                    element: <MembersPage />,
                },
                {
                    path: "invite",
                    element: <InviteMembersPage />,
                    handle: {
                        label: "Invite members",
                    },
                },
            ],
        },
        {
            path: "runners",
            handle: {
                label: "Runners",
                menuItem: {
                    label: "Runners",
                    section: "Organization",
                    to: "/settings/runners",
                    isAdminRole: false,
                } satisfies MenuItem,
            },
            children: [
                {
                    index: true,
                    element: <RunnersPage />,
                },
                {
                    path: ":runnerId",
                    element: <RunnerDetailsPage />,
                    handle: {
                        label: "Details",
                    },
                },
            ],
        },
        {
            path: "environments",
            element: <EnvironmentInventoryPage />,
            handle: {
                label: "Environments",
                menuItem: {
                    label: "Environments",
                    section: "Organization",
                    to: "/settings/environments",
                    isAdminRole: true,
                } satisfies MenuItem,
            },
        },
        {
            path: "login",
            element: <LoginAndSecurityPage />,
            handle: {
                label: "Log in and security",
                menuItem: {
                    label: "Log in and security",
                    section: "Organization",
                    to: "/settings/login",
                    isAdminRole: true,
                    featureFlag: Flags.LoginAndSecurityEnabled,
                } satisfies MenuItem,
            },
        },
    ],
};

// eslint-disable-next-line react-refresh/only-export-components
export const onboardingRoutes: RouteObject = {
    element: <OnboardingLayout />,
    path: "/onboarding",
    children: [
        // Step 1
        {
            path: "how-gitpod-works",
            element: <HowGitpodWorksPage />,
        },
        // Step 2
        {
            path: "set-up-runner",
            children: [
                {
                    index: true,
                    element: <SelectARunnerPage />,
                },
                {
                    path: "desktop",
                    element: <GitpodDesktopPage />,
                },
                {
                    path: "remote",
                    children: [
                        {
                            path: "provider",
                            element: <SelectAProviderPage />,
                        },
                        {
                            path: "configure",
                            element: <ConfigureRemoteRunnerLayout />,
                            children: [
                                {
                                    path: "create",
                                    element: <CreateRunnerPage />,
                                },
                                {
                                    path: ":runnerId",
                                    children: [
                                        {
                                            path: "install",
                                            element: <InstallRunnerPage />,
                                        },
                                        {
                                            path: "source-control-access",
                                            element: <ConfigureSCMPage />,
                                        },
                                        {
                                            path: "environment-classes",
                                            element: <ConfigureEnvironmentClassesPage />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        // Step 3
        {
            path: "automate-your-dev-environment",
            element: <AutomateYourDevEnvironmentPage />,
        },
        // Step 4
        {
            path: "share-with-your-team",
            element: <ShareWithYourTeamPage />,
        },
        // Backwards compatibility with the old URLs
        {
            path: "enabler",
            element: <OnboardingRedirect />,
        },
    ],
};

// eslint-disable-next-line react-refresh/only-export-components
export const appRoutes: RouteObject[] = [
    {
        // Pages using Sidebar
        element: <SidebarLayout />,
        children: [
            {
                element: (
                    <>
                        <AuthenticatedUserLayout />
                        <FlexUpgradeModal />
                    </>
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <Navigate
                                to={{ pathname: "/projects", hash: document.location.hash }}
                                replace
                                state={{ showCreateModal: !!document.location.hash }}
                            />
                        ),
                    },
                    settingsRoutes,
                    projectRoutes,
                    createEnvironmentDetailsRoute(),
                    onboardingRoutes,
                    {
                        path: "create",
                        element: <Navigate to="/projects" replace state={{ showCreateModal: true }} />,
                    },
                ],
            },
        ],
    },
    // Onboarding pages using custom sidebar
    {
        element: <SidebarLayout customSidebar={<OnboardingSidebar />} />,
        children: [
            {
                path: "/",
                element: <AuthenticatedUserLayout />,
                children: [
                    {
                        path: "/create-organization",
                        element: (
                            <PageSuspense>
                                <CreateOrganizationPage />
                            </PageSuspense>
                        ),
                    },
                    {
                        path: "/onboarding/primary-interests",
                        element: <OnboardingQuestionnairePage />,
                        handle: {
                            label: "Primary Interests",
                        },
                    },
                ],
            },
        ],
    },
    // Pages without a Sidebar
    {
        element: <SidebarLayout customSidebar={<NoSidebar />} />,
        children: [
            {
                path: "/",
                element: <AuthenticatedUserLayout />,
                children: [
                    {
                        path: `${JOIN_ORGANIZATION_PATH}/:inviteId?`,
                        element: (
                            <PageSuspense>
                                <JoinOrganizationPage />
                            </PageSuspense>
                        ),
                    },
                ],
            },
        ],
    },
    // UNAUTHENTICATED ROUTES
    {
        element: (
            <NoMinWidth>
                <LoginLayout />
            </NoMinWidth>
        ),
        children: [
            {
                path: "/login",
                element: (
                    <PageSuspense>
                        <LoginPage />
                    </PageSuspense>
                ),
            },
        ],
    },
    // Pages without a Sidebar
    {
        element: (
            <NoMinWidth>
                <SidebarLayout customSidebar={<NoSidebar />} />
            </NoMinWidth>
        ),
        children: [
            {
                path: "/confirm/signin",
                element: (
                    <PageSuspense>
                        <SigninDesktopPage />
                    </PageSuspense>
                ),
            },
            {
                path: "/local-runner",
                element: (
                    <PageSuspense>
                        <LocalRunner />
                    </PageSuspense>
                ),
            },
            {
                path: "/quit-app",
                element: (
                    <PageSuspense>
                        <QuitApp />
                    </PageSuspense>
                ),
            },
            {
                path: "*",
                element: <NotFound />,
            },
        ],
    },
];

export const NewSidebarRouter = createBrowserRouter(appRoutes);
export const CreateTestRouter = (opts?: { initialEntries?: string[] }) => createMemoryRouter(appRoutes, opts);
