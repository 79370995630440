import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconApp: FC<{ size: Size } & PropsWithClassName> = ({ className }) => {
    return (
        <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect y="-0.00012207" width="44" height="44" rx="11" fill="url(#paint0_radial_1240_14665)" />
            <rect
                x="0.25"
                y="0.249878"
                width="43.5"
                height="43.5"
                rx="10.75"
                stroke="url(#paint1_linear_1240_14665)"
                strokeOpacity="0.1"
                strokeWidth="0.5"
            />
            <rect
                x="3.6665"
                y="3.20831"
                width="36.6667"
                height="36.6667"
                rx="7.79167"
                fill="url(#paint2_linear_1240_14665)"
                fillOpacity="0.95"
            />
            <rect
                x="3.20817"
                y="2.74998"
                width="37.5833"
                height="37.5833"
                rx="8.25"
                stroke="url(#paint3_linear_1240_14665)"
                strokeOpacity="0.5"
                strokeWidth="0.916667"
            />
            <g filter="url(#filter0_ddi_1240_14665)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.4528 10.3778C25.1062 11.5301 24.7089 12.9979 23.5654 13.6563L16.4555 17.7503C16.2676 17.8585 16.1516 18.0601 16.1516 18.2784V24.7051C16.1516 24.9234 16.2676 25.1249 16.4555 25.2331L22.0835 28.4738C22.2687 28.5805 22.4959 28.5805 22.6811 28.4738L28.3092 25.2331C28.4971 25.1249 28.6131 24.9234 28.6131 24.7051V20.7082L23.5541 23.5845C22.4071 24.2366 20.9527 23.8283 20.3055 22.6725C19.6583 21.5167 20.0635 20.0511 21.2105 19.3989L28.4492 15.2833C30.6542 14.0297 33.3823 15.6351 33.3823 18.1862V25.1931C33.3823 26.8342 32.5096 28.3496 31.0956 29.1638L24.634 32.8844C23.2388 33.6879 21.5259 33.6879 20.1306 32.8844L13.6691 29.1638C12.255 28.3496 11.3823 26.8342 11.3823 25.1931V17.7904C11.3823 16.1492 12.255 14.6339 13.6691 13.8197L21.1992 9.4837C22.3427 8.82528 23.7993 9.22559 24.4528 10.3778Z"
                    fill="url(#paint4_linear_1240_14665)"
                />
            </g>
            <g filter="url(#filter1_i_1240_14665)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.4528 10.3778C25.1062 11.5301 24.7089 12.9979 23.5654 13.6563L16.4555 17.7503C16.2676 17.8585 16.1516 18.0601 16.1516 18.2784V24.7051C16.1516 24.9234 16.2676 25.1249 16.4555 25.2331L22.0835 28.4738C22.2687 28.5805 22.4959 28.5805 22.6811 28.4738L28.3092 25.2331C28.4971 25.1249 28.6131 24.9234 28.6131 24.7051V20.7082L23.5541 23.5845C22.4071 24.2366 20.9527 23.8283 20.3055 22.6725C19.6583 21.5167 20.0635 20.0511 21.2105 19.399L28.4492 15.2833C30.6542 14.0297 33.3823 15.6351 33.3823 18.1862V25.1931C33.3823 26.8342 32.5096 28.3496 31.0956 29.1638L24.634 32.8844C23.2388 33.6879 21.5259 33.6879 20.1306 32.8844L13.6691 29.1638C12.255 28.3496 11.3823 26.8342 11.3823 25.1931V17.7904C11.3823 16.1492 12.255 14.6339 13.6691 13.8197L21.1992 9.4837C22.3427 8.82528 23.7993 9.22559 24.4528 10.3778Z"
                    fill="url(#pattern0_1240_14665)"
                    fillOpacity="0.5"
                    style={{ mixBlendMode: "soft-light" }}
                />
            </g>
            <defs>
                <filter
                    id="filter0_ddi_1240_14665"
                    x="9.31982"
                    y="7.10419"
                    width="26.125"
                    height="28.4453"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.171875" />
                    <feGaussianBlur stdDeviation="0.214844" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1240_14665" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="1.03125" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_1240_14665" result="effect2_dropShadow_1240_14665" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1240_14665" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.6875" />
                    <feGaussianBlur stdDeviation="0.515625" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1240_14665" />
                </filter>
                <filter
                    id="filter1_i_1240_14665"
                    x="11.3823"
                    y="9.16669"
                    width="22"
                    height="24.412"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.0916667" />
                    <feGaussianBlur stdDeviation="0.458333" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1240_14665" />
                </filter>
                <radialGradient
                    id="paint0_radial_1240_14665"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(16.1191 -70.5979) rotate(90) scale(217.461 217.46)"
                >
                    <stop offset="0.32096" stopColor="#F9DEBE" />
                    <stop offset="0.474836" stopColor="#F6D2A8" />
                    <stop offset="0.596903" stopColor="#F6F6FA" />
                </radialGradient>
                <linearGradient
                    id="paint1_linear_1240_14665"
                    x1="22"
                    y1="43.9999"
                    x2="22"
                    y2="-0.00012207"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop />
                    <stop offset="1" stopColor="#666666" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1240_14665"
                    x1="10.8027"
                    y1="5.21906"
                    x2="30.9497"
                    y2="33.2119"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1240_14665"
                    x1="21.9998"
                    y1="3.20831"
                    x2="21.9998"
                    y2="40.9789"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB45B" stopOpacity="0.19" />
                    <stop offset="1" stopColor="#D08123" stopOpacity="0.75" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1240_14665"
                    x1="17.6558"
                    y1="11.8737"
                    x2="26.9029"
                    y2="31.7009"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFC784" />
                    <stop offset="0.795" stopColor="#FF8A00" />
                    <stop offset="1" stopColor="#FF6F00" />
                </linearGradient>
            </defs>
        </svg>
    );
};
