import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconSSO: FC<PropsWithClassName> = ({ className }) => {
    return (
        <svg
            className={className}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.30992 29.0243C3.68475 29.6492 3.33344 30.4969 3.33325 31.3809V35.0009C3.33325 35.4429 3.50885 35.8669 3.82141 36.1794C4.13397 36.492 4.55789 36.6676 4.99992 36.6676H9.99992C10.4419 36.6676 10.8659 36.492 11.1784 36.1794C11.491 35.8669 11.6666 35.4429 11.6666 35.0009V33.3343C11.6666 32.8922 11.8422 32.4683 12.1547 32.1557C12.4673 31.8432 12.8912 31.6676 13.3333 31.6676H14.9999C15.4419 31.6676 15.8659 31.492 16.1784 31.1794C16.491 30.8669 16.6666 30.4429 16.6666 30.0009V28.3343C16.6666 27.8922 16.8422 27.4683 17.1547 27.1557C17.4673 26.8432 17.8912 26.6676 18.3333 26.6676H18.6199C19.5039 26.6674 20.3516 26.3161 20.9766 25.6909L22.3333 24.3343C24.6496 25.1412 27.1713 25.1381 29.4857 24.3255C31.8001 23.5129 33.7703 21.939 35.0738 19.8611C36.3774 17.7833 36.9373 15.3245 36.6618 12.8871C36.3863 10.4498 35.2918 8.178 33.5573 6.44353C31.8228 4.70906 29.5511 3.61456 27.1137 3.33907C24.6763 3.06358 22.2176 3.62341 20.1397 4.92699C18.0619 6.23056 16.4879 8.20071 15.6753 10.5151C14.8628 12.8295 14.8597 15.3512 15.6666 17.6676L4.30992 29.0243Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.5001 13.334C27.9603 13.334 28.3334 12.9609 28.3334 12.5007C28.3334 12.0404 27.9603 11.6673 27.5001 11.6673C27.0398 11.6673 26.6667 12.0404 26.6667 12.5007C26.6667 12.9609 27.0398 13.334 27.5001 13.334Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="3.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
