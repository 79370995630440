import { useFlag } from "@/hooks/use-flag";
import type { FC, PropsWithChildren } from "react";

export const VisibilityControlledByFeatureFlag: FC<{ featureFlag: string } & PropsWithChildren> = ({
    featureFlag,
    children,
}) => {
    const { value } = useFlag(featureFlag, false);
    return value ? <>{children}</> : null;
};
