export enum ProjectsFilterType {
    All = "all",
    Active = "active",
    NotShared = "notShared",
}

export enum ProjectSharingType {
    OnlyMe = "only-me",
    AnyoneInOrg = "anyone-in-org",
}
