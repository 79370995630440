import { NotAuthorized } from "@/components/NotAuthorized";
import { Input } from "@/components/podkit/forms/Input";
import { LoadingState } from "@/components/podkit/loading/LoadingState";
import { toast } from "@/components/podkit/toasts/use-toast";
import { Heading4, Subheading } from "@/components/podkit/typography/Headings";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useTemporaryState } from "@/hooks/use-temporary-value";
import { useCreateOrganizationInvite, useOrganization, useOrganizationInvite } from "@/queries/organization-queries";
import { useMembership } from "@/hooks/use-membership";
import { joinOrganizationURL } from "@/routes/join-organization/join-organization";
import { formatError } from "@/utils/errors";
import { OrganizationRole } from "gitpod-next-api/gitpod/v1/organization_pb";
import { Check, Copy } from "lucide-react";
import { useCallback, useMemo, useState, type FC } from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/flexkit/Button";
import { IconPlus } from "@/assets/icons/geist/IconPlus";
import { Text } from "@/components/podkit/typography/Text";
import { useSegmentTrack } from "@/hooks/use-segment";
import { TagsInput } from "@/components/podkit/forms/TagsInput";

export const InviteMembersPage: FC = () => {
    useDocumentTitle("Invite Members");

    const { data: organization, isLoading: isLoadingOrganization } = useOrganization();
    const { membership, isPending: isLoadingMembership } = useMembership();

    if (!organization && isLoadingOrganization) {
        return <LoadingState />;
    }

    if (!membership && isLoadingMembership) {
        return <LoadingState />;
    }

    if (membership?.userRole !== OrganizationRole.ADMIN) {
        return (
            <NotAuthorized description="You do not have sufficient permissions to invite others to this organization. Contact your organization's admin to grant those permissions." />
        );
    }

    return (
        <div className="flex flex-col gap-6 pt-6">
            <InviteMembers />
            <Text className="text-xs text-content-primary">
                If you would like to invite people by email domain, you can set that up in{" "}
                <Link className="font-bold underline" to="/settings/manage-organization">
                    Manage Organization
                </Link>
                .
            </Text>
        </div>
    );
};

const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const InviteMembers: FC = () => {
    const track = useSegmentTrack();
    const { data: invite } = useOrganizationInvite();
    const { data: organization } = useOrganization();
    const createOrganizationInvite = useCreateOrganizationInvite();
    const [copied, setCopied] = useTemporaryState(false, 2000);
    const [emails, setEmails] = useState<string[]>([]);

    const inviteURL = useMemo(() => {
        return joinOrganizationURL(invite?.inviteId || "");
    }, [invite?.inviteId]);

    const organizationName = organization?.name || "";

    const handleCopyToClipboard = useCallback(() => {
        if (!invite?.inviteId) {
            return;
        }

        void navigator.clipboard.writeText(inviteURL).then(() => {
            setCopied(true);
        });
    }, [setCopied, invite?.inviteId, inviteURL]);

    const handleInviteUsers = useCallback(
        (evt: React.FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            if (emails.length === 0 || !invite) return;

            emails.forEach((email) => {
                track("organization_email_invited", {
                    email: email,
                    invite_link: inviteURL,
                    organization_name: organizationName,
                });
            });

            setEmails([]);
            toast({
                title: `Invitation${emails.length > 1 ? "s" : ""} sent successfully.`,
            });
        },
        [emails, invite, inviteURL, organizationName, track],
    );

    return (
        <div className="space-y-6">
            <div className="space-y-3">
                <div>
                    <Heading4>Invite link</Heading4>
                    <Subheading>Share this link with others you’d like to join your organization.</Subheading>
                </div>

                <div className="gap-6 pt-1">
                    <Input className="pr-12 text-stone-400" readOnly value={inviteURL} />
                </div>

                <div className="inline-flex items-start justify-start gap-3">
                    <Button
                        variant="secondary"
                        onClick={handleCopyToClipboard}
                        LeadingIcon={() => (copied ? <Check size={20} /> : <Copy size={20} />)}
                    >
                        Copy Link
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            createOrganizationInvite.mutate(void 0, {
                                onSuccess: () => {
                                    toast({ title: `Invite link reset` });
                                },
                                onError: (error) => {
                                    toast({ title: `Failed to reset invite link`, description: formatError(error) });
                                },
                            });
                        }}
                    >
                        Reset Invite Link
                    </Button>
                </div>
            </div>
            <div className="space-y-3">
                <div>
                    <Heading4>Add people by email address</Heading4>
                    <Subheading>Invite your team members to your organization</Subheading>
                </div>
                <form onSubmit={handleInviteUsers}>
                    <TagsInput
                        name="invite_emails"
                        value={emails}
                        onChange={setEmails}
                        placeHolder="email@example.com"
                        validate={validateEmail}
                        onValidationFail={(value) => {
                            toast({
                                title: "Invalid email format",
                                description: `"${value}" is not a valid email address`,
                            });
                        }}
                    />
                    <Button variant="secondary" className="mt-3" type="submit" LeadingIcon={IconPlus}>
                        Send invite
                    </Button>
                </form>
            </div>
        </div>
    );
};
