import { ContinueWithExistingRunnerCard } from "@/components/onboarding/ContinueWithExistingRunnerCard";
import { useMembers } from "@/queries/organization-queries";
import type { PlainRunner } from "@/queries/runner-queries";
import { useAuthenticatedUser } from "@/queries/user-queries";
import { type FC } from "react";

export const ContinueWithExistingRunners: FC<{ runners: PlainRunner[] }> = ({ runners }) => {
    const { data: user } = useAuthenticatedUser();
    const { data: membersData } = useMembers();
    const currentMember = membersData?.members.find((m) => m.userId === user?.id);
    return (
        <ul className="flex flex-col gap-2">
            {runners.map((runner) => {
                const creator = membersData?.members.find((m) => m.userId === runner.creator?.id);
                return (
                    <li key={runner.runnerId}>
                        <ContinueWithExistingRunnerCard
                            runner={runner}
                            currentMember={currentMember}
                            creator={creator}
                        />
                    </li>
                );
            })}
        </ul>
    );
};
