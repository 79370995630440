import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/components/podkit/lib/cn";

const alertVariants = cva("relative w-full rounded-lg border p-4 text-base border-border-base", {
    variants: {
        variant: {
            default: "bg-surface-tertiary text-content-primary",
            destructive: "bg-surface-negative",
        },
    },
    defaultVariants: {
        variant: "default",
    },
});

const Alert = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
    <div ref={ref} role="alert" className={cn(alertVariants({ variant }), className)} {...props} />
));
Alert.displayName = "Alert";

export { Alert };
