import type { Size } from "@/assets/icons/geist/Size";
import { type PropsWithClassName } from "@/components/podkit/lib/cn";
import type { FC } from "react";

export const IconNewEnvironment: FC<{ size: Size } & PropsWithClassName> = ({ size, className }) => {
    switch (size) {
        case "sm":
            return (
                <svg
                    className={className}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14 9.2V4C14 2.89543 13.1046 2 12 2H4C2.89543 2 2 2.89543 2 4V11.2C2 12.3046 2.89543 13.2 4 13.2H7.25"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                    />
                    <ellipse cx="4.0625" cy="4.19998" rx="0.5625" ry="0.6" fill="currentColor" />
                    <ellipse cx="5.9375" cy="4.19998" rx="0.5625" ry="0.6" fill="currentColor" />
                    <ellipse cx="7.8125" cy="4.19998" rx="0.5625" ry="0.6" fill="currentColor" />
                    <path d="M2 6.40002H14" stroke="currentColor" strokeWidth="1.2" />
                    <path
                        d="M11 9.19995V11.6M11 11.6V14M11 11.6H8.75M11 11.6H13.25"
                        stroke="currentColor"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "base":
            return (
                <svg
                    className={className}
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 11.5V4.5C18 3.39543 17.1046 2.5 16 2.5H4C2.89543 2.5 2 3.39543 2 4.5V14.5C2 15.6046 2.89543 16.5 4 16.5H9"
                        stroke="currentColor"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                    />
                    <circle cx="4.75" cy="5.25" r="0.75" fill="currentColor" />
                    <circle cx="7.25" cy="5.25" r="0.75" fill="currentColor" />
                    <circle cx="9.75" cy="5.25" r="0.75" fill="currentColor" />
                    <path d="M2 8H18" stroke="currentColor" strokeWidth="1.4" />
                    <path
                        d="M14 11.5V14.5M14 14.5V17.5M14 14.5H11M14 14.5H17"
                        stroke="currentColor"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case "lg":
            return (
                <svg
                    className={className}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21 13.6875V5.5625C21 4.45793 20.1046 3.5625 19 3.5625H5C3.89543 3.5625 3 4.45793 3 5.5625V17.3125C3 18.4171 3.89543 19.3125 5 19.3125H10.875"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                    <circle cx="6.09375" cy="6.65625" r="0.84375" fill="currentColor" />
                    <circle cx="8.90625" cy="6.65625" r="0.84375" fill="currentColor" />
                    <circle cx="11.7188" cy="6.65625" r="0.84375" fill="currentColor" />
                    <path d="M3 9.75H21" stroke="currentColor" strokeWidth="1.5" />
                    <path
                        d="M16.5 13.6875V17.0625M16.5 17.0625V20.4375M16.5 17.0625H13.125M16.5 17.0625H19.875"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
};
