import { Button } from "@/components/flexkit/Button";
import {
    DialogBody,
    DialogClose,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/podkit/modal/Modal";
import {
    EnvironmentClass,
    FieldValue,
    type EnvironmentClassValidationResult,
    type RunnerConfigurationSchema_BoolField,
    type RunnerConfigurationSchema_EnumField,
    type RunnerConfigurationSchema_Field,
    type RunnerConfigurationSchema_IntField,
} from "gitpod-next-api/gitpod/v1/runner_configuration_pb";
import { startTransition, useCallback, useMemo, useState, type FC, type FormEvent } from "react";
import { CheckboxInputField } from "@/components/podkit/checkbox/CheckboxInputField";
import * as SelectPrimitive from "@radix-ui/react-select";
import {
    SelectContentCombobox,
    SelectItemCombobox,
    SelectTrigger,
    SelectValue,
} from "@/components/podkit/select/Select";
import { InputField } from "@/components/podkit/forms/InputField";
import { useCreateEnvironmentClass, useValidateEnvironmentClass } from "@/queries/runner-configuration-queries";
import { useToast } from "@/components/podkit/toasts/use-toast";
import { formatError } from "@/utils/errors";
import { RunnerConfigurationKeys } from "@/components/runners/details/runner-configuration-keys";
import { DisplayForm } from "@/components/runners/details/EnvironmentClassEditModal";
import { Input } from "@/components/podkit/forms/Input";
import { ComboboxProvider } from "@ariakit/react";

type State = { page: "configuration" } | { page: "display"; configuration: FieldValue[] };

export const EnvironmentClassAddModal: FC<{
    runnerId: string;
    schema: RunnerConfigurationSchema_Field[];
    onClose: () => void;
}> = ({ runnerId, schema, onClose }) => {
    const { toast } = useToast();

    const validateEnvironmentClass = useValidateEnvironmentClass();
    const createEnvironmentClass = useCreateEnvironmentClass(runnerId);
    const isLoading = validateEnvironmentClass.isPending || createEnvironmentClass.isPending;

    const [validationErrors, setValidationErrors] = useState<EnvironmentClassValidationResult | undefined>();
    const [state, setState] = useState<State>({ page: "configuration" });

    const handleConfigurationSubmit = useCallback(
        async (configuration: FieldValue[]) => {
            const validationResult = await validateEnvironmentClass.mutateAsync(
                new EnvironmentClass({
                    runnerId,
                    displayName: defaultDisplayValue(configuration),
                    description: defaultDescription(configuration),
                    configuration,
                }),
            );
            setValidationErrors(validationResult);
            if (!validationResult?.valid) {
                return;
            }
            setState({ page: "display", configuration });
        },
        [runnerId, validateEnvironmentClass, setValidationErrors],
    );

    const handleSave = useCallback(
        async (values: { displayName: string; description: string; configuration: FieldValue[] }) => {
            const validationResult = await validateEnvironmentClass.mutateAsync(
                new EnvironmentClass({
                    runnerId,
                    displayName: values.displayName,
                    description: values.description,
                    configuration: values.configuration,
                }),
            );
            setValidationErrors(validationResult);
            if (!validationResult?.valid) {
                return;
            }

            try {
                await createEnvironmentClass.mutateAsync({
                    configuration: values.configuration,
                    description: values.description,
                    displayName: values.displayName,
                });
                onClose();
                toast({
                    title: "Created environment class",
                    description: "The environment class has been successfully created.",
                });
            } catch (e) {
                toast({
                    title: "Failed to create environment class",
                    description: formatError(e),
                });
            }
        },
        [runnerId, onClose, toast, validateEnvironmentClass, setValidationErrors, createEnvironmentClass],
    );

    return (
        <>
            <DialogHeader>
                <DialogTitle>Add environment class</DialogTitle>
                <DialogDescription />
            </DialogHeader>

            <DialogBody>
                {state.page === "configuration" && (
                    <ConfigurationForm
                        schema={schema}
                        validationErrors={validationErrors}
                        onSubmit={handleConfigurationSubmit}
                    />
                )}
                {state.page === "display" && (
                    <DisplayForm
                        formId="environment-class-add"
                        schema={schema}
                        configuration={state.configuration}
                        initialValues={{
                            displayName: defaultDisplayValue(state.configuration),
                            description: defaultDescription(state.configuration),
                        }}
                        validationErrors={validationErrors}
                        onSubmit={handleSave}
                    />
                )}
            </DialogBody>

            <DialogFooter>
                <DialogClose asChild>
                    <Button type="button" variant="secondary" onClick={onClose} disabled={isLoading}>
                        Cancel
                    </Button>
                </DialogClose>
                <Button
                    autoFocus={false}
                    variant="brand"
                    type="submit"
                    form="environment-class-add"
                    loading={isLoading}
                >
                    {state.page === "configuration" ? "Next" : "Create"}
                </Button>
            </DialogFooter>
        </>
    );
};

const ConfigurationForm: FC<{
    schema: RunnerConfigurationSchema_Field[];
    validationErrors: EnvironmentClassValidationResult | undefined;
    onSubmit: (cfg: FieldValue[]) => void;
}> = ({ schema, validationErrors, onSubmit }) => {
    const [values, setValues] = useState<Record<string, FieldValue>>(
        Object.fromEntries(
            schema.map((field) => {
                const v = new FieldValue({
                    key: field.id,
                    value: encodeDefaultFieldValue(field),
                });
                return [field.id, v];
            }),
        ),
    );

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit(Object.values(values));
        },
        [onSubmit, values],
    );

    return (
        <form
            onSubmit={handleSubmit}
            id="environment-class-add"
            name="environment-class-add"
            className="flex flex-col gap-4"
        >
            {schema.map((field) => {
                const validationError = validationErrors?.configurationErrors.find((e) => e.key === field.id)?.error;
                switch (field.type.case) {
                    case "enum": {
                        return (
                            <SchemaInputFieldEnum
                                key={field.id}
                                field={field}
                                fieldType={field.type.value}
                                validationError={validationError}
                                onChange={(value) =>
                                    setValues((old) => ({
                                        ...old,
                                        [field.id]: new FieldValue({ key: field.id, value }),
                                    }))
                                }
                            />
                        );
                    }
                    case "int": {
                        return (
                            <SchemaInputFieldInt
                                key={field.id}
                                field={field}
                                fieldType={field.type.value}
                                validationError={validationError}
                                onChange={(value) =>
                                    setValues((old) => ({
                                        ...old,
                                        [field.id]: new FieldValue({
                                            key: field.id,
                                            value: encodeFieldValueInt(value),
                                        }),
                                    }))
                                }
                            />
                        );
                    }
                    case "bool": {
                        return (
                            <SchemaInputFieldBool
                                key={field.id}
                                field={field}
                                fieldType={field.type.value}
                                validationError={validationError}
                                onChange={(value) =>
                                    setValues((old) => ({
                                        ...old,
                                        [field.id]: new FieldValue({
                                            key: field.id,
                                            value: encodeFieldValueBool(value),
                                        }),
                                    }))
                                }
                            />
                        );
                    }
                }
            })}
        </form>
    );
};

const SchemaInputFieldEnum: FC<{
    field: RunnerConfigurationSchema_Field;
    fieldType: RunnerConfigurationSchema_EnumField;
    validationError?: string;
    onChange: (value: string) => void;
}> = ({ field, fieldType, validationError, onChange }) => {
    const [selection, setSelection] = useState(fieldType.default !== "" ? fieldType.default : undefined);

    const updateValue = useCallback(
        (value: string) => {
            setSelection(value);
            onChange(value);
        },
        [setSelection, onChange],
    );
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const matches = useMemo(() => {
        if (!searchValue) return fieldType.values.slice(0, 10);

        const words = searchValue.split(" ");
        const matches = fieldType.values
            .filter((v) => {
                const label = v.toLowerCase();
                return words.reduce((acc, word) => acc && label.includes(word), true);
            })
            .slice(0, 200);

        // Radix Select does not work if we don't render the selected item, so we
        // make sure to include it in the list of matches.
        if (selection && !matches.includes(selection)) {
            matches.push(selection);
        }

        return matches;
    }, [fieldType, selection, searchValue]);

    return (
        <InputField
            id={field.id}
            label={field.name}
            error={validationError}
            // Positioning the hidden select so the native "required" validation dialog is placed correctly
            className="[&_select]:left-72 [&_select]:top-32"
        >
            <SelectPrimitive.Root
                name={field.id}
                value={selection}
                onValueChange={updateValue}
                open={open}
                onOpenChange={setOpen}
                required={field.required}
            >
                <ComboboxProvider
                    open={open}
                    setOpen={setOpen}
                    resetValueOnHide
                    includesBaseElement={false}
                    setValue={(value) => {
                        startTransition(() => {
                            setSearchValue(value.toLowerCase());
                        });
                    }}
                >
                    <SelectTrigger loading={false}>
                        <SelectValue placeholder={field.description}>{selection || ""}</SelectValue>
                    </SelectTrigger>
                    <SelectContentCombobox inputPlaceholder="Type to search...">
                        <span className="p-2 text-sm text-content-tertiary">
                            Showing {matches.length} of {fieldType.values.length}
                        </span>
                        {matches.map((value) => {
                            return (
                                <SelectItemCombobox key={value} value={value} asChild>
                                    <span className="text-base">{value}</span>
                                </SelectItemCombobox>
                            );
                        })}
                    </SelectContentCombobox>
                </ComboboxProvider>
            </SelectPrimitive.Root>
        </InputField>
    );
};

const SchemaInputFieldInt: FC<{
    field: RunnerConfigurationSchema_Field;
    fieldType: RunnerConfigurationSchema_IntField;
    validationError?: string;
    onChange: (value: number) => void;
}> = ({ field, fieldType, validationError, onChange }) => {
    const [value, setValue] = useState(fieldType.default);

    const updateValue = useCallback(
        (value: number) => {
            setValue(value);
            onChange(value);
        },
        [setValue, onChange],
    );

    return (
        <InputField
            label={field.name}
            hint={field.description}
            id={field.name}
            error={validationError}
            disabled={false}
        >
            <Input
                id={field.name}
                name={field.name}
                required={field.required}
                min={fieldType.min}
                max={fieldType.max}
                value={value}
                type="number"
                onChange={(event) => updateValue(Number.parseInt(event.target.value))}
            />
        </InputField>
    );
};

const SchemaInputFieldBool: FC<{
    field: RunnerConfigurationSchema_Field;
    fieldType: RunnerConfigurationSchema_BoolField;
    validationError?: string;
    onChange: (value: boolean) => void;
}> = ({ field, fieldType, validationError, onChange }) => {
    const [checked, setChecked] = useState(fieldType.default);

    const updateValue = useCallback(
        (value: boolean) => {
            setChecked(value);
            onChange(value);
        },
        [setChecked, onChange],
    );

    return (
        <CheckboxInputField
            label={field.name}
            hint={field.description}
            id={field.name}
            error={validationError}
            checked={checked}
            disabled={false}
            onChange={updateValue}
        />
    );
};

function defaultDisplayValue(configuration: FieldValue[]): string {
    const instanceType = configuration.find((field) => field.key === RunnerConfigurationKeys.AWSInstanceType);
    return instanceType ? instanceType.value : "";
}

function defaultDescription(configuration: FieldValue[]): string {
    const instanceType = configuration.find((field) => field.key === RunnerConfigurationKeys.AWSInstanceType);
    const diskSpace = configuration.find((field) => field.key === RunnerConfigurationKeys.DiskSizeGB);
    const isSpot = configuration.find((field) => field.key === RunnerConfigurationKeys.SpotInstanceEnabled);

    const strs = [
        instanceType ? instanceType.value : null,
        diskSpace ? `${diskSpace.value.toUpperCase()} GiB Disk` : null,
        isSpot?.value === "true" ? "Spot Instance" : null,
    ];

    return strs.filter((v) => v !== null).join(" · ");
}

/**
 * The {@link FieldValue} represents all values as strings, so values from {@link RunnerConfigurationSchema_BoolField} fields
 * need to have a stable string representation.
 */
function encodeFieldValueBool(value: boolean): string {
    return value ? "true" : "false";
}

function encodeFieldValueInt(value: number): string {
    return value.toFixed(0);
}

function encodeDefaultFieldValue(field: RunnerConfigurationSchema_Field): string {
    switch (field.type.case) {
        case "display":
        case "string":
        case "enum":
            return field.type.value.default;
        case "int":
            return field.type.value.default.toString();
        case "bool":
            return encodeFieldValueBool(field.type.value.default);
        default:
            return "";
    }
}
