import { useDocumentTitle } from "@/hooks/use-document-title";
import { type FC } from "react";
import { Heading2 } from "@/components/podkit/typography/Headings";
import { Text } from "@/components/podkit/typography/Text";
import { OnboardingTabContent } from "@/components/onboarding/OnboardingTabContent";
import { VideoSection } from "@/components/onboarding/VideoSection";
import { Button } from "@/components/flexkit/Button";
import howGitpodWorksImg from "@/assets/how-gitpod-works-thumbnail.jpg";
import { TrackLocations } from "@/hooks/use-segment";
import { Link } from "react-router-dom";
import { OnboardingStepId, routeForStep, useOnboarding } from "@/hooks/use-onboarding";

type ContentSection = {
    title: string;
    description: string;
};

const contentSections: ContentSection[] = [
    {
        title: "Runners",
        description:
            "The infrastructure that runs your dev environments. Self-host in your cloud in minutes, or run Gitpod Desktop locally on your Mac.",
    },
    {
        title: "Dev container",
        description:
            "Your environment configurations, including all the tools, dependencies and access required for development.",
    },
    {
        title: "Automations",
        description:
            "Reusable self-serve actions for automating common development workflows like seeding databases, provisioning infra, and configuring code assistants.",
    },
    {
        title: "Development environments",
        description:
            "Use your favorite editor or CLI to edit, compile, and run code in your automated, standardized development environment.",
    },
];

const ContentSection: FC<ContentSection> = ({ title, description }) => (
    <div className="flex flex-col items-start justify-start self-stretch">
        <Heading2 className="flex items-center gap-1 self-stretch text-base font-medium text-content-primary">
            {title}
        </Heading2>
        <Text className="text-base text-content-secondary">{description}</Text>
    </div>
);

export const HowGitpodWorksPage: FC = () => {
    useDocumentTitle("Onboarding - How Gitpod Works");
    const onboarding = useOnboarding();
    return (
        <OnboardingTabContent
            title="Learn the ins and outs of using Gitpod for your development environments"
            data-track-location={TrackLocations.OnboardingHowGitpodWorksTab}
        >
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-8 lg:flex-row lg:items-center">
                    <div className="flex basis-1/2 justify-center">
                        <VideoSection
                            playbackId="59ryp68i29e00y7Acv02xQU01OAQIPH8px65ij9wQS8zK4"
                            metadataVideoTitle="How Gitpod Flex works (Flex)"
                            poster={howGitpodWorksImg}
                        />
                    </div>
                    <div className="flex basis-1/2 flex-col items-start justify-start gap-6">
                        {contentSections.map((section, index) => (
                            <ContentSection key={index} {...section} />
                        ))}
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button variant="brand" size="md" asChild>
                        <Link
                            to={routeForStep(OnboardingStepId.SetUpARunner)}
                            onClick={() => onboarding.complete(OnboardingStepId.HowGitpodWorks)}
                        >
                            Setup a runner
                        </Link>
                    </Button>
                </div>
            </div>
        </OnboardingTabContent>
    );
};
